import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from '../api-service.service';

@Component({ 
  selector: 'app-inward-outwad-popup-type',
  templateUrl: './inward-outwad-popup-type.component.html',
  styleUrls: ['./inward-outwad-popup-type.component.scss']
})
export class InwardOutwadPopupTypeComponent implements OnInit {
  popupInwardOutwardTypeForm: FormGroup;
  listOfInoutType:any;
  totalLength:any;
  searchText:any;
  page = 1;
  size =3;
  sid:any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<InwardOutwadPopupTypeComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,

  ) { InwardOutwadPopupTypeComponent}

  ngOnInit(): void {
    this.page = 1;
    this.popupInwardOutwardTypeForm = this.fb.group({  
      "inoutType": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "createdAt": new Date(),
      "searchinoutType": new FormControl(''),
    });
    this.fetchTypeList();
  }

  fetchTypeList() {
    let pg = 1;
    let size = 3;
    let search=''; 
        this.apimicroservice.getInoutTypeData(pg,size,search).subscribe(res => {    
           this.listOfInoutType = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });   
 
  }
  saveInoutType(){
    let payload = this.popupInwardOutwardTypeForm.value; 
    //
    this.spinner.show();
    this.apimicroservice.saveInoutTypeData(payload).subscribe(res => {
      // 
      this.spinner.hide();
      if(res.status){ 
        this.apimicroservice.openSnackbar(res.message);      
        //this.ngOnInit();
       this.onClose();        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
      this.onClose();    
  });
  }
  save() {
    if (this.popupInwardOutwardTypeForm.invalid) {
      this.popupInwardOutwardTypeForm.get("inoutType").markAsTouched();
      return;
    } else {
      this.saveInoutType();
    }
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.popupInwardOutwardTypeForm.get('searchinoutType').value;
    this.apimicroservice.getInoutTypeData(this.page,this.size,this.searchText).subscribe(res => {
      this.listOfInoutType = res.data.data;
      this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  prepopulation(id) {
    this.apimicroservice.getInoutTypeDataById(id).subscribe(res => {
      let data = res.data[0];
      this.sid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupInwardOutwardTypeForm = this.fb.group({
          "inoutType" :new FormControl(data.inoutType),
          "updateddAt": new Date(),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  } 
  updateInoutType() {
    let payload = this.popupInwardOutwardTypeForm.value;
     this.apimicroservice.updateInoutTypeData(payload,this.sid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.popupInwardOutwardTypeForm.get('searchinoutType').value;
 
    this.apimicroservice.getInoutTypeData(this.page,this.size,this.searchText).subscribe(res => {
      //
      
      this.listOfInoutType = res.data.data;
      this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
  
  }
  cancelUpdate(){
    this.popupInwardOutwardTypeForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
  onClose(): void {
    this.dialogRef.close();
  }

}
