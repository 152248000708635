import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
import { HttpErrorResponse } from "@angular/common/http";
import { Chart } from 'chart.js';
declare var google: any;
declare var google;
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as e from 'express';
// declare var google: google;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  userType: string;
  pieNotStarted: any;
  pieInProgress: any;
  pieCompleted: any;
  isFromToVisible=false;
  totalLength: any;
  paymentStatusArr=[];
  followUpStatusArr=[];
  purchaseArr=[];
  purchaseLabels=[];
  InquiryArr=[];
  stockInHandArr=[];
  HaBusinessArr=[];
  lineChartLabelsInquiry=[];
  pieChartDataPaymentMode=[];
  stockInHandLabels=[];
  haBusinessLabels=[];
  districtLabels=[];
  districtData=[];
  serviceRateTest:ChartDataSets[]; 
  serviceRateTestLabels=[];
  paymentMode=[];
  clinicExpensesArr=[];
  serviceRateArr=[];
  labelsClinicExpenses=[];
  salesArr=[];
  inquryToPatientArr=[];
  salesLabels=[];
inquiryToPatientLabels=[];
  // districtLabels=[];
  arrServiceRateTest=[];
  dataArr=[];
  docterArr=[];
  docterLabelsInquiry=[];
  scenario1data=[];
  Jan = 'Jan';
  Feb = 'Feb';
  Mar = 'Mar';
  Apr = 'Apr';
  May = 'May';
  June = 'June';
  July = 'July';
  Aug = 'Aug';
  Sept = 'Sept';
  Oct = 'Oct';
  Nov = 'Nov';
  Dec = 'Dec';
  //////
  day1 = 'day1';
  day2 = 'day2';
  day3 = 'day3';
  day4 = 'day4';
  day5 = 'day5';
  day6 = 'day6';
  day7 = 'day7';
  day8 = 'day8';
  day9 = 'day9';
  day10 = 'day10';
  day11 = 'day11';
  day12 = 'day12';
  day13 = 'day13';
  day14 = 'day14';
  day15 = 'day15';

  isSuperAdmin= true;
  todaysAppointment: any;
  totalAppointmentCancel: any;
  totalAppointmentCompleted: any;
  totalAppointmentReschedule: any;
  totalASP: any;
  toDate: Date;
  docterArryData=[]; 
  clinicExpenseData=[]; 
  payReaceiveLabels = [];
  payReceiveArr: any;
  currentdate: Date;
  laterDate: Date;
  scenarioData2: any[];
  scenario1 = false;
  scenario2 = false;
  scenario1dataLabel: string[];
  scenario2dataLabel: string[];
  typeOfSelect: any;
  branchRef: string;
  updateButton: boolean;
  BranchList: any;
  filteredListBranch: any;
  selectType1: any;
  branchData: any;
  greet: string;
  branchData2=[
    {branchName:''},
      ]
  userName: string;
  constructor(
    private fb: FormBuilder,
    public apimicroservice: ApiServiceService, 
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) { }
  dashboardForm: FormGroup;
  userPermission:any;
  chart: any;
  dataList = [{
    "productionDevices": ["Uid","", "Model Id", "IMEI No.", "Iccid"],
    "dispatchedDevices": ["UIN No.", "IMEI No.", "ICCID No.", "Plant Name",],
    "ais140": ["Uid", "Model Id", "IMEI No.", "Iccid"],
    "installedDevices": ["UIN No.", "IMEI No.", "VIN No.", "Status",],
    "devicesNotActiveFrom": ["UIN No.", "IMEI No.", "VIN No.", "Status",],
    "newFirmwareStatus": ["UIN No.", "IMEI No.", "VIN No.", "Status",],
    "firmwareWisedetails": ["UIN No.", "IMEI No.", "VIN No.", "Status",],
  }
  ]
  // dataList3 = [{
  //   "productionDevices": 2,
  //   "dispatchedDevices": 4, 
  // },
  // {
  //   "productionDevices": 6,
  //   "dispatchedDevices": 7, 
  // }

  // ]
  dataList2 = [{
     "countData": {
        "dataPush": [
            {
                "Year": 2022,
                "TotalPayment": 6,
                "PaymentStatusPaid": 4,
                "PaymentStatusUnpaid": 2
            }
        ]
    }
  }
]
  allBrand=[
'10','20','30','40'
  ]
 //District label
//  public districtLabels = ['Maharastra', 'Goa', 'Karnataka', 'Gujarat'];
//  public districtData = [120, 30, 180, 90];
  //piechart
  pieChartColor:any = [
    {
        backgroundColor: ['rgba(30, 169, 224, 0.8)',
        'rgba(255,165,0,0.9)',
        'rgba(139, 136, 136, 0.9)',
        'rgba(255, 161, 181, 0.9)',
        'rgba(255, 102, 0, 0.9)'
        ]
    }
]
pieChartOptions = {
  responsive: true
}
pieChartLabels =  ['Total HA Trial','Not-Converted','Business Converted'];
    // pieChartData:any = [
    //     { 
    //         data: []
    //     }
    // ];
  // public pieChartLabels = ['Sales Q1', 'Sales Q2', 'Sales Q3', 'Sales Q4'];
  public pieChartLabelsDevice = ['Device BA', 'Device A', 'Device B'];
  public pieChartLabelsCheque = ['Bounce','Clear', 'Deposited','Postdated','Returned','Cancelled','Received'];
  public pieChartLabelsPaymentMode = ['Cash', 'Card','Cheque','NEFT','UPI'];
  public pieChartLabelsReceiverPayable = ['Receiver', 'Payable'];
 public pieChartData = [120, 150, 180, 90];
  public pieChartDataCheque = [];
  public pieChartDataFollowUp = []; 
  public pieChartLabelsFollowUp = ['Follow Up Done','Follow Up Scheduled','Not Answered','Rescheduled Follow Up', ];
 
  // public pieChartDataPaymentMode = [40, 60,100];
  public pieChartDataDevice = [120, 150, 180];
  public pieChartLabelsBill = ['Paid', 'Unpaid'];
  public pieChartDataBill = [40, 80];
  public pieChartType = 'bar'; 
    public pieChartTypeNew = 'pie';
  public pieChartType1 = 'pie';
  public chartColors: any[] = [
    { 
      backgroundColor:["#36a2eb","#ffa1b5","#B5D397","#fdb658","#C8CDF0", "#5dc1e9", "#b7f3eb", "#7fe79b", "#efc77e"] 
    }];
   //Barchart
  public barChartOptions = {
    scaleShowVerticalLines: false,
    // responsive: true,
    // showTooltips: true,
  };
  public barChartLabels = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartLabelsSales = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  public barChartLabelsRepairActivity = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7', 'Step 8', 'Step 9', 'Step 10'];
  public barChartType = 'bar';
  public barChartType2 = 'line';
  public barChartLegend = false;
  public barCharteHorizontal = 'horizontalBar';
  public barChartData = [
    {data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A'},
    {data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B'}
  ];
  public barChartDataSales = [
    {data: [65, 59, 80, 81, 56, 55, 40], label: 'Total Sales'},
  ];
  public barChartDataPurchase = [
    {data: [100, 20, 50, 60, 10, 30, 90],  backgroundColor: [
      'rgb(54, 162, 235)','rgb(54, 162, 235)','rgb(54, 162, 235)','rgb(54, 162, 235)','rgb(54, 162, 235)','rgb(54, 162, 235)',
      'rgb(54, 162, 235)','rgb(54, 162, 235)','rgb(54, 162, 235)','rgb(54, 162, 235)','rgb(54, 162, 235)','rgb(54, 162, 235)',
      // 'rgb(255, 205, 86)'
    ], label: 'Total Purchase'},
  ];
  public barChartDataRepairActivity = [
    {data: [100, 20, 50, 60, 10, 30, 90,60,110,70], backgroundColor: [
      'rgb(255, 226, 154)','rgb(255, 226, 154)','rgb(255, 226, 154)','rgb(255, 226, 154)','rgb(255, 226, 154)'
      ,'rgb(255, 226, 154)','rgb(255, 226, 154)','rgb(255, 226, 154)','rgb(255, 226, 154)','rgb(255, 226, 154)'
      // 'rgb(255, 205, 86)'
    ],  label: 'Repair Activity'},
  ];
  //line
  lineChartOptions: ChartOptions = {
    responsive: true
  };
  lineChartLegend = true;
  lineChartType = 'line';
  lineChartPlugins = [];
  lineChartData: ChartDataSets[] = [
    { data: [20000, 40000, 30000, 10000, 80000, 20000, 100000], label: 'PPM',lineTension:0, fill: false},
    // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product B' }
  ];
  lineChartDataApptByDoctor: ChartDataSets[] = [
    { data: [3, 6, 1, 10, 2, 7, 3], label: 'Docter A',lineTension:0, fill: false},
    { data: [9, 10, 4, 5, 10, 12, 4], label: 'Docter B',lineTension:0, fill: false},
    // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product B' }
  ];
  lineChartDataServiceTypeTestType: ChartDataSets[] = [
    { data: [500, 1000, 200, 700, 300], label: 'Service 1',lineTension:0, fill: false},
    { data: [700, 1200, 100, 400, 500], label: 'Service 2',lineTension:0, fill: false},
  ];
  lineChartDataInquiry: ChartDataSets[] = [
    { data: [20, 80, 180, 50, 350], label: 'In-Person',lineTension:0, fill: false},
    { data: [220, 120, 30, 90, 200], label: 'Phone',lineTension:0, fill: false},
  ];
  lineChartDataClinicExpenses: ChartDataSets[] = [
    { data: [200, 400, 50, 80, 90], label: 'Stationery',lineTension:0, fill: false},
    { data: [5000, 2000, 1500, 4500, 900], label: 'Medicine',lineTension:0, fill: false},
    { data: [220, 450, 700, 400, 1100], label: 'Electricity',lineTension:0, fill: false},
  ];
  lineChartLabelsServiceTypeTestType: Label[] = ['Test 1', 'Test 2', 'Test 3', 'Test 4', 'Test 5'];
  lineChartLabelsApptByDoctor: Label[] = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
  // lineChartLabelsInquiry: Label[] = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
  lineChartLabelsClinicExpenses: Label[] = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
  lineChartLabels: Label[] = ['Week 1-Dec', 'Week 2-Dec', 'Week 3-Dec', 'Week 4-Dec', 'Week 1-Jan', 'Week 2-Jan', 'Week 3-Jan'];
  lineChartColors: Color[] = [
    { // dark blue
      // backgroundColor: '#6cb5e9',
       borderColor: '#0070c0',
       pointStyle:'rectRot',
       pointRadius:4,
       borderJoinStyle:'round'
     },
    { // blue
     // backgroundColor: '#7bc9e5',
      borderColor: '#00b0f0',
      pointStyle:'rectRot',
      pointRadius:4,
      borderJoinStyle:'round'
    },
    {
      // red
   //  backgroundColor: '#f95353',
     borderColor: '#ffa1b5',
     pointStyle:'rectRot',
     pointRadius:4,
     borderJoinStyle:'round'
   },
  ];
  chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //
  }
  chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //
  }
  ngOnInit() {
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.branchRef = sessionStorage.getItem('branchRef');
    this.userName = sessionStorage.getItem('userName');
    this.toDate = new Date();
    this.currentdate = new Date();
  //   if(this.userType !== 'Super Admin'){
  //   this.userPermission.forEach((element) => {
  //     if(element.pageName == 'Role' && element.create == false)
  //     {
  //         this.roleCreate = false;  
  //     }
  //     if(element.pageName == 'Role' && element.update == false)
  //     {
  //         this.roleUpdate = false;  
  //     }  
  //   });
  // }
    this.dashboardForm = this.fb.group({
      "searchdashboard": new FormControl(''),
      "selectType": new FormControl(''),
      "formDate": new FormControl(''),
      "toDate": new FormControl(''),
      "repairActivity": new FormControl(''),
      "branchRefId": new FormControl(''),
      
    }); 
    this.selectType('Monthly'); 
    this.dashboardForm.controls["selectType"].setValue('Monthly');
    this.dashboardForm.controls["formDate"].setValue('');
    this.dashboardForm.controls["toDate"].setValue('');
    this.dashboardForm.controls["repairActivity"].setValue('scenario1');
    this.dashboardForm.controls["branchRefId"].setValue('');
    
    //this.getASPPerMonth();
   // this.getASPPerYear();
    this.RepairActivity('scenario1');
    this.fetchBranchList();
    if(this.userType !== 'Super Admin')
    {
    this.getBranchName(this.branchRef);
    }
    else
    {
      this.branchData = this.branchData2;
    }
    this.greetMessage();
    this.getDistrictData();
  }
  getDistrictData()
  {
    this.apimicroservice.getPatientCountByMonth().subscribe(res => {
      if (res.status) {   
        if(res.countData.dataPush.length > 0){
        res.countData.dataPush.forEach(element => {
          //
          this.districtLabels.push(element.district);
          this.districtData.push(element.TotalPatient);
         // this.paymentStatusArr =[element.PaymentStatusPaid,element.PaymentStatusUnpaid];
        });
      }
        else
        {
          //this.paymentStatusArr =[0,0];
        }
        //
        //
      } 
    }); 
  }
  greetMessage(){
    let myDate = new Date();
    let hrs = myDate.getHours();
    if (hrs < 12)
        this.greet = 'Good Morning';
    else if (hrs >= 12 && hrs <= 17)
        this.greet = 'Good Afternoon';
    else if (hrs >= 17 && hrs <= 24)
        this.greet = 'Good Evening';
  }
  getBranchName(id) {
    this.apimicroservice.getBranchById(id).subscribe(res => {
      //
     this.branchData = res.data[0];
     });
  }
  refres()
  {
    this.ngOnInit();
  }
  refresh()
  {
    this.ngOnInit(); 
  }
  selectBranch(){
    let payload = this.dashboardForm.value; 
    this.selectType1 = payload.selectType;
  }
  selectType(e)
  {
    //
    //
    let branchRef;
    let payload = this.dashboardForm.value; 
    if(payload.branchRefId == '' || payload.branchRefId == null)
    {
      branchRef='';
    }
    else
    {
      branchRef=payload.branchRefId;
    }
    this.RepairActivity(payload.repairActivity);
     if(e == 'Custom')
     {
       this.isFromToVisible = true;
      this.typeOfSelect = 'Custom';
     }
     else if(e == 'Monthly')
     {
      this.dashboardForm.controls["toDate"].setValue('');
      this.dashboardForm.controls["formDate"].setValue('');

      this.typeOfSelect = 'Monthly';
      this.isFromToVisible = false;
     // this.getASPPerMonth();
      //
    

     //
     //ASP per Month
     if(this.userType !== 'Super Admin'){
      this.apimicroservice.getASPPerMonth(this.branchRef).subscribe(res => {
        if (res.status) {   
            this.totalASP = res.totalASP;
        }  
      },(ERROR:HttpErrorResponse) => {
        this.totalASP = 0;
    });
    }
    else{
      this.apimicroservice.getASPPerMonth(branchRef).subscribe(res => {
        if (res.status) {   
            this.totalASP = res.totalASP;
        }  
      },(ERROR:HttpErrorResponse) => {
        this.totalASP = 0;
    });
    }
      //Total Bill
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPaymentStatusCountByMonth(this.branchRef).subscribe(res => {
        if (res.status) {   
          if(res.countData.dataPush.length > 0){
          res.countData.dataPush.forEach(element => {
            //
            this.paymentStatusArr =[element.PaymentStatusPaid,element.PaymentStatusUnpaid];
          });
        }
          else
          {
            this.paymentStatusArr =[0,0];
          }
        } 
      }); 
      }
      else
      {
        this.apimicroservice.getPaymentStatusCountByMonth(branchRef).subscribe(res => {
          if (res.status) {   
            if(res.countData.dataPush.length > 0){
            res.countData.dataPush.forEach(element => {
              //
              this.paymentStatusArr =[element.PaymentStatusPaid,element.PaymentStatusUnpaid];
            });
          }
            else
            {
              this.paymentStatusArr =[0,0];
            }
          } 
        });
      }

      //FollowUp 
      if (this.userType !== 'Super Admin') {
        this.apimicroservice.getFollowupStatusCountByMonth(this.branchRef).subscribe(res => {
          if (res.status) {   
            if(res.countData.dataPush.length > 0){
            res.countData.dataPush.forEach(element => {
              //
              this.followUpStatusArr =[element.TotalFollowUpDone,element.TotalFollowUpSchedule,element.TotalNotAnsweredFollowUp,element.TotalRescheduledFollowUp];
            });
          }
            else
            {
              this.followUpStatusArr =[0,0,0,0];
            }
          } 
        }); 
        }
        else
        {
          this.apimicroservice.getFollowupStatusCountByMonth(branchRef).subscribe(res => {
            //
            
            if (res.status) {   
              if(res.countData.dataPush.length > 0){
              res.countData.dataPush.forEach(element => {
                //
                this.followUpStatusArr =[element.TotalFollowUpDone,element.TotalFollowUpSchedule,element.TotalNotAnsweredFollowUp,element.TotalRescheduledFollowUp];
              });
            }
              else
              {
                this.followUpStatusArr =[0,0,0,0];
              }
            } 
          });
        }
      //Inqury
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getInquiryCountByMonth(this.branchRef).subscribe(res => {
        if (res.status) {   
          //this.InquiryArr =[res.countData.totalInquieryByInPerson,res.countData.totalInquieryByPhone];
          let JanCount = 0;
          let FebCount = 0;
          let MarCount = 0;
          let AprCount = 0;
          let MayCount = 0;
          let JuneCount = 0;
          let JulyCount = 0;
          let AugCount = 0;
          let SeptCount = 0;
          let OctCount = 0;
          let NovCount = 0;
          let DecCount = 0;

          let JanCount2 = 0;
          let FebCount2 = 0;
          let MarCount2 = 0;
          let AprCount2 = 0;
          let MayCount2 = 0;
          let JuneCount2 = 0;
          let JulyCount2 = 0;
          let AugCount2 = 0;
          let SeptCount2 = 0;
          let OctCount2 = 0;
          let NovCount2 = 0;
          let DecCount2= 0;
        //  this.InquiryArr = [];
             this.InquiryArr = [
         { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'In-Person' },
         { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Phone' },
      ];
          if(res.countData.dataPush.length > 0){
            //
            res.countData.dataPush.forEach(element => {
              if(element.month == 1)
                  {
                    JanCount = element.TotalInquiryByInPerson;
                    JanCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 2)
                  {
                    FebCount = element.TotalInquiryByInPerson;
                    FebCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 3)
                  {
                    MarCount = element.TotalInquiryByInPerson;
                    MarCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 4)
                  {
                    AprCount = element.TotalInquiryByInPerson;
                    AprCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 5)
                  {
                    MayCount = element.TotalInquiryByInPerson;
                    MayCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 6)
                  {
                    JuneCount = element.TotalInquiryByInPerson;
                    JuneCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 7)
                  {
                    JulyCount = element.TotalInquiryByInPerson;
                    JulyCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 8)
                  {
                    AugCount = element.TotalInquiryByInPerson;
                    AugCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 9)
                  {
                    SeptCount = element.TotalInquiryByInPerson;
                    SeptCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 10)
                  {
                    OctCount = element.TotalInquiryByInPerson;
                    OctCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 11)
                  {
                    NovCount = element.TotalInquiryByInPerson;
                    NovCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 12)
                  {
                    DecCount = element.TotalInquiryByInPerson;
                    DecCount2 = element.TotalInquiryByPhone;
                  }
              
            });
            this.InquiryArr = [
              { data: [JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount], label: 'In-Person',lineTension:0, fill: false},
              { data: [JanCount2,FebCount2,MarCount2,AprCount2,MayCount2,JuneCount2,JulyCount2,AugCount2,SeptCount2,OctCount2,NovCount2,DecCount2], label: 'Phone',lineTension:0, fill: false},
              // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product B' }
            ];
           // this.purchaseArr = [JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount];
            //
            this.lineChartLabelsInquiry = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
          }
          else
          {
            this.InquiryArr = [
               { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'In-Person' },
               { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Phone' },
            ];
            this.lineChartLabelsInquiry = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
          }
        } 
      });
    }
    else{
      this.apimicroservice.getInquiryCountByMonth(branchRef).subscribe(res => {
        if (res.status) {   
          //this.InquiryArr =[res.countData.totalInquieryByInPerson,res.countData.totalInquieryByPhone];
          let JanCount = 0;
          let FebCount = 0;
          let MarCount = 0;
          let AprCount = 0;
          let MayCount = 0;
          let JuneCount = 0;
          let JulyCount = 0;
          let AugCount = 0;
          let SeptCount = 0;
          let OctCount = 0;
          let NovCount = 0;
          let DecCount = 0;

          let JanCount2 = 0;
          let FebCount2 = 0;
          let MarCount2 = 0;
          let AprCount2 = 0;
          let MayCount2 = 0;
          let JuneCount2 = 0;
          let JulyCount2 = 0;
          let AugCount2 = 0; 
          let SeptCount2 = 0;
          let OctCount2 = 0;
          let NovCount2 = 0;
          let DecCount2= 0;
             this.InquiryArr = [
         { data: [], label: 'In-Person' },
         { data: [], label: 'Phone' },
      ];
          if(res.countData.dataPush.length > 0){
            //
            res.countData.dataPush.forEach(element => {
              if(element.month == 1)
                  {
                    JanCount = element.TotalInquiryByInPerson;
                    JanCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 2)
                  {
                    FebCount = element.TotalInquiryByInPerson;
                    FebCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 3)
                  {
                    MarCount = element.TotalInquiryByInPerson;
                    MarCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 4)
                  {
                    AprCount = element.TotalInquiryByInPerson;
                    AprCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 5)
                  {
                    MayCount = element.TotalInquiryByInPerson;
                    MayCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 6)
                  {
                    JuneCount = element.TotalInquiryByInPerson;
                    JuneCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 7)
                  {
                    JulyCount = element.TotalInquiryByInPerson;
                    JulyCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 8)
                  {
                    AugCount = element.TotalInquiryByInPerson;
                    AugCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 9)
                  {
                    SeptCount = element.TotalInquiryByInPerson;
                    SeptCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 10)
                  {
                    OctCount = element.TotalInquiryByInPerson;
                    OctCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 11)
                  {
                    NovCount = element.TotalInquiryByInPerson;
                    NovCount2 = element.TotalInquiryByPhone;
                  }
                  if(element.month == 12)
                  {
                    DecCount = element.TotalInquiryByInPerson;
                    DecCount2 = element.TotalInquiryByPhone;
                  }
              
            });
            this.InquiryArr = [
              { data: [JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount], label: 'In-Person',lineTension:0, fill: false},
              { data: [JanCount2,FebCount2,MarCount2,AprCount2,MayCount2,JuneCount2,JulyCount2,AugCount2,SeptCount2,OctCount2,NovCount2,DecCount2], label: 'Phone',lineTension:0, fill: false},
              // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product B' }
            ];
           // this.purchaseArr = [JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount];
            //
            this.lineChartLabelsInquiry = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
          }
          else
          {
            this.InquiryArr = [
               { data: [], label: 'In-Person' },
               { data: [], label: 'Phone' },
            ];
            this.lineChartLabelsInquiry = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
          }
        } 
      });
    }
      //Total
      //stock in hand 
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.stockInHandCount(this.branchRef).subscribe(res => {
        if (res.status) {   
          this.stockInHandLabels = [];
          this.stockInHandArr = [];
          //
          if(res.countData.dataPush.length > 0){
        res.countData.dataPush.forEach(element => {
           this.stockInHandLabels.push(element.deviceName);
           this.stockInHandArr.push(element.stockInHand); 
        });
      }
      else
      {
        this.stockInHandLabels =['',' '];
        this.stockInHandArr = [0,0];
      }
        } 
      }); 
    }
    else{
      this.apimicroservice.stockInHandCount(branchRef).subscribe(res => {
        if (res.status) {   
          this.stockInHandLabels = [];
          this.stockInHandArr = [];
          //
          if(res.countData.dataPush.length > 0){
        res.countData.dataPush.forEach(element => {
           this.stockInHandLabels.push(element.deviceName);
           this.stockInHandArr.push(element.stockInHand);
        });
      }
      else
      {
        this.stockInHandLabels =['',' '];
        this.stockInHandArr = [0,0];
      }
        } 
      });
    }
    //HA Business
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getHACountByMonth(this.branchRef).subscribe(res => {
        if (res.status) {      
          this.haBusinessLabels = [];
          this.HaBusinessArr = [];
          //
          if(res.countData.dataPush.length > 0){
        // res.countData.dataPush.forEach(element => {
        //    this.haBusinessLabels.push(element.TotaltotalHA);
        //    this.HaBusinessArr.push(element); 
        // });
        // this.haBusinessLabels.push(element.TotaltotalHA);
        this.HaBusinessArr.push(res.countData.dataPush[0].TotaltotalHA,
          res.countData.dataPush[0].TotaltotalHANo,
          res.countData.dataPush[0].TotaltotalHAYes);
      }
      else
      {
        this.haBusinessLabels =['',' '];
        this.HaBusinessArr = [0,0];
      }
        } 
      }); 
    }
    else{
      this.apimicroservice.getHACountByMonth(branchRef).subscribe(res => {
        if (res.status) {   
          this.haBusinessLabels = [];
          this.HaBusinessArr = [];
          if(res.countData.dataPush.length > 0){
            this.HaBusinessArr.push(res.countData.dataPush[0].TotaltotalHA,
              res.countData.dataPush[0].TotaltotalHANo,
              res.countData.dataPush[0].TotaltotalHAYes);
      }
      else
      {
        this.haBusinessLabels =['',' '];
        this.HaBusinessArr = [0,0];
      }
        } 
      });
    }
       //receiver and payable
       if (this.userType !== 'Super Admin') {
       this.apimicroservice.getTotalPayReaciveByMonth(this.branchRef).subscribe(res => {
        if (res.status) {   
          this.payReaceiveLabels = ['Payable','Receivable']; 
          this.payReceiveArr = [];
          //
          if(res.payData.length > 0){
            this.payReceiveArr.push(res.payData[0].totalPayable);
          }
          else
          {
            this.payReceiveArr = [0];
          }
          if(res.receiveData.length > 0){
            this.payReceiveArr.push(res.receiveData[0].totalReceiveable);
          }
          else
          {
            this.payReceiveArr = [0];
          }
        // res.countData.dataPush.forEach(element => {
        //    this.stockInHandLabels.push(element.deviceName);
        //    this.stockInHandArr.push(element.stockInHand);
        // });
     
        } 
      }); 
    }
    else{
      this.apimicroservice.getTotalPayReaciveByMonth(branchRef).subscribe(res => {
        if (res.status) {   
          this.payReaceiveLabels = ['Payable','Receivable']; 
          this.payReceiveArr = []; 
          this.payReceiveArr.push(res.payData[0].totalPayable,res.receiveData[0].totalReceiveable);
          // if(res.payData.length > 0){ 
            
          //   this.payReaceiveLabels.push("Payable");
          //   this.payReceiveArr.push(res.payData[0].totalPayable,res.receiveData[0].totalReceiveable);
          // }
          // else
          // {
          //   this.payReaceiveLabels.push("Payable");
          //   this.payReceiveArr = [0];
          // }
          // if(res.receiveData.length > 0){
            
          //   this.payReaceiveLabels.push("Receivable");
          //   this.payReceiveArr.push(res.receiveData[0].totalReceiveable);
          // }
          // else
          // {
          //   this.payReaceiveLabels.push("Receivable");
          //   this.payReceiveArr = [0];
          // }
     
     
        } 
      }); 
    }
      //Mode of payment
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPaymentModeCountByMonth(this.branchRef).subscribe(res => {
        if (res.status) {   
          if(res.countData.dataPush.length > 0){
          res.countData.dataPush.forEach(element => {
            //
            this.pieChartDataPaymentMode =[element.TotalPaymentDoneByCash,element.TotalPaymentDoneByCard,
              element.TotalPaymentDoneByCheque,element.TotalPaymentDoneByNEFT,element.TotalPaymentDoneByUPI];
              this.paymentMode=['Cash', 'Card','Cheque','NEFT','UPI'];
          });
        }
          else
          {
            this.pieChartDataPaymentMode =[0,0,0,0,0];
            this.paymentMode=['Cash', 'Card','Cheque','NEFT','UPI'];
          }
        } 
      });
    }
    else{
      this.apimicroservice.getPaymentModeCountByMonth(branchRef).subscribe(res => {
        if (res.status) {   
          if(res.countData.dataPush.length > 0){
          res.countData.dataPush.forEach(element => {
            //
            this.pieChartDataPaymentMode =[element.TotalPaymentDoneByCash,element.TotalPaymentDoneByCard,
              element.TotalPaymentDoneByCheque,element.TotalPaymentDoneByNEFT,element.TotalPaymentDoneByUPI];
              this.paymentMode=['Cash', 'Card','Cheque','NEFT','UPI'];
          });
        }
          else
          {
            this.pieChartDataPaymentMode =[0,0,0,0,0];
            this.paymentMode=['Cash', 'Card','Cheque','NEFT','UPI'];
          }
        } 
      });
    } 
      //getTotalSalesByMonth
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getTotalSalesByMonth(this.branchRef).subscribe(res => {
        if (res.status) {   
          let JanCount = 0;
          let FebCount = 0;
          let MarCount = 0;
          let AprCount = 0;
          let MayCount = 0;
          let JuneCount = 0;
          let JulyCount = 0;
          let AugCount = 0;
          let SeptCount = 0;
          let OctCount = 0;
          let NovCount = 0;
          let DecCount = 0;
          if(res.data.length > 0){
            res.data.forEach(element => {
              if(element.Month == 1)
                  {
                    JanCount = element.TotalSales;
                  }
                  if(element.Month == 2)
                  {
                    FebCount = element.TotalSales;
                  }
                  if(element.Month == 3)
                  {
                    MarCount = element.TotalSales;
                  }
                  if(element.Month == 4)
                  {
                    AprCount = element.TotalSales;
                  }
                  if(element.Month == 5)
                  {
                    MayCount = element.TotalSales;
                  }
                  if(element.Month == 6)
                  {
                    JuneCount = element.TotalSales;
                  }
                  if(element.Month == 7)
                  {
                    JulyCount = element.TotalSales;
                  }
                  if(element.Month == 8)
                  {
                    AugCount = element.TotalSales;
                  }
                  if(element.Month == 9)
                  {
                    SeptCount = element.TotalSales;
                  }
                  if(element.Month == 10)
                  {
                    OctCount = element.TotalSales;
                  }
                  if(element.Month == 11)
                  {
                    NovCount = element.TotalSales;
                  }
                  if(element.Month == 12)
                  {
                    DecCount = element.TotalSales;
                  }
              
            });
            this.salesArr = [JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount];
            //
            this.salesLabels = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
          }
          else
          {
            this.salesArr = [0,0,0,0,0,0,0,0,0,0,0,0];
            //
            this.salesLabels = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
         
          }
        } 
      }); 
    }
    else{
      this.apimicroservice.getTotalSalesByMonth(branchRef).subscribe(res => {
        if (res.status) {   
          let JanCount = 0;
          let FebCount = 0;
          let MarCount = 0;
          let AprCount = 0;
          let MayCount = 0;
          let JuneCount = 0;
          let JulyCount = 0;
          let AugCount = 0;
          let SeptCount = 0;
          let OctCount = 0;
          let NovCount = 0;
          let DecCount = 0;
          if(res.data.length > 0){
            res.data.forEach(element => {
              if(element.Month == 1)
                  {
                    JanCount = element.TotalSales;
                  }
                  if(element.Month == 2)
                  {
                    FebCount = element.TotalSales;
                  }
                  if(element.Month == 3)
                  {
                    MarCount = element.TotalSales;
                  }
                  if(element.Month == 4)
                  {
                    AprCount = element.TotalSales;
                  }
                  if(element.Month == 5)
                  {
                    MayCount = element.TotalSales;
                  }
                  if(element.Month == 6)
                  {
                    JuneCount = element.TotalSales;
                  }
                  if(element.Month == 7)
                  {
                    JulyCount = element.TotalSales;
                  }
                  if(element.Month == 8)
                  {
                    AugCount = element.TotalSales;
                  }
                  if(element.Month == 9)
                  {
                    SeptCount = element.TotalSales;
                  }
                  if(element.Month == 10)
                  {
                    OctCount = element.TotalSales;
                  }
                  if(element.Month == 11)
                  {
                    NovCount = element.TotalSales;
                  }
                  if(element.Month == 12)
                  {
                    DecCount = element.TotalSales;
                  }
              
            });
            this.salesArr = [JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount];
            //
            this.salesLabels = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
          }
          else
          {
            this.salesArr = [0,0,0,0,0,0,0,0,0,0,0,0];
            //
            this.salesLabels = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
         
          }
        } 
      }); 
    }
    /////inqury to patient 
    if (this.userType !== 'Super Admin') { 
      this.apimicroservice.getInquiryConvertPatientCountByMonth(this.branchRef).subscribe(res => {
        // if (res.status) {   
        //   let JanCount = 0; 
        //   let FebCount = 0;
        //   let MarCount = 0;
        //   let AprCount = 0;
        //   let MayCount = 0;
        //   let JuneCount = 0;
        //   let JulyCount = 0;
        //   let AugCount = 0;
        //   let SeptCount = 0;
        //   let OctCount = 0;
        //   let NovCount = 0;
        //   let DecCount = 0;
        //   if(res.countData.dataPush.length > 0){
        //     res.countData.dataPush.forEach(element => {
        //       if(element.month == 1)
        //           {
        //             JanCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 2)
        //           {
        //             FebCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 3)
        //           {
        //             MarCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 4)
        //           {
        //             AprCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 5)
        //           {
        //             MayCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 6)
        //           {
        //             JuneCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 7)
        //           {
        //             JulyCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 8)
        //           {
        //             AugCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 9)
        //           {
        //             SeptCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 10)
        //           {
        //             OctCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 11)
        //           {
        //             NovCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 12)
        //           {
        //             DecCount = element.TotalInquiryConvertToPatient;
        //           }
              
        //     });
        //     this.inquryToPatientArr = [JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount];
        //     //
        //     this.inquiryToPatientLabels = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
        //   }
        //   else
        //   {
        //     this.inquryToPatientArr = [0,0,0,0,0,0,0,0,0,0,0,0];
        //     //
        //     this.inquiryToPatientLabels = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
         
        //   }
        // } 
        if (res.status) {  
          
          this.inquiryToPatientLabels = ['Total Inquiry','Converted'];
          this.inquryToPatientArr = []; 
          if(res.countData.dataPush.length >0){
            this.inquryToPatientArr.push(res.countData.dataPush[0].TotalInquiry,
              
              res.countData.dataPush[0].TotalConvetToPatient);
        }
         else
         {
           this.inquiryToPatientLabels =[' ',' '];
           this.inquryToPatientArr = [0,0];
         }
        } 
      }); 
    }
    else{
      this.apimicroservice.getInquiryConvertPatientCountByMonth(branchRef).subscribe(res => {
        if (res.status) {   
          
      


          this.inquiryToPatientLabels = ['Total Inquiry','Converted'];
          this.inquryToPatientArr = [];
          if(res.countData.dataPush.length >0){
            this.inquryToPatientArr.push(res.countData.dataPush[0].TotalInquiry,
              res.countData.dataPush[0].TotalConvetToPatient);
        }
         else
         {
           this.inquiryToPatientLabels =[' ',' '];
           this.inquryToPatientArr = [0,0];
         }
        } 
        // if (res.status) {   
        //   let JanCount = 0;
        //   let FebCount = 0;
        //   let MarCount = 0;
        //   let AprCount = 0;
        //   let MayCount = 0;
        //   let JuneCount = 0;
        //   let JulyCount = 0;
        //   let AugCount = 0;
        //   let SeptCount = 0;
        //   let OctCount = 0;
        //   let NovCount = 0;
        //   let DecCount = 0;
        //   if(res.countData.dataPush.length > 0){
        //     res.countData.dataPush.forEach(element => {
        //       if(element.month == 1)
        //           {
        //             JanCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 2)
        //           {
        //             FebCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 3)
        //           {
        //             MarCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 4)
        //           {
        //             AprCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 5)
        //           {
        //             MayCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 6)
        //           {
        //             JuneCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 7)
        //           {
        //             JulyCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 8)
        //           {
        //             AugCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 9)
        //           {
        //             SeptCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 10)
        //           {
        //             OctCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 11)
        //           {
        //             NovCount = element.TotalInquiryConvertToPatient;
        //           }
        //           if(element.month == 12)
        //           {
        //             DecCount = element.TotalInquiryConvertToPatient;
        //           }
              
        //     });
        //     this.inquryToPatientArr = [JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount];
        //     //
        //     this.inquiryToPatientLabels = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
        //   }
        //   else
        //   {
        //     this.inquryToPatientArr = [0,0,0,0,0,0,0,0,0,0,0,0];
        //     //
        //     this.inquiryToPatientLabels = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
         
        //   }
        // } 
      }); 
    }
      //Appoinment count dashboard/getAppointmentCountByStatusByMonth
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getTotalTodaysAppointmentCount(this.toDate,this.branchRef).subscribe(res => {
        if (res.status) {   
          if(res.data.dataPush.length > 0){
          res.data.dataPush.forEach(element => {
            this.todaysAppointment = element.totalAppointment;
            this.totalAppointmentCancel = element.totalAppointmentCancel;
            this.totalAppointmentCompleted = element.totalAppointmentCompleted;
            this.totalAppointmentReschedule = element.totalAppointmentReschedule;
          });
        } 
        else
        {
          this.todaysAppointment = 0;
          this.totalAppointmentCancel = 0;
          this.totalAppointmentCompleted = 0;
          this.totalAppointmentReschedule = 0;
        }
        } 
      
      });
    }
    else{
      this.apimicroservice.getTotalTodaysAppointmentCount(this.toDate,branchRef).subscribe(res => {
        if (res.status) {   
          if(res.data.dataPush.length > 0){
          res.data.dataPush.forEach(element => {
            this.todaysAppointment = element.totalAppointment;
            this.totalAppointmentCancel = element.totalAppointmentCancel;
            this.totalAppointmentCompleted = element.totalAppointmentCompleted;
            this.totalAppointmentReschedule = element.totalAppointmentReschedule;
          });
        } 
        else
        {
          this.todaysAppointment = 0;
          this.totalAppointmentCancel = 0;
          this.totalAppointmentCompleted = 0;
          this.totalAppointmentReschedule = 0;
        }
        } 
      
      });
    } 
      //clinic expenses
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.clinicExpencesTotalCountByMonth(this.branchRef).subscribe(res => {
        if (res.status) {   
          let clinicExpencesArry = [];
          if(res.countData.data.length > 0){
                res.countData.data.forEach(element => {
                  clinicExpencesArry.push({"expenceType":element.expenceType,"TotalAmount":element.TotalAmount,
                      "Month":element.month});
                });
                this.clinicExpenseData = [];   
                      this.clinicExpensesArr = [
                        clinicExpencesArry.forEach(element => {
                          let JanCount = 0;
                          let FebCount = 0;
                          let MarCount = 0;
                          let AprCount = 0;
                          let MayCount = 0;
                          let JuneCount = 0;
                          let JulyCount = 0;
                          let AugCount = 0;
                          let SeptCount = 0;
                          let OctCount = 0;
                          let NovCount = 0;
                          let DecCount = 0;

                          //
                          if(element.Month == 1)
                          {
                            JanCount = element.TotalAmount;
                          }
                          if(element.Month == 2)
                          {
                            FebCount = element.TotalAmount;
                          }
                          if(element.Month == 3)
                          {
                            MarCount = element.TotalAmount;
                          }
                          if(element.Month == 4)
                          {
                            AprCount = element.TotalAmount;
                          }
                          if(element.Month == 5)
                          {
                            MayCount = element.TotalAmount;
                          }
                          if(element.Month == 6)
                          {
                            JuneCount = element.TotalAmount;
                          }
                          if(element.Month == 7)
                          {
                            JulyCount = element.TotalAmount;
                          }
                          if(element.Month == 8)
                          {
                            AugCount = element.TotalAmount;
                          }
                          if(element.Month == 9)
                          {
                            SeptCount = element.TotalAmount;
                          }
                          if(element.Month == 10)
                          {
                            OctCount = element.TotalAmount;
                          }
                          if(element.Month == 11)
                          {
                            NovCount = element.TotalAmount;
                          }
                          if(element.Month == 12)
                          {
                            DecCount = element.TotalAmount;
                          }
                          this.clinicExpenseData.push({ data:[JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount], label:element.expenceType })
                      })
                    ]
                //
                //
                this.labelsClinicExpenses = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
                  }
                  else
                  {
                    this.clinicExpenseData=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'' },
                    { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'' }],
                    this.labelsClinicExpenses = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
                  }
              
              }}); 
            }
            else{
              this.apimicroservice.clinicExpencesTotalCountByMonth(branchRef).subscribe(res => {
                if (res.status) {   
                  let clinicExpencesArry = [];
                  if(res.countData.data.length > 0){
                        res.countData.data.forEach(element => {
                          clinicExpencesArry.push({"expenceType":element.expenceType,"TotalAmount":element.TotalAmount,
                              "Month":element.month});
                        });
                        this.clinicExpenseData = [];   
                              this.clinicExpensesArr = [
                                clinicExpencesArry.forEach(element => {
                                  let JanCount = 0;
                                  let FebCount = 0;
                                  let MarCount = 0;
                                  let AprCount = 0;
                                  let MayCount = 0;
                                  let JuneCount = 0;
                                  let JulyCount = 0;
                                  let AugCount = 0;
                                  let SeptCount = 0;
                                  let OctCount = 0;
                                  let NovCount = 0;
                                  let DecCount = 0;
        
                                  //
                                  if(element.Month == 1)
                                  {
                                    JanCount = element.TotalAmount;
                                  }
                                  if(element.Month == 2)
                                  {
                                    FebCount = element.TotalAmount;
                                  }
                                  if(element.Month == 3)
                                  {
                                    MarCount = element.TotalAmount;
                                  }
                                  if(element.Month == 4)
                                  {
                                    AprCount = element.TotalAmount;
                                  }
                                  if(element.Month == 5)
                                  {
                                    MayCount = element.TotalAmount;
                                  }
                                  if(element.Month == 6)
                                  {
                                    JuneCount = element.TotalAmount;
                                  }
                                  if(element.Month == 7)
                                  {
                                    JulyCount = element.TotalAmount;
                                  }
                                  if(element.Month == 8)
                                  {
                                    AugCount = element.TotalAmount;
                                  }
                                  if(element.Month == 9)
                                  {
                                    SeptCount = element.TotalAmount;
                                  }
                                  if(element.Month == 10)
                                  {
                                    OctCount = element.TotalAmount;
                                  }
                                  if(element.Month == 11)
                                  {
                                    NovCount = element.TotalAmount;
                                  }
                                  if(element.Month == 12)
                                  {
                                    DecCount = element.TotalAmount;
                                  }
                                  this.clinicExpenseData.push({ data:[JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount], label:element.expenceType })
                              })
                            ]
                        //
                        //
                        this.labelsClinicExpenses = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
                          }
                          else
                          {
                            this.clinicExpenseData=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'' },
                            { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'' }],
                            this.labelsClinicExpenses = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
                          }
                      
                      }}); 
            }
       //Total Apt done by Docter
       if (this.userType !== 'Super Admin') {
       this.apimicroservice.appointmentDoneByDoctorInMonth(this.branchRef).subscribe(res => {
        if (res.status) {    
              let doctArry = [];
              if(res.countData.dataPush.length > 0){
                res.countData.dataPush.forEach(element => {
                      doctArry.push({"Doctor":element.Doctor,"AppointmentCompletdByDoctor":element.AppointmentCompletdByDoctor,
                      "Month":element.month});
                });
                this.docterArryData = [];   
                      this.docterArr = [
                        doctArry.forEach(element => {
                          let JanCount = 0;
                          let FebCount = 0;
                          let MarCount = 0;
                          let AprCount = 0;
                          let MayCount = 0;
                          let JuneCount = 0;
                          let JulyCount = 0;
                          let AugCount = 0;
                          let SeptCount = 0;
                          let OctCount = 0;
                          let NovCount = 0;
                          let DecCount = 0;

                          //
                          if(element.Month == 1)
                          {
                            JanCount = element.AppointmentCompletdByDoctor;
                          }
                          if(element.Month == 2)
                          {
                            FebCount = element.AppointmentCompletdByDoctor;
                          }
                          if(element.Month == 3)
                          {
                            MarCount = element.AppointmentCompletdByDoctor;
                          }
                          if(element.Month == 4)
                          {
                            AprCount = element.AppointmentCompletdByDoctor;
                          }
                          if(element.Month == 5)
                          {
                            MayCount = element.AppointmentCompletdByDoctor;
                          }
                          if(element.Month == 6)
                          {
                            JuneCount = element.AppointmentCompletdByDoctor;
                          }
                          if(element.Month == 7)
                          {
                            JulyCount = element.AppointmentCompletdByDoctor;
                          }
                          if(element.Month == 8)
                          {
                            AugCount = element.AppointmentCompletdByDoctor;
                          }
                          if(element.Month == 9)
                          {
                            SeptCount = element.AppointmentCompletdByDoctor;
                          }
                          if(element.Month == 10)
                          {
                            OctCount = element.AppointmentCompletdByDoctor;
                          }
                          if(element.Month == 11)
                          {
                            NovCount = element.AppointmentCompletdByDoctor;
                          }
                          if(element.Month == 12)
                          {
                            DecCount = element.AppointmentCompletdByDoctor;
                          }
                          this.docterArryData.push({ data:[JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount], label:element.Doctor })
                      })
                    ]
                //
                //
                this.docterLabelsInquiry = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
                  }
                  else
                  {
                    this.docterArryData=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:''},
                    { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:''}]
                    this.docterLabelsInquiry = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
                  }
              }
                });
              }
              else{
                this.apimicroservice.appointmentDoneByDoctorInMonth(branchRef).subscribe(res => {
                  if (res.status) {    
                        let doctArry = [];
                        if(res.countData.dataPush.length > 0){
                          res.countData.dataPush.forEach(element => {
                                doctArry.push({"Doctor":element.Doctor,"AppointmentCompletdByDoctor":element.AppointmentCompletdByDoctor,
                                "Month":element.month});
                          });
                          this.docterArryData = [];   
                                this.docterArr = [
                                  doctArry.forEach(element => {
                                    let JanCount = 0;
                                    let FebCount = 0;
                                    let MarCount = 0;
                                    let AprCount = 0;
                                    let MayCount = 0;
                                    let JuneCount = 0;
                                    let JulyCount = 0;
                                    let AugCount = 0;
                                    let SeptCount = 0;
                                    let OctCount = 0;
                                    let NovCount = 0;
                                    let DecCount = 0;
          
                                    //
                                    if(element.Month == 1)
                                    {
                                      JanCount = element.AppointmentCompletdByDoctor;
                                    }
                                    if(element.Month == 2)
                                    {
                                      FebCount = element.AppointmentCompletdByDoctor;
                                    }
                                    if(element.Month == 3)
                                    {
                                      MarCount = element.AppointmentCompletdByDoctor;
                                    }
                                    if(element.Month == 4)
                                    {
                                      AprCount = element.AppointmentCompletdByDoctor;
                                    }
                                    if(element.Month == 5)
                                    {
                                      MayCount = element.AppointmentCompletdByDoctor;
                                    }
                                    if(element.Month == 6)
                                    {
                                      JuneCount = element.AppointmentCompletdByDoctor;
                                    }
                                    if(element.Month == 7)
                                    {
                                      JulyCount = element.AppointmentCompletdByDoctor;
                                    }
                                    if(element.Month == 8)
                                    {
                                      AugCount = element.AppointmentCompletdByDoctor;
                                    }
                                    if(element.Month == 9)
                                    {
                                      SeptCount = element.AppointmentCompletdByDoctor;
                                    }
                                    if(element.Month == 10)
                                    {
                                      OctCount = element.AppointmentCompletdByDoctor;
                                    }
                                    if(element.Month == 11)
                                    {
                                      NovCount = element.AppointmentCompletdByDoctor;
                                    }
                                    if(element.Month == 12)
                                    {
                                      DecCount = element.AppointmentCompletdByDoctor;
                                    }
                                    this.docterArryData.push({ data:[JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount], label:element.Doctor })
                                })
                              ]
                          //
                          //
                          this.docterLabelsInquiry = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
                            }
                            else
                            {
                              this.docterArryData=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:''},
                              { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:''}]
                              this.docterLabelsInquiry = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
                            }
                        }
                          });
              }
      //Service rate and test
      this.apimicroservice.getServiceRateTotalCountByMonth().subscribe(res => {
        if (res.status) { 
          //  
          let ServiceRateArry = [];
          if(res.countData.data.length > 0){

          let serviceName = [];
          let TotalAmount = [];
          let testType = [];
          this.serviceRateTestLabels = [];
          res.countData.data[0].serviceName.forEach(element => {
            ServiceRateArry.push({"serviceName":element});
             serviceName.push(element);
            // TotalAmount.push(element.TotalAmount);
            // testType.push(element.testType);
          });
          res.countData.data[0].testType.forEach((element,i) => {
            ServiceRateArry[i]['testType'] = element;
             //serviceName.push(element.serviceName);
            // TotalAmount.push(element.TotalAmount);
            // testType.push(element.testType);
          });

          res.countData.data[0].TotalAmount.forEach((element,i) => {
            ServiceRateArry[i]['TotalAmount'] = element;
             //serviceName.push(element.serviceName);
            // TotalAmount.push(element.TotalAmount);
            // testType.push(element.testType);
          });

          //

          let uniqService = [];
          let servicetemparr = [];
          uniqService = [...new Set(serviceName)];
          //
          let i = 0;
          uniqService.forEach((ele) => {
            i++;
            let count = "service" + i;
            servicetemparr.push({"serviceName":ele,"service":count});
            this.serviceRateTestLabels.push(ele);
          })
          // ServiceRateArry.push({"serviceName":serviceName,"TotalAmount":TotalAmount,
          // "testType":testType});
          //
          
          this.arrServiceRateTest = [];   
                this.serviceRateArr = [
                  servicetemparr.forEach(ele =>{
                    ServiceRateArry.forEach(element => {
                      let service1 = 0;
                      let service2 = 0;
                      let service3 = 0;
                      let service4 = 0;
                      let service5 = 0;
                      let service6 = 0;
                      let service7 = 0;
                      let service8 = 0;
                      let service9 = 0;
                      let service10 = 0;
                    
                     if(ele.serviceName == element.serviceName){
                      if(ele.service == 'service1')
                     {
                      service1 = element.TotalAmount;
                    }
                    if(ele.service == 'service2')
                     {
                      service2 = element.TotalAmount;
                    }
                    if(ele.service == 'service3')
                     {
                      service3 = element.TotalAmount;
                    }
                    if(ele.service == 'service4')
                     {
                      service4 = element.TotalAmount;
                    }
                    if(ele.service == 'service5')
                     {
                      service5 = element.TotalAmount;
                    }
                    if(ele.service == 'service6')
                     {
                      service6 = element.TotalAmount;
                    }
                    if(ele.service == 'service7')
                     {
                      service7 = element.TotalAmount;
                    }
                    if(ele.service == 'service8')
                     {
                      service8 = element.TotalAmount;
                    }
                    if(ele.service == 'service9')
                     {
                      service9 = element.TotalAmount;
                    }
                    if(ele.service == 'service10')
                     {
                      service10 = element.TotalAmount;
                    }

                      this.arrServiceRateTest.push({ data:[service1,service2,service3,service4,service5,service6,service7,service8,service9,service10], label:element.testType });
                     }
                    
                          
                  })
                  })
                  
              ]
          //
          //
          
             } 
             else
             {
               this.arrServiceRateTest=
                [
                { data: [0,0,0], label: '',lineTension:0, fill: false},
                { data: [0,0,0], label: '',lineTension:0, fill: false},
                { data: [0,0,0], label: '',lineTension:0, fill: false},
                // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product B' }
              ];
               this.serviceRateTestLabels =[' ',' ',' '];
             }
            }
      }); 
      //clinic expense
      // this.apimicroservice.getclinicExpencesTotalCountBy().subscribe(res => {
      //   if (res.status) {   
      // //    this.paymentStatusArr =[res.countData.totalPaid,res.countData.totalUnpaid];
      //   } 
      // }); 
      
       //Purchase
       if (this.userType !== 'Super Admin') {
       this.apimicroservice.getPurchaseTotalCountByMonth(this.branchRef).subscribe(res => {
        if (res.status) {   
        let JanCount = 0;
        let FebCount = 0;
        let MarCount = 0;
        let AprCount = 0;
        let MayCount = 0;
        let JuneCount = 0;
        let JulyCount = 0;
        let AugCount = 0;
        let SeptCount = 0;
        let OctCount = 0;
        let NovCount = 0;
        let DecCount = 0;
        if(res.data.length > 0){
          res.data.forEach(element => {
            if(element.month == 1)
                {
                  JanCount = element.count;
                }
                if(element.month == 2)
                {
                  FebCount = element.count;
                }
                if(element.month == 3)
                {
                  MarCount = element.count;
                }
                if(element.month == 4)
                {
                  AprCount = element.count;
                }
                if(element.month == 5)
                {
                  MayCount = element.count;
                }
                if(element.month == 6)
                {
                  JuneCount = element.count;
                }
                if(element.month == 7)
                {
                  JulyCount = element.count;
                }
                if(element.month == 8)
                {
                  AugCount = element.count;
                }
                if(element.month == 9)
                {
                  SeptCount = element.count;
                }
                if(element.month == 10)
                {
                  OctCount = element.count;
                }
                if(element.month == 11)
                {
                  NovCount = element.count;
                }
                if(element.month == 12)
                {
                  DecCount = element.count;
                }
            
          });
          this.purchaseArr = [JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount];
          //
          this.purchaseLabels = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
        }
        else
        {
          this.purchaseArr = [0,0,0,0,0,0,0,0,0,0,0,0];
          this.purchaseLabels = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
       
        }
        } 
      }); 
    }
    else{
      this.apimicroservice.getPurchaseTotalCountByMonth(branchRef).subscribe(res => {
        if (res.status) {   
        let JanCount = 0;
        let FebCount = 0;
        let MarCount = 0;
        let AprCount = 0;
        let MayCount = 0;
        let JuneCount = 0;
        let JulyCount = 0;
        let AugCount = 0;
        let SeptCount = 0;
        let OctCount = 0;
        let NovCount = 0;
        let DecCount = 0;
        if(res.data.length > 0){
          res.data.forEach(element => {
            if(element.month == 1)
                {
                  JanCount = element.count;
                }
                if(element.month == 2)
                {
                  FebCount = element.count;
                }
                if(element.month == 3)
                {
                  MarCount = element.count;
                }
                if(element.month == 4)
                {
                  AprCount = element.count;
                }
                if(element.month == 5)
                {
                  MayCount = element.count;
                }
                if(element.month == 6)
                {
                  JuneCount = element.count;
                }
                if(element.month == 7)
                {
                  JulyCount = element.count;
                }
                if(element.month == 8)
                {
                  AugCount = element.count;
                }
                if(element.month == 9)
                {
                  SeptCount = element.count;
                }
                if(element.month == 10)
                {
                  OctCount = element.count;
                }
                if(element.month == 11)
                {
                  NovCount = element.count;
                }
                if(element.month == 12)
                {
                  DecCount = element.count;
                }
            
          });
          this.purchaseArr = [JanCount,FebCount,MarCount,AprCount,MayCount,JuneCount,JulyCount,AugCount,SeptCount,OctCount,NovCount,DecCount];
          //
          this.purchaseLabels = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
        }
        else
        {
          this.purchaseArr = [0,0,0,0,0,0,0,0,0,0,0,0];
          this.purchaseLabels = [this.Jan,this.Feb,this.Mar,this.Apr,this.May,this.June,this.July,this.Aug,this.Sept,this.Oct,this.Nov,this.Dec];
       
        }
        } 
      }); 
    }
    }
   
     else if(e == 'Yearly')
     {
      this.dashboardForm.controls["toDate"].setValue('');
      this.dashboardForm.controls["formDate"].setValue('');
      
      this.typeOfSelect = 'Yearly';
      this.isFromToVisible = false;
      // let branchRef='';
         //Total Bill
         if (this.userType !== 'Super Admin') { 
      this.apimicroservice.getPaymentStatusCountByYear(this.branchRef).subscribe(res => {
        if (res.status) {   
          if(res.countData.dataPush.length > 0){ 
          res.countData.dataPush.forEach(element => {
             this.paymentStatusArr =[element.PaymentStatusPaid,element.PaymentStatusUnpaid];
          }); 
        }
          else
          {
            this.paymentStatusArr =[0,0];
          }
          //
          
        } 
      }); 
    }
    else{
      this.apimicroservice.getPaymentStatusCountByYear(branchRef).subscribe(res => {
        if (res.status) {   
          if(res.countData.dataPush.length > 0){ 
          res.countData.dataPush.forEach(element => {
             this.paymentStatusArr =[element.PaymentStatusPaid,element.PaymentStatusUnpaid];
          }); 
        }
          else
          {
            this.paymentStatusArr =[0,0];
          }
          //
          
        } 
      });
    }
     //asp 
     if (this.userType !== 'Super Admin') {
       this.apimicroservice.getASPPerYear(this.branchRef).subscribe(res => {
         if (res.status) {   
             this.totalASP = res.totalASP;
         } 
       },(ERROR:HttpErrorResponse) => {
         this.totalASP = 0;
     });
    }
    else{
      this.apimicroservice.getASPPerYear(branchRef).subscribe(res => {
        if (res.status) {   
            this.totalASP = res.totalASP;
        } 
      },(ERROR:HttpErrorResponse) => {
        this.totalASP = 0;
    });
    }
     
      //purchase
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPurchaseTotalCountByYear(this.branchRef).subscribe(res => {
        // if (res.status) {   
        //   res.data.forEach(element => {
        //     this.purchaseLabels = [element.Year];
        //     this.purchaseArr = [element.count];
        //   });
          let purchaseArrData = [];
        if (res.status) {  
          if(res.data.length > 0){ 
          res.data.forEach(element => {
            this.purchaseLabels = [element.Year];
            purchaseArrData.push(element.count);
          
          });
          this.purchaseArr = [purchaseArrData];
        }
          else
          {
            this.purchaseArr = [0];
            this.purchaseLabels = [2022];
         
          }
        } 
      });
    }
    else{
      this.apimicroservice.getPurchaseTotalCountByYear(branchRef).subscribe(res => {
        // if (res.status) {   
        //   res.data.forEach(element => {
        //     this.purchaseLabels = [element.Year];
        //     this.purchaseArr = [element.count];
        //   });
          let purchaseArrData = [];
        if (res.status) {  
          if(res.data.length > 0){ 
          res.data.forEach(element => {
            this.purchaseLabels = [element.Year];
            purchaseArrData.push(element.count);
          
          });
          this.purchaseArr = [purchaseArrData];
        }
          else
          {
            this.purchaseArr = [0];
            this.purchaseLabels = [2022];
         
          }
        } 
      });
    }
      //sales 
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getTotalSalesByYear(this.branchRef).subscribe(res => {
          let salesArrData = [];
        if (res.status) {   
          if(res.data.length > 0){
          res.data.forEach(element => {
            this.salesLabels = [element.Year];
            salesArrData.push(element.TotalSales);
          
          });
          this.salesArr = [salesArrData];
        }
          else
          {
            this.salesArr = [0];
            //
            this.salesLabels = [2022];
         
          }
        } 
      });
    }
    else{
      this.apimicroservice.getTotalSalesByYear(branchRef).subscribe(res => {
        let salesArrData = [];
      if (res.status) {   
        if(res.data.length > 0){
        res.data.forEach(element => {
          this.salesLabels = [element.Year];
          salesArrData.push(element.TotalSales);
        
        });
        this.salesArr = [salesArrData];
      }
        else
        {
          this.salesArr = [0];
          //
          this.salesLabels = [2022];
       
        }
      } 
    });
    }
    //inqury to patient converted
    if (this.userType !== 'Super Admin') {  
      this.apimicroservice.getInquiryConvertPatientCountByYear(this.branchRef).subscribe(res => {
          let inquiryToPatientArrData = []; 
          if (res.status) {    


            this.inquiryToPatientLabels = ['Total Inquiry','Converted'];
            this.inquryToPatientArr = [];
            if(res.countData.dataPush.length >0){
              this.inquryToPatientArr.push(res.countData.dataPush[0].TotalInquiry,
                
                res.countData.dataPush[0].TotalConvetToPatient);
          }
           else
           {
             this.inquiryToPatientLabels =[' ',' '];
             this.inquryToPatientArr = [0,0];
           }
          }  
        //   if(res.countData.dataPush.length > 0){
        //     res.countData.dataPush.forEach(element => {
        //     this.inquiryToPatientLabels = [element.Year];
        //     inquiryToPatientArrData.push(element.TotalInquiryConvertToPatient);
          
        //   }); 
        //   this.inquryToPatientArr = [inquiryToPatientArrData];
        // }
        //   else
        //   {
        //     this.inquryToPatientArr = [0];
        //     //
        //     this.inquiryToPatientLabels = [2022];
         
        //   }
      });
    }
    else{
      this.apimicroservice.getInquiryConvertPatientCountByYear(branchRef).subscribe(res => {
        let inquiryToPatientArrData = [];
        if (res.status) {    

          this.inquiryToPatientLabels = ['Total Inquiry','Converted'];
          this.inquryToPatientArr = [];
          if(res.countData.dataPush.length >0){
            this.inquryToPatientArr.push(res.countData.dataPush[0].TotalInquiry,
              
              res.countData.dataPush[0].TotalConvetToPatient);
        }
         else
         {
           this.inquiryToPatientLabels =[' ',' '];
           this.inquryToPatientArr = [0,0];
         }
        }  
      // if (res.status) {   
      //   if(res.countData.dataPush.length > 0){
      //     res.countData.dataPush.forEach(element => {
      //     this.inquiryToPatientLabels = [element.Year];
      //     inquiryToPatientArrData.push(element.TotalInquiryConvertToPatient);
        
      //   });
      //   this.inquryToPatientArr = [inquiryToPatientArrData];
      // }
      //   else
      //   {
      //     this.inquryToPatientArr = [0];
      //     //
      //     this.inquiryToPatientLabels = [2022];
       
      //   }
      // } 
    });
    }
      //inquiry
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getInquiryCountByYear(this.branchRef).subscribe(res => {
          let TotalInquiryByInPerson = [];
          let TotalInquiryByPhone = [];
             this.InquiryArr = [
         { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'In-Person' },
         { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Phone' },
      ];
        if (res.status) { 
          if(res.countData.dataPush.length > 0){
            res.countData.dataPush.forEach(element => {
              this.lineChartLabelsInquiry = ['x',element.Year];
             TotalInquiryByInPerson.push(element.TotalInquiryByInPerson);
             TotalInquiryByPhone.push(element.TotalInquiryByPhone);
            //  TotalInquiryByPhone = element.TotalInquiryByPhone;
             
            });
            this.InquiryArr = [
              { data: [0,TotalInquiryByInPerson], label: 'In-Person',lineTension:0, fill: false},
              { data: [0,TotalInquiryByPhone], label: 'Phone',lineTension:0, fill: false},
              // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product B' }
            ];
         
          } 
        else
        {
          this.InquiryArr = [
             { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'In-Person' },
             { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Phone' },
          ];
          this.lineChartLabelsInquiry = [2022];
        }
      }
      });
    }
    else{
      this.apimicroservice.getInquiryCountByYear(branchRef).subscribe(res => {
        let TotalInquiryByInPerson = [];
        let TotalInquiryByPhone = [];
           this.InquiryArr = [
         { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'In-Person' },
         { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Phone' },
      ];
      if (res.status) { 
        if(res.countData.dataPush.length > 0){
          res.countData.dataPush.forEach(element => {
            this.lineChartLabelsInquiry = ['x',element.Year];
           TotalInquiryByInPerson.push(element.TotalInquiryByInPerson);
           TotalInquiryByPhone.push(element.TotalInquiryByPhone);
          //  TotalInquiryByPhone = element.TotalInquiryByPhone;
           
          });
          this.InquiryArr = [
            { data: [0,TotalInquiryByInPerson], label: 'In-Person',lineTension:0, fill: false},
            { data: [0,TotalInquiryByPhone], label: 'Phone',lineTension:0, fill: false},
            // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product B' }
          ];
       
        } 
      else
      {
        this.InquiryArr = [
           { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'In-Person' },
           { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Phone' },
        ];
        this.lineChartLabelsInquiry = [2022];
      }
    }
    });
    }
      //mode of payment
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPaymentModeCountByYear(this.branchRef).subscribe(res => {
        if(res.countData.dataPush.length > 0){
        res.countData.dataPush.forEach(element => {
          //
          this.pieChartDataPaymentMode =[element.TotalPaymentDoneByCash,element.TotalPaymentDoneByCard,
            element.TotalPaymentDoneByCheque,element.TotalPaymentDoneByNEFT,element.TotalPaymentDoneByUPI];
            this.paymentMode=['Cash', 'Card','Cheque','NEFT','UPI'];
        });
      }
      else
      {
        this.pieChartDataPaymentMode =[0,0,0,0,0];
        this.paymentMode=['Cash', 'Card','Cheque','NEFT','UPI'];
      }
    });
  }
  else{
    this.apimicroservice.getPaymentModeCountByYear(branchRef).subscribe(res => {
      if(res.countData.dataPush.length > 0){
      res.countData.dataPush.forEach(element => {
        //
        this.pieChartDataPaymentMode =[element.TotalPaymentDoneByCash,element.TotalPaymentDoneByCard,
          element.TotalPaymentDoneByCheque,element.TotalPaymentDoneByNEFT,element.TotalPaymentDoneByUPI];
          this.paymentMode=['Cash', 'Card','Cheque','NEFT','UPI'];
      });
    }
    else
    {
      this.pieChartDataPaymentMode =[0,0,0,0,0];
      this.paymentMode=['Cash', 'Card','Cheque','NEFT','UPI'];
    }
  });
  }
    //cheque status
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getChequeStatusCountByYear(this.branchRef).subscribe(res => {
      //
      
      if(res.countData.dataPush.length > 0 ){
      res.countData.dataPush.forEach(element => {
        //
        this.pieChartDataCheque =[element.TotalChequeBounce,element.TotalChequeClear,
          element.TotalChequeDeposited,element.TotalChequePostdated,element.TotalChequeReturn,element.TotalChequeCancelled,element.TotalChequeReceived];
      
      });
    }
    else
    {
      this.pieChartDataCheque =[0,0,0,0,0];
    }
  });
}else{

  this.apimicroservice.getChequeStatusCountByYear(branchRef).subscribe(res => {
    //
    if(res.countData.dataPush.length > 0 ){
      //
    res.countData.dataPush.forEach(element => {
      //
      this.pieChartDataCheque =[element.TotalChequeBounce,element.TotalChequeClear,
        element.TotalChequeDeposited,element.TotalChequePostdated,element.TotalChequeReturn,element.TotalChequeCancelled,element.TotalChequeReceived];
    
    });
  }
  else
  {
    this.pieChartDataCheque =[0,0,0,0,0];
  }
});
}

//followup
if (this.userType !== 'Super Admin') {
  this.apimicroservice.getFollowupStatusCountByYear(this.branchRef).subscribe(res => {
    
    if(res.countData.dataPush.length > 0 )
    {   
      if(res.countData.dataPush.length > 0){
      res.countData.dataPush.forEach(element => {
        //
        this.followUpStatusArr =[element.TotalFollowUpDone,element.TotalFollowUpSchedule,element.TotalNotAnsweredFollowUp,element.TotalRescheduledFollowUp];
      });
    }
      else
      {
        this.followUpStatusArr =[0,0,0,0];
      }
    } 
});
}
else{
this.apimicroservice.getFollowupStatusCountByYear(branchRef).subscribe(res => {
  if(res.countData.dataPush.length > 0 )
  {   
    if(res.countData.dataPush.length > 0){
    res.countData.dataPush.forEach(element => {
      //
      this.followUpStatusArr =[element.TotalFollowUpDone,element.TotalFollowUpSchedule,element.TotalNotAnsweredFollowUp,element.TotalRescheduledFollowUp];
    });
  }
    else
    {
      this.followUpStatusArr =[0,0,0,0];
    }
  } 
}); 
}
    //Stoack in hand 
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.stockInHandCount(this.branchRef).subscribe(res => {
      this.stockInHandLabels = [];
      this.stockInHandArr = [];
      //
      if(res.countData.dataPush.length > 0){
      res.countData.dataPush.forEach(element => {     
        this.stockInHandLabels.push(element.deviceName);
        this.stockInHandArr.push(element.stockInHand);
      });
    }
    else
    {
      this.stockInHandLabels =[' ',' '];
      this.stockInHandArr = [0,0];
    }
  });
}
else{
  this.apimicroservice.stockInHandCount(branchRef).subscribe(res => {
    this.stockInHandLabels = [];
    this.stockInHandArr = [];
    //
    if(res.countData.dataPush.length > 0){
    res.countData.dataPush.forEach(element => {     
      this.stockInHandLabels.push(element.deviceName);
      this.stockInHandArr.push(element.stockInHand);
    });
  }
  else
  {
    this.stockInHandLabels =[' ',' '];
    this.stockInHandArr = [0,0];
  }
});
}
//HA business
if (this.userType !== 'Super Admin') {
  this.apimicroservice.getHACountByYear(this.branchRef).subscribe(res => {
    this.haBusinessLabels = []; 
    this.HaBusinessArr = [];
    //
    if(res.countData.dataPush.length > 0){
      this.HaBusinessArr.push(res.countData.dataPush[0].Totalha,
        res.countData.dataPush[0].TotalHANo,
        res.countData.dataPush[0].TotalHAYes);
  }
  else
  {
    this.haBusinessLabels =[' ',' '];
    this.HaBusinessArr = [0,0];
  }
});
}
else{
this.apimicroservice.getHACountByYear(branchRef).subscribe(res => {
  this.haBusinessLabels = [];
  this.HaBusinessArr = [];
  if(res.countData.dataPush.length > 0){
    this.HaBusinessArr.push(res.countData.dataPush[0].Totalha,
      res.countData.dataPush[0].TotalHANo,
      res.countData.dataPush[0].TotalHAYes);
}
else
{
  this.haBusinessLabels =[' ',' '];
  this.HaBusinessArr = [0,0];
}
});
}
  //docter year
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.appointmentDoneByDoctorInYear(this.branchRef).subscribe(res => {
    if (res.status) {    
          let doctArry = [];
          if(res.countData.dataPush.length > 0)
          {
            res.countData.dataPush.forEach(element => {
              this.docterLabelsInquiry = ['x',element.Year];
                  doctArry.push({"Doctor":element.Doctor,"AppointmentCompletdByDoctor":element.AppointmentCompletdByDoctor
                  });
            });
            this.docterArryData = [];   
                  this.docterArr = [
                    doctArry.forEach(element => {
                      this.docterArryData.push({ data:[0,element.AppointmentCompletdByDoctor], label:element.Doctor })
                  })
                ]
              }
              else
              {
                this.docterArryData=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:''},
                { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:''}]
                this.docterLabelsInquiry = [2022];
              }
              }
            });
          }
          else{
            this.apimicroservice.appointmentDoneByDoctorInYear(branchRef).subscribe(res => {
              if (res.status) {    
                    let doctArry = [];
                    if(res.countData.dataPush.length > 0)
                    {
                      res.countData.dataPush.forEach(element => {
                        this.docterLabelsInquiry = ['x',element.Year];
                            doctArry.push({"Doctor":element.Doctor,"AppointmentCompletdByDoctor":element.AppointmentCompletdByDoctor
                            });
                      });
                      this.docterArryData = [];   
                            this.docterArr = [
                              doctArry.forEach(element => {
                                this.docterArryData.push({ data:[0,element.AppointmentCompletdByDoctor], label:element.Doctor })
                            })
                          ]
                        }
                        else
                        {
                          this.docterArryData=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:''},
                          { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:''}]
                          this.docterLabelsInquiry = [2022];
                        }
                        }
                      });
          }
            //clinic expense
            if (this.userType !== 'Super Admin') {
            this.apimicroservice.clinicExpencesTotalCountByYear(this.branchRef).subscribe(res => {
              if (res.status) {    
                    let clinicExpenseArry = [];
                    if(res.countData.data.length > 0)
                    {
                      res.countData.data.forEach(element => {
                        this.labelsClinicExpenses = ['x',element.Year];
                        clinicExpenseArry.push({"expenceType":element.expenceType,"TotalAmount":element.TotalAmount
                            });
                      });
                      this.clinicExpenseData = [];   
                            this.docterArr = [
                              clinicExpenseArry.forEach(element => {
                                this.clinicExpenseData.push({ data:[0,element.TotalAmount], label:element.expenceType })
                            })
                          ]
                        }
                        else
                        {
                          this.clinicExpenseData=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'' },
                          { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'' }],
                          this.labelsClinicExpenses = [2022];
                     
                        }
                        }
                      });
                    }
                    else{
                      this.apimicroservice.clinicExpencesTotalCountByYear(branchRef).subscribe(res => {
                        if (res.status) {    
                              let clinicExpenseArry = [];
                              if(res.countData.data.length > 0)
                              {
                                res.countData.data.forEach(element => {
                                  this.labelsClinicExpenses = ['x',element.Year];
                                  clinicExpenseArry.push({"expenceType":element.expenceType,"TotalAmount":element.TotalAmount
                                      });
                                });
                                this.clinicExpenseData = [];   
                                      this.docterArr = [
                                        clinicExpenseArry.forEach(element => {
                                          this.clinicExpenseData.push({ data:[0,element.TotalAmount], label:element.expenceType })
                                      })
                                    ]
                                  }
                                  else
                                  {
                                    this.clinicExpenseData=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'' },
                                    { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'' }],
                                    this.labelsClinicExpenses = [2022];
                               
                                  }
                                  }
                                });
                    }
  //Receiver and payable
  if (this.userType !== 'Super Admin') {
  this.apimicroservice.getTotalPayReaciveByYear(this.branchRef).subscribe(res => {
    this.payReaceiveLabels = ['Payable','Receivable']; 
    this.payReceiveArr = [];
    //
    // if(res.payData.length > 0){
            
    //   this.payReaceiveLabels.push("Payable");
    //   this.payReceiveArr.push(res.payData[0].totalPayable);
    // }
    // if(res.receiveData.length > 0){
      
    //   this.payReaceiveLabels.push("Receivable");
    //   this.payReceiveArr.push(res.receiveData[0].totalReceiveable);
    // }
    // if(res.payData.length > 0){
            
    //   this.payReaceiveLabels.push("Payable");
    //   this.payReceiveArr.push(res.payData[0].totalPayable);
    // }
    // else
    // {
    //   this.payReaceiveLabels.push("Payable");
    //   this.payReceiveArr = [0];
    // }
    // if(res.receiveData.length > 0){
      
    //   this.payReaceiveLabels.push("Recivable");
    //   this.payReceiveArr.push(res.receiveData[0].totalReceiveable);
    // }
    // else
    // {
    //   this.payReaceiveLabels.push("Recivable");
    //   this.payReceiveArr = [0];
    // }
    this.payReaceiveLabels = ['Payable','Receivable']; 
    this.payReceiveArr = [];

    //
    if(res.payData.length > 0){
      this.payReceiveArr.push(res.payData[0].totalPayable);
    }
    else
    {
      this.payReceiveArr = [0];
    }
    if(res.receiveData.length > 0){
      this.payReceiveArr.push(res.receiveData[0].totalReceiveable);
    }
    else
    {
      this.payReceiveArr = [0];
    }
});
  }
  else{
    this.apimicroservice.getTotalPayReaciveByYear(branchRef).subscribe(res => {
      this.payReaceiveLabels = ['Payable','Receivable']; 
      this.payReceiveArr = [];
      //
      // if(res.payData.length > 0){
              
      //   this.payReaceiveLabels.push("Payable");
      //   this.payReceiveArr.push(res.payData[0].totalPayable);
      // }
      // if(res.receiveData.length > 0){
        
      //   this.payReaceiveLabels.push("Receivable");
      //   this.payReceiveArr.push(res.receiveData[0].totalReceiveable);
      // }
      // if(res.payData.length > 0){
              
      //   this.payReaceiveLabels.push("Payable");
      //   this.payReceiveArr.push(res.payData[0].totalPayable);
      // }
      // else
      // {
      //   this.payReaceiveLabels.push("Payable");
      //   this.payReceiveArr = [0];
      // }
      // if(res.receiveData.length > 0){
        
      //   this.payReaceiveLabels.push("Recivable");
      //   this.payReceiveArr.push(res.receiveData[0].totalReceiveable);
      // }
      // else
      // {
      //   this.payReaceiveLabels.push("Recivable");
      //   this.payReceiveArr = [0];
      // }
      this.payReaceiveLabels = ['Payable','Receivable']; 
      this.payReceiveArr = [];
      //
      if(res.payData.length > 0){
        this.payReceiveArr.push(res.payData[0].totalPayable);
      }
      else
      {
        this.payReceiveArr = [0];
      }
      if(res.receiveData.length > 0){
        this.payReceiveArr.push(res.receiveData[0].totalReceiveable);
      }
      else
      {
        this.payReceiveArr = [0];
      }
  });
  }
    //getServiceRateTotalCountByYear
    this.apimicroservice.getServiceRateTotalCountByYear().subscribe(res => {
      if(res.status){
      //  
      let ServiceRateArry = [];
      if(res.countData.data.length > 0){
      let serviceName = [];
      let TotalAmount = [];
      let testType = [];
      res.countData.data[0].serviceName.forEach(element => {
        ServiceRateArry.push({"serviceName":element});
         serviceName.push(element);
        // TotalAmount.push(element.TotalAmount);
        // testType.push(element.testType);
      });
      res.countData.data[0].testType.forEach((element,i) => {
        ServiceRateArry[i]['testType'] = element;
         //serviceName.push(element.serviceName);
        // TotalAmount.push(element.TotalAmount);
        // testType.push(element.testType);
      });

      res.countData.data[0].TotalAmount.forEach((element,i) => {
        ServiceRateArry[i]['TotalAmount'] = element;
         //serviceName.push(element.serviceName);
        // TotalAmount.push(element.TotalAmount);
        // testType.push(element.testType);
      });

      //

      let uniqService = [];
      let servicetemparr = [];
      this.serviceRateTestLabels = [];
      uniqService = [...new Set(serviceName)];
      //
      let i = 0;
      uniqService.forEach((ele) => {
        i++;
        let count = "service" + i;
        servicetemparr.push({"serviceName":ele,"service":count});
        this.serviceRateTestLabels.push(ele);
      })
      // ServiceRateArry.push({"serviceName":serviceName,"TotalAmount":TotalAmount,
      // "testType":testType});
      //
      
      this.arrServiceRateTest = [];   
            this.serviceRateArr = [
              servicetemparr.forEach(ele =>{
                ServiceRateArry.forEach(element => {
                  let service1 = 0;
                  let service2 = 0;
                  let service3 = 0;
                  let service4 = 0;
                  let service5 = 0;
                  let service6 = 0;
                  let service7 = 0;
                  let service8 = 0;
                  let service9 = 0;
                  let service10 = 0;
                
                 if(ele.serviceName == element.serviceName){
                  if(ele.service == 'service1')
                 {
                  service1 = element.TotalAmount;
                }
                if(ele.service == 'service2')
                 {
                  service2 = element.TotalAmount;
                }
                if(ele.service == 'service3')
                 {
                  service3 = element.TotalAmount;
                }
                if(ele.service == 'service4')
                 {
                  service4 = element.TotalAmount;
                }
                if(ele.service == 'service5')
                 {
                  service5 = element.TotalAmount;
                }
                if(ele.service == 'service6')
                 {
                  service6 = element.TotalAmount;
                }
                if(ele.service == 'service7')
                 {
                  service7 = element.TotalAmount;
                }
                if(ele.service == 'service8')
                 {
                  service8 = element.TotalAmount;
                }
                if(ele.service == 'service9')
                 {
                  service9 = element.TotalAmount;
                }
                if(ele.service == 'service10')
                 {
                  service10 = element.TotalAmount;
                }

                  this.arrServiceRateTest.push({ data:[service1,service2,service3,service4,service5,service6,service7,service8,service9,service10], label:element.testType });
                 }
                
                      
              })
              })
              
          ]
      //
      //
      
         } 
         else
         {
           this.arrServiceRateTest=
            [
            { data: [0,0,0], label: '',lineTension:0, fill: false},
            { data: [0,0,0], label: '',lineTension:0, fill: false},
            { data: [0,0,0], label: '',lineTension:0, fill: false},
            // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product B' }
          ];
           this.serviceRateTestLabels =['Service 1','Service 2','Service 3'];
         }
        }  
  });
  }

}

selectFromdate(event : any){
  let payload = this.dashboardForm.value;
  this.laterDate = new Date(payload.formDate);
  this.laterDate.setDate(this.laterDate.getDate() + 14);
  //
}
  toDatee(event :any){
    let payload = this.dashboardForm.value; 
     let branchRef='';
     if(payload.branchRefId == '' || payload.branchRefId == null)
     {
       branchRef='';
     } 
     else
     {
       branchRef=payload.branchRefId;
     }
    this.RepairActivity(payload.repairActivity);
    //
    if(payload.formDate !== '')
    {
 /// Total Bill
 if (this.userType !== 'Super Admin') {
 this.apimicroservice.getPaymentStatusCountByCustom(payload.formDate,payload.toDate,this.branchRef).subscribe(res => {
   if (res.status) {  
     if(res.data.length > 0)
     {
     this.paymentStatusArr =[res.data.totalPaidPayment,res.data.totalUnpaidPayment];
    }
    else
    {
      this.paymentStatusArr =[0,0];
    }
   } 
 }); 
}
else{
  this.apimicroservice.getPaymentStatusCountByCustom(payload.formDate,payload.toDate,branchRef).subscribe(res => {
    if (res.status) {  
      //
      
      if(res.data.length > 0)
      {
      this.paymentStatusArr =[res.data.totalPaidPayment,res.data.totalUnpaidPayment];
     }
     else
     {
       this.paymentStatusArr =[0,0];
     }
    } 
  });
}
//followup
//followup
if (this.userType !== 'Super Admin') {
  this.apimicroservice.getFollowTotalCountByCustomDate(payload.formDate,payload.toDate,this.branchRef).subscribe(res => {
    // //
     if(res.data )
     {
      this.followUpStatusArr =[res.data.TotalFollowUpDone,res.data.TotalFollowUpSchedule,res.data.TotalNotAnsweredFollowUp,res.data.TotalRescheduledFollowUp];
     }
   else
   {
     this.followUpStatusArr =[0,0,0,0];
   }
   });
}
else{
  this.apimicroservice.getFollowTotalCountByCustomDate(payload.formDate,payload.toDate,branchRef).subscribe(res => {
    //
    
     if(res.data )
     {
      this.followUpStatusArr =[res.data.TotalFollowUpDone,res.data.TotalFollowUpSchedule,res.data.TotalNotAnsweredFollowUp,res.data.TotalRescheduledFollowUp];
    }
   else
   {
     this.followUpStatusArr =[0,0,0,0];
   }
   });
}

 //purchase
 if (this.userType !== 'Super Admin') {
 this.apimicroservice.getPurchaseTotalCountByCustomDate(payload.formDate,payload.toDate,this.branchRef).subscribe(res => {
   if (res.status) {   
    this.purchaseLabels = [];
    this.purchaseArr = [];
    if(res.data.length > 0)
    {
    res.data.forEach(element => {
     this.purchaseLabels.push(element.Date);
     this.purchaseArr.push(element.totalPurchaseData);  
    });
  }
    else
          {
            this.purchaseArr = [0];
            this.purchaseLabels = ['2022-06-14'];
         
          }
   } 
 }); 
}
else{
  this.apimicroservice.getPurchaseTotalCountByCustomDate(payload.formDate,payload.toDate,branchRef).subscribe(res => {
    if (res.status) {   
     this.purchaseLabels = [];
     this.purchaseArr = [];
     if(res.data.length > 0)
     {
     res.data.forEach(element => {
      this.purchaseLabels.push(element.Date);
      this.purchaseArr.push(element.totalPurchaseData);  
     });
   }
     else
           {
             this.purchaseArr = [0];
             this.purchaseLabels = ['2022-06-14'];
          
           }
    } 
  }); 
}
 //sales
 if (this.userType !== 'Super Admin') {
 this.apimicroservice.getTotalSalesByCustomdate(payload.formDate,payload.toDate,this.branchRef).subscribe(res => {
  if (res.status) {   
    this.salesLabels = [];
    this.salesArr = [];
    //
    if(res.data.length > 0)
    {
      res.data.forEach(element => {
        //
        //  this.salesLabels = [element.Year];
        //  this.salesArr = [element.count];
        this.salesLabels.push(element.Date);
        this.salesArr.push(element.TotalSales);
       });
    }
 
   else
   {
     this.salesArr = [0];
     //
     this.salesLabels = ['2022-06-14'];
  
   }
  } 
});
 }
 else{
  this.apimicroservice.getTotalSalesByCustomdate(payload.formDate,payload.toDate,branchRef).subscribe(res => {
    if (res.status) {   
      this.salesLabels = [];
      this.salesArr = [];
      //
      if(res.data.length > 0)
      {
        res.data.forEach(element => {
          //
          //  this.salesLabels = [element.Year];
          //  this.salesArr = [element.count];
          this.salesLabels.push(element.Date);
          this.salesArr.push(element.TotalSales);
         });
      }
   
     else
     {
       this.salesArr = [0];
       //
       this.salesLabels = ['2022-06-14'];
    
     }
    } 
  });
 }
///inqury to patient
if (this.userType !== 'Super Admin') {
  this.apimicroservice.getInquiryConvertPatientCountByCustom(payload.formDate,payload.toDate,this.branchRef).subscribe(res => {
   if (res.status) {    

    this.inquiryToPatientLabels = ['Total Inquiry','Converted'];
     this.inquryToPatientArr = [];
     //
     
     if(res.data)
     {
         this.inquryToPatientArr.push(res.data.totalInquiry,
          res.data.NotConvertToPatient,
          res.data.TotalConvertToPatient);
     }
    else
    {
      this.inquryToPatientArr = [0];
      this.inquiryToPatientLabels = ['',''];
    }
   } 
 });
  }
  else{
   this.apimicroservice.getInquiryConvertPatientCountByCustom(payload.formDate,payload.toDate,branchRef).subscribe(res => {
     if (res.status) {   
      this.inquiryToPatientLabels = ['Total Inquiry','Converted'];
       this.inquryToPatientArr = [];
       //
       if(res.data)
       {
           this.inquryToPatientArr.push(res.data.totalInquiry,
            res.data.NotConvertToPatient,
            res.data.TotalConvertToPatient);
       }
      else
      {
        this.inquryToPatientArr = [0];
        this.inquiryToPatientLabels = ['',''];
      }
     } 
   });
  }
//stock in hand
// if (this.userType !== 'Super Admin') {
// this.apimicroservice.stockInHandCountByCustomDate(payload.formDate,payload.toDate,this.branchRef).subscribe(res => {
//   if (res.status) {   
//     this.stockInHandLabels = [];
//     this.stockInHandArr = [];
//     if(res.data.count.length > 0){
//    res.data.count.forEach(element => {
//     //
//     this.stockInHandLabels.push(element._id);
//     this.stockInHandArr.push(element.count);
//    });
//   }
//    else
//    {
//      this.stockInHandLabels =[' ',' '];
//      this.stockInHandArr = [0,0];
//    }
//   } 
// });
// }
// else{
//   this.apimicroservice.stockInHandCountByCustomDate(payload.formDate,payload.toDate,branchRef).subscribe(res => {
//     if (res.status) {   
//       this.stockInHandLabels = [];
//       this.stockInHandArr = [];
//       if(res.data.count.length > 0){
//      res.data.count.forEach(element => {
//       //
//       this.stockInHandLabels.push(element._id);
//       this.stockInHandArr.push(element.count);
//      });
//     }
//      else
//      {
//        this.stockInHandLabels =[' ',' '];
//        this.stockInHandArr = [0,0];
//      }
//     } 
//   });
// }
//Ha business
if (this.userType !== 'Super Admin') {
  this.apimicroservice.getHACountByCustomDate(payload.formDate,payload.toDate,this.branchRef).subscribe(res => {
    if (res.status) {    
      this.haBusinessLabels = [];
      this.HaBusinessArr = [];
      if(res.data){
        this.HaBusinessArr.push(res.data.totalHA,
          res.data.totalHANo,
          res.data.totalHAYes);
    }
     else
     {
       this.haBusinessLabels =[' ',' '];
       this.HaBusinessArr = [0,0];
     }
    } 
  });
  }
  else{
    this.apimicroservice.getHACountByCustomDate(payload.formDate,payload.toDate,branchRef).subscribe(res => {
      if (res.status) {   
        this.haBusinessLabels = [];
        this.HaBusinessArr = [];
        if(res.data){
        this.HaBusinessArr.push(res.data.totalHA,
          res.data.totalHANo,
          res.data.totalHAYes);
      }
       else
       {
         this.haBusinessLabels =[' ',' '];
         this.HaBusinessArr = [0,0];
       }
        
      } 
    });
  }
//Reaceiver Payable 
if (this.userType !== 'Super Admin') {
this.apimicroservice.getTotalPayReaciveByCustomDate(payload.formDate,payload.toDate,this.branchRef).subscribe(res => {
  if (res.status) {   
    this.payReaceiveLabels = ['Payable','Receivable']; 
    this.payReceiveArr = [];
    //
    // if(res.payData.dataPush.length > 0){
            
    //   this.payReaceiveLabels.push("Payable");
    //   this.payReceiveArr.push(res.payData.dataPush[0].totalPayable);
    // }
    // if(res.receiveData.dataPush.length > 0){
      
    //   this.payReaceiveLabels.push("Receivable");
    //   this.payReceiveArr.push(res.receiveData.dataPush[0].totalReceiveable);
    // }
    if(res.payData.dataPush.length > 0){
      this.payReceiveArr.push(res.payData.dataPush[0].totalPayable);
    }
    else
    {
      this.payReceiveArr = [0];
    }
    if(res.receiveData.dataPush.length > 0){
      this.payReceiveArr.push(res.receiveData.dataPush[0].totalReceiveable);
    }
    else
    {
      this.payReceiveArr = [0];
    }
  } 
});
}else{
  this.apimicroservice.getTotalPayReaciveByCustomDate(payload.formDate,payload.toDate,branchRef).subscribe(res => {
    if (res.status) {   
      this.payReaceiveLabels = ['Payable','Receivable']; 
      this.payReceiveArr = [];
      
      
      this.payReceiveArr.push(res.payData.dataPush[0].totalPayable,res.receiveData.dataPush[0].totalReceiveable);
      //
      // if(res.payData.dataPush.length > 0){
              
      //   this.payReaceiveLabels.push("Payable");
      //   this.payReceiveArr.push(res.payData.dataPush[0].totalPayable);
      // }
      // if(res.receiveData.dataPush.length > 0){
        
      //   this.payReaceiveLabels.push("Receivable");
      //   this.payReceiveArr.push(res.receiveData.dataPush[0].totalReceiveable);
      // }
      // if(res.payData.dataPush.length > 0){
      //   //
      //   this.payReceiveArr=res.payData.dataPush[0].totalPayable;
      
      //   //
        
      // }
      // else
      // {
      //   this.payReceiveArr = [0];
      // }
      // if(res.receiveData.dataPush.length > 0){
      //   this.payReceiveArr=(res.receiveData.dataPush[0].totalReceiveable);
      // }
      // else
      // {
      //   this.payReceiveArr = [0];
      // }
    } 
  });
}
 //inquiry
 if (this.userType !== 'Super Admin') {
 this.apimicroservice.getInquiryCountByCustom(payload.formDate,payload.toDate,this.branchRef).subscribe(res => {
  if (res.status) {   
    let TotalInquiryByInPerson = [];
    let TotalInquiryByPhone = [];
    this.InquiryArr = [
      { data: [], label: 'In-Person',lineTension:0, fill: false},
      { data: [], label: 'Phone',lineTension:0, fill: false},
      // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product B' }
    ];
  if (res.status) { 
    if(res.data.length > 0){
      res.data.forEach(element => {
        this.lineChartLabelsInquiry = ['x',element.Date];
       TotalInquiryByInPerson.push(element.TotalInquiryByInPerson);
       TotalInquiryByPhone.push(element.TotalInquiryByPhone);
      //  TotalInquiryByPhone = element.TotalInquiryByPhone;
       
      });
      this.InquiryArr = [
        { data: [0,TotalInquiryByInPerson], label: 'In-Person',lineTension:0, fill: false},
        { data: [0,TotalInquiryByPhone], label: 'Phone',lineTension:0, fill: false},
        // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product B' }
      ];
    }
  else
  {
    this.InquiryArr = [
       { data: [0, 0, 0, 0, 0, 20, 0, 0, 0, 0, 0, 0], label: 'In-Person' },
       { data: [0, 0, 0, 0, 0, 30, 0, 0, 0, 0, 0, 0], label: 'Phone' },
    ];
    this.lineChartLabelsInquiry = ['2022-06-14'];
  }
  }
  } 
}); 
 }
 else{
  this.apimicroservice.getInquiryCountByCustom(payload.formDate,payload.toDate,branchRef).subscribe(res => {
    if (res.status) {   
      let TotalInquiryByInPerson = [];
      let TotalInquiryByPhone = [];
      this.InquiryArr = [
         { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'In-Person' },
         { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Phone' },
      ];
    if (res.status) { 
      if(res.data.length > 0){
        res.data.forEach(element => {
          this.lineChartLabelsInquiry = ['x',element.Date];
         TotalInquiryByInPerson.push(element.TotalInquiryByInPerson);
         TotalInquiryByPhone.push(element.TotalInquiryByPhone);
        //  TotalInquiryByPhone = element.TotalInquiryByPhone;
         
        });
        this.InquiryArr = [
          { data: [0,TotalInquiryByInPerson], label: 'In-Person',lineTension:0, fill: false},
          { data: [0,TotalInquiryByPhone], label: 'Phone',lineTension:0, fill: false},
          // { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product B' }
        ];
      }
    else
    {
      this.InquiryArr = [
         { data: [0, 0, 0, 0, 0, 20, 0, 0, 0, 0, 0, 0], label: 'In-Person' },
         { data: [0, 0, 0, 0, 0, 30, 0, 0, 0, 0, 0, 0], label: 'Phone' },
      ];
      this.lineChartLabelsInquiry = ['2022-06-14'];
    }
    }
    } 
  }); 
 }
//clinicExpencesTotalCountByCustomDate
if (this.userType !== 'Super Admin') {
this.apimicroservice.clinicExpencesTotalCountByCustomDate(payload.formDate,payload.toDate,this.branchRef).subscribe(res => {
  if (res.status) {   
    let clinicExpenseArry = [];
    let expenceTypeFilter = [];
    let dateFilter = [];
    let exType= [];
    let dFilter= [];
    let ex1;
    let date1;
    let fd = new Date(payload.formDate);
    let td = new Date(payload.toDate);
    td.setDate(td.getDate() + 1);
    const dates = [];
    //
    //
    let i = 0;
    let day1 = 0;
        let day2 = 0;
        let day3 = 0;
        let day4 = 0;
        let day5 = 0;
        let day6 = 0;
        let day7 = 0;
        let day8 = 0;
        let day9 = 0;
        let day10 = 0;
        let day11 = 0;
        let day12 = 0;
        let day13 = 0;
        let day14 = 0;
        let day15 = 0;
    while(fd < td){
      i++;
      fd.setDate(fd.getDate() + 1);
      let day = "day"+ i;
    dates.push({"Date":new Date(fd).toISOString().slice(0,10),"day":day});
    
    }

    //
    //
    if(res.data.length > 0)
    {
    res.data.forEach(element => {
     
      clinicExpenseArry.push({"expenceType":element.expenceType,"TotalAmount":element.TotalAmount,
      "Date":element.Date
          });
       
          // exType.push(element.expenceType);
          // dFilter.push(element.Date)
         
    });
    this.labelsClinicExpenses = [];
    dates.forEach(ele =>{
      this.labelsClinicExpenses.push(ele.Date);
      });
      //
    this.clinicExpenseData = []; 
    this.clinicExpensesArr = [
      dates.forEach(element =>{
    clinicExpenseArry.forEach(ele => {
      let day1 = 0;
        let day2 = 0;
        let day3 = 0;
        let day4 = 0;
        let day5 = 0;
        let day6 = 0;
        let day7 = 0;
        let day8 = 0;
        let day9 = 0;
        let day10 = 0;
        let day11 = 0;
        let day12 = 0;
        let day13 = 0;
        let day14 = 0;
        let day15 = 0;
        if(element.Date == ele.Date){
          if(element.day == "day1"){
            day1 = ele.TotalAmount;
          }
          if(element.day == "day2"){
            day2 = ele.TotalAmount;
          }
          if(element.day == "day3"){
            day3 = ele.TotalAmount;
          }
          if(element.day == "day4"){
            day4 = ele.TotalAmount;
          }
          if(element.day == "day5"){
            day5 = ele.TotalAmount;
          }
          if(element.day == "day6"){
            day6 = ele.TotalAmount;
          }
          if(element.day == "day7"){
            day7 = ele.TotalAmount;
          }
          if(element.day == "day8"){
            day8 = ele.TotalAmount;
          }
          if(element.day == "day9"){
            day9 = ele.TotalAmount;
          }
          if(element.day == "day10"){
            day10 = ele.TotalAmount;
          }
          if(element.day == "day11"){
            day11 = ele.TotalAmount;
          }
          if(element.day == "day12"){
            day12 = ele.TotalAmount;
          }
          if(element.day == "day13"){
            day13 = ele.TotalAmount;
          }
          if(element.day == "day14"){
            day14 = ele.TotalAmount;
          }
          if(element.day == "day15"){
            day15 = ele.TotalAmount;
          }
          this.clinicExpenseData.push({ data:[day1,day2,day3,day4,day5,day6,day7,day8,day9,day10,day11,day12,day13,day14,day15], label:ele.expenceType })
        }
      
    })
  })
  
  ]
}
else
{
  this.clinicExpenseData=[{ data:[0,0,0,0,0,30,0,0,0,0,0,0], label:'Electricity Bill' },
  { data:[0,0,0,0,0,10,0,0,0,0,0,0], label:'Travelling' }],
  this.labelsClinicExpenses = ['2022-06-14'];

}
  } 
}); 
}
else{
  this.apimicroservice.clinicExpencesTotalCountByCustomDate(payload.formDate,payload.toDate,branchRef).subscribe(res => {
    if (res.status) {   
      let clinicExpenseArry = [];
      let expenceTypeFilter = [];
      let dateFilter = [];
      let exType= [];
      let dFilter= [];
      let ex1;
      let date1;
      let fd = new Date(payload.formDate);
      let td = new Date(payload.toDate);
      td.setDate(td.getDate() + 1);
      const dates = [];
      //
      //
      let i = 0;
      let day1 = 0;
          let day2 = 0;
          let day3 = 0;
          let day4 = 0;
          let day5 = 0;
          let day6 = 0;
          let day7 = 0;
          let day8 = 0;
          let day9 = 0;
          let day10 = 0;
          let day11 = 0;
          let day12 = 0;
          let day13 = 0;
          let day14 = 0;
          let day15 = 0;
      while(fd < td){
        i++;
        fd.setDate(fd.getDate() + 1);
        let day = "day"+ i;
      dates.push({"Date":new Date(fd).toISOString().slice(0,10),"day":day});
      
      }
  
      //
      //
      if(res.data.length > 0)
      {
      res.data.forEach(element => {
       
        clinicExpenseArry.push({"expenceType":element.expenceType,"TotalAmount":element.TotalAmount,
        "Date":element.Date
            });
         
            // exType.push(element.expenceType);
            // dFilter.push(element.Date)
           
      });
      this.labelsClinicExpenses = [];
      dates.forEach(ele =>{
        this.labelsClinicExpenses.push(ele.Date);
        });
        //
      this.clinicExpenseData = []; 
      this.clinicExpensesArr = [
        dates.forEach(element =>{
      clinicExpenseArry.forEach(ele => {
        let day1 = 0;
          let day2 = 0;
          let day3 = 0;
          let day4 = 0;
          let day5 = 0;
          let day6 = 0;
          let day7 = 0;
          let day8 = 0;
          let day9 = 0;
          let day10 = 0;
          let day11 = 0;
          let day12 = 0;
          let day13 = 0;
          let day14 = 0;
          let day15 = 0;
          if(element.Date == ele.Date){
            if(element.day == "day1"){
              day1 = ele.TotalAmount;
            }
            if(element.day == "day2"){
              day2 = ele.TotalAmount;
            }
            if(element.day == "day3"){
              day3 = ele.TotalAmount;
            }
            if(element.day == "day4"){
              day4 = ele.TotalAmount;
            }
            if(element.day == "day5"){
              day5 = ele.TotalAmount;
            }
            if(element.day == "day6"){
              day6 = ele.TotalAmount;
            }
            if(element.day == "day7"){
              day7 = ele.TotalAmount;
            }
            if(element.day == "day8"){
              day8 = ele.TotalAmount;
            }
            if(element.day == "day9"){
              day9 = ele.TotalAmount;
            }
            if(element.day == "day10"){
              day10 = ele.TotalAmount;
            }
            if(element.day == "day11"){
              day11 = ele.TotalAmount;
            }
            if(element.day == "day12"){
              day12 = ele.TotalAmount;
            }
            if(element.day == "day13"){
              day13 = ele.TotalAmount;
            }
            if(element.day == "day14"){
              day14 = ele.TotalAmount;
            }
            if(element.day == "day15"){
              day15 = ele.TotalAmount;
            }
            this.clinicExpenseData.push({ data:[day1,day2,day3,day4,day5,day6,day7,day8,day9,day10,day11,day12,day13,day14,day15], label:ele.expenceType })
          }
        
      })
    })
    
    ]
  }
  else
  {
    this.clinicExpenseData=[{ data:[0,0,0,0,0,30,0,0,0,0,0,0], label:'Electricity Bill' },
    { data:[0,0,0,0,0,10,0,0,0,0,0,0], label:'Travelling' }],
    this.labelsClinicExpenses = ['2022-06-14'];
  
  }
    } 
  }); 
}
//mode of payment
this.apimicroservice.getPaymentModeCountByCustomDate(payload.formDate,payload.toDate).subscribe(res => {
  // if(res.countData.dataPush > 0)
  // {
  //   res.countData.dataPush.forEach(element => {
  //     //
  //     this.pieChartDataPaymentMode =[element.TotalPaymentDoneByCash,element.TotalPaymentDoneByCard,
  //       element.TotalPaymentDoneByCheque,element.TotalPaymentDoneByNEFT,element.TotalPaymentDoneByUPI];
  //   });
  // }
  if(res.data.length > 0)
  {
      this.pieChartDataPaymentMode =[res.data.TotalPaymentDoneByCash,res.data.TotalPaymentDoneByCard,
        res.data.TotalPaymentDoneByCheque,res.data.TotalPaymentDoneByNEFT,res.data.TotalPaymentDoneByUPI];
        this.paymentMode=['Cash', 'Card','Cheque','NEFT','UPI'];
  }
else
{
  this.pieChartDataPaymentMode =[0,0,0,0,0];
  this.paymentMode=['Cash', 'Card','Cheque','NEFT','UPI'];
}
});

//cheque status by custom date
this.apimicroservice.getChequeStatusCountByCustomDate(payload.formDate,payload.toDate).subscribe(res => {
 // //
 //
 
  if(res.data )
  {
   
    // //
    // res.data.forEach(element => {
    //   //
    //   this.pieChartDataCheque =[res.data.totalChequeBounce,res.data.totalChequeCancelled,
    //     res.data.totalChequeClear,res.data.totalChequeDeposited, res.data.totalChequePostdated,res.data.totalChequeReceived,res.data.totalChequeReturn];
 
    //   });
      this.pieChartDataCheque =[res.data.totalChequeBounce,res.data.totalChequeCancelled,
        res.data.totalChequeClear,res.data.totalChequeDeposited, res.data.totalChequePostdated,res.data.totalChequeReceived,res.data.totalChequeReturn];
  }
else
{
  this.pieChartDataCheque =[0,0,0,0,0,0,0];
}
});
// this.tDate = new Date(ele.endDate);

//                 this.tDate.setDate(this.tDate.getDate()+1);
let newFromDate=new Date(payload.formDate);
newFromDate.setDate(newFromDate.getDate()+1);
let newToDate =new Date(payload.toDate);
newToDate.setDate(newToDate.getDate()+1);
//ASP
if (this.userType !== 'Super Admin'){
  this.apimicroservice.getASPPerCustomDate(newFromDate,newToDate,this.branchRef).subscribe(res => { 
    if (res.status) {   
        this.totalASP = res.totalASP;
        //
    }  
  },(ERROR:HttpErrorResponse) => {
    this.totalASP = 0;
});
}
else{
  this.apimicroservice.getASPPerCustomDate(newFromDate,newToDate,branchRef).subscribe(res => { 
    if (res.status) {   
        this.totalASP = res.totalASP;
        //
    }  
  },(ERROR:HttpErrorResponse) => {
    this.totalASP = 0;
});
}
 

//Docter
if (this.userType !== 'Super Admin') {
this.apimicroservice.appointmentDoneByDoctorInCustomDate(payload.formDate,payload.toDate,this.branchRef).subscribe(res => {
  if (res.status) {    
    let doctArry = [];
    let lableArr = [];
    let unique;
    let docterArr = [];
    let docterArrFilter =[];
    let docterCount = [];
    let docterCountFilter =[];
    this.docterLabelsInquiry = [];

    let fd = new Date(payload.formDate);
    let td = new Date(payload.toDate);
    td.setDate(td.getDate() + 1);
    const dates = [];
    //
    //
    let i = 0;
    let day1 = 0;
        let day2 = 0;
        let day3 = 0;
        let day4 = 0;
        let day5 = 0;
        let day6 = 0;
        let day7 = 0;
        let day8 = 0;
        let day9 = 0;
        let day10 = 0;
        let day11 = 0;
        let day12 = 0;
        let day13 = 0;
        let day14 = 0;
        let day15 = 0;
    while(fd < td){
      i++;
      fd.setDate(fd.getDate() + 1);
      let day = "day"+ i;
    dates.push({"Date":new Date(fd).toISOString().slice(0,10),"day":day});
    
    }

    //
    //
    if(res.data.dataPush > 0)
    {

    res.data.dataPush.forEach(element => {

      doctArry.push({"Doctor":element.Doctor,"AppointmentCompletdByDoctor":element.AppointmentCompletdByDoctor
        ,"Date":element.Date
        });
           
    });
    this.docterLabelsInquiry = [];
    dates.forEach(ele =>{
      this.docterLabelsInquiry.push(ele.Date);
      });
      //
     
    this.docterArryData = []; 
    this.docterArr = [
      dates.forEach(element =>{
        doctArry.forEach(ele => {
      let day1 = 0;
        let day2 = 0;
        let day3 = 0;
        let day4 = 0;
        let day5 = 0;
        let day6 = 0;
        let day7 = 0;
        let day8 = 0;
        let day9 = 0;
        let day10 = 0;
        let day11 = 0;
        let day12 = 0;
        let day13 = 0;
        let day14 = 0;
        let day15 = 0;
        if(element.Date == ele.Date){
          if(element.day == "day1"){
            day1 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day2"){
            day2 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day3"){
            day3 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day4"){
            day4 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day5"){
            day5 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day6"){
            day6 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day7"){
            day7 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day8"){
            day8 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day9"){
            day9 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day10"){
            day10 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day11"){
            day11 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day12"){
            day12 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day13"){
            day13 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day14"){
            day14 = ele.AppointmentCompletdByDoctor;
          }
          if(element.day == "day15"){
            day15 = ele.AppointmentCompletdByDoctor;
          }
          this.docterArryData.push({ data:[day1,day2,day3,day4,day5,day6,day7,day8,day9,day10,day11,day12,day13,day14,day15], label:ele.Doctor })
        }
      
    })
  })
  ]
    }
    else
    {
      this.docterArryData=[{ data:[0,10,0,0,0,30,0,0,0,0,0,0], label:'Docter A'},
      { data:[0,30,0,0,0,20,0,0,0,0,0,0], label:'Docter B'}]
      this.docterLabelsInquiry = [this.day1,this.day2,this.day3,this.day4,this.day5,this.day6,this.day7,this.day8,
        this.day9,this.day10,this.day11,this.day12,this.day13,this.day14,this.day15];
    }
 

    //   res.data.dataPush.forEach(element => {
    //     // /this.docterLabelsInquiry = ['x',element.Date];
    //    // this.docterLabelsInquiry.push(element.Date);
    //  //  lableArr = [];
    //  docterArr.push(element.Doctor);
    //    //]);
    //   docterArrFilter=[...new Set(docterArr)];    

    //   doctArry.push({"Doctor":docterArrFilter,"AppointmentCompletdByDoctor":element.AppointmentCompletdByDoctor
    //   ,"Date":element.Date
    //   });
    //   });
     
    //   unique = [...new Set(lableArr)];
    //   //
    //   this.docterLabelsInquiry=unique;
    //   //
    //   this.docterArryData = [];   
    //         this.docterArr = [
    //           doctArry.forEach(element => {
               
    //           let JanCount = 0;
    //           //
    //                       //
    //                       unique.forEach((e,i) => {
    //                       //
                          
    //                                               });
    //                       if(element.Date == 1)
    //                       {
    //                         JanCount = element.AppointmentCompletdByDoctor;
    //                       }
    //                     //  unique = [...new Set(lableArr)];
    //             this.docterArryData.push({ data:[element.AppointmentCompletdByDoctor], label:element.Doctor })
    //         })
    //       ]
        }
});
}
else{
  
  
  this.apimicroservice.appointmentDoneByDoctorInCustomDate(payload.formDate,payload.toDate,branchRef).subscribe(res => {
    if (res.status) {    
      let doctArry = [];
      let lableArr = [];
      let unique;
      let docterArr = [];
      let docterArrFilter =[];
      let docterCount = [];
      let docterCountFilter =[];
      this.docterLabelsInquiry = [];
  
      let fd = new Date(payload.formDate);
      let td = new Date(payload.toDate);
      td.setDate(td.getDate() + 1);
      const dates = [];
      //
      //
      let i = 0;
      let day1 = 0;
          let day2 = 0;
          let day3 = 0;
          let day4 = 0;
          let day5 = 0;
          let day6 = 0;
          let day7 = 0;
          let day8 = 0;
          let day9 = 0;
          let day10 = 0;
          let day11 = 0;
          let day12 = 0;
          let day13 = 0;
          let day14 = 0;
          let day15 = 0;
      while(fd < td){
        i++;
        fd.setDate(fd.getDate() + 1);
        let day = "day"+ i;
      dates.push({"Date":new Date(fd).toISOString().slice(0,10),"day":day});
      
      }
  
      //
      //
      if(res.data.dataPush.length > 0)
      {
  
      res.data.dataPush.forEach(element => {
  
        doctArry.push({"Doctor":element.Doctor,"AppointmentCompletdByDoctor":element.AppointmentCompletdByDoctor
          ,"Date":element.Date
          });
             
      });
      this.docterLabelsInquiry = [];
      dates.forEach(ele =>{
        this.docterLabelsInquiry.push(ele.Date);
        });
        //
       
      this.docterArryData = []; 
      this.docterArr = [
        dates.forEach(element =>{
          doctArry.forEach(ele => {
        let day1 = 0;
          let day2 = 0;
          let day3 = 0;
          let day4 = 0;
          let day5 = 0;
          let day6 = 0;
          let day7 = 0;
          let day8 = 0;
          let day9 = 0;
          let day10 = 0;
          let day11 = 0;
          let day12 = 0;
          let day13 = 0;
          let day14 = 0;
          let day15 = 0;
          this.docterLabelsInquiry.push(element.Date);
          if(element.Date == ele.Date){
            if(element.day == "day1"){
              day1 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day2"){
              day2 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day3"){
              day3 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day4"){
              day4 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day5"){
              day5 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day6"){
              day6 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day7"){
              day7 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day8"){
              day8 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day9"){
              day9 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day10"){
              day10 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day11"){
              day11 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day12"){
              day12 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day13"){
              day13 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day14"){
              day14 = ele.AppointmentCompletdByDoctor;
            }
            if(element.day == "day15"){
              day15 = ele.AppointmentCompletdByDoctor;
            } 
            this.docterArryData.push({ data:[day1,day2,day3,day4,day5,day6,day7,day8,day9,day10,day11,day12,day13,day14,day15], label:ele.Doctor })

          }
        
      }) 
    })
    ]
 
      }
      else
      {
        this.docterArryData=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:''},
        { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:''}]
        this.docterLabelsInquiry = [this.day1,this.day2,this.day3,this.day4,this.day5,this.day6,this.day7,this.day8,
          this.day9,this.day10,this.day11,this.day12,this.day13,this.day14,this.day15];
    
      }
  
  
      //   res.data.dataPush.forEach(element => {
      //     // /this.docterLabelsInquiry = ['x',element.Date];
      //    // this.docterLabelsInquiry.push(element.Date);
      //  //  lableArr = [];
      //  docterArr.push(element.Doctor);
      //    //]);
      //   docterArrFilter=[...new Set(docterArr)];    
  
      //   doctArry.push({"Doctor":docterArrFilter,"AppointmentCompletdByDoctor":element.AppointmentCompletdByDoctor
      //   ,"Date":element.Date
      //   });
      //   });
       
      //   unique = [...new Set(lableArr)];
      //   //
      //   this.docterLabelsInquiry=unique;
      //   //
      //   this.docterArryData = [];   
      //         this.docterArr = [
      //           doctArry.forEach(element => {
                 
      //           let JanCount = 0;
      //           //
      //                       //
      //                       unique.forEach((e,i) => {
      //                       //
                            
      //                                               });
      //                       if(element.Date == 1)
      //                       {
      //                         JanCount = element.AppointmentCompletdByDoctor;
      //                       }
      //                     //  unique = [...new Set(lableArr)];
      //             this.docterArryData.push({ data:[element.AppointmentCompletdByDoctor], label:element.Doctor })
      //         })
      //       ]
          }
  });
}
    }
    else
    {
      this.apimicroservice.openSnackbar("Enter From Date");
    }
   
  }
  if(){
  }
  
  getASPPerMonth()
  {
    this.apimicroservice.getASPPerMonth(this.branchRef).subscribe(res => {
      if (res.status) {   
          this.totalASP = res.totalASP;
      }  
    },(ERROR:HttpErrorResponse) => {
      this.totalASP = 0;
  });
  }
   //ASP by Year
  //  getASPPerYear()
  //  {
  //    this.apimicroservice.getASPPerYear().subscribe(res => {
  //      if (res.status) {   
  //          this.totalASP = res.totalASP;
  //      } 
  //    },(ERROR:HttpErrorResponse) => {
  //      this.totalASP = 0;
  //  });
  //  }
  scrollto(e)
  {

  }
  RepairActivity(e)
  { 
    let payload = this.dashboardForm.value;
    let branchRef='';
    if(payload.branchRefId == '' || payload.branchRefId == null)
    {
      branchRef='';
    }
    else
    {
      branchRef=payload.branchRefId;
    }
    if(payload.selectType == 'Monthly')
    {
     
      if(this.userType !== 'Super Admin'){
        this.apimicroservice.getASPPerMonth(this.branchRef).subscribe(res => {
          if (res.status) {   
              this.totalASP = res.totalASP;
          }  
        },(ERROR:HttpErrorResponse) => {
          this.totalASP = 0;
      });
      }
      else{
        this.apimicroservice.getASPPerMonth(branchRef).subscribe(res => {
          if (res.status) {   
              this.totalASP = res.totalASP;
          }  
        },(ERROR:HttpErrorResponse) => {
          this.totalASP = 0;
      });
      }
      //cheque status month
      if (this.userType !== 'Super Admin') {
 this.apimicroservice.getChequeStatusCountByMonth(this.branchRef).subscribe(res => {
  if (res.status) {   
    if(res.countData.dataPush.length > 0){
    res.countData.dataPush.forEach(element => {
      //
      
      //
    //  this.paymentStatusArr =[element.PaymentStatusPaid,element.PaymentStatusUnpaid];
      this.pieChartDataCheque =[element.TotalChequeBounce,element.TotalChequeClear,
        element.TotalChequeDeposited,element.TotalChequePostdated,element.TotalChequeReturn,element.TotalChequeCancelled,element.TotalChequeReceived];
    });
  }
    else
    {
      this.pieChartDataCheque =[0,0,0,0];
    }
  } 
}); 
      }
      else{
        this.apimicroservice.getChequeStatusCountByMonth(branchRef).subscribe(res => {
          if (res.status) {   
            if(res.countData.dataPush.length > 0){
            res.countData.dataPush.forEach(element => {
              //
              
              //
            //  this.paymentStatusArr =[element.PaymentStatusPaid,element.PaymentStatusUnpaid];
              this.pieChartDataCheque =[element.TotalChequeBounce,element.TotalChequeClear,
                element.TotalChequeDeposited,element.TotalChequePostdated,element.TotalChequeReturn,element.TotalChequeCancelled,element.TotalChequeReceived];
            });
          }
            else
            {
              this.pieChartDataCheque =[0,0,0,0];
            }
          } 
        });
      }
      this.apimicroservice.getStepperInfoByMonth().subscribe(res => {
        this.scenario1data =[];
        this.scenarioData2 =[];
        if (res.status) {   
          let scenario1data=[];
          let scenarioData2=[];
          if(e == 'scenario1')
          {
            this.scenario1 = true;
            this.scenario2 = false;
          this.scenario1dataLabel=["Step 1","Step 2","Step 3","Step 4","Step 5","Step 6","Step 7","Step 8","Step 9","Step 10","Step 11","Step 12","Step 13"]
          if(res.scenario1data.scenarioData1.length > 0){
          res.scenario1data.scenarioData1.forEach(element => {
            this.scenario1data.push({ data:element.data, label:element.status })
          });
        }
        else
        {
          this.scenario1data=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'scenario 1' },
          { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'scenario 2' }]
        }
          
        }
        else 
        {
          this.scenario2 = true;
          this.scenario1 = false;
          this.scenario2dataLabel = ["Step 1","Step 2","Step 3","Step 4","Step 5","Step 6","Step 7","Step 8","Step 9","Step 10","Step 11"];
          if(res.scenario2data.scenarioData2.length > 0){
          res.scenario2data.scenarioData2.forEach(element => {
            this.scenarioData2.push({ data:element.data, label:element.status })
          });
          }
          else
          {
            this.scenarioData2=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'scenario 1' },
            { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'scenario 2' }]
          }
        } 
      }
      }); 
    }
    if(payload.selectType == 'Yearly')
    {
      this.apimicroservice.getStepperInfoByYear().subscribe(res => {
        this.scenario1data =[];
        this.scenarioData2 =[];
        if (res.status) {   
          let scenario1data=[];
          let scenarioData2=[];
          if(e == 'scenario1')
          {
            this.scenario1 = true;
            this.scenario2 = false;
          this.scenario1dataLabel=["Step 1","Step 2","Step 3","Step 4","Step 5","Step 6","Step 7","Step 8","Step 9","Step 10","Step 11","Step 12","Step 13"]
       if(res.scenario1data.scenarioData1.length > 0)
       {
        res.scenario1data.scenarioData1.forEach(element => {
          this.scenario1data.push({ data:element.data, label:element.status })
        });
       }
          else
          {
            this.scenario1data=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'' },
            { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:' ' }]
          }
        }
        else 
        {
          this.scenario2 = true;
          this.scenario1 = false;
          this.scenario2dataLabel = ["Step 1","Step 2","Step 3","Step 4","Step 5","Step 6","Step 7","Step 8","Step 9","Step 10","Step 11"];
          if(res.scenario2data.scenarioData2.length > 0)
       {
          res.scenario2data.scenarioData2.forEach(element => {
            this.scenarioData2.push({ data:element.data, label:element.status })
          });
        }
          else
          {
            this.scenarioData2=[{ data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'' },
            { data:[0,0,0,0,0,0,0,0,0,0,0,0], label:'' }]
          }
        } 
      }
      }); 
    }
    if(payload.selectType == 'Custom')
    {
      this.apimicroservice.getStepperInfoByCustomDate(payload.formDate,payload.toDate).subscribe(res => {
        this.scenario1data =[];
        this.scenarioData2 =[];
        if (res.status) {   
          let scenario1data=[];
          let scenarioData2=[];
          if(e == 'scenario1')
          {
            this.scenario1 = true;
            this.scenario2 = false;
          this.scenario1dataLabel=["Step 1","Step 2","Step 3","Step 4","Step 5","Step 6","Step 7","Step 8","Step 9","Step 10","Step 11","Step 12","Step 13"]
          if(res.scenario1data.scenarioData1.length > 0)
          {
          res.scenario1data.scenarioData1.forEach(element => {
            this.scenario1data.push({ data:element.data, label:element.status })
          });
        }
        }
        else 
        {
          this.scenario2 = true;
          this.scenario1 = false;
          this.scenario2dataLabel = ["Step 1","Step 2","Step 3","Step 4","Step 5","Step 6","Step 7","Step 8","Step 9","Step 10","Step 11"];
          if(res.scenario2data.scenarioData2.length > 0)
          {
          res.scenario2data.scenarioData2.forEach(element => {
            this.scenarioData2.push({ data:element.data, label:element.status })
          });
          }
        } 
      }
      }); 
    }


  }
  onback() {
    this.apimicroservice.back()
  }

  branchClick(id,branchName)
  {
    let payload = this.dashboardForm.value; 
    this.selectType1 = payload.selectType;
  //   this.selectType(this.selectType1);
  let newpayload = new Promise<void>((resolve, reject) => {
    let payload = this.dashboardForm.value;
     this.branchRef =id;
     //
    setTimeout(() => {
      resolve();
    }, 1000);
  });
  //
  //
  
  this.selectType(payload.selectType);
  }

  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
}