import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from '../api-service.service';
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupBrandComponent } from "../popup-brand/popup-brand.component";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpStyleComponent } from "../pop-up-style/pop-up-style.component";
import { PopUpDevicetypeComponent } from "../pop-up-devicetype/pop-up-devicetype.component";
import { PopUpBatterytypeComponent } from "../pop-up-batterytype/pop-up-batterytype.component";
import { PopUpConditionComponent } from "../pop-up-condition/pop-up-condition.component";
import { PopupBillingcompanyComponent } from '../popup-billingcompany/popup-billingcompany.component';

@Component({
  selector: 'app-pop-up-device',
  templateUrl: './pop-up-device.component.html',
  styleUrls: ['./pop-up-device.component.scss']
})
export class PopUpDeviceComponent implements OnInit {
  errormessage: string;
  searchText: any;
  devicelist: any;
  id: any;
  allVent=[
    {name:'No Vent',value:'No Vent'},
    { name:'1 mm',value:'1 mm' },
    {name:'2 mm',value:'2 mm' },
    { name:'IROS',value:'IROS'},
   
  ]
filteredList: { name: string; value: string; }[];
filteredDeviceList: { name: string; value: string; }[];
filteredConditionList: { name: string; value: string; }[];
filteredAllVentList: { name: string; value: string; }[];

filteredListStyle: { name: string; value: string; }[];
filteredListbatteryType: { name: string; value: string; }[];
brandlist: any;
branchRef: string;
clinicRef: string;
BranchList: any;
deviceTypelist:any;
styleList:any;
batteryTypeList:any;
conditionTypeList:any;
userType: string;
branchName: string;
filteredListBranch: any;
listOfBilling: any;
filteredBillingCompany: any;
isGstApplicable= false;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PopUpDeviceComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  ) { dialogRef.disableClose = true;}

  deviceMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton=false;
  loggedId: any;
  myimage;
  date: any;
  size:number = 5;
  page:number = 1;
  totalLength: any;
  arr = [];
  userPermission:any;
  devicesCreate= true;
  devicesUpdate= true;
  isSuperAdmin= true;
  superBranchId: any;

  ngOnInit(): void {
    this.date = new Date();
    this.page=1;
    //this.filteredListStyle = this.deviceStyle.slice();
   // this.filteredListbatteryType = this.batteryType.slice();
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
   // this.filteredDeviceList = this.allDeviceType.slice();
    // this.filteredConditionList = this.allCondition.slice();
    this.filteredAllVentList = this.allVent.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Devices' && element.create == false)
      {
          this.devicesCreate = false;  
      }
      if(element.pageName == 'Devices' && element.update == false)
      {
          this.devicesUpdate = false;  
      }    
    });
  }
    this.deviceMasterForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "searchDevice": new FormControl(''),
      "brand": new FormControl('',Validators.required),
      "brandLable": new FormControl(''),
      "model": new FormControl('',Validators.required),
      //"serialNo": new FormControl('',Validators.required),
      "deviceType": new FormControl('',Validators.required),
      "style": new FormControl(''),
      "batteryType": new FormControl(''),
      "cordType": new FormControl(''),
      "ear": new FormControl(''),
      "technology": new FormControl(''),
      "condition": new FormControl(''),
      "deviceColor": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "shellColor": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "facePlateColor": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "vent": new FormControl(''),
      "matrix": new FormControl(''),
      "receiver": new FormControl(''),
      "dome": new FormControl(''),
      "facePlateNumber": new FormControl('',Validators.pattern("^[0-9 ]*$")),
      "waxManagement": new FormControl(''),
      "removalString": new FormControl(''),
      "notes": new FormControl(''),
      "createdAt": this.date,
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "branchName": new FormControl(),
      "billingCompanyRefId": new FormControl('',Validators.required),
      "billingCompanyName": new FormControl(),
      "gstApplicable": new FormControl('No',Validators.required),
      "has": new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(6),Validators.minLength(4)]),
      "sgst": new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(3)]),
      "cgst": new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(3)]),
      "igst": new FormControl('',[Validators.pattern("^[0-9 ]*$"),Validators.maxLength(3)]),
      "mrp": new FormControl('',[Validators.required,Validators.pattern("^[0-9 ]*$"),Validators.maxLength(8)]),
    });
     this.deviceMasterForm.controls["deviceType"].setValue("Hearing Aid");
     this.deviceMasterForm.controls["technology"].setValue("Digital");
     this.deviceMasterForm.controls["style"].setValue("RIC");
     //this.deviceMasterForm.controls["batteryType"].setValue("Rechargable");
     this.deviceMasterForm.controls["ear"].setValue("Not Defined");
     this.deviceMasterForm.controls["batteryType"].setValue("Rechargable");
    this.fetchDeviceList();
    this.getLastId();
    this.fetchBrandList();
    this.fetchDeviceTypeList();
    this.fetchStyleTypeList();
    this.fetchBatteryTypeList();
    this.fetchConditionTypeList();
    this.fetchBillingCompanyDetails();
    this.removeValidator();
    this.fetchBranchList();
    this.getAllBranch();
   
  }


  getAllBranch()
  {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {        
        this.deviceMasterForm.get("branchRefId").setValue(res.data[0]._id);
        this.branchClick(res.data[0]._id,this.branchName);
        if(this.userType == 'Super Admin')
        {  
          this.apimicroservice.getBranchById(res.data[0]._id).subscribe(res => {
            this.spinner.hide();
            this.deviceMasterForm.get("branchName").setValue(res.data[0].branchName);
           });
        }
      }
    });
  }
  submitDevice() {
    let payload = this.deviceMasterForm.value;
    this.setValidor();
    if(this.userType == 'Super Admin')
    {
      this.deviceMasterForm.controls['branchRefId'].setValidators(Validators.required);
      this.deviceMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.deviceMasterForm.controls['branchRefId'].setErrors(null);
      this.deviceMasterForm.controls['branchRefId'].clearValidators();
      this.deviceMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    if(payload.gstApplicable == 'Yes')
    {
      this.deviceMasterForm.controls['has'].setValidators(Validators.required);
      this.deviceMasterForm.controls['has'].updateValueAndValidity();

      this.deviceMasterForm.controls['sgst'].setValidators(Validators.required);
      this.deviceMasterForm.controls['sgst'].updateValueAndValidity();
      
      this.deviceMasterForm.controls['cgst'].setValidators(Validators.required);
      this.deviceMasterForm.controls['cgst'].updateValueAndValidity();
      
      this.deviceMasterForm.controls['igst'].setValidators(Validators.required);
      this.deviceMasterForm.controls['igst'].updateValueAndValidity();
      

    }
    else{
      this.deviceMasterForm.controls['has'].setErrors(null);
      this.deviceMasterForm.controls['has'].clearValidators();
      this.deviceMasterForm.controls['has'].updateValueAndValidity();

      this.deviceMasterForm.controls['sgst'].setErrors(null);
      this.deviceMasterForm.controls['sgst'].clearValidators();
      this.deviceMasterForm.controls['sgst'].updateValueAndValidity();

      this.deviceMasterForm.controls['cgst'].setErrors(null);
      this.deviceMasterForm.controls['cgst'].clearValidators();
      this.deviceMasterForm.controls['cgst'].updateValueAndValidity();

      this.deviceMasterForm.controls['igst'].setErrors(null);
      this.deviceMasterForm.controls['igst'].clearValidators();
      this.deviceMasterForm.controls['igst'].updateValueAndValidity();

    }
    if (this.deviceMasterForm.invalid) {
      this.deviceMasterForm.get("brand").markAsTouched();
      this.deviceMasterForm.get("model").markAsTouched();
      this.deviceMasterForm.get("deviceType").markAsTouched();
       this.deviceMasterForm.get("mrp").markAsTouched();
      this.deviceMasterForm.get("billingCompanyRefId").markAsTouched();
      this.deviceMasterForm.get("has").markAsTouched();
      this.deviceMasterForm.get("sgst").markAsTouched();
      this.deviceMasterForm.get("igst").markAsTouched();
      this.deviceMasterForm.get("cgst").markAsTouched();
      
      return;
    } else {
      this.date = new Date();
    
     // payload.brandLable = this.arr;
      this.spinner.show();
      if(this.userType !== 'Super Admin')
      {
        payload.branchRefId = this.branchRef;
        payload.clinicRefId = this.clinicRef;
        payload.branchName = this.branchName;
      }
      this.apimicroservice.addDevice(payload).subscribe((res) => {
        this.spinner.hide(); 
        if (res.status) {
          this.apimicroservice.openSnackbar(res.message);
          this.dialogRef.close();
          this.ngOnInit();
          
        } else {
        }
      });
    }
  }

  removeValidator() { 
    this.deviceMasterForm.controls['brand'].setErrors(null);
    this.deviceMasterForm.controls['brand'].clearValidators();
    this.deviceMasterForm.controls['brand'].updateValueAndValidity();

    this.deviceMasterForm.controls['model'].setErrors(null);
    this.deviceMasterForm.controls['model'].clearValidators();
    this.deviceMasterForm.controls['model'].updateValueAndValidity();

   
  
    }

    setValidor() { 
     

      this.deviceMasterForm.controls['brand'].setValidators(Validators.required);
      this.deviceMasterForm.controls['brand'].updateValueAndValidity();

      this.deviceMasterForm.controls['model'].setValidators(Validators.required);
      this.deviceMasterForm.controls['model'].updateValueAndValidity();

    

      }

  update() {
    this.setValidor();
    if(this.userType == 'Super Admin')
    {
      this.deviceMasterForm.controls['branchRefId'].setValidators(Validators.required);
      this.deviceMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.deviceMasterForm.controls['branchRefId'].setErrors(null);
      this.deviceMasterForm.controls['branchRefId'].clearValidators();
      this.deviceMasterForm.controls['branchRefId'].updateValueAndValidity();
    }
    if (this.deviceMasterForm.invalid) {
      this.deviceMasterForm.get("brand").markAsTouched();
      this.deviceMasterForm.get("model").markAsTouched();
      this.deviceMasterForm.get("deviceType").markAsTouched();
      return;
    } else {
      this.updateDevice();
    }
  }

  updateUpdateSingleCustomer() {
    if (this.deviceMasterForm.invalid) {
      // this.deviceMasterForm.get("firstName").markAsTouched();
      // this.deviceMasterForm.get("lastName").markAsTouched();
      // this.deviceMasterForm.get("customerEmail").markAsTouched();
      // this.deviceMasterForm.get("mobile1").markAsTouched();
      // this.deviceMasterForm.get("mobile2").markAsTouched();
      // this.deviceMasterForm.get("mobile3").markAsTouched();
      // this.deviceMasterForm.get("mobile4").markAsTouched();
      // this.deviceMasterForm.get("mobile5").markAsTouched();
      // this.deviceMasterForm.get("addressLineOne").markAsTouched();
      // this.deviceMasterForm.get("addressLineTwo").markAsTouched();
      // this.deviceMasterForm.get("city").markAsTouched();
      // this.deviceMasterForm.get("state").markAsTouched();
      // this.deviceMasterForm.get("country").markAsTouched();
      // this.deviceMasterForm.get("pincode").markAsTouched();
      // this.deviceMasterForm.get("userRole").markAsTouched();
      // this.deviceMasterForm.get("userPermission").markAsTouched();
      // this.deviceMasterForm.get("Status").markAsTouched();
      return;
    } else {
    //update code
    }
  }
  
 
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  

  fetchDeviceList() {
    this.spinner.show();
    let pg = 1;
    let size = 5;
    let search='';
   
    this.searchText = this.deviceMasterForm.get('searchDevice').value;    
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    
     if(this.userType !== 'Super Admin')
     {
    this.apimicroservice.getDeviceList(pg,size,search,branchRef,clinicRef).subscribe(res => {
     this.spinner.hide();
       this.devicelist = res.data.deviceData;
       this.totalLength = res.data.totalItems;     
      if(res.status){
    //this.apimicroservice.openSnackbar(res.message);
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }

    }); 
  }
  else 
  {
    this.apimicroservice.getDeviceList(pg,size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      this.spinner.hide();
        this.devicelist = res.data.deviceData;
        this.totalLength = res.data.totalItems;     
       if(res.status){
  //   this.apimicroservice.openSnackbar(res.message);
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
 
     }); 
  }
  }

  // prepopulation(id) {
  //   this.spinner.show();
  //   this.apimicroservice.getDeviceById(id).subscribe(res => {
  //     this.spinner.hide();
  //    let data = res.data[0];
  //    this.submitButton = false;
  //    this.updateButton = true;
  //    this.cancelButton=true;
  //     if(res.status){
  //       this.deviceMasterForm = this.fb.group({
  //         "branchWiseId": new FormControl(data.branchWiseId),
  //     "brand": new FormControl(data.brand,Validators.required),
  //     "brandLable": new FormControl(data.brandLable),
  //     "model": new FormControl(data.model,Validators.required),
  //     //"serialNo": new FormControl(data.serialNo,Validators.required),
  //     "deviceType": new FormControl(data.deviceType,Validators.required),
  //     "style": new FormControl(data.style),
  //     "batteryType": new FormControl(data.batteryType),
  //     "cordType": new FormControl(data.cordType),
  //     "ear": new FormControl(data.ear),
  //     "technology": new FormControl(data.technology),
  //     "condition": new FormControl(data.condition),
  //     "deviceColor": new FormControl(data.deviceColor, Validators.pattern("^[a-zA-Z ]*$")),
  //     "shellColor": new FormControl(data.shellColor, Validators.pattern("^[a-zA-Z ]*$")),
  //     "facePlateColor": new FormControl(data.facePlateColor, Validators.pattern("^[a-zA-Z ]*$")),
  //     "vent": new FormControl(data.vent),
  //     "matrix": new FormControl(data.matrix),
  //     "receiver": new FormControl(data.receiver),
  //     "dome": new FormControl(data.dome),
  //     "facePlateNumber": new FormControl(data.facePlateNumber,Validators.pattern("^[0-9 ]*$")),
  //     "waxManagement": new FormControl(data.waxManagement),
  //     "removalString": new FormControl(data.removalString),
  //     "notes": new FormControl(data.notes),
  //     "updatedAt": this.date,
  //     "branchRefId": new FormControl(data.branchRefId),
  //     "clinicRefId": new FormControl(data.clinicRefId),
  //     "branchName": new FormControl(data.branchName),
  //     "billingCompanyRefId": new FormControl(data.billingCompanyRefId,Validators.required),
  //     "billingCompanyName": new FormControl(data.billingCompanyName),
  //     "gstApplicable": new FormControl(data.gstApplicable),
  //     "has": new FormControl(data.has),
  //     "sgst": new FormControl(data.sgst),
  //     "cgst": new FormControl(data.cgst),
  //     "igst": new FormControl(data.igst),
  //     "mrp": new FormControl(data.mrp,[Validators.required,Validators.pattern("^[0-9 ]*$"),Validators.maxLength(8)]),
  //     // "deviceId": id
  //       });
  //       this.gstApplicable();
  //       this.superBranchId = data.branchWiseId;
  //       this.id = id;
  //         this.brandSelect(data.branchName);
  //     }
  //   });
  // }
  addNew() {
    this.deviceMasterForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
  updateDevice() {
    let payload = this.deviceMasterForm.value;
    this.spinner.show();
    // payload.brandLable = this.arr;
   if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
   
    this.apimicroservice.updateDevice(this.id,payload).subscribe(res => {
      this.spinner.hide();
      this.updateButton = false;
      this.submitButton = true;
      this.cancelButton=false;
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
        //this.deviceMasterForm.reset();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
      
    });
    
  }
  
 




  getLastId()
  {
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getLastDeviceID(this.branchRef).subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.deviceMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let deviceIdd = res['data']['totalItems'];
          this.deviceMasterForm.controls['branchWiseId'].setValue(deviceIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }else{
      this.apimicroservice.getLastDeviceAll().subscribe(res => {
        //   
        // if(res['data']['data'] == '')
        // {
        //   this.deviceMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          let deviceIdd = res['data']['totalItems'];
          this.superBranchId = deviceIdd + 1;
          // this.deviceMasterForm.controls['branchWiseId'].setValue(deviceIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }
    
  }


  onPageChange(event) {
    this.page = event;
    this.spinner.show();
    this.searchText = this.deviceMasterForm.get("searchDevice").value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getDeviceList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
         this.devicelist = res.data.deviceData;
         this.totalLength = res.data.totalItems;  
        });
      }
      else{
        this.apimicroservice.getDeviceList(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          this.spinner.hide();
           this.devicelist = res.data.deviceData;
           this.totalLength = res.data.totalItems;  
          });
      }
  }
  onKeyUpEvent(event: any) {
    //
    
    this.searchText = this.deviceMasterForm.get("searchDevice").value; 
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getDeviceList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        //
         this.devicelist = res.data.deviceData;
         this.totalLength = res.data.totalItems;  
        });
      }
      else
      {
        this.apimicroservice.getDeviceList(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          //
           this.devicelist = res.data.deviceData;
           this.totalLength = res.data.totalItems;  
          });
      }
  }

  cancelUpdate(){
   
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit(); 
    this.deviceMasterForm.reset();
  }
 
  redirect(to): void {
    this.router.navigate([to]);
  }
  toDeviceForm() {
    document.getElementById("toDeviceForm").scrollIntoView();
  }
  toDeviceList() {
    document.getElementById("toDeviceList").scrollIntoView();
  }
  fetchBrandList() {
    this.apimicroservice.getBrandList().subscribe(res => {
      //
       this.brandlist = res.data;   
       this.filteredList = this.brandlist.slice();
    }); 
  }
  openBrandPopUp() {
    localStorage.clear();
    const dialogRef = this.dialog.open(PopupBrandComponent,
      {
        width: "400px",
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.apimicroservice.getBrandList().subscribe(res => {
          //
           this.brandlist = res.data;   
           this.filteredList = this.brandlist.slice();
           this.brandSelect(res.data[0].brandName);
           this.deviceMasterForm.get("brand").setValue(res.data[0].brandName);
        }); 
       // this.fetchBrandList();
      });
  }
  brandSelect(e)
  {
    let payload = this.deviceMasterForm.value;
    // if(event.source.selected){
    //   this.arr.push(e);
    // }
    // else{
    //   this.arr = this.arr.filter(b => b!=e);
    // } 
    // //
    
    // payload.brandLable = this.arr;
    this.deviceMasterForm.controls['brandLable'].setValue(e);
  }
 
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 

  fetchDeviceTypeList() {
    this.apimicroservice.getAllDeviceTypeList().subscribe(res => {
      //  
      if (res.status) {    
        this.deviceTypelist = res.data;
        this.filteredDeviceList= this.deviceTypelist.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  fetchStyleTypeList() {
    this.apimicroservice.getAllStyleTypeList().subscribe(res => {
      //  
      if (res.status) {    
        this.styleList = res.data;
        this.filteredListStyle= this.styleList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  fetchBatteryTypeList() {
    this.apimicroservice.getAllBatteryTypeList().subscribe(res => {
      //  
      if (res.status) {    
        this.batteryTypeList = res.data;
        this.filteredListbatteryType= this.batteryTypeList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 

  fetchConditionTypeList() {
    this.apimicroservice.getAllConditionTypeList().subscribe(res => {
      //  
      if (res.status) {    
        this.conditionTypeList = res.data;
        this.filteredConditionList= this.conditionTypeList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 
  branchClick(id,branchName)
  {

    this.deviceMasterForm.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0];
     let payload = this.deviceMasterForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.deviceMasterForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if(this.updateButton == false){
        this.spinner.show();
        this.apimicroservice.getLastDeviceID(payload.branchRefId).subscribe(res => {
          //   
          if (res['status']) {
            let deviceIdd = res['data']['totalItems'];
            this.deviceMasterForm.controls['branchWiseId'].setValue(deviceIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
   openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      }); 
      dialogRef3.afterClosed().subscribe((result) => {
        this.fetchBranchList();
      })
  }

  openStylePopUp() {
    localStorage.clear();
    const dialogRef1 = this.dialog.open(PopUpStyleComponent,
      {
        width: "400px",
      });
      dialogRef1.afterClosed().subscribe((result) => {
        this.apimicroservice.getAllStyleTypeList().subscribe(res => {
          //  
          if (res.status) {    
            this.styleList = res.data;
            this.filteredListStyle= this.styleList.slice();

            this.deviceMasterForm.get("style").setValue(res.data[0].styleName);
          } else {
            //this.apimicroservice.openSnackbar(res.message);
          }
        })
        //this.fetchStyleTypeList();
      })
  }
  openDeviceTypePopUp() {
    localStorage.clear();
    const dialogRef4 = this.dialog.open(PopUpDevicetypeComponent,
      {
        width: "400px",
      });
      dialogRef4.afterClosed().subscribe((result) => {
        this.apimicroservice.getAllDeviceTypeList().subscribe(res => {
          //  
          if (res.status) {    
            this.deviceTypelist = res.data;
            this.filteredDeviceList= this.deviceTypelist.slice();
            this.deviceMasterForm.get("deviceType").setValue(res.data[0].deviceType)
          } else {
            //this.apimicroservice.openSnackbar(res.message);
          }
        })
       // this.fetchDeviceTypeList();
      });
  }
  openBatteryTypePopUp() {
    localStorage.clear();
    const dialogRef5 = this.dialog.open(PopUpBatterytypeComponent,
      {
        width: "400px",
      });
dialogRef5.afterClosed().subscribe((result) =>{
  this.fetchBatteryTypeList();
})
  }
  openConditionTypePopUp() {
    localStorage.clear();
    const dialogRef6 = this.dialog.open(PopUpConditionComponent,
      {
        width: "400px",
      });
      dialogRef6.afterClosed().subscribe((result) =>{
        this.apimicroservice.getAllConditionTypeList().subscribe(res => {
          //  
          if (res.status) {    
            this.conditionTypeList = res.data;
            this.filteredConditionList= this.conditionTypeList.slice();
            this.deviceMasterForm.get("condition").setValue(res.data[0].conditionType);
          } else {
            //this.apimicroservice.openSnackbar(res.message);
          }
        })
        //this.fetchConditionTypeList();
      })
  }

  styleSelect(event :any,e)
  {
    let payload = this.deviceMasterForm.value;
    if(event.source.selected){
      this.arr.push(e);
    }
    else{
      this.arr = this.arr.filter(b => b!=e);
    } 
    //
    
    payload.style = this.arr;
  }

  onSelectBillingComp(cName){
    //
    this.deviceMasterForm.controls["billingCompanyName"].setValue(cName);
  }
  gstApplicable()
  {
  let payload = this.deviceMasterForm.value;
    if(payload.gstApplicable == 'Yes')
    {
      this.isGstApplicable = true;

    }
    else
    {
      this.isGstApplicable = false;
    }
     
  }
  igstCalculate()
  {
    let payload = this.deviceMasterForm.value;
    let igst = parseInt(payload.sgst) + parseInt(payload.cgst);
      this.deviceMasterForm.controls["igst"].setValue(igst);
  }
  onback() {
    this.apimicroservice.back()
  }

  onClose(): void {
    this.dialogRef.close();
  }
  openBillingCompPopUp(){ 
    const dialogRef3 = this.dialog.open(PopupBillingcompanyComponent,
      {
        width: "1200px",
      });
  
      dialogRef3.afterClosed().subscribe((result) => {
        let id='';
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
         
          if(res.status){
            //
            this.listOfBilling = res.data;
            this.filteredBillingCompany= this.listOfBilling.slice();
            this.onSelectBillingComp(res.data[0].companyName);
            this.deviceMasterForm.get("billingCompanyRefId").setValue(res.data[0]._id);
          }
        });
         
       });
  }
  fetchBillingCompanyDetails(){
    let id='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getBillingDataById(id,clinicRef).subscribe(res => {
      //
      this.listOfBilling = res.data; 
      this.filteredBillingCompany= this.listOfBilling.slice();
      let lenght = res.data.length-1;  
      this.onSelectBillingComp(res.data[lenght].companyName);
      this.deviceMasterForm.get("billingCompanyRefId").setValue(res.data[lenght]._id);
      if(res.status){
        //this.apimicroservice.openSnackbar(res.message);
      }else{ 
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
}
