 <div class="container-fluid no-print bgColor" >
    <div class=" mt-2 navbar-custom">
       
            <ul class="marginL"> 
                <li class="nav-item font-weight-semibold d-none d-lg-block ms-0">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="row">
                                <!-- <img *ngIf="!logoIcon" class=" img2 frame rounded-circle " src="../../assets/main.jpeg" alt="Amanda Wilson" width="500rem">
                          <img *ngIf="logoIcon" class=" img2 frame rounded-circle " [src]="logoIcon" alt="patient photo" width="500rem"> -->
                          <img class="pr-3 pl-0 logoClass" *ngIf="!logoIcon" width="120rem" src="../../assets/clinic_logo2.jpg">
                                <img class="pr-3 pl-0 logoClass" *ngIf="logoIcon"  width="120rem" [src]="logoIcon" >
                            </div>
                        </div>
                       
                    </div>
                  
                  </li>
            </ul> 
        <ul class="top-right float-end">
            <li>
            <div class="app-search dropdown">
                <form  [formGroup]="homeForm" autocomplete="off" novalidate>
                   <div class="input-group">
                    
                      <mat-select  placeholder="Global Search.." formControlName="name"
                      placeholder="Global Search.."  id="inputGroupSelect01">
                      <mat-option selected>Global Search...</mat-option>
                      <mat-option *ngFor="let item of filteredList1" [value]="item" 
                        (click)="openDepartmentPopUp();">
                            {{ item }}
                          </mat-option>
                          <!-- <option value="" (click)="shiv()" (change)="openDepartmentPopUp(2);">Manish Yadav</option> -->
                       
                      </mat-select>
                   </div>
                </form>
             </div>
                </li>
                
                <li>
              <div class="  text-right right">
               
            <img class="pr-3 "  width="200rem" height="60rem" src="../../assets/audiology.png">
            <!-- <img class="roundedcirle"  width="50rem" src="../../assets/profile.png"> -->
        
                <img *ngIf="userType == 'Super Admin'" class="roundedcirle pb-2 pt-2" src="../../assets/profile.png" alt="Amanda Wilson" width="50rem">
                <img *ngIf="userType !== 'Super Admin'" class="roundedcirle pb-2 pt-2" [src]="userImage" alt="photo" width="50rem">
            </div>
        </li>
                
        <li>
            <div routerLinkActive="active" class="nav-item dropdown ">
                <a class="nav-link " href="javascript:;" id="navbarDropdownProfile"  data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <i class="fas fa-ellipsis-v"></i>
                
                </a> 
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                  <a  class="dropdown-item" href="#" [routerLink]="['profile']">Profile </a>
                  <a class="dropdown-item" href="#" (click)="logout();">Logout </a>
                
                </div>
                
            </div>
            
                
                </li>
                </ul>
          
    </div>
</div>
