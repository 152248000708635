import { Component,OnInit, ElementRef, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiServiceService } from '../api-service.service';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { element } from 'protractor';
import { PopUpPatientComponent } from '../pop-up-patient/pop-up-patient.component';
import { PopUpBranchComponent } from '../pop-up-branch/pop-up-branch.component';
import { PopUpDoctorComponent } from '../pop-up-doctor/pop-up-doctor.component';
import { PopUpAppointmenttypeComponent } from "../pop-up-appointmenttype/pop-up-appointmenttype.component";
import { Pipe, PipeTransform } from '@angular/core';
import { PopupBookappointmentComponent } from '../popup-bookappointment/popup-bookappointment.component';
import { HttpErrorResponse } from '@angular/common/http'; 
import { PatientSearchPopComponent } from '../patient-search-pop/patient-search-pop.component';
import { AppoinmentPopupComponent } from '../appoinment-popup/appoinment-popup.component';
@Component({
  selector: "app-calander",
  templateUrl: "./calander.component.html",
  styleUrls: ["./calander.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CalanderComponent implements OnInit, AfterViewInit {
  @ViewChild("calendar", { static: true }) calendar: ElementRef<any>;
  appointmentMaster: FormGroup;
  appointtypelist;
  doctorlist;
  patientlist;
  cliniclist;
  durationList;
  searchText: any;
  updateRefid;
  savebutton = true;
  updatebutton = false;
  isUpdate = false;
  clear = false;
  delete = false;
  searcheddoctor = false;
  endDateTime: string;
  fieldsOfCalenders: any;
  newAppointment: any;
  tDate :any;
  arrayCalender = [
    {
      calenderDuration: "Yes",
      calenderAppointmentType: "Yes",
      calenderClinicBranch: "Yes",
      calenderDoctorName: "Yes",
      dailyCalender: "Yes",
      weeklyCalender: "Yes",
      monthlyCalender: "Yes",
    },
  ];
  userType: string;
  listOfDoctorAvail: any;
  totalLength: any;
  clinicRef: any;
  branchRef: any;
  doctorList: any;
  timeLabels = [];
  durationValue: any;
  appointList: any;
  filteredPatientList: any;
  filteredAppointTypeList: any;
  filteredClinicList: any;
  filteredDoctorList: any;
  patientRefId: any;
  logged_id: string;
  pipe = new DatePipe("en-US"); // Use your own locale
  myFormattedDate: string;
  dateTime: Date;
  dateTime2: Date;
  dd: Date;
  isSave = true;
  closetime: any;
  userName: string;
  doctorID: string;
  PatientCreate = true;
  AppointmentTypeCreate = true;
  DoctorCreate = true;
  BackDatedCreate=true;
  appointment_id: string;
  patientName: any;
  patientId: any;
  openBranchTime: any;
  tt: string;
  ct: string;
  weeklyOff: any;
  branchHolidayList = [];
  doctorLeavesList = [];
  unavailable = false;
  branchmsg = "";
  branchUnavailable = false;
  bookRefId:any;
  bookDate:any;
  branchReffIdd: string;
  ispop=true;
  endTime: Date;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService
  ) {}
  page2: number = 1;
  size: number = 5;
  branchRefId: string;
  clinicRefId: string;
  doctorRefId: string;
  userPermission: any;
  bookAppointmentsCreate = true;
  bookAppointmentsUpdate = true;
  currentdate;
  isDisabled = false;
  dayArray = [
    { value: "Monday", id: 1 },
    { value: "Tuesday", id: 2 },
    { value: "Wednesday", id: 3 },
    { value: "Thursday", id: 4 },
    { value: "Friday", id: 5 },
    { value: "Saturday", id: 6 },
    { value: "Sunday", id: 7 },
  ];
  ngAfterViewInit(): void {
    // this.ngOnInit();
    setTimeout(() => {
      //////
      this.spinner.show();
      let ssss = [];
      let clinicRef = sessionStorage.getItem('clinicRef');
      let branchRef = sessionStorage.getItem('branchRef');
      
      if (this.userType !== "Super Admin") {
        // clinicRef = sessionStorage.getItem('clinicRef');
        branchRef = sessionStorage.getItem('branchRef');
      } else {
        //  clinicRef = sessionStorage.getItem('clinicRef');
         branchRef = sessionStorage.getItem('branchRef');
      }
      this.apimicroservice.getAppointment(branchRef, clinicRef).subscribe((res) => {
       // this.spinner.hide();
             ////
             
        if (res.status) {
          ////
          if (res.data.length > 0) {
            ////
            
            if (this.branchHolidayList.length > 0) {
              let appDate = this.appointmentMaster.controls["startDateTime"].value;
              let datesarr = [];
              appDate = new Date(appDate);
              this.branchHolidayList.forEach((ele) => {
                if (ele.branchRefId == this.branchRef) {
                  datesarr.push({
                    startDate: ele.fromDate,
                    endDate: ele.toDate,
                    holidayName: ele.holidayName,
                  });
                }
              });
              ////
              datesarr.forEach((ele) => {
                let st = new Date(ele.startDate).toISOString();
                let et = new Date(ele.endDate).toISOString();
                let fDate =this.datepipe.transform(st, 'yyyy-MM-dd');
             //   this.tDate = new Date();
              this.tDate = new Date(ele.endDate);
         
                this.tDate.setDate(this.tDate.getDate()+1);
             let tdate=this.datepipe.transform(this.tDate, 'yyyy-MM-dd');
                ////
                let holiday = ele.holidayName;
                let tomorrow = moment(et).add(1, "days");
                 // this.openSnackbar("On occusion of "+holiday+", "+"Clinic is Close From"+st.toDateString()+" to "+et.toDateString());
                  this.unavailable = true;
                  let xx = {}; 
      
            xx["start"] = fDate;
            xx["end"] = tdate;
            xx["overlap"] = false;
            xx["display"] = 'background';
            xx["color"] = 'black';
            xx["title"] = ele.holidayName;
           
                  ssss.push(xx);
                  ////
          
                }
              );
             
            }

            res.data.forEach((element) => {
              this.endTime = moment(element.startDateTime).add(this.durationValue, 'm').toDate();
             
              let x = {}; 
              x["id"] = element._id;
              x["title"] = element.clientName;
              x["start"] = new Date(element.startDateTime);
              x["end"] = new Date(this.endTime);
              x["color"] = element.eventColor;
              x["backgroundColor"] = element.eventColor;
               this.ispop = false;
              ssss.push(x);
            });
            var today = new Date().toISOString().slice(0, 10);
            var calendarEl = this.calendar.nativeElement;
            var calendar = new Calendar(calendarEl, {
               
              contentHeight: 'auto',
              eventMinHeight:40,
              initialView: this.fieldsOfCalenders.defaultView,

              //   validRange: {
              //     start: today
              //  },
              initialDate: new Date(),
              selectable: true,
              editable: true,
              eventTimeFormat: {
                // like '14:30:00'
                hour: "numeric",
                minute: "2-digit",
                meridiem: "short",
              },
              //  slotMinTime: "09:00:00",
              //  slotMaxTime:"23:00:00",
              //weekends:true,
              slotMinTime: this.tt,
              slotMaxTime: this.ct,
              eventBackgroundColor: "",

              // hiddenDays:[2],
              dateClick: (info) => {
            
                ////
                this.reset();
                this.timeLabels = [];
                this.bookDate = info.date;
                this.openBookAppointmentPopup(info.date);
                this.appointmentMaster = this.fb.group({
                  clientName: new FormControl(),
                  contactNumber: new FormControl(),
                  // "title": new FormControl(),
                  eventColor: new FormControl(),
                  doctorName: new FormControl(),
                  // "clinicName": new FormControl(res.data.clientName),
                  clinicLocation: new FormControl(),
                  AppoinmentType: new FormControl(),
                  startDateTime: new FormControl(info.date),
                  appoinmentTime: new FormControl(this.newAppointment),
                  aptStatus: new FormControl("Cancel"),
                  // "duration": new FormControl(''),
                });
                
              },

              eventDrop: (info) => {
                this.apimicroservice
                  .getAppointmentbyId(info.event.id)
                  .subscribe((res) => {
                    // alert(JSON.stringify(info.event.start))
                    if (res.status) {
                      let dataaaa = res.data[0];
                      this.updateRefid = dataaaa._id;
                      // this.selectBranch(dataaaa.branchRefId);
                      // this.selectDoctor(dataaaa.doctorRefId);
                      // this.getDoctorAvailability();
                      this.branchRef = dataaaa.branchRefId;
                      this.patientRefId = dataaaa.patientRefId;
                      this.patientName = dataaaa.patientName;
                      this.patientId = dataaaa.patientId;
                      this.appointmentMaster = this.fb.group({
                        clientName: new FormControl(dataaaa.clientName),
                        contactNumber: new FormControl(dataaaa.contactNumber),
                        // "title": new FormControl(dataaaa.title),
                        eventColor: new FormControl(dataaaa.eventColor),
                        doctorName: new FormControl(dataaaa.doctorName),
                        doctorRefId: new FormControl(dataaaa.doctorRefId),
                        // "clinicName": new FormControl(res.data.clientName),
                        clinicLocation: new FormControl(dataaaa.clinicLocation),
                        AppoinmentType: new FormControl(dataaaa.AppoinmentType),
                        startDateTime: new FormControl(info.event.start),
                        appoinmentTime: new FormControl(dataaaa.appoinmentTime),
                        aptStatus: new FormControl(dataaaa.aptStatus),
                        // "duration": new FormControl(dataaaa.duration),
                      });
                      this.branchRef = dataaaa.branchRefId;
                      this.doctorID = dataaaa.doctorRefId;
                        ////
                        ////.value);
                      this.draganddropbranch(
                        dataaaa.doctorRefId,
                        dataaaa.appoinmentTime,
                        info.event.start
                      );
                    } else {
                      //  this.apimicroservice.openSnackbar(res.message);
                    }
                  });
              },
              eventClick: (arg) => {
                ////
                ////
                
                this.bookRefId = arg.event._def.publicId;
                let datee = null;
                this.openBookAppointmentPopup(datee);

                this.apimicroservice
                  .getAppointmentbyId(arg.event._def.publicId)
                  .subscribe((res) => {
                    if (res.status) {
                      let dataaaa = res.data[0];
                      ////
                      this.updateRefid = dataaaa._id;
                      this.savebutton = false;
                      this.updatebutton = true;
                      this.clear = true;
                      this.delete = true;
                      // ////);
                      this.appointmentMaster = this.fb.group({
                        clientName: new FormControl(dataaaa.clientName),
                        contactNumber: new FormControl(dataaaa.contactNumber),
                        // "title": new FormControl(dataaaa.title),
                        eventColor: new FormControl(dataaaa.eventColor),
                        doctorName: new FormControl(dataaaa.doctorName),
                        doctorRefId: new FormControl(dataaaa.doctorRefId),
                        // "clinicName": new FormControl(res.data.clientName),
                        clinicLocation: new FormControl(dataaaa.clinicLocation),
                        AppoinmentType: new FormControl(dataaaa.AppoinmentType),
                        startDateTime: new FormControl(dataaaa.startDateTime),
                        appoinmentTime: new FormControl(dataaaa.appoinmentTime),
                        aptStatus: new FormControl(dataaaa.aptStatus),
                        // "duration": new FormControl(dataaaa.duration),
                      });
                      this.patientRefId = dataaaa.patientRefId;
                      this.patientName = dataaaa.patientName;
                      this.patientId = dataaaa.patientId;
                      this.branchRef = dataaaa.branchRefId;
                      this.doctorID = dataaaa.doctorRefId;
                      // this.toDateChange();
                      this.eventclickbranch(
                        dataaaa.branchRefId,
                        dataaaa.doctorRefId
                      );
                    } else {
                      //this.apimicroservice.openSnackbar(res.message);
                    }
                  });
                 // this.openBookAppointmentPopup();
              },
              plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
              droppable: true,
              headerToolbar: {
                left: "prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              },
              events: ssss,
              
            });

            calendar.render();
          } else {
            var today = new Date().toISOString().slice(0, 10);
            var calendarEl = this.calendar.nativeElement;
          
            var calendar = new Calendar(calendarEl, {
              contentHeight: 'auto',
              eventMinHeight:40,
              initialView: this.fieldsOfCalenders.defaultView,
          
              initialDate: new Date(),
              selectable: true,
              editable: true,
              eventTimeFormat: {
                // like '14:30:00'
                hour: "numeric",
                minute: "2-digit",
                meridiem: "short",
              },
              slotMinTime: this.tt,
              slotMaxTime: this.ct,
              // slotMinTime: "09:00:00",
              // slotMaxTime:"23:00:00",
              // hiddenDays:[2],
              eventBackgroundColor: "",
              dateClick: (info) => {
                this.reset();
                this.timeLabels = [];

                this.appointmentMaster = this.fb.group({
                  clientName: new FormControl(),
                  contactNumber: new FormControl(),
                  // "title": new FormControl(),
                  eventColor: new FormControl(),
                  doctorName: new FormControl(),
                  // "clinicName": new FormControl(res.data.clientName),
                  clinicLocation: new FormControl(),
                  AppoinmentType: new FormControl(),
                  startDateTime: new FormControl(info.date),
                  appoinmentTime: new FormControl(this.newAppointment),
                  aptStatus: new FormControl("Cancel"),
                  // "duration": new FormControl(''),
                });
          
                 this.openBookAppointmentPopup(info.date);
              },
              eventClick: (arg) => {
                this.apimicroservice
                  .getAppointmentbyId(arg.event._def.publicId)
                  .subscribe((res) => {
                    if (res.status) {
                      let dataaaa = res.data[0];
                      this.updateRefid = dataaaa._id;
                      this.savebutton = false;
                      this.updatebutton = true;
                      this.clear = true;
                      this.delete = true;
                      // ////);
                      this.appointmentMaster = this.fb.group({
                        clientName: new FormControl(dataaaa.clientName),
                        contactNumber: new FormControl(dataaaa.contactNumber),
                        // "title": new FormControl(dataaaa.title),
                        eventColor: new FormControl(dataaaa.eventColor),
                        doctorName: new FormControl(dataaaa.doctorName),
                        doctorRefId: new FormControl(dataaaa.doctorRefId),
                        // "clinicName": new FormControl(res.data.clientName),
                        clinicLocation: new FormControl(dataaaa.clinicLocation),
                        AppoinmentType: new FormControl(dataaaa.AppoinmentType),
                        startDateTime: new FormControl(dataaaa.startDateTime),
                        appoinmentTime: new FormControl(dataaaa.appoinmentTime),
                        aptStatus: new FormControl(dataaaa.aptStatus),
                        // "duration": new FormControl(dataaaa.duration),
                      });
                      this.patientRefId = dataaaa.patientRefId;
                      this.patientName = dataaaa.patientName;
                      this.patientId = dataaaa.patientId;

                      this.branchRef = dataaaa.branchRefId;
                      this.doctorID = dataaaa.doctorRefId;
                      //this.toDateChange();
                      this.eventclickbranch(
                        dataaaa.branchRefId,
                        dataaaa.doctorRefId
                      );
                    } else {
                      // this.apimicroservice.openSnackbar(res.message);
                    }
                  });
              },
              plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
              droppable: true,
              headerToolbar: {
                left: "prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              },
            });

            calendar.render();
            //this.spinner.hide();
          }
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
        this.spinner.hide();
      });
      
    },
     300);

  }
  ngOnInit(): void {
    this.ngAfterViewInit();
    this.currentdate = new Date();
    this.updatebutton = false;
    this.timeLabels = [];
    this.searcheddoctor = false;
    this.bookDate = null;
    this.bookRefId = null; 
    this.clinicRef = sessionStorage.getItem("clinicRef");
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem("userType");
    this.logged_id = sessionStorage.getItem("userRef");
    this.branchRefId = sessionStorage.getItem("branchRef");
    this.branchRef = this.branchRefId;
    this.branchReffIdd = this.branchRefId;
    this.userName = sessionStorage.getItem("userName");
    this.doctorID = sessionStorage.getItem("doctorRefId");
    this.appointment_id = sessionStorage.getItem("appointment_id");
    const now = Date.now();
    this.myFormattedDate = this.pipe.transform(now, "shortTime");
    this.getCalender();
    if (this.userType !== "Super Admin") {
      this.userPermission.forEach((element) => {
        if (
          element.pageName == "Book Appointments" &&
          element.create == false
        ) {
          this.bookAppointmentsCreate = false;
        }
        if (
          element.pageName == "Book Appointments" &&
          element.update == false
        ) {
          this.bookAppointmentsUpdate = false;
        }
        if (element.pageName == "Patient" && element.create == false) {
          this.PatientCreate = false;
        }
        if (element.pageName == "Appointment Type" && element.create == false) {
          this.AppointmentTypeCreate = false;
        }
        if (element.pageName == "Doctor" && element.create == false) {
          this.DoctorCreate = false;
        }
        if (element.pageName == "Back Dated Appointment Permissions" && element.create == false) {
          this.BackDatedCreate = false;
        }
      });
    }

    this.appointmentMaster = this.fb.group({
      clientName: new FormControl(""),
      contactNumber: new FormControl(""),
      // "title": new FormControl(''),
      eventColor: new FormControl(""),
      doctorName: new FormControl(""),
      // "clinicName": new FormControl(''),
      clinicLocation: new FormControl(""),
      AppoinmentType: new FormControl(""),
      startDateTime: new FormControl(""),
      appoinmentTime: new FormControl(""),
      aptStatus: new FormControl("Cancel"),
      // "duration": new FormControl(''),
    });
    this.appointmentMaster.get("startDateTime").setValue(this.currentdate);
    this.isSave = true;
    this.toDateChange();
    if (this.userType == "Doctor") {
      //this.appointmentMaster.get("contactNumber").setValue(mobile1);
    }
  
    if (
      (this.userType !== "Super Admin" && this.appointment_id !== "") ||
      this.appointment_id !== undefined
    ) {
      //  let bid = sessionStorage.getItem('branchRef');
      this.apimicroservice.getBranchById(this.branchRefId).subscribe((res) => {
        this.branchRef = this.branchRefId;

        this.appointmentMaster
          .get("clinicLocation")
          .setValue(res.data[0].branchName);
        this.appointmentMaster.controls["clinicLocation"].disable();
        this.selectBranch(res.data[0]._id);

        if (this.userType == "Doctor") {
          let date = new Date();
          this.appointmentMaster.get("startDateTime").setValue(date);
          this.appointmentMaster.get("doctorName").setValue(this.userName);
          this.selectDoctor(this.doctorID);
        }
      },
      (ERROR: HttpErrorResponse) => {
   
        this.spinner.hide();
      }
    );

    }

    // this.apimicroservice.getAppointmentTypelist().subscribe((res) => {
      // if (res.status) {
      //   // ////
      //   this.appointtypelist = res.data.data;
      //   this.filteredAppointTypeList = this.appointtypelist.slice();
      // }
    // });


    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getAppointmentTypelist(branchRef,clinicRef).subscribe((res) => {
      if (res.status) {
        // ////
        this.appointtypelist = res.data.data;
        this.filteredAppointTypeList = this.appointtypelist.slice();
      }
    });
  } else {
    this.apimicroservice.getAppointmentTypelist(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
      if (res.status) {
        // ////
        this.appointtypelist = res.data.data;
        this.filteredAppointTypeList = this.appointtypelist.slice();
      }
    });
  }
  if (this.userType !== 'Super Admin') {
    this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe((res) => {
      if (res.status) {
        // ////
        this.patientlist = res.data.data;
        this.filteredPatientList = this.patientlist.slice();
      }
    });
  }else{
    this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
      if (res.status) {
        // ////
        this.patientlist = res.data.data;
        this.filteredPatientList = this.patientlist.slice();
      }
    });
  }

    this.apimicroservice.getAllDoctorslist().subscribe((res) => {
      if (res.status) {
        // ////
        this.doctorlist = res.data.data;
      }
    });
    this.apimicroservice.getAllBranchList(clinicRef).subscribe((res) => {
      let length =res.data.length - 1;
      if (res.status) {
        // //// 
        this.cliniclist = res.data;

        this.filteredClinicList = this.cliniclist.slice();

        if (
          (this.userType == "Super Admin" && this.appointment_id !== "") ||
          this.appointment_id !== undefined
        ) {
          this.appointmentMaster
            .get("clinicLocation")
            .setValue(this.cliniclist[length].branchName);
          this.selectBranch(this.cliniclist[length]._id);
        }
      }
    });

    setTimeout(() => {
      ////
      this.spinner.show();
      let ssss = [];
      let clinicRef = sessionStorage.getItem('clinicRef');
      let branchRef = sessionStorage.getItem('branchRef');
      
      if (this.userType !== "Super Admin") {
        // clinicRef = sessionStorage.getItem('clinicRef');
        branchRef = sessionStorage.getItem('branchRef');
      } else {
        //  clinicRef = sessionStorage.getItem('clinicRef');
         branchRef = sessionStorage.getItem('branchRef');
      }
      this.apimicroservice.getAppointment(branchRef, clinicRef).subscribe((res) => {
       // this.spinner.hide();
             ////
             
        if (res.status) {
          ////
          if (res.data.length > 0) {
            ////
            
            if (this.branchHolidayList.length > 0) {
              let appDate = this.appointmentMaster.controls["startDateTime"].value;
              let datesarr = [];
              appDate = new Date(appDate);
              this.branchHolidayList.forEach((ele) => {
                if (ele.branchRefId == this.branchRef) {
                  datesarr.push({
                    startDate: ele.fromDate,
                    endDate: ele.toDate,
                    holidayName: ele.holidayName,
                  });
                }
              });
              ////
              datesarr.forEach((ele) => {
                let st = new Date(ele.startDate).toISOString();
                let et = new Date(ele.endDate).toISOString();
                let fDate =this.datepipe.transform(st, 'yyyy-MM-dd');
             //   this.tDate = new Date();
              this.tDate = new Date(ele.endDate);
            
             
                this.tDate.setDate(this.tDate.getDate()+1);
             let tdate=this.datepipe.transform(this.tDate, 'yyyy-MM-dd');
                ////
                let holiday = ele.holidayName;
                let tomorrow = moment(et).add(1, "days");
                 // this.openSnackbar("On occusion of "+holiday+", "+"Clinic is Close From"+st.toDateString()+" to "+et.toDateString());
                  this.unavailable = true;
                  let xx = {}; 
      
            xx["start"] = fDate;
            xx["end"] = tdate;
            xx["overlap"] = false;
            xx["display"] = 'background';
            xx["color"] = 'black';
            xx["title"] = ele.holidayName;
           
                  ssss.push(xx);
          
                }
              );
             
            }
 res.data.forEach((element) => {
              this.endTime = moment(element.startDateTime).add(this.durationValue, 'm').toDate();
              let x = {}; 
              x["id"] = element._id;
              x["title"] = element.clientName;
              x["start"] = new Date(element.startDateTime);
              x["end"] = new Date(this.endTime);
              x["color"] = element.eventColor; 
              x["backgroundColor"] = element.eventColor;
               this.ispop = false;
              ssss.push(x);
            });
            var today = new Date().toISOString().slice(0, 10);
            var calendarEl = this.calendar.nativeElement;
            var calendar = new Calendar(calendarEl, {
               
              contentHeight: 'auto',
              eventMinHeight:40,
              initialView: this.fieldsOfCalenders.defaultView,

              //   validRange: {
              //     start: today
              //  },
              initialDate: new Date(),
              selectable: true,
              editable: true,
              eventTimeFormat: {
                // like '14:30:00'
                hour: "numeric",
                minute: "2-digit",
                meridiem: "short",
              },

              slotMinTime: this.tt,
              slotMaxTime: this.ct,
              eventBackgroundColor: "",

              // hiddenDays:[2],
              dateClick: (info) => {
            
                ////
                this.reset();
                this.timeLabels = [];
                this.bookDate = info.date;
                this.openBookAppointmentPopup(info.date);
                this.appointmentMaster = this.fb.group({
                  clientName: new FormControl(),
                  contactNumber: new FormControl(),
                  // "title": new FormControl(),
                  eventColor: new FormControl(),
                  doctorName: new FormControl(),
                  // "clinicName": new FormControl(res.data.clientName),
                  clinicLocation: new FormControl(),
                  AppoinmentType: new FormControl(),
                  startDateTime: new FormControl(info.date),
                  appoinmentTime: new FormControl(this.newAppointment),
                  aptStatus: new FormControl("Cancel"),
                  // "duration": new FormControl(''),
                });
                
              },

              eventDrop: (info) => { 
                this.apimicroservice
                  .getAppointmentbyId(info.event.id)
                  .subscribe((res) => {
                    // alert(JSON.stringify(info.event.start))
                    if (res.status) {
                      let dataaaa = res.data[0];
                      this.updateRefid = dataaaa._id;
                      this.branchRef = dataaaa.branchRefId;
                      this.patientRefId = dataaaa.patientRefId;
                      this.patientName = dataaaa.patientName;
                      this.patientId = dataaaa.patientId;
                      this.appointmentMaster = this.fb.group({
                        clientName: new FormControl(dataaaa.clientName),
                        contactNumber: new FormControl(dataaaa.contactNumber),
                        // "title": new FormControl(dataaaa.title),
                        eventColor: new FormControl(dataaaa.eventColor),
                        doctorName: new FormControl(dataaaa.doctorName),
                        doctorRefId: new FormControl(dataaaa.doctorRefId),
                        // "clinicName": new FormControl(res.data.clientName),
                        clinicLocation: new FormControl(dataaaa.clinicLocation),
                        AppoinmentType: new FormControl(dataaaa.AppoinmentType),
                        startDateTime: new FormControl(info.event.start),
                        appoinmentTime: new FormControl(dataaaa.appoinmentTime),
                        aptStatus: new FormControl(dataaaa.aptStatus),
                        // "duration": new FormControl(dataaaa.duration),
                      });
                      this.branchRef = dataaaa.branchRefId;
                      this.doctorID = dataaaa.doctorRefId;
                     this.draganddropbranch(
                        dataaaa.doctorRefId,
                        dataaaa.appoinmentTime,
                        info.event.start
                      );
                    } else {
                      //  this.apimicroservice.openSnackbar(res.message);
                    }
                  });
              },
              eventClick: (arg) => {
               
                this.bookRefId = arg.event._def.publicId;
                let datee = null;
                this.openBookAppointmentPopup(datee);

                this.apimicroservice
                  .getAppointmentbyId(arg.event._def.publicId)
                  .subscribe((res) => {
                    if (res.status) {
                      let dataaaa = res.data[0];
                      ////
                      this.updateRefid = dataaaa._id;
                      this.savebutton = false;
                      this.updatebutton = true;
                      this.clear = true;
                      this.delete = true;
                      // ////);
                      this.appointmentMaster = this.fb.group({
                        clientName: new FormControl(dataaaa.clientName),
                        contactNumber: new FormControl(dataaaa.contactNumber),
                        // "title": new FormControl(dataaaa.title),
                        eventColor: new FormControl(dataaaa.eventColor),
                        doctorName: new FormControl(dataaaa.doctorName),
                        doctorRefId: new FormControl(dataaaa.doctorRefId),
                        // "clinicName": new FormControl(res.data.clientName),
                        clinicLocation: new FormControl(dataaaa.clinicLocation),
                        AppoinmentType: new FormControl(dataaaa.AppoinmentType),
                        startDateTime: new FormControl(dataaaa.startDateTime),
                        appoinmentTime: new FormControl(dataaaa.appoinmentTime),
                        aptStatus: new FormControl(dataaaa.aptStatus),
                        // "duration": new FormControl(dataaaa.duration),
                      });
                      this.patientRefId = dataaaa.patientRefId;
                      this.patientName = dataaaa.patientName;
                      this.patientId = dataaaa.patientId;
                      this.branchRef = dataaaa.branchRefId;
                      this.doctorID = dataaaa.doctorRefId;
                      // this.toDateChange();
                      this.eventclickbranch(
                        dataaaa.branchRefId,
                        dataaaa.doctorRefId
                      );

                    } else {
                      //this.apimicroservice.openSnackbar(res.message);
                    }
                  });
                 // this.openBookAppointmentPopup();
              },
              plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
              droppable: true,
              headerToolbar: {
                left: "prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              },
              events: ssss,
              
            });

            calendar.render();
          } else {
            var today = new Date().toISOString().slice(0, 10);
            var calendarEl = this.calendar.nativeElement;

            var calendar = new Calendar(calendarEl, {
              contentHeight: 'auto',
             eventMinHeight:40,
              initialView: this.fieldsOfCalenders.defaultView,
              //   initialView: 'timeGridWeek',

              //   validRange: {
              //     start: today
              //  },
              initialDate: new Date(),
              selectable: true,
              editable: true,
              eventTimeFormat: {
                // like '14:30:00'
                hour: "numeric",
                minute: "2-digit",
                meridiem: "short",
              },
              slotMinTime: this.tt,
              slotMaxTime: this.ct,

              eventBackgroundColor: "",
              dateClick: (info) => {
              
                ////
                ////
                this.reset();
                this.timeLabels = [];

                this.appointmentMaster = this.fb.group({
                  clientName: new FormControl(),
                  contactNumber: new FormControl(),
                  // "title": new FormControl(),
                  eventColor: new FormControl(),
                  doctorName: new FormControl(),
                  // "clinicName": new FormControl(res.data.clientName),
                  clinicLocation: new FormControl(),
                  AppoinmentType: new FormControl(),
                  startDateTime: new FormControl(info.date),
                  appoinmentTime: new FormControl(this.newAppointment),
                  aptStatus: new FormControl("Cancel"),
                  // "duration": new FormControl(''),
                });
          
                 this.openBookAppointmentPopup(info.date);
              },
              eventClick: (arg) => {
 
                this.apimicroservice
                  .getAppointmentbyId(arg.event._def.publicId)
                  .subscribe((res) => {
                    if (res.status) {
                      let dataaaa = res.data[0];
                      this.updateRefid = dataaaa._id;
                      this.savebutton = false;
                      this.updatebutton = true;
                      this.clear = true;
                      this.delete = true;
                      // ////);
                      this.appointmentMaster = this.fb.group({
                        clientName: new FormControl(dataaaa.clientName),
                        contactNumber: new FormControl(dataaaa.contactNumber),
                        // "title": new FormControl(dataaaa.title),
                        eventColor: new FormControl(dataaaa.eventColor),
                        doctorName: new FormControl(dataaaa.doctorName),
                        doctorRefId: new FormControl(dataaaa.doctorRefId),
                        // "clinicName": new FormControl(res.data.clientName),
                        clinicLocation: new FormControl(dataaaa.clinicLocation),
                        AppoinmentType: new FormControl(dataaaa.AppoinmentType),
                        startDateTime: new FormControl(dataaaa.startDateTime),
                        appoinmentTime: new FormControl(dataaaa.appoinmentTime),
                        aptStatus: new FormControl(dataaaa.aptStatus),
                        // "duration": new FormControl(dataaaa.duration),
                      });
                      this.patientRefId = dataaaa.patientRefId;
                      this.patientName = dataaaa.patientName;
                      this.patientId = dataaaa.patientId;
                      // this.selectBranch(dataaaa.branchRefId);
                      // this.selectDoctor(dataaaa.doctorRefId);
                      // this.getDoctorAvailability();
                      this.branchRef = dataaaa.branchRefId;
                      this.doctorID = dataaaa.doctorRefId;
                      //this.toDateChange();
                      this.eventclickbranch(
                        dataaaa.branchRefId,
                        dataaaa.doctorRefId
                      );
                    } else {
                      // this.apimicroservice.openSnackbar(res.message);
                    }
                  });
              },
              plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
              droppable: true,
              headerToolbar: {
                left: "prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              },
            });

            calendar.render();
            //this.spinner.hide();
          }
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
        this.spinner.hide();
      });
      
    },
     300);

    this.fetchDuration();
    this.fetchAllBranchLeaves();
    if (this.appointment_id !== "") {

      this.apimicroservice
        .getAppointmentbyId(this.appointment_id)
        .subscribe((res) => {
          if (res.status) {
            let dataaaa = res.data[0];
            this.updateRefid = dataaaa._id;
            this.savebutton = false;
            this.updatebutton = true;
            this.clear = true;
            this.delete = true;
            // ////);
            this.appointmentMaster = this.fb.group({
              clientName: new FormControl(dataaaa.clientName),
              contactNumber: new FormControl(dataaaa.contactNumber),
              // "title": new FormControl(dataaaa.title),
              eventColor: new FormControl(dataaaa.eventColor),
              doctorName: new FormControl(dataaaa.doctorName),
              doctorRefId: new FormControl(dataaaa.doctorRefId),
              // "clinicName": new FormControl(res.data.clientName),
              clinicLocation: new FormControl(dataaaa.clinicLocation),
              AppoinmentType: new FormControl(dataaaa.AppoinmentType),
              startDateTime: new FormControl(dataaaa.startDateTime),
              appoinmentTime: new FormControl(dataaaa.appoinmentTime),
              aptStatus: new FormControl("Rescheduled"),
              // "duration": new FormControl(dataaaa.duration),
            });
            this.patientRefId = dataaaa.patientRefId;
            this.patientName = dataaaa.patientName;
            this.patientId = dataaaa.patientId;
            this.branchRef = dataaaa.branchRefId;
            this.doctorID = dataaaa.doctorRefId;

            this.appointmentMaster
              .get("clinicLocation")
              .setValue(dataaaa.clinicLocation);

            // this.selectBranch(dataaaa.branchRefId);
            this.appointmentMaster.controls["clinicLocation"].disable();
            this.eventclickbranch(dataaaa.branchRefId, dataaaa.doctorRefId);
          } else {
            //   this.apimicroservice.openSnackbar(res.message);
          }
        });
      sessionStorage.setItem("appointment_id", "");
    } else {
      sessionStorage.setItem("appointment_id", "");
    }
  }

 

  fetchPatient() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe((res) => {
      if (res.status) {
        // ////
        this.patientlist = res.data.data;
        this.filteredPatientList = this.patientlist.slice();
      }
    });
  }else{
    this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
      if (res.status) {
        // ////
        this.patientlist = res.data.data;
        this.filteredPatientList = this.patientlist.slice();
      }
    });
  }
  }

  fetchAllBranchLeaves() {
    let id = "";
    this.apimicroservice.getBranchHolidayDataById(id).subscribe((res) => {
      if (res.status) {
        ////
        this.branchHolidayList = res.data;
      }
    });
  }

  // fetchAppointmentTypeList() {
  //   this.apimicroservice.getAppointmentTypelist().subscribe((res) => {
  //     if (res.status) {
  //       // ////
  //       this.appointtypelist = res.data.data;
  //       this.filteredAppointTypeList = this.appointtypelist.slice();
  //     }
  //   });
  // }

  fetchAppointmentTypeList() {
    let clinicRef = sessionStorage.getItem('clinicRef');
    let branchRef = sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin = sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin = '';
    
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAppointmentTypelist(branchRef, clinicRef).subscribe((res) => {
        if (res.status) {
          // ////
          this.appointtypelist = res.data.data;
          this.filteredAppointTypeList = this.appointtypelist.slice();
        }
      });
    } else {
      this.apimicroservice.getAppointmentTypelist(branchRefSuperAdmin, clinicRefSuperAdmin).subscribe((res) => {
        if (res.status) {
          // ////
          this.appointtypelist = res.data.data;
          this.filteredAppointTypeList = this.appointtypelist.slice();
        }
      });
    }
  }

  getDoctorAvailability() {
   
    this.apimicroservice
      .getDoctorAppointAvailable(
        this.branchRef,
        this.clinicRef,
        this.doctorRefId
      )
      .subscribe((res) => {
        let day = this.appointmentMaster.value.startDateTime;
        let appointDay = moment(day).format("dddd");
        ////

        let drInfo = res.data[0];
        this.timeLabels = [];
        drInfo.dayInfo.forEach((element) => {
          // ////
          if (element.day == appointDay) {
            // ////
            //this.timelineLabels(element.morningFrom,1,'hours',element.morningTo);
            this.timelineLabels(
              element.morningFrom,
              this.durationValue,
              "minutes",
              element.morningTo
            );
            this.timelineLabels(
              element.afternoonFrom,
              this.durationValue,
              "minutes",
              element.afternoonTo
            );
            this.timelineLabels(
              element.eveningFrom,
              this.durationValue,
              "minutes",
              element.eveningTo
            );
          }
        });
      });
  }

  timelineLabels = (desiredStartTime, interval, period, desiredEndTime) => {

    const start = moment.utc(desiredStartTime, "hh:mm A");
    const end = moment.utc(desiredEndTime, "hh:mm A");
    // account for crossing over to midnight the next day
    if (end.isBefore(start)) end.add(0, "day");
    // calculate the duration
    const d = moment.duration(end.diff(start)).as(period);

    const periodsInADay = moment.duration(desiredEndTime).as(period);

    const startTimeMoment = moment(desiredStartTime, "hh:mm A");
    for (let i = 0; i < d; i += interval) {
      startTimeMoment.add(i === 0 ? 0 : interval, period);

      this.timeLabels.push({
        slot: startTimeMoment.format("hh:mm A"),
        status: "unbooked",
        isDisabled: "No",
      });
      // ////
    }

    return this.timeLabels;
  };

  reset() {
    this.savebutton = true;
    this.updatebutton = false;
    this.clear = false;
    this.delete = false;
    this.appointmentMaster.reset();
    this.timeLabels = [];
  }

  submit() {
    if (
      this.BackDatedCreate == false && this.appointmentMaster.value.startDateTime <
      new Date(this.currentdate).toISOString()
    ) { 
      this.apimicroservice.openSnackbar(
        "Previous Dated Slots Cannot be booked"
      );
      this.ngOnInit();
    } else {
      this.spinner.show();
      let payload = this.appointmentMaster.value;
      payload.appoinmentTime = this.newAppointment;
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.doctorRefId = this.doctorRefId;
      payload.patientRefId = this.patientRefId;
      payload.patientName = this.patientName;
      payload.patientId = this.patientId;
      payload.aptStatus = "Cancel";
      // ////
      this.apimicroservice.addAppointment(payload).subscribe(
        (res) => {
          // ////

          this.spinner.hide();
          if (res.status) {
            // ////
            if (res.message == "Saved Successfully") {
              this.apimicroservice.openSnackbar("Appoinment Saved");
            } else {
              this.apimicroservice.openSnackbar(res.message);
            }

            this.ngOnInit();
          } else {
            this.apimicroservice.openSnackbar(res.message);
          }
        },
        (ERROR: HttpErrorResponse) => {
          //The number  is unverified. Trial accounts cannot send messages to unverified numbers; verify  at twilio.com/user/account/phone-numbers/verified, or purchase a Twilio number to send messages to unverified numbers.
          // this.apimicroservice.openSnackbar(ERROR.error.message);
          if (
            ERROR.error.message ==
            "The number  is unverified. Trial accounts cannot send messages to unverified numbers; verify  at twilio.com/user/account/phone-numbers/verified, or purchase a Twilio number to send messages to unverified numbers."
          ) {
            this.apimicroservice.openSnackbar("In valide number");
          }

          this.spinner.hide();
        }
      );
    }
  }

  update() {
    if (
      this.BackDatedCreate == false && this.appointmentMaster.value.startDateTime <
      new Date(this.currentdate).toISOString()
    ) {
      this.apimicroservice.openSnackbar(
        "Previous Dated Slots Cannot be booked"
      );
      this.ngOnInit();
    } else {
      this.spinner.show();
      let payload = this.appointmentMaster.value;
      payload.appoinmentTime = this.newAppointment;
      payload.branchRefId = this.branchRef;
      payload.doctorRefId = this.doctorRefId;
      payload.patientRefId = this.patientRefId;
      payload.patientName = this.patientName;
      payload.patientId = this.patientId;
      payload.aptStatus = "Rescheduled";
      this.apimicroservice
        .updateAppointment(this.updateRefid, this.appointmentMaster.value)
        .subscribe((res) => {
          this.spinner.hide();
          if (res.status) {
            // ////
            this.savebutton = true;
            this.updatebutton = false;
            this.clear = false;
            this.delete = false;
            this.apimicroservice.openSnackbar(res.message);
            this.ngOnInit();
          } else {
            this.apimicroservice.openSnackbar(res.message);
          }
        }, (ERROR: HttpErrorResponse) => {
          //The number  is unverified. Trial accounts cannot send messages to unverified numbers; verify  at twilio.com/user/account/phone-numbers/verified, or purchase a Twilio number to send messages to unverified numbers.
          // this.apimicroservice.openSnackbar(ERROR.error.message);
          if (
            ERROR.error.message ==
            "The number  is unverified. Trial accounts cannot send messages to unverified numbers; verify  at twilio.com/user/account/phone-numbers/verified, or purchase a Twilio number to send messages to unverified numbers."
          ) {
            this.apimicroservice.openSnackbar("In valide number");
          }

          this.spinner.hide();
        }
      );
    }
  }

  fetchDuration() {
    this.apimicroservice.getAppointGenericAllData().subscribe((res) => {
     ////
      if (res.message == "No Data Found") {
        this.durationValue = 30;
      } else {
        if (res.data[0].duration == undefined) {
          this.durationValue = 30;
        } else {
          this.durationValue = res.data[0].duration;
        }

        // this.durationValue = res.data[0].duration;
      }

    });
  }

  functiondelete() {
    this.spinner.show();
    let payload = this.appointmentMaster.value;
    this.appointmentMaster.get("aptStatus").setValue("Cancel");
    this.apimicroservice
      .deleteAppointmentData(this.updateRefid)
      .subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          //  this.apimicroservice.openSnackbar(res.message);
          this.savebutton = true;
          this.updatebutton = false;
          this.clear = false;
          this.delete = false;
          this.appointmentMaster.reset();
          this.ngOnInit();
        } else {
          // this.apimicroservice.openSnackbar(res.message);
        }
      });
  }

  onSelectTime(time) {
    this.isSave = false;
    if (this.updatebutton == true) {
      this.isUpdate = false;
    }
    this.branchUnavailable = false;
    let ssss = this.appointmentMaster.get("startDateTime").value;
    let currentime = new Date();
    let ss = moment.utc(time, "HH:mm A").toISOString();
    let mydaya = new Date(ss);
    let dtOffset = new Date(
      mydaya.setMinutes(mydaya.getMinutes() + currentime.getTimezoneOffset())
    );
    let hours = new Date(dtOffset).getHours();
    let minutes = new Date(dtOffset).getMinutes();
    let timex = new Date(ssss).setHours(hours);
    let timess = new Date(timex).setMinutes(minutes);
    this.appointmentMaster
      .get("startDateTime")
      .setValue(new Date(timess).toISOString());
    this.appointmentMaster.get("appoinmentTime").setValue(time);
    this.newAppointment = time;

    // // Date in EST and ISO format: "2021-11-30T15:33:32.222Z"
    // ////);
  }

  dddd(e) {
    this.appointmentMaster.get("eventColor").setValue(e.color);
  }
  toDateChange() {
    let payload = this.appointmentMaster.value;

    if (Date.parse(payload.endDateTime) <= Date.parse(payload.startDateTime)) {
      this.apimicroservice.openSnackbar(
        "To date should be greater than From date"
      );
      this.appointmentMaster.controls["startDateTime"].setValue("");
    }

    this.apimicroservice
      .getAppointmentbydatedate(payload.startDateTime)
      .subscribe((res) => {
        // ////
        if (res.status) {
          //  ////
          //this.apimicroservice.openSnackbar(res.message);
        } else {
          // this.apimicroservice.openSnackbar(res.message);
        }
      });
    ////
    ////
    ////
    this.appointmentMaster.controls["doctorName"].setValue("");
    this.timeLabels = [];
    if (this.updatebutton == true) {
      this.isUpdate = true;
    }
    // this.filteredDoctorList =[];
    //this.timeLabels = [];
    if (payload.doctorName == "") {
      this.searcheddoctor = false;
      this.timeLabels = [];
      if (this.updatebutton == true) {
        this.isUpdate = true;
      }
    }
    ////
    if (payload.clinicLocation !== "") {
      ////

      ////
      if (this.updatebutton == true) {
        this.isUpdate = true;
      }
      let bar = new Promise<void>((resolve, reject) => {
        this.selectBranch(this.branchRef);
        resolve();
      });

      bar.then(() => {
        if (payload.doctorName !== "") {
          ////
          // this.filteredDoctorList =[];
          //this.timeLabels = [];
          ////
          if (this.appointmentMaster.controls["doctorName"].disabled == false) {
            ////
            this.searcheddoctor = false;
            this.timeLabels = [];
            if (this.updatebutton == true) {
              this.isUpdate = true;
            }
            if (payload.doctorName == "") {
              this.searcheddoctor = false;
              this.timeLabels = [];
            }
            this.selectDoctor(this.doctorID);
          }
        } else {
          ////
          this.searcheddoctor = false;
          this.timeLabels = [];
          if (this.updatebutton == true) {
            this.isUpdate = true;
          }
        }
      });
    }

    // this.timeLabels = [];
  }
  fromDateChange() {
    let payload = this.appointmentMaster.value;
    if (Date.parse(payload.endDateTime) <= Date.parse(payload.startDateTime)) {
      this.apimicroservice.openSnackbar(
        "From date should be Less than To date"
      );
      this.appointmentMaster.controls["endDateTime"].setValue("");
    }
  }
  toTimeClick() {
    // let payload = this.appointmentMaster.value;
    // let durationTime = this.datepipe.transform(payload.duration, 'mm:ss a');
    // ////
    // let timee = durationTime + payload.startDateTime;
    // ////
    // let latest_date =this.datepipe.transform(timee, 'MMM d, y, h:mm:ss a');
    // this.endDateTime =  latest_date;
    // ////
  }

  selectBranch(id) {
    this.timeLabels = [];
    this.branchRef = id;
    //this.isSave = false;
    let payload = this.appointmentMaster.value;
    this.searcheddoctor = false;
    this.appointmentMaster.controls["doctorName"].setValue("");
    if (this.updatebutton == true) {
      this.isUpdate = true;
      this.branchUnavailable = false;
      this.branchmsg = "";
      this.appointmentMaster.controls["doctorName"].enable();
    } else {
      this.isSave = true;
      this.branchUnavailable = false;
      this.branchmsg = "";
      this.appointmentMaster.controls["doctorName"].enable();
    }
    let appDate = this.appointmentMaster.controls["startDateTime"].value;
    this.apimicroservice.getBranchById(id).subscribe((res) => {
      ////
      //  ////
      //  ////
      this.clinicRef = res.data[0].clinicRefId;
      this.openBranchTime = res.data[0].openTime;
      this.closetime = res.data[0].closeTime;
      this.weeklyOff = res.data[0].branchWeeklyOff;
      this.tt = moment(this.openBranchTime, "hh:mm A").format("HH:mm:ss");
      ////
      this.ct = moment(this.closetime, "hh:mm A").format("HH:mm:ss");
      ////
      ////
      ////
      // this.fetchDoctorList();

      appDate = new Date(appDate);
      let datesarr = [];
      if (this.branchHolidayList.length > 0) {
        this.branchHolidayList.forEach((ele) => {
          if (ele.branchRefId == this.branchRef) {
            datesarr.push({
              startDate: ele.fromDate,
              endDate: ele.toDate,
              holidayName: ele.holidayName,
            });
          }
        });
        ////
        datesarr.forEach((ele) => {
          let st = new Date(ele.startDate);
          let et = new Date(ele.endDate);
          let holiday = ele.holidayName;
          let tomorrow = moment(et).add(1, "days");
          if (appDate.getTime() >= st.getTime() && appDate < tomorrow) {
            // this.openSnackbar("On occusion of "+holiday+", "+"Clinic is Close From"+st.toDateString()+" to "+et.toDateString());
            this.unavailable = true;
            if (st.toDateString() !== et.toDateString()) {
              this.branchmsg =
                "On occusion of " +
                holiday +
                ", " +
                "clinic will be closed from " +
                st.toDateString() +
                " to " +
                et.toDateString();
            } else {
              this.branchmsg =
                "On occusion of " +
                holiday +
                ", " +
                "clinic will be closed on " +
                st.toDateString();
            }
            ////
            // this.isSave = true;
            if (this.updatebutton == true) {
              this.isUpdate = true;
              this.branchUnavailable = true;
              this.appointmentMaster.controls["doctorName"].disable();
              this.timeLabels = [];
            } else {
              this.isSave = true;
              this.branchUnavailable = true;
              this.appointmentMaster.controls["doctorName"].disable();
              this.timeLabels = [];
            }
          }
        });
        // this.branchHolidayList.forEach(ele =>{
        //   if(ele.branchRefId == id){
        //    found = "true";
        //    holidayName = ele.holidayName;
        //    startDt = new Date(ele.fromDate);
        //    endDate = new Date(ele.toDate);
        //   }
        // });
      }
      //  else{
      ////
      let appDate2 = this.appointmentMaster.controls["startDateTime"].value;
      appDate2 = new Date(appDate2);
      let dayNo = 0;
      this.dayArray.forEach((ele, i) => {
        if (ele.value == this.weeklyOff) {
          dayNo = ele.id;
        }
      });
      let startDate = moment(appDate2);
      let dateeee = startDate.clone(),
        isWeekend = false;

      if (dateeee.isoWeekday() == dayNo) {
        isWeekend = true;
      }
      if (isWeekend) {
        //appDate = dd;

        // this.openSnackbar('Cant add appointment - branch weekly off!');
        this.unavailable = true;
        this.branchmsg = "Branch weekly off!";
        ////
        // this.isSave = true;
        if (this.updatebutton == true) {
          this.isUpdate = true;
          this.branchUnavailable = true;
          this.appointmentMaster.controls["doctorName"].disable();
          this.timeLabels = [];
        } else {
          this.isSave = true;
          this.branchUnavailable = true;
          this.appointmentMaster.controls["doctorName"].disable();
          this.timeLabels = [];
        }
        //  return false;
      }
      // }

      //  if(found == "true"){
      //      if(appDate >= startDt && appDate <= endDate){
      //        this.apimicroservice.openSnackbar("Branch is closed from "+startDt.toDateString()+" to "+endDate.toDateString()+" for"+holidayName+" holiday");
      //        this.appointmentMaster.controls['startDateTime'].setValue('');
      //      }
      //  }

      //lates code - for doctor
      // this.apimicroservice.getAllDoctorsList(id,this.clinicRef).subscribe(res => {
      //   if (res.status) {
      //     this.doctorList = res.data;
      //     this.filteredDoctorList= this.doctorList.slice();
      //   } else {
      //  //   this.apimicroservice.openSnackbar(res.message);
      //   }
      //   });

      //testing code
      this.apimicroservice
        .getAllDoctorsListAvailable(id, this.clinicRef)
        .subscribe((res) => {
          if (res.status) {
            this.doctorList = res.data;
            this.filteredDoctorList = this.doctorList.slice();
          } else {
            //   this.apimicroservice.openSnackbar(res.message);
          }
        });
    },
    (ERROR: HttpErrorResponse) => {
     ////
     
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
    }
  );
    if (payload.doctorName == "") {
      this.searcheddoctor = false;
      this.timeLabels = [];
    }
  }
  eventclickbranch(branch, doct) {
    this.timeLabels = [];
    this.branchRef = branch;
    let docunavilable = "false";
    //this.fetchDoctorLeaves(doct);
    this.apimicroservice.getBranchById(branch).subscribe((res) => {
      ////
      
      //  ////
      //  ////
      this.clinicRef = res.data[0].clinicRefId;
      this.fetchDoctorList();
      this.appointList = [];
      this.searcheddoctor = false;
      this.doctorRefId = doct;
      let day = this.appointmentMaster.value.startDateTime;
      day = new Date(day).toISOString();

      let datesarr = [];
      let appDate = this.appointmentMaster.controls["startDateTime"].value;
      appDate = new Date(appDate);
      this.getDoctorAvailability();
      this.apimicroservice
        .getDoctorLeaveDataByRefId(this.doctorRefId)
        .subscribe((res) => {
          if (res.status) {
            ////
            this.doctorLeavesList = res.data;

            if (this.doctorLeavesList.length > 0) {
              this.doctorLeavesList.forEach((ele) => {
                datesarr.push({ startDate: ele.fromDate, endDate: ele.toDate });
              });

              datesarr.forEach((ele) => {
                let st = new Date(ele.startDate);
                let et = new Date(ele.endDate);
                let tomorrow = moment(et).add(1, "days");
                if (appDate.getTime() >= st.getTime() && appDate < tomorrow) {
                  if(st.toDateString() !== et.toDateString())
                  {
                    this.openSnackbar(
                      "Audiologists is on leave From - " +
                        st.toDateString() +
                        " to - " +
                        et.toDateString()
                    );
                  }
                  else
                  {
                    this.openSnackbar(
                      "Audiologists is on leave On - " +
                        st.toDateString()
                    );
                  }
                 
                  docunavilable = "true";
                  this.isUpdate = true;
                }
               
              });
              if (docunavilable == "false") {
                this.isUpdate = false;
                this.apimicroservice
                  .getDoctorAppointAvailable(
                    this.branchRef,
                    this.clinicRef,
                    this.doctorRefId
                  )
                  .subscribe((res) => {
                    // ////
                    // ////
                    // let appointStartTime = this.appointmentMaster.value.startDateTime._d.toLocaleTimeString('en-GB');
                    // let appointEndTime = this.appointmentMaster.value.endDateTime._d.toLocaleTimeString('en-GB');
                    let day = this.appointmentMaster.value.startDateTime;
                    let appointDay = moment(day).format("dddd");
                    let drInfo = res.data[0];
                    this.timeLabels = [];
                    drInfo.dayInfo.forEach((element) => {
                      // ////
                      if (element.day == appointDay) {
                        // ////
                        //this.timelineLabels(element.morningFrom,1,'hours',element.morningTo);
                        this.timelineLabels(
                          element.morningFrom,
                          this.durationValue,
                          "minutes",
                          element.morningTo
                        );
                        this.timelineLabels(
                          element.afternoonFrom,
                          this.durationValue,
                          "minutes",
                          element.afternoonTo
                        );
                        this.timelineLabels(
                          element.eveningFrom,
                          this.durationValue,
                          "minutes",
                          element.eveningTo
                        );
                      }
                    });
                    this.apimicroservice
                      .getAppointmentbydatedoctor(day, this.doctorRefId)
                      .subscribe((res) => {
                        this.dd = new Date();
                        let a = "";
                        let b = "";
                        a = "6:00 AM";
                        b = "6:00 PM";
                        let xyz = this.appointmentMaster.get("startDateTime")
                          .value;
                        let appointDate = new Date(xyz);
                        /////////
                        ////
                        for (let i = 0; i < this.timeLabels.length; i++) {
                          ////
                          const now = Date.now();
                          this.myFormattedDate = this.pipe.transform(
                            now,
                            "shortTime"
                          );

                          // //
                          this.dateTime = new Date(
                            this.dd.toDateString() +
                              " " +
                              this.timeLabels[i].slot
                          );
                          this.dateTime2 = new Date(
                            this.dd.toDateString() + " " + this.myFormattedDate
                          );
                          // //
                          // //
                          if (
                            this.dd.toDateString() == appointDate.toDateString()
                          ) {
                            if (this.dateTime < this.dateTime2) {
                              this.timeLabels[i].isDisabled = "Yes";
                              // //
                              // this.isDisabled = true;
                              // //

                              // //
                              // //
                            }
                          }
                        }
                        this.appointList = res.data;
                        if (this.appointList.length > 0) {
                          for (let i = 0; i < this.timeLabels.length; i++) {
                            for (let j = 0; j < this.appointList.length; j++) {
                              if (
                                this.timeLabels[i].slot ===
                                this.appointList[j].appoinmentTime
                              ) {
                                this.timeLabels[i].status = "booked";
                              }
                            }
                          }
                          this.searcheddoctor = false;
                        } else {
                          this.searcheddoctor = true;
                        }
                        // //
                      });
                  });
              }
            } else {
              this.isUpdate = false;
              this.apimicroservice
                .getDoctorAppointAvailable(
                  this.branchRef,
                  this.clinicRef,
                  this.doctorRefId
                )
                .subscribe((res) => {
                  // //
                  // //
                  // let appointStartTime = this.appointmentMaster.value.startDateTime._d.toLocaleTimeString('en-GB');
                  // let appointEndTime = this.appointmentMaster.value.endDateTime._d.toLocaleTimeString('en-GB');
                  let day = this.appointmentMaster.value.startDateTime;
                  let appointDay = moment(day).format("dddd");
                  let drInfo = res.data[0];
                  this.timeLabels = [];
                  drInfo.dayInfo.forEach((element) => {
                    // //
                    if (element.day == appointDay) {
                      // //
                      //this.timelineLabels(element.morningFrom,1,'hours',element.morningTo);
                      this.timelineLabels(
                        element.morningFrom,
                        this.durationValue,
                        "minutes",
                        element.morningTo
                      );
                      this.timelineLabels(
                        element.afternoonFrom,
                        this.durationValue,
                        "minutes",
                        element.afternoonTo
                      );
                      this.timelineLabels(
                        element.eveningFrom,
                        this.durationValue,
                        "minutes",
                        element.eveningTo
                      );
                    }
                  });
                  this.apimicroservice
                    .getAppointmentbydatedoctor(day, this.doctorRefId)
                    .subscribe((res) => {
                      this.dd = new Date();
                      let a = "";
                      let b = "";
                      a = "6:00 AM";
                      b = "6:00 PM";
                      let xyz = this.appointmentMaster.get("startDateTime")
                        .value;
                      let appointDate = new Date(xyz);
                      /////////
                      ////
                      for (let i = 0; i < this.timeLabels.length; i++) {
                        ////
                        const now = Date.now();
                        this.myFormattedDate = this.pipe.transform(
                          now,
                          "shortTime"
                        );

                        // //
                        this.dateTime = new Date(
                          this.dd.toDateString() + " " + this.timeLabels[i].slot
                        );
                        this.dateTime2 = new Date(
                          this.dd.toDateString() + " " + this.myFormattedDate
                        );
                        // //
                        // //
                        if (
                          this.dd.toDateString() == appointDate.toDateString()
                        ) {
                          if (this.dateTime < this.dateTime2) {
                            this.timeLabels[i].isDisabled = "Yes";
                            // //
                            // // this.isDisabled = true;
                            // //

                            // //
                            // //
                          }
                        }
                      }
                      this.appointList = res.data;
                      if (this.appointList.length > 0) {
                        for (let i = 0; i < this.timeLabels.length; i++) {
                          for (let j = 0; j < this.appointList.length; j++) {
                            if (
                              this.timeLabels[i].slot ===
                              this.appointList[j].appoinmentTime
                            ) {
                              this.timeLabels[i].status = "booked";
                            }
                          }
                        }
                        this.searcheddoctor = false;
                      } else {
                        this.searcheddoctor = true;
                      }
                      // //
                    });
                });
            }
          }
        });

      //   this.apimicroservice.getDoctorAppointAvailable(this.branchRef,this.clinicRef,this.doctorRefId).subscribe(res => {
      //     // //
      //     // //
      //     // let appointStartTime = this.appointmentMaster.value.startDateTime._d.toLocaleTimeString('en-GB');
      //     // let appointEndTime = this.appointmentMaster.value.endDateTime._d.toLocaleTimeString('en-GB');
      //     let day =this.appointmentMaster.value.startDateTime;
      //     let appointDay = moment(day).format('dddd')
      //     let drInfo = res.data[0];
      //     this.timeLabels = [];
      //     drInfo.dayInfo.forEach(element =>{
      //       // //
      //       if (element.day == appointDay ) {
      //         // //
      //         //this.timelineLabels(element.morningFrom,1,'hours',element.morningTo);
      //         this.timelineLabels(element.morningFrom,this.durationValue,'minutes',element.morningTo);
      //         this.timelineLabels(element.afternoonFrom,this.durationValue,'minutes',element.afternoonTo);
      //         this.timelineLabels(element.eveningFrom,this.durationValue,'minutes',element.eveningTo);
      //         }
      //     })
      //     this.apimicroservice.getAppointmentbydatedoctor(day,this.doctorRefId).subscribe(res => {
      //       this.dd = new Date();
      //       let a = '';
      //       let b ='';
      //       a = '6:00 AM';
      //       b = '6:00 PM';
      //       let xyz = this.appointmentMaster.get('startDateTime').value;
      //       let appointDate = new Date(xyz);
      //       /////////
      //       //
      //       for(let i=0;i<this.timeLabels.length;i++){
      //         //
      //        const now = Date.now();
      //         this.myFormattedDate = this.pipe.transform(now, 'shortTime');

      //         //
      //         this.dateTime = new Date(this.dd.toDateString()+" "+this.timeLabels[i].slot);
      //         this.dateTime2 = new Date(this.dd.toDateString()+" "+this.myFormattedDate);
      //         //
      //         //
      //         if(this.dd.toDateString() == appointDate.toDateString()){
      //         if(this.dateTime  < this.dateTime2) {
      //           this.timeLabels[i].isDisabled = 'Yes';
      //           //
      //        // this.isDisabled = true;
      //          //

      //          //
      //          //

      //         }
      //        }

      //       }
      //       this.appointList = res.data;
      //       if(this.appointList.length > 0){
      //         for(let i=0;i<this.timeLabels.length;i++){
      //           for(let j=0;j<this.appointList.length;j++){
      //             if(this.timeLabels[i].slot === this.appointList[j].appoinmentTime){
      //               this.timeLabels[i].status = "booked";
      //             }
      //           }
      //         }
      //         this.searcheddoctor = false;
      //       }else{
      //         this.searcheddoctor = true;
      //       }
      //       // //
      //     });

      //   });
    },
    (ERROR: HttpErrorResponse) => {
     ////
     
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
    }
  );
  }

  selectPatient(id, fname, lname, pid) {
    ////

    this.patientRefId = id;
    this.patientName = fname + " " + lname;
    this.patientId = pid;
    this.apimicroservice.getPatientById(id).subscribe((res) => {
      // ////);
      let mobile1 = res.data[0].mobile1;
      this.appointmentMaster.get("contactNumber").setValue(mobile1);
    });
  }

  fetchDoctorLeaves(doctorId) {
    // let docunavilable = "false";
    this.apimicroservice
      .getDoctorLeaveDataByRefId(doctorId)
      .subscribe((res) => {
        if (res.status) {
          ////
          this.doctorLeavesList = res.data;
          // let datesarr = [];
          // let appDate = this.appointmentMaster.controls['startDateTime'].value;
          // appDate = new Date(appDate);
          // if(this.doctorLeavesList.length > 0){
          //   this.doctorLeavesList.forEach(ele =>{
          //      datesarr.push({"startDate":ele.fromDate,'endDate':ele.toDate});
          //   });

          //   datesarr.forEach(ele =>{
          //     let st = new Date(ele.startDate);
          //     let et = new Date(ele.endDate);

          //     if(appDate >= st && appDate <= et){
          //       this.openSnackbar("Audiologists is on leave From - "+st.toDateString()+" to - "+et.toDateString());
          //        docunavilable = "true";
          //     }
          //   })
          //   if(docunavilable == "false"){

          //   }
          // }else{

          // }
        }
      });
  }
  selectDoctor(id) {
    ////
    let payload = this.appointmentMaster.value;
    let docunavilable = "false";
    ////
    // this.fetchDoctorLeaves(id);

    this.appointList = [];
    this.searcheddoctor = false;
    this.doctorRefId = id;
    this.doctorID = id;
    let day = this.appointmentMaster.value.startDateTime;
    day = new Date(day).toISOString();
    let xyz = this.appointmentMaster.get("startDateTime").value;
    let appointDate = new Date(xyz);
    // this.getDoctorAvailability();
    let datesarr = [];
    let appDate = this.appointmentMaster.controls["startDateTime"].value;
    appDate = new Date(appDate);

    let bar = new Promise<void>((resolve, reject) => {
      this.apimicroservice.getDoctorLeaveDataByRefId(id).subscribe((res) => {
        if (res.status) {
          ////
          this.doctorLeavesList = res.data;
        }
        resolve();
      });
    });

    bar.then(() => {
      if (this.doctorLeavesList.length > 0) {
        this.doctorLeavesList.forEach((ele) => {
          datesarr.push({ startDate: ele.fromDate, endDate: ele.toDate });
        });

        datesarr.forEach((ele) => {
          let st = new Date(ele.startDate);
          let et = new Date(ele.endDate);
          let tomorrow = moment(et).add(1, "days");
          ////
          ////
          if (appDate.getTime() >= st.getTime() && appDate < tomorrow) {
            // this.openSnackbar(
            //   "Audiologists is on leave From - " +
            //     st.toDateString() +
            //     " to - " +
            //     et.toDateString()
            // );
            if(st.toDateString() !== et.toDateString())
            {
              this.openSnackbar(
                "Audiologists is on leave From - " +
                  st.toDateString() +
                  " to - " +
                  et.toDateString()
              );
            }
            else
            {
              this.openSnackbar(
                "Audiologists is on leave On - " +
                  st.toDateString()
              );
            }
            docunavilable = "true";
            if (this.updatebutton == true) {
              this.isUpdate = true;
            } else {
              this.isSave = true;
            }
          }
        });
        if (docunavilable == "false") {
          if (this.updatebutton == true) {
            this.isUpdate = true;
          } else {
            this.isSave = true;
          }

          this.apimicroservice
            .getDoctorAppointAvailable(
              this.branchRef,
              this.clinicRef,
              this.doctorRefId
            )
            .subscribe((res) => {
              ////
              if (res.message == "No Data Found") {
                this.openSnackbar("Audiologists Un-Available");
              }
              // ////
              // let appointStartTime = this.appointmentMaster.value.startDateTime._d.toLocaleTimeString('en-GB');
              // let appointEndTime = this.appointmentMaster.value.endDateTime._d.toLocaleTimeString('en-GB');
              let day = this.appointmentMaster.value.startDateTime;
              let appointDay = moment(day).format("dddd");
              let drInfo = res.data[0];
              ////

              this.timeLabels = [];
              ////
              ////

              drInfo.dayInfo.forEach((element) => {
                // ////
                if (element.day == appointDay) {
                  // ////
                  //this.timelineLabels(element.morningFrom,1,'hours',element.morningTo);
                  this.timelineLabels(
                    element.morningFrom,
                    this.durationValue,
                    "minutes",
                    element.morningTo
                  );
                  this.timelineLabels(
                    element.afternoonFrom,
                    this.durationValue,
                    "minutes",
                    element.afternoonTo
                  );
                  this.timelineLabels(
                    element.eveningFrom,
                    this.durationValue,
                    "minutes",
                    element.eveningTo
                  );
                }
              });
              this.apimicroservice
                .getAppointmentbydatedoctor(day, this.doctorRefId)
                .subscribe((res) => {
                  ////
                  /////////////////
                  if (payload.doctorName == "") {
                    this.searcheddoctor = false;
                    this.timeLabels = [];
                  }
                  this.dd = new Date();
                  let a = "";
                  let b = "";
                  a = "6:00 AM";
                  b = "6:00 PM";

                  /////////
                  ////
                  for (let i = 0; i < this.timeLabels.length; i++) {
                    ////
                    const now = Date.now();
                    this.myFormattedDate = this.pipe.transform(
                      now,
                      "shortTime"
                    );

                    // ////
                    this.dateTime = new Date(
                      this.dd.toDateString() + " " + this.timeLabels[i].slot
                    );
                    this.dateTime2 = new Date(
                      this.dd.toDateString() + " " + this.myFormattedDate
                    );
                    ////
                    ////
                    ////);
                    ////);
                    if (this.dd.toDateString() == appointDate.toDateString()) {
                      if (this.dateTime < this.dateTime2) {
                        this.timeLabels[i].isDisabled = "Yes";
                        ////
                        // this.isDisabled = true;
                        // //

                        // //
                        // //
                      }
                    }
                    if (payload.doctorName == "") {
                      this.searcheddoctor = false;
                      this.timeLabels = [];
                    }
                  }
                  this.appointList = res.data;
                  if (this.appointList.length > 0) {
                    if (payload.doctorName == "") {
                      this.searcheddoctor = false;
                      this.timeLabels = [];
                    }
                    for (let i = 0; i < this.timeLabels.length; i++) {
                      ////
                      // if(this.timeLabels[i].slot <==)
                      //  const now = Date.now();
                      //   this.myFormattedDate = this.pipe.transform(now, 'shortTime');
                      //   ////
                      //   ////
                      //   if(this.timeLabels[i].slot > this.myFormattedDate) {

                      //    this.isDisabled = true;
                      //    ////

                      //    ////
                      //    ////

                      //   }
                      if (payload.doctorName == "") {
                        this.searcheddoctor = false;
                        this.timeLabels = [];
                      }
                      ////////////////
                      for (let j = 0; j < this.appointList.length; j++) {
                        if (
                          this.timeLabels[i].slot ===
                          this.appointList[j].appoinmentTime
                        ) {
                          this.timeLabels[i].status = "booked";
                        }
                        if (payload.doctorName == "") {
                          this.searcheddoctor = false;
                          this.timeLabels = [];
                        }
                      }
                    }
                    this.searcheddoctor = false;
                    if (payload.doctorName == "") {
                      this.searcheddoctor = false;
                      this.timeLabels = [];
                    }
                  } else {
                    this.searcheddoctor = true;
                    if (payload.doctorName == "") {
                      this.searcheddoctor = false;
                      this.timeLabels = [];
                    }
                  }
                  ////
                });
            });
        }
      } else {
        if (this.updatebutton == true) {
          this.isUpdate = true;
        } else {
          this.isSave = true;
        }
        this.apimicroservice
          .getDoctorAppointAvailable(
            this.branchRef,
            this.clinicRef,
            this.doctorRefId
          )
          .subscribe((res) => {
            ////
            if (res.message == "No Data Found") {
              this.openSnackbar("Audiologists Un-Available");
            }
            // ////
            // let appointStartTime = this.appointmentMaster.value.startDateTime._d.toLocaleTimeString('en-GB');
            // let appointEndTime = this.appointmentMaster.value.endDateTime._d.toLocaleTimeString('en-GB');
            let day = this.appointmentMaster.value.startDateTime;
            let appointDay = moment(day).format("dddd");
            let drInfo = res.data[0];
            ////

            this.timeLabels = [];
            ////
            ////
            if (payload.doctorName == "") {
              this.searcheddoctor = false;
              this.timeLabels = [];
            }

            drInfo.dayInfo.forEach((element) => {
              // ////
              if (element.day == appointDay) {
                // ////
                //this.timelineLabels(element.morningFrom,1,'hours',element.morningTo);
                this.timelineLabels(
                  element.morningFrom,
                  this.durationValue,
                  "minutes",
                  element.morningTo
                );
                this.timelineLabels(
                  element.afternoonFrom,
                  this.durationValue,
                  "minutes",
                  element.afternoonTo
                );
                this.timelineLabels(
                  element.eveningFrom,
                  this.durationValue,
                  "minutes",
                  element.eveningTo
                );
              }
            });
            this.apimicroservice
              .getAppointmentbydatedoctor(day, this.doctorRefId)
              .subscribe((res) => {
                ////
                /////////////////
                if (payload.doctorName == "") {
                  this.searcheddoctor = false;
                  this.timeLabels = [];
                }
                this.dd = new Date();
                let a = "";
                let b = "";
                a = "6:00 AM";
                b = "6:00 PM";

                /////////
                ////
                for (let i = 0; i < this.timeLabels.length; i++) {
                  if (payload.doctorName == "") {
                    this.searcheddoctor = false;
                    this.timeLabels = [];
                  }
                  ////
                  const now = Date.now();
                  this.myFormattedDate = this.pipe.transform(now, "shortTime");

                  // //
                  this.dateTime = new Date(
                    this.dd.toDateString() + " " + this.timeLabels[i].slot
                  );
                  this.dateTime2 = new Date(
                    this.dd.toDateString() + " " + this.myFormattedDate
                  );
                 // //
                  ////
                  ////);
                  ////);
                  if (this.dd.toDateString() == appointDate.toDateString()) {
                    if (this.dateTime < this.dateTime2) {
                      this.timeLabels[i].isDisabled = "Yes";
                      ////
                      // this.isDisabled = true;
                      ////

                      // //
                      // //
                    }
                  }
                }
                this.appointList = res.data;
                if (this.appointList.length > 0) {
                  for (let i = 0; i < this.timeLabels.length; i++) {
                    ////
                    // if(this.timeLabels[i].slot <==)
                    //  const now = Date.now();
                    //   this.myFormattedDate = this.pipe.transform(now, 'shortTime');
                    //   //
                    //   //
                    //   if(this.timeLabels[i].slot > this.myFormattedDate) {

                    //    this.isDisabled = true;
                    //    //

                    //    //
                    //    //

                    //   }
                    if (payload.doctorName == "") {
                      this.searcheddoctor = false;
                      this.timeLabels = [];
                    }
                    ////////////////
                    for (let j = 0; j < this.appointList.length; j++) {
                      if (
                        this.timeLabels[i].slot ===
                        this.appointList[j].appoinmentTime
                      ) {
                        this.timeLabels[i].status = "booked";
                      }
                      if (payload.doctorName == "") {
                        this.searcheddoctor = false;
                        this.timeLabels = [];
                      }
                    }
                  }
                  this.searcheddoctor = false;
                  if (payload.doctorName == "") {
                    this.searcheddoctor = false;
                    this.timeLabels = [];
                  }
                } else {
                  ////

                  /// this.searcheddoctor = true;
                }
                // //
              });
          });
      }
    });

    if (payload.doctorName == "") {
      this.searcheddoctor = false;
      this.timeLabels = [];
    }
  }

  fetchDoctorList() {
    let branchRef = "";
    let clinicRef = "";

    this.apimicroservice
      .getAllDoctorsListAvailable(this.branchRef, this.clinicRef)
      .subscribe((res) => {
        if (res.status) {
          this.doctorList = res.data;
          this.filteredDoctorList = this.doctorList.slice();
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      });

  }

  getCalender() {
    this.apimicroservice.getAppointGenericAllData().subscribe((res) => {
      ////

      if (res.data.length > 0) {
        this.fieldsOfCalenders = res.data[0];
      } else {
        this.fieldsOfCalenders = this.arrayCalender;
      }

      // ////",res.data);
      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  draganddropbranch(id, appoinmentTime, slot) {
    // let payload = this.appointmentMaster.value;
    this.appointList = [];
    this.searcheddoctor = false;
    this.doctorRefId = id;
    this.doctorID = id;
    ////
    let bar = new Promise<void>((resolve, reject) => {
      this.apimicroservice.getBranchById(this.branchRef).subscribe((res) => {
        ////
        
        this.weeklyOff = res.data[0].branchWeeklyOff;
        resolve();
      },
      (ERROR: HttpErrorResponse) => {
       ////
       
        this.apimicroservice.openSnackbar(ERROR.error.message);
        this.spinner.hide();
      }
    );
    });
 
    bar.then(() => {
      let appDate2 = this.appointmentMaster.controls["startDateTime"].value;
      appDate2 = new Date(appDate2);
      let datesarr2 = [];
      if (this.branchHolidayList.length > 0) {
        this.branchHolidayList.forEach((ele) => {
          if (ele.branchRefId == this.branchRef) {
            datesarr2.push({
              startDate: ele.fromDate,
              endDate: ele.toDate,
              holidayName: ele.holidayName,
            });
          }
        });
        ////
        datesarr2.forEach((ele) => {
          let st = new Date(ele.startDate);
          let et = new Date(ele.endDate);
          let tomorrow = moment(et).add(1, "days");
          let holiday = ele.holidayName;
          ////
          ////
          if (appDate2.getTime() >= st.getTime() && appDate2 < tomorrow) {
            // this.openSnackbar("On occusion of "+holiday+", "+"Clinic is Close From"+st.toDateString()+" to "+et.toDateString());
            this.unavailable = true;
            //////);
            
            if (st.toDateString() !== et.toDateString()) {
              this.apimicroservice.openSnackbar("On occusion of " + holiday + ", " +
              "clinic will be closed from " + st.toDateString() +" to " + et.toDateString());
              this.branchmsg =
                "On occusion of " +
                holiday +
                ", " +
                "clinic will be closed from " +
                st.toDateString() +
                " to " +
                et.toDateString();
            } else {
              this.apimicroservice.openSnackbar(this.branchmsg ="On occusion of " + holiday + ", " +
                "clinic will be closed on " + st.toDateString());
              this.branchmsg =
                "On occusion of " +
                holiday +
                ", " +
                "clinic will be closed on " +
                st.toDateString();
            }
            //////
            // this.isSave = true;
            this.ngOnInit();
          }
        });
  
      }
      // else{
      //////

      let appDt2 = this.appointmentMaster.controls["startDateTime"].value;
      appDt2 = new Date(appDt2);
      ////
      let dayNo = 0;
      this.dayArray.forEach((ele, i) => {
        if (ele.value == this.weeklyOff) {
          dayNo = ele.id;
          ////
        }
      });
      let startDate = moment(appDt2);
      let dateeee = startDate.clone(),
        isWeekend = false;
      // //dateeee.isoWeekday()",
      //   dateeee.isoWeekday()
      // );

      if (dateeee.isoWeekday() == dayNo) {
        isWeekend = true;
      }
      if (isWeekend) {
        //appDate = dd;
        //alert('cant add appointment - branch weekly off!');
        // //
     
        this.unavailable = true;
        this.openSnackbar('Branch weekly off!');
        this.branchmsg = "Branch weekly off!";
        ////
        this.ngOnInit();
        //  return false;
      }
      //}
    });

    let docunavilable = "false";
    this.branchmsg = "";
    let day = this.appointmentMaster.value.startDateTime;
    day = new Date(day).toISOString();
    let hour = new Date(slot).getHours();
    let minutes = new Date(slot).getMinutes();
    let min = "";
    let hhu = "";
    if (minutes == 0) {
      min = "00";
    } else {
      min = minutes.toString();
    }
    if (hour < 10) {
      hhu = "0" + hour;
    } else {
      hhu = hour.toString();
    }
    let slotraw = hhu + ":" + min;
    var hourEnd = slotraw.indexOf(":");
    var H = +slotraw.substr(0, hourEnd);
    var h = H % 12 || 12;
    var x = h < 10 ? "0" + h : h;
    var ampm = H < 12 ? " AM" : " PM";
    slotraw = x + slotraw.substr(hourEnd, 3) + ampm;
    var ts = slotraw;

    let datesarr = [];
    let appDate = this.appointmentMaster.controls["startDateTime"].value;
    appDate = new Date(appDate);
    this.apimicroservice.getDoctorLeaveDataByRefId(id).subscribe((res) => {
      if (res.status) {
        //
        this.doctorLeavesList = res.data;

        if (this.doctorLeavesList.length > 0) {
          this.doctorLeavesList.forEach((ele) => {
            datesarr.push({ startDate: ele.fromDate, endDate: ele.toDate });
          });

          datesarr.forEach((ele) => {
            let st = new Date(ele.startDate);
            let et = new Date(ele.endDate);
            let tomorrow = moment(et).add(1, "days");
            if (appDate.getTime() >= st.getTime() && appDate < tomorrow) {
              // this.openSnackbar(
              //   "Audiologists is on leave From - " +
              //     st.toDateString() +
              //     " to - " +
              //     et.toDateString()
              // );
              if(st.toDateString() !== et.toDateString())
              {
                this.openSnackbar(
                  "Audiologists is on leave From - " +
                    st.toDateString() +
                    " to - " +
                    et.toDateString()
                );
              }
              else
              {
                this.openSnackbar(
                  "Audiologists is on leave On - " +
                    st.toDateString()
                );
              }
              docunavilable = "true";
              this.ngOnInit();
              //  return;
              // this.isSave = true;
            }
          });
          if (docunavilable == "false") {
            // this.isSave = false;
            this.apimicroservice
              .getDoctorAppointAvailable(
                this.branchRef,
                this.clinicRef,
                this.doctorRefId
              )
              .subscribe((res) => {
                let appointDay = moment(day).format("dddd");
                let drInfo = res.data[0];
                drInfo.dayInfo.forEach((element) => {
                  // //
                  if (element.day == appointDay) {
                    this.timelineLabels(
                      element.morningFrom,
                      this.durationValue,
                      "minutes",
                      element.morningTo
                    );
                    this.timelineLabels(
                      element.afternoonFrom,
                      this.durationValue,
                      "minutes",
                      element.afternoonTo
                    );
                    this.timelineLabels(
                      element.eveningFrom,
                      this.durationValue,
                      "minutes",
                      element.eveningTo
                    );
                  }
                });
                this.apimicroservice
                  .getAppointmentbydatedoctor(day, this.doctorRefId)
                  .subscribe((res) => {
                    this.appointList = res.data;
                    if (this.appointList.length > 0) {
                      for (let i = 0; i < this.timeLabels.length; i++) {
                       
                        for (let j = 0; j < this.appointList.length; j++) {
                          if (
                            this.timeLabels[i].slot ===
                            this.appointList[j].appoinmentTime
                          ) {
                            this.timeLabels[i].status = "booked";
                          }
                        }
                      }
                      if (this.timeLabels.length > 0) {
                        this.timeLabels.forEach((element) => {
                        
                          if (
                            element.slot == ts &&
                            element.status == "unbooked"
                          ) {
                            this.onSelectTime(ts);
                            this.update();
                            return;
                          }
                          if (
                            element.slot == ts &&
                            element.status == "booked"
                          ) {
                            this.apimicroservice.openSnackbar(
                              "Slots are already Booked"
                            );
                            return;
                          }
                          if (element.slot != ts && element.status == "booked") {
                            this.apimicroservice.openSnackbar(
                              "Audiologists Un-Available"
                            );
                          }
                        });
                        this.ngOnInit();
                      } else {
                        this.apimicroservice.openSnackbar(
                          "Audiologists Un-Available"
                        );
                        this.ngOnInit();
                      }
                    } else {
                      if (this.timeLabels.length > 0) {
                        this.timeLabels.forEach((element) => {
                          if (
                            element.slot == ts &&
                            element.status == "unbooked"
                          ) {
                           
                            this.onSelectTime(ts);
                            this.update();
                            return;
                          }
                          if (
                            element.slot == ts &&
                            element.status == "booked"
                          ) {
                            this.openSnackbar("Slots are already Booked");
                            return;
                          }
                          if (element.slot != ts && element.status == "booked") {
                            this.openSnackbar("Audiologists Un-Available");
                          }
                        });
                        this.ngOnInit();
                      } else {
                        this.openSnackbar("Audiologists Un-Available");
                        this.ngOnInit();
                      }
                    }
                  });
              });
          }
        } else {
          // this.isSave = false;
          this.apimicroservice
            .getDoctorAppointAvailable(
              this.branchRef,
              this.clinicRef,
              this.doctorRefId
            )
            .subscribe((res) => {
              let appointDay = moment(day).format("dddd");
              let drInfo = res.data[0];
              drInfo.dayInfo.forEach((element) => {
                // //
                if (element.day == appointDay) {
                  this.timelineLabels(
                    element.morningFrom,
                    this.durationValue,
                    "minutes",
                    element.morningTo
                  );
                  this.timelineLabels(
                    element.afternoonFrom,
                    this.durationValue,
                    "minutes",
                    element.afternoonTo
                  );
                  this.timelineLabels(
                    element.eveningFrom,
                    this.durationValue,
                    "minutes",
                    element.eveningTo
                  );
                }
              });
              this.apimicroservice
                .getAppointmentbydatedoctor(day, this.doctorRefId)
                .subscribe((res) => {
                  this.appointList = res.data;
                  if (this.appointList.length > 0) {
                    for (let i = 0; i < this.timeLabels.length; i++) {
                      for (let j = 0; j < this.appointList.length; j++) {
                        if (
                          this.timeLabels[i].slot ===
                          this.appointList[j].appoinmentTime
                        ) {
                          this.timeLabels[i].status = "booked";
                        }
                      }
                    }
                    if (this.timeLabels.length > 0) {
                      this.timeLabels.forEach((element) => {
                      
                        if (
                          element.slot == ts &&
                          element.status == "unbooked"
                        ) {
                          this.onSelectTime(ts);
                          this.update();
                          return;
                        }
                        if (element.slot == ts && element.status == "booked") {
                          this.apimicroservice.openSnackbar(
                            "Slots are already Booked"
                          );
                          return;
                        }
                        if (element.slot != ts && element.status == "booked") {
                          this.apimicroservice.openSnackbar(
                            "Audiologists Un-Available"
                          );
                        }
                      });
                      this.ngOnInit();
                    } else {
                      this.apimicroservice.openSnackbar(
                        "Audiologists Un-Available"
                      );
                      this.ngOnInit();
                    }
                  } else {
                    if (this.timeLabels.length > 0) {
                      this.timeLabels.forEach((element) => {
                        if (
                          element.slot == ts &&
                          element.status == "unbooked"
                        ) {

                          this.onSelectTime(ts);
                          this.update();
                          return;
                        }
                       else if (element.slot == ts && element.status == "booked") {
                          this.openSnackbar("Slots are already Booked");
                          return;
                        }
                       else if (element.slot != ts && element.status == "booked") {

                          this.openSnackbar("Audiologists Un-Available");
                        }
                      });
                      this.ngOnInit();
                    } else {
                      this.openSnackbar("Audiologists Un-Available");
                      this.ngOnInit();
                    }
                  }
                });
            });
        }
      }
    });

   
  }
  openPatientPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpPatientComponent, {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe((res) => {
        if (res.status) {
          // //
          this.patientlist = res.data.data;
          this.filteredPatientList = this.patientlist.slice();
      

          this.selectPatient(
            res.data.data[0]._id,
            res.data.data[0].firstName,
            res.data.data[0].lastName,
            res.data.data[0].patientId
          );
          this.appointmentMaster
            .get("clientName")
            .setValue(res.data.data[0].firstName);
        }
      });
    }else{
      this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe((res) => {
        if (res.status) {
          // //
          this.patientlist = res.data.data;
          this.filteredPatientList = this.patientlist.slice();
      

          this.selectPatient(
            res.data.data[0]._id,
            res.data.data[0].firstName,
            res.data.data[0].lastName,
            res.data.data[0].patientId
          );
          this.appointmentMaster
            .get("clientName")
            .setValue(res.data.data[0].firstName);
        }
      });
    }
    });
  }
  openDoctorPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpDoctorComponent, {
      width: "1200px",
    });
    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchDoctorList();
    });
  }
  openAppointmentTypePopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpAppointmenttypeComponent, {
      width: "700px",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchAppointmentTypeList();
    });
  }
  controlClick() {
    sessionStorage.setItem("controlClick", "calander");
    this.redirect('/home/generic-setting');
  } 
  redirect(to): void {
    this.router.navigate([to]);
   }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "top",
      horizontalPosition: "right",
      duration: 4500,
      panelClass: ["snackbarStyle"],
    });
  }

  WhatsApp() {
    let payload = this.appointmentMaster.value;
    let num = payload.contactNumber;
    if (num.length == 10) {
      sessionStorage.setItem("whatsAppNumber", payload.contactNumber);
      let mobileNumber = sessionStorage.getItem("whatsAppNumber");
      window.open("https://wa.me/91" + mobileNumber);
    } else {
      this.appointmentMaster.get("contactNumber").markAsTouched();
    }
  }
  onback() {
    this.apimicroservice.back();
  }

  openBookAppointmentPopup(e) {
    let payload = this.appointmentMaster.value
   
    if(e !== null)
    {
      if(e.toDateString() == new Date(this.currentdate).toDateString()) 
      {
        const dialogRef3 = this.dialog.open(AppoinmentPopupComponent, {
          width: "70%",
         data: {
           patientInfo:  this.bookRefId,
           bookDate: this.bookDate,
         },
       }); 
   
       dialogRef3.afterClosed().subscribe((result) => {
         this.ngOnInit();
         
       });
        ////
        
      }
      else if( this.BackDatedCreate == false && e.toISOString() < new Date(this.currentdate).toISOString()) 
      {
        this.apimicroservice.openSnackbar(
          "Previous Dated Slots Cannot be booked"
        );
       
      }
      else
      {
        const dialogRef3 = this.dialog.open(AppoinmentPopupComponent, {
          width: "70%",
          //height: "550px",
         data: {
           patientInfo:  this.bookRefId,
           bookDate: this.bookDate,
         },
       }); 
   
       dialogRef3.afterClosed().subscribe((result) => {
         this.ngOnInit();
         
       });
      }
    }
    else
    {
      const dialogRef3 = this.dialog.open(AppoinmentPopupComponent, {
        width: "70%",
        //height: "550px",
       data: {
         patientInfo:  this.bookRefId,
         bookDate: this.bookDate,
       },
     }); 
 
     dialogRef3.afterClosed().subscribe((result) => {
       this.ngOnInit();
       
     });
    }
   
   
  }

  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%", 
    });

    dialogRef3.afterClosed().subscribe((result) => {
      this.appointmentMaster.get("clientName").setValue(result.data.firstName);
 
      this.selectPatient(
        result.data._id,
        result.data.firstName,
        result.data.lastName,
        result.data.patientId
      ); 
    });
  }
}