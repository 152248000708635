import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray 
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopupModeOfPaymentComponent } from "../popup-mode-of-payment/popup-mode-of-payment.component";
import { PopupAddDeviceComponent } from "../popup-add-device/popup-add-device.component";
import { PreviewPopupComponent } from "../preview-popup/preview-popup.component";
import { element } from "protractor";
import { PopupConfirmationMsgComponent } from "../popup-confirmation-msg/popup-confirmation-msg.component";
import { PopupServiceComponent } from "../popup-service/popup-service.component";
@Component({
  selector: 'app-bill-form',
  templateUrl: './bill-form.component.html',
  styleUrls: ['./bill-form.component.scss']
})
export class BillFormComponent implements OnInit {
  BillingForm: FormGroup;
  patientByDeviceForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=20;
  page=1;
  searchText: any;
  submitButton = false;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  serialVal = '';
  dashVal = '-';
  courierServiceCreate = true;
  branchCreate = true;
  filteredListPatient: any;
  patientList: any;
  paymentMode='Cash';
  history = false;
  serviceInfoLength= 1;
  readonly= false;
  showWarranty = false;
  isAdvance=false;
  yearData=[
    { name:'1', value:1 },
    { name:'2', value:2 },
    { name:'3', value:3 },
    { name:'4', value:4 },
    { name:'5', value:5 },
    { name:'6', value:6 },
    { name:'Custom', value:7},               
  ]
  
  printData=[
    {
      srNo:1,
      date:'12/03/2001',
      serviceName:'Test',
      hanSac:90,
      Quantity:1,
      rate:100,
     },
     {
      srNo:2,
      date:'12/03/2001',
      serviceName:'Test',
      hanSac:11,
      Quantity:1,
      rate:200,
     },
     {
      srNo:3,
      date:'12/03/2001',
      serviceName:'Test 2',
      hanSac:30,
      Quantity:3,
      rate:100,
     },
     {
      srNo:4,
      date:'12/03/2001',
      serviceName:'Test 3',
      hanSac:20,
      Quantity:2,
      rate:150,
     },                 
  ]
  printData2=[
    {
      srNo:1,
      date:'12/03/2001',
      bankName:'HDFC',
      hanSac:90,
      Quantity:1,
      rate:100,
     },
     {
      srNo:2,
      date:'12/03/2001',
      bankName:'Axis',
      hanSac:11,
      Quantity:1,
      rate:200,
     },
     {
      srNo:3,
      date:'12/03/2001',
      bankName:'BOI',
      hanSac:30,
      Quantity:3,
      rate:100,
     },
     {
      srNo:4,
      date:'12/03/2001',
      bankName:'Karnataka',
      hanSac:20,
      Quantity:2,
      rate:150,
     },                 
  ]
  billNo: any;
  billingList = [];
  patientName: any;
  amount: any;
  totalAmount: any;
  discount = 0;
  billData: any;
  advanceAmount:any;
  balanceAmount = 0;
  gst = 0;
  chequeDetails = [];
  billRefId: any;
  paymentHistoryList = [];
  amountInWord: any;
  totalAmountInWords: any;
  totalsgst = 0;
  totalcgst = 0;
  totaligst = 0;
  totalsgstAmt = 0;
  totalcgstAmt = 0;
  totaligstAmt = 0;
  tempTotalAmount = 0;
  checkAmount = [];
  pendingChequeAmount = 0;
  modeval = '';
  totalPaidAmount: any;
  bounceAmount = 0;
  bounceStatus = 'no';
  disableSubmit = true;
  servicePaidAmount = 0;
  fullBalanceAmount = 0;
  deviceInfo = [];
  serialNo: any;
  patientRefId: any;
  advaceData= [];
  paidAdvanceAmount= 0;
  divideAmount: number;
  fullTempAmt = 0;
  showMsg = false;
  showMsg1 = false;
  advanceAmtCopy = false;
  mode: any;
  paymentStatus: any;
  serviceAdvanceAmount: any;
  tempBalanceAmt: any;
  tempPaidAmount: any;
  sumOfAdvance: any;
  state: any;
  chequeAmt: number;
  filteredListWarrenty: { name: string; value: number; }[];
  minDate: Date;
  minDate2: Date;
  minDate3: Date;
  totalAmtPaidTillDate=0;
  advanceAmt=0;
  enterValue=0;
  RemainingAmt: number;
  chequeAdvance=0;
  chequeData: any;
  isPrintAvailable = false;
  items = [
    { amount: 1000, paid: 0 },
    { amount: 1000, paid: 0 },
  ];
  newAmount: number = 0;
  subtractAmount: number = 0;
  chequeStatus: any;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { }
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;

  ngOnInit(): void {
    this.filteredListWarrenty = this.yearData.slice();
    this.readonly = false;
    this.showMsg = false;
    this.showMsg1 = false;
    this.showWarranty = false;
    this.currentdate = new Date();
    this.bounceStatus = 'no';
    this.disableSubmit = true;
    this.paymentHistoryList = [];
    this.checkAmount = [];
    this.totalsgst = 0;
    this.servicePaidAmount = 0;
  this.totalcgst = 0;
  this.totaligst = 0;
  this.fullBalanceAmount = 0;
  this.totalsgstAmt = 0;
  this.totalcgstAmt = 0;
  this.totaligstAmt = 0;
  this.tempTotalAmount = 0;
  this.totalPaidAmount = 0;
  this.advanceAmount = 0;
  this.balanceAmount = 0;
  this.bounceAmount = 0;
  this.pendingChequeAmount = 0;
  this.discount = 0;

    //this.balanceAmount = this.totalAmount;
    this.page=1;
    let i = 0;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.billNo = this.route.snapshot.paramMap.get('billNo'); 
    //console.log("this.billNo",this.billNo); 
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false; 
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Billing Record' && element.create == false)
      {
          this.billingCreate = false;  
      }
      if(element.pageName == 'Billing Record' && element.update == false)
      {
          this.billingUpdate = false;  
      }  
    });
  }
  
    this.BillingForm = this.fb.group({
      "modeOfPayment": new FormControl('',Validators.required),
      "branchRefId": new FormControl(''),
      "branchName": new FormControl(''),
      "clinicRefId": new FormControl(''), 
      "searchBilling": new FormControl(''), 
      "paidAdvanceAmount": new FormControl(''), 
      "chequeArray": this.fb.array([]),
      "serviceInfo": this.fb.array([]),
      "selectAllService": new FormControl(false),
      "searchHistory": new FormControl(''), 
      "totalCal": new FormControl('',[Validators.pattern("^[0-9]*$"),Validators.required]) 
    });

    this.patientByDeviceForm = this.fb.group({
      "billingRefId": new FormControl(''),
      "patientRefId": new FormControl(''),
      "patientName": new FormControl(''),
      "model": new FormControl(''), 
      "oldSerialNo": new FormControl(''), 
      "newSerialNo": new FormControl(''), 
      "warranty": new FormControl(''), 
      "billNo": new FormControl(''),
      "type": new FormControl('Fitted By Us'),
      "createdAt": new Date(),
    
      

    });
  //this.BillingForm.controls['modeOfPayment'].setValue('Cash');
 //this.addRow(i);
 this.fetchBillData();
 this.fetchPaymentHistoryData();
}
  
fetchBillData(){ 
  this.apimicroservice.getBillDataById(this.billNo).subscribe(res => {
    //console.log("bill data",res);
   let data = res.data[0];
  //  ////////////

  let remainingAmount;
  if(res.data[0].paidAmount !== undefined)
  {
    remainingAmount = res.data[0].paidAmount;
  }else{
    remainingAmount = 0;
  }
  
 console.log("remainingAmount",remainingAmount);
 console.log("res.data[0].serviceInfo",res.data[0].serviceInfo);
 
  res.data[0].serviceInfo = res.data[0].serviceInfo.map((item,i) => {
    console.log("pillu",item,i);
    console.log("item.totalServiceAmount",item.totalServiceAmount);
    
    if(item.totalServiceAmount!==undefined)
    {
      const subtractedValue = Math.min(remainingAmount, item.totalServiceAmount);
      remainingAmount -= subtractedValue;
      console.log('subtractedValue', subtractedValue);
      item.subValue = subtractedValue;
      return { ...item, paid: subtractedValue };
    }else{
    const subtractedValue = Math.min(remainingAmount, 0);
    remainingAmount -= subtractedValue;
    console.log('subtractedValue', subtractedValue);
    item.subValue = subtractedValue;
    return { ...item, paid: subtractedValue };
    }


  });
  data = res.data[0];

  ////////////////////////////////
   this.billData = data;
   if(data.paidAdvanceAmount !== undefined)
   {
    this.chequeAdvance =data.paidAdvanceAmount;
   }else
   {
    this.chequeAdvance =0;
   }
 
   
   this.deviceInfo = data.deviceInfo;
   this.patientName = data.patientName;
   this.billingList = data.serviceInfo;
   this.totalAmount = data.totalAmount;
   this.patientRefId = data.patientRefId;
   this.tempTotalAmount = data.totalAmount;

   if(data.paidAmount !== undefined && data.paidAmount !== 0){

   this.totalPaidAmount = JSON.parse(data.paidAmount);
   console.log("totalPaidAmount",this.totalPaidAmount);
  //  data.serviceInfo.map((element,i)=>{
  //   let remainAmt = 0;
  //   if(element.servicePaidAmount >= this.totalPaidAmount){
  //     remainAmt = parseInt(element.totalServiceAmount) - parseInt(this.totalPaidAmount);
  //     console.log(remainAmt);
  //   }else{
  //     element.servicePaidAmount = parseInt(element.servicePaidAmount) - parseInt(this.totalPaidAmount)
  //     console.log(element.servicePaidAmount);
      
  //   }
    
  //   // console.log("ele",parseInt(element.servicePaidAmount) - parseInt(this.tempPaidAmount));
    
  //  })
  this.totalCalculationn(this.totalPaidAmount)
   this.paymentStatus = data.paymentStatus;
   }
   else{
    this.totalPaidAmount = 0;
    this.paymentStatus = data.paymentStatus;
   }
   let advanceStatus = 'Yes'; 
   let billnum = '';
   this.advaceData=[];
   
   this.apimicroservice.getPaymentHistoryByPatient(this.patientRefId,billnum,advanceStatus).subscribe(res => {
  
    this.advaceData = res.data;
    let pdata=0;
    let amt = 0;
this.advaceData.forEach(ele=>{   
  if(ele.modeOfPayment == 'Cheque' && ele.chequePaymentStatus !== 'Clear')
  {
    // ele.chequeDetails.forEach(e =>{
    //   console.log("shivvvvvvvv",e);
    //   e.chequeStatus='postdated';
    // });
    // this.newChequeArra.push(ele.chequeDetails);
    // this.chequeDetails.push(ele.chequeDetails); 
    amt = ele.pendingChequeAmount;
    // pdata = pdata + ele.pendingChequeAmount; 
  }
  else
  {
    amt = amt + ele.paidAmount;
    // pdata = pdata + ele.paidAmount;
  }
  // this.chequeAmt = ele.pendingChequeAmount;
}) 
this.chequeAmt = amt;
// this.chequeAmt = this.pendingChequeAmount + pdata;
    let data = [];

    let bar = new Promise<void>((resolve, reject) => {
      res.data.forEach(element => {
        data.push(element.paidAmount);
      });
        resolve();
     });
     bar.then(()=>{
      this.sumOfAdvance = data.reduce((acc, cur) => acc + cur, 0);
     }
     )

    if(this.advaceData.length >0) 
    {
      this.showMsg = true;
    }
      else
      {
        this.showMsg = false;
      }
    
    //console.log("advaceDataadvaceData",this.advaceData);
  });
  this.apimicroservice.getPatientById(data.patientRefId).subscribe(res => { 

    this.state =res.data[0].state;

    if(data.paymentStatus == "Paid"){
      this.submitButton = false;
    }else{
     this.submitButton = true;
    }
    this.gst = data.gst;
 
    //this.balanceAmount = data.balanceAmount;
 
    this.billRefId = data._id;
    //console.log("this.billRefId",this.billRefId);
    if(data !== undefined){
     this.serviceInfoLength = data.serviceInfo.length;
     data.serviceInfo.forEach((element,i) => {
       let sgstAmt = 0;
       let cgstAmt = 0;
       let igstAmt = 0;
       if(this.discount !== null){
         this.discount = this.discount + element.discount;
       }
       if(element.sgst !== null){
         this.totalsgst = this.totalsgst + element.sgst;
        sgstAmt = (element.totalRate * element.sgst) / 100;
        this.totalsgstAmt = this.totalsgstAmt + sgstAmt;
         //console.log("this.totalsgst",this.totalsgstAmt);
       }
       if(element.cgst !== null){
         this.totalcgst = this.totalcgst + element.cgst;
         cgstAmt = (element.totalRate * element.cgst) / 100;
        this.totalcgstAmt = this.totalcgstAmt + cgstAmt;
         //console.log("this.totalcgst",this.totalcgstAmt);
       }
       if(element.igst !== null){
        this.totaligst = this.totaligst + element.igst;
        igstAmt = (element.totalRate * element.igst) / 100;
        this.totaligstAmt = this.totaligstAmt + igstAmt;
         //console.log("this.totaligst",this.totaligstAmt);
       }
       // if(element.status == 'unpaid' || element.status == 'bounce'){
       //   this.disableSubmit = false;
       // }
       
       this.addNew(element,i)
     });
     // this.totalsgstAmt = (this.totalAmount * this.totalsgst) / 100;
     // this.totalcgstAmt = (this.totalAmount * this.totalcgst) / 100;
     // this.totaligstAmt = (this.totalAmount * this.totaligst) / 100;
 if(res.data[0].state == 'Maharashtra')
 {
  this.totalAmount = this.totalAmount + this.totalsgstAmt + this.totalcgstAmt;
 }
 else
 {
  this.totalAmount = this.totalAmount + this.totaligstAmt;
 }
 if(data.paidAmount == undefined)
 {
  this.totalAmtPaidTillDate =0;
 }
 else{
  this.totalAmtPaidTillDate = parseInt(data.paidAmount);
 }

this.advanceAmt = this.paidAdvanceAmount;

    //  if(data.paidAdvanceAmount !== undefined){   
    //    this.paidAdvanceAmount = data.paidAdvanceAmount;
    //   }
    //  if(data.balanceAmount !== undefined){
       
    //    this.balanceAmount = data.balanceAmount;
    //    this.tempBalanceAmt = data.balanceAmount;
    //    this.tempPaidAmount=data.paidAmount;
    //    this.fullBalanceAmount = data.balanceAmount;
    //    this.fullTempAmt = this.fullBalanceAmount;
    //   }else{
    //    this.balanceAmount = this.totalAmount;
    //    this.tempBalanceAmt = data.totalAmount;
    //    this.tempPaidAmount=data.paidAmount;
    //    this.fullBalanceAmount = this.totalAmount;
    //    this.fullTempAmt = this.fullBalanceAmount;
    //   }
    //   if(data.pendingChequeAmount !== undefined){
    //   this.pendingChequeAmount = data.pendingChequeAmount;
    //   }
    //   if(data.bounceAmount !== undefined){
    //    this.bounceAmount = data.bounceAmount;
    //    if(this.bounceAmount !== 0){
    //      this.bounceStatus = "yes";
         
    //    }else{
    //      this.bounceStatus = "no";
    //    }
    //    }
    //   this.amountInWord =this.totalAmount;
    
    // const numWords = require('num-words');
    //  this.totalAmountInWords = numWords(this.totalAmount);
    }
     
 });
 
  
   
   //console.log("data",data);
  });
}

addNew(data,i) {
  console.log("shivvvvvvvvvvvvvvvvvvvv",data);
  //let amt = data.rate * 1;
  const creds = this.BillingForm.controls.serviceInfo as FormArray;
      creds.push(
      this.fb.group({
       // "serviceRefId": new FormControl(data.serviceRefId),
        "serviceName": new FormControl(data.serviceName),
        "testType": new FormControl(data.testType),
        "earStyle": new FormControl(data.earStyle),
        "rate": new FormControl(data.rate),
        "quantity": new FormControl(data.quantity),
        "discount": new FormControl(data.discount),
        "status" : new FormControl(data.status),
        "comment" :new FormControl(data.comment ? data.comment : ''),
        "totalRate":new FormControl(data.totalRate),
        "totalServiceAmount":new FormControl(data.totalServiceAmount), 
        "serialNumber": new FormControl(data.serialNumber), 
        "sgst":new FormControl(data.sgst),
        "cgst":new FormControl(data.cgst),
        "igst":new FormControl(data.igst),
        "hasSac":new FormControl(data.hasSac),
        "warrantyPeriod": new FormControl(data.warrantyPeriod),
        // "warrantyPeriod":new FormControl(data.warrantyPeriod ? data.warrantyPeriod : 0),
        "warrantyPeriodTxt":new FormControl('',Validators.pattern("^[0-9]*$")),
        "expiryDate":new FormControl(data.expiryDate ? data.expiryDate : null),
        "servicePaidAmount":new FormControl(data.servicePaidAmount ? data.servicePaidAmount : 0),
        "serviceAdvanceAmount":new FormControl('',Validators.pattern("^[0-9 .]*$")), 
        "initialValue":new FormControl(data.servicePaidAmount ? data.servicePaidAmount : 0),  
        "selectService": new FormControl(false),
        "subValue":new FormControl(data.subValue),
      toggle: false
      
})
);
this.calculateExpiry(i);
// let serviceControl = (<FormArray>this.BillingForm.get("serviceInfo")).at(i);
// serviceControl.get('discount').disable();
let serviceControl = (<FormArray>this.BillingForm.get("serviceInfo")).at(i);
let statusval = serviceControl.get('status').value;
this.serialNo = serviceControl.get('serialNumber').value;
//console.log("serialNo ===",this.serialNo);


if(this.serialNo == null){
  serviceControl.get('warrantyPeriod').disable(); 

  serviceControl.get('warrantyPeriod').setErrors(null);
  serviceControl.get('warrantyPeriod').clearValidators();
  serviceControl.get('warrantyPeriod').updateValueAndValidity();
}else{  
  serviceControl.get('warrantyPeriod').enable();
  serviceControl.get('warrantyPeriod').setValidators(Validators.required);
  serviceControl.get('warrantyPeriod').updateValueAndValidity();
}
if(statusval == 'paid'){
  serviceControl.get('serviceAdvanceAmount').disable();
  serviceControl.get('warrantyPeriod').setErrors(null);
  serviceControl.get('warrantyPeriod').clearValidators();
  serviceControl.get('warrantyPeriod').updateValueAndValidity();
}else{
  serviceControl.get('serviceAdvanceAmount').enable();
  serviceControl.get('warrantyPeriod').setValidators(Validators.required);
  serviceControl.get('warrantyPeriod').updateValueAndValidity();
}

}

selectAllService(event:any){
  //console.log("event",event.checked);
  const creds = this.BillingForm.controls.serviceInfo as FormArray;
  // let serviceControl = (<FormArray>this.BillingForm.get("serviceRateInfo")).at(i);
  
  //   let serviceVal = serviceControl.get("addService").value;

  let value = event.checked;
    
  if(value == true){
    creds.controls.forEach(element =>{
       element.get('selectService').setValue(true);
    });
    this.advanceAmount = this.balanceAmount;
    //console.log("this.advanceAmount ",this.advanceAmount);
     this.balanceAmount =  0;
  }else{
    
    creds.controls.forEach(element =>{
      element.get('selectService').setValue(false);
   });
   this.balanceAmount =  JSON.parse(this.advanceAmount);
   this.advanceAmount = 0;
    //console.log("this.advanceAmount ",this.advanceAmount);
   
  }
}

calculateExpiry(i){
  let serviceControl = (<FormArray>this.BillingForm.get("serviceInfo")).at(i);
  let warrantyVal = serviceControl.get("warrantyPeriod").value;

  
  if(warrantyVal == 7){
    this.showWarranty = true;
  }else{
    this.showWarranty = false;
    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    let expDate = new Date(year + parseInt(warrantyVal), month, day);
    serviceControl.patchValue({
          expiryDate: expDate,
         });
}
  
}

calculateExpTxt($event,i){
  let serviceControl = (<FormArray>this.BillingForm.get("serviceInfo")).at(i);
  let warrantyVal = serviceControl.get("warrantyPeriodTxt").value;
    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    let expDate = new Date(year + parseInt(warrantyVal), month, day);
    serviceControl.patchValue({
          warrantyPeriod:warrantyVal,
          expiryDate: expDate,
         });
}

calculateAmount(event:any,i){
  //console.log("amount advance",event.target.value);
  let serviceControl = (<FormArray>this.BillingForm.get("serviceInfo")).at(i);
  let advanceVal = serviceControl.get("serviceAdvanceAmount").value;
  let servicePaidVal = serviceControl.get("servicePaidAmount").value;
  let totalServiceAmount = serviceControl.get("totalServiceAmount").value;
   this.servicePaidAmount = 0;
  // this.servicePaidAmount = parseInt(servicePaidVal);
  //console.log("advanceVal",advanceVal);
 
  if(advanceVal == ''){
    this.disableSubmit = true;
    // this.servicePaidAmount = 0;
    
    // this.servicePaidAmount = this.servicePaidAmount + parseInt(servicePaidVal);
    // serviceControl.patchValue({
    //     servicePaidAmount: this.servicePaidAmount,
    //    });
   
  }else{
    let finalamt = parseInt(advanceVal) + parseInt(servicePaidVal);
    let remainamt = totalServiceAmount - parseInt(servicePaidVal);
    if(finalamt > totalServiceAmount){
      serviceControl.patchValue({
        serviceAdvanceAmount: ''
    });
      this.apimicroservice.openSnackbar("Advance Amount should be less than or equal to = "+remainamt + " Rs.");
      this.disableSubmit = true;
    }else{
      this.disableSubmit = false;
    // this.servicePaidAmount = parseInt(advanceVal);
    // this.servicePaidAmount = this.servicePaidAmount + parseInt(servicePaidVal);
    // //console.log("this.servicePaidAmount",this.servicePaidAmount);
    //    serviceControl.patchValue({
    //     servicePaidAmount: this.servicePaidAmount,
    //    });
      }
  }

  const tempData= this.BillingForm.controls.serviceInfo.value;
    //console.log("temp dataa=",tempData);
    this.advanceAmount = 0;
    //this.pendingChequeAmount = 0;
    this.balanceAmount = this.fullBalanceAmount;
    this.checkAmount = [];
    tempData.forEach((element,i) =>{
      if(element.serviceAdvanceAmount == null || element.serviceAdvanceAmount == ''){
        element.serviceAdvanceAmount = 0;
      }
      this.checkAmount.push({"amount":parseInt(element.serviceAdvanceAmount),"id":i});
      if(this.modeval !== 'Cheque'){
        this.advanceAmount = JSON.parse(this.advanceAmount) + parseInt(element.serviceAdvanceAmount);
        // this.balanceAmount =  this.balanceAmount - parseInt(element.serviceAdvanceAmount);
        this.balanceAmount =  this.balanceAmount - parseInt(element.serviceAdvanceAmount) + parseInt(element.servicePaidAmount);
      }else{
        this.advanceAmount = JSON.parse(this.advanceAmount) + parseInt(element.serviceAdvanceAmount);
        this.pendingChequeAmount = this.pendingChequeAmount + parseInt(element.serviceAdvanceAmount);
      }

    });
   
}

onSelectService(event:any,i){

  let serviceControl = (<FormArray>this.BillingForm.get("serviceInfo")).at(i);
  
    let serviceVal = serviceControl.get("selectService").value;
    let totalRate = serviceControl.get("totalRate").value;
    
    let discount = serviceControl.get("discount").value;
    let sgstVal = serviceControl.get("sgst").value;
    let cgstVal = serviceControl.get("cgst").value;
   // let igstVal = serviceControl.get("igst").value;
    //console.log("sgstVal",sgstVal);
    //console.log("cgstVal",cgstVal);
    //console.log("discount",discount);
    let totalsval = 0;
    let totalcval = 0;
  if(serviceVal == true){
    if(discount !== null){
     let amt = 0;
      amt = totalRate - discount;
      if(sgstVal !== null){
        totalsval = (totalRate * sgstVal) / 100;
      }
      if(cgstVal !== null){
        totalcval = (totalRate * cgstVal) / 100;
      }
      let calAmt = amt + totalsval + totalcval;
      this.checkAmount.push({"amount":calAmt,"id":i});
      if(this.modeval !== 'Cheque'){
      this.advanceAmount = JSON.parse(this.advanceAmount) + amt + totalsval + totalcval;
      
    //console.log("this.advanceAmount discount",this.advanceAmount);
    //console.log("this.advanceAmount discount",totalsval);
    //console.log("this.advanceAmount discount",totalcval);
     this.balanceAmount =  this.balanceAmount - calAmt;
      }else{
        
        this.advanceAmount = JSON.parse(this.advanceAmount) + amt + totalsval + totalcval;
        this.pendingChequeAmount = this.pendingChequeAmount + amt + totalsval + totalcval;
      }

    }else{
      if(sgstVal !== null){
        totalsval = (totalRate * sgstVal) / 100;
      }
      if(cgstVal !== null){
        totalcval = (totalRate * cgstVal) / 100;
      }
      let calAmt = totalRate + totalsval + totalcval;
      this.checkAmount.push({"amount":calAmt,"id":i});
      if(this.modeval !== 'Cheque'){
      this.advanceAmount = JSON.parse(this.advanceAmount) + totalRate + totalsval + totalcval;
      
      //console.log("this.advanceAmount ",this.advanceAmount);
      this.balanceAmount =  this.balanceAmount - calAmt;
      }else{
        
        this.advanceAmount = JSON.parse(this.advanceAmount) + totalRate + totalsval + totalcval;
        this.pendingChequeAmount = this.pendingChequeAmount + totalRate + totalsval + totalcval;
      }
    }
    
  }else{
    if(discount !== null){
      let amt = 0;
       amt = totalRate - discount;
       if(sgstVal !== null){
        totalsval = (totalRate * sgstVal) / 100;
      }
      if(cgstVal !== null){
        totalcval = (totalRate * cgstVal) / 100;
      }
      let calAmt = amt + totalsval + totalcval;
      this.checkAmount = this.checkAmount.filter(m => m.id !== i);
      if(this.modeval !== 'Cheque'){
       this.advanceAmount = JSON.parse(this.advanceAmount) - calAmt;
     //console.log("this.advanceAmount ",this.advanceAmount);
      this.balanceAmount =  this.balanceAmount + calAmt;
      
      }else{
        
        this.advanceAmount = JSON.parse(this.advanceAmount) - calAmt;
        this.pendingChequeAmount = this.pendingChequeAmount - calAmt;
      }
     }else{
      if(sgstVal !== null){
        totalsval = (totalRate * sgstVal) / 100;
      }
      if(cgstVal !== null){
        totalcval = (totalRate * cgstVal) / 100;
      }

      let calAmt = totalRate + totalsval + totalcval; 
      this.checkAmount = this.checkAmount.filter(m => m.id !== i);
      if(this.modeval !== 'Cheque'){     
       this.advanceAmount = JSON.parse(this.advanceAmount) - calAmt;
       //console.log("this.advanceAmount ",this.advanceAmount);
       this.balanceAmount =  this.balanceAmount + calAmt;
       
      }
      else{
        
        this.advanceAmount = JSON.parse(this.advanceAmount) - calAmt;
        this.pendingChequeAmount = this.pendingChequeAmount - calAmt;
      }
     }
    //console.log("not selected");
    
  }
  //console.log("this.checkAmount",this.checkAmount);
  //console.log("this.advanceamt",this.advanceAmount);
}

  submitPayment() {
    // this.setValidator();
    let payload = this.BillingForm.value;

    const creds = this.BillingForm.controls.serviceInfo as FormArray;
    if (this.BillingForm.invalid || creds.invalid) {
      this.BillingForm.get("modeOfPayment").markAsTouched();
      creds.controls.forEach(element => {
        element.get('warrantyPeriod').markAsTouched();
      });
      return;
    } else {
      let dataInfo = this.BillingForm.get('serviceInfo').value;
      // dataInfo.forEach(ele => {
      //   ele.servicePaidAmount = parseInt(ele.servicePaidAmount) + parseInt(ele.serviceAdvanceAmount);
      // });

      dataInfo.forEach((element) => {
        if (this.deviceInfo.length > 0) {
          this.deviceInfo.forEach((ele, i) => {
            if (element.serialNumber == ele.serialNumber) {
              this.deviceInfo[i]['warrantyPeriod'] = element.warrantyPeriod;
              this.deviceInfo[i]['expiryDate'] = element.expiryDate;
            }
          });
        }
      });
      this.addData();
    }
  }
  addData() {
    this.spinner.show();
    let payload = this.BillingForm.value;
    let paymentStatus = "Paid";
    let paymentHistoryStatus = '';
    let servicePayload = {};
    let dataInfo = this.BillingForm.get('serviceInfo').value;
    console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhiiii", dataInfo);
    dataInfo.forEach(element => {
      if (this.serialNo !== null) {
        // this.addPatientByDevice();
        let payload1 = this.patientByDeviceForm.value;
        payload1.patientRefId = this.patientRefId;
        payload1.billingRefId = this.billRefId;
        payload1.patientName = this.patientName;
        payload1.model = element.serviceName;
        payload1.oldSerialNo = element.serialNumber;
        payload1.paymentStatus = element.status;
        payload1.earStyle = element.earStyle;

        payload1.dateOfPurchase = new Date();
        //  payload1.warranty = element.warranty;
        payload1.billNo = this.billNo;
        if (element.warrantyPeriod == '') {
          payload1.warranty = 0;
        }
        else {
          payload1.warranty = element.warrantyPeriod;
        }
        this.apimicroservice.addPatientByDevice(payload1).subscribe(res => {
          //   this.spinner.hide();
          // this.ngOnInit();
          if (res.status) {
            this.apimicroservice.openSnackbar(res.message);
          } else {
            this.apimicroservice.openSnackbar(res.message);
          }
        }, (ERROR: HttpErrorResponse) => {
          this.apimicroservice.openSnackbar(ERROR.error.message);
          //this.spinner.hide();
        });
      }
    });

    if (payload.modeOfPayment !== 'Cheque') {
      if (this.advaceData.length > 0) {
        this.totalPaidAmount = JSON.parse(this.totalPaidAmount) + JSON.parse(this.advanceAmount);
      }
      else {
        //alert(this.totalPaidAmount)
        this.totalPaidAmount = 0;
        // alert(this.totalPaidAmount)
      }

    }
    let receiptNo = Math.floor(10000 + Math.random() * 90000);
    //console.log("payload",payload);
    let info = [];
    let paymentPayload = {};
    let advacePayload = {};
    // dataInfo.forEach(element =>{
    //    if(element.selectService == true && this.modeval !== 'Cheque'){
    //      element.status = "paid";
    //      paymentHistoryStatus = "Paid";
    //     info.push(element);
    //    // paymentStatus = "Paid";
    //     }else if(element.selectService == true && this.modeval == 'Cheque'){
    //       element.status = "postdated";
    //       paymentHistoryStatus = "Unpaid";
    //       info.push(element);
    //     }

    //  });

    dataInfo.forEach(element => {
      if (element.status !== 'paid') {
        if ((parseInt(element.servicePaidAmount) == element.totalServiceAmount) && this.modeval !== 'Cheque') {
          element.status = "paid";
          paymentHistoryStatus = "Paid";
          info.push(element);

        }
        else if ((parseInt(element.servicePaidAmount) > element.totalServiceAmount) && this.modeval !== 'Cheque') {
          element.status = "paid";
          paymentHistoryStatus = "Paid";
          info.push(element);
        }
        else if ((parseInt(element.servicePaidAmount) < element.totalServiceAmount) && this.modeval !== 'Cheque'
          && this.balanceAmount == 0) {
          element.status = "paid";
          paymentHistoryStatus = "Paid";
          info.push(element);
        }
        else if ((parseInt(element.servicePaidAmount) == element.totalServiceAmount) && this.modeval == 'Cheque') {
          element.status = "postdated";
          paymentHistoryStatus = "Unpaid";
          info.push(element);
        } else if ((parseInt(element.servicePaidAmount) !== element.totalServiceAmount) && (element.serviceAdvanceAmount !== 0) && this.modeval == 'Cheque') {
          element.status = "postdated";
          paymentHistoryStatus = "Unpaid";
          info.push(element);
        } else if ((parseInt(element.servicePaidAmount) !== element.totalServiceAmount) && (element.serviceAdvanceAmount !== 0) && this.modeval !== 'Cheque') {
          element.status = "unpaid";
          paymentHistoryStatus = "Unpaid";
          info.push(element);

        }
      }
    });

    //console.log("info",info);
    dataInfo.forEach(ele => {

      if (ele.status == 'unpaid' || ele.status == 'postdated' || ele.status == 'deposited' || ele.status == 'bounce') {
        paymentStatus = "Unpaid";
      }
      // }else if((ele.status == 'postdated' || ele.status == 'deposited') && paymentStatus !== 'Unpaid'){
      //   paymentStatus = "Cheque Payment Pending";
      // }
    });

    servicePayload['totalsgst'] = this.totalsgst;
    servicePayload['totalcgst'] = this.totalcgst;
    servicePayload['totaligst'] = this.totaligst;
    servicePayload['serviceInfo'] = dataInfo;
    console.log("this.totalAmtPaidTillDate", this.totalAmtPaidTillDate);

    console.log(" parseInt(val))", parseInt(payload.totalCal));
    if (payload.modeOfPayment !== 'Cheque') {
      servicePayload['paidAmount'] = (this.totalAmtPaidTillDate + parseInt(payload.totalCal));
    }
    else if(payload.modeOfPayment == 'Cheque'){ 
      if(this.chequeStatus == 'Clear')
      {
        servicePayload['paidAmount'] = (this.totalAmtPaidTillDate + parseInt(payload.totalCal));
      }else{
        servicePayload['paidAmount'] = this.totalAmtPaidTillDate;
      }

    }
    else {
      servicePayload['paidAmount'] = this.totalAmtPaidTillDate;
    }

    servicePayload['balanceAmount'] = this.balanceAmount;
    servicePayload['pendingChequeAmount'] = this.pendingChequeAmount;
    servicePayload['bounceAmount'] = this.bounceAmount;
    servicePayload['gst'] = this.gst;
    servicePayload['totalDiscount'] = this.discount;
    servicePayload['paymentStatus'] = paymentStatus;
    servicePayload['paidAdvanceAmount'] = this.paidAdvanceAmount;
    if (this.deviceInfo.length > 0) {
      servicePayload['deviceInfo'] = this.deviceInfo;
    }
    //console.log("servicePayload",servicePayload);
    paymentPayload["serviceInfo"] = info;
    paymentPayload['billingCompanyRefId'] = this.billData.billingCompanyRefId;
    paymentPayload['billingCompanyName'] = this.billData.billingCompanyName;
    paymentPayload['billNo'] = this.billData.billNo;
    paymentPayload['patientRefId'] = this.billData.patientRefId;
    paymentPayload['patientName'] = this.billData.patientName;
    paymentPayload['mobile'] = this.billData.mobile;
    paymentPayload['branchRefId'] = this.billData.branchRefId;
    paymentPayload['branchName'] = this.billData.branchName;
    paymentPayload['paidAdvanceAmount'] = this.paidAdvanceAmount;
    paymentPayload['advanceStatus'] = 'No';
    if (payload.modeOfPayment !== 'Cheque') {
      // if(this.advaceData.length > 0)
      // {
      //   paymentPayload['paidAmount'] = this.advanceAmount + this.paidAdvanceAmount;
      // }
      // else{
      //   paymentPayload['paidAmount'] = this.advanceAmount;
      // }
      paymentPayload['paidAmount'] = payload.totalCal;
      // paymentPayload['paidAmount'] = this.billData.paidAmount;   

    } else {
      paymentPayload['paidAmount'] = this.totalAmtPaidTillDate;
    }
    paymentPayload['paymentDate'] = this.currentdate;
    paymentPayload['modeOfPayment'] = payload.modeOfPayment;
    paymentPayload['totalCal'] = payload.totalCal;
    paymentPayload['totalAmount'] = this.totalAmount;
    paymentPayload['balanceAmount'] = this.balanceAmount;
    paymentPayload['pendingChequeAmount'] = this.pendingChequeAmount;
    paymentPayload['bounceAmount'] = this.bounceAmount;
    paymentPayload['gst'] = this.gst;
    paymentPayload['totalDiscount'] = this.discount;
    if (payload.modeOfPayment !== 'Cheque') {
      //paymentPayload['receiptNo'] = receiptNo; 
    }
    paymentPayload['paymentStatus'] = paymentHistoryStatus;
    paymentPayload['patientId'] = this.billData.patientId;
    paymentPayload['totalsgst'] = this.totalsgst;
    paymentPayload['totalcgst'] = this.totalcgst;
    paymentPayload['totaligst'] = this.totaligst;


    //  if(payload.modeOfPayment == 'Cheque'){
    //   paymentPayload['chequeDetails'] = this.chequeDetails;
    //   paymentPayload['chequePaymentStatus'] = "postdated";
    //  }

    //console.log("paymentPayload",paymentPayload);
    advacePayload['billNo'] = this.billData.billNo;
    servicePayload['remainingAmt'] = this.totalAmount - this.totalAmtPaidTillDate - this.advanceAmt;
    console.log("servicePayload", servicePayload);
    this.apimicroservice.updateBillingDetails(servicePayload, this.billRefId).subscribe(res => {
      //  this.spinner.hide();
      //this.ngOnInit();
      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);
        if (this.deviceInfo.length > 0) {
          let devicePayload = {};

          this.deviceInfo.forEach((ele) => {
            devicePayload['warrantyPeriod'] = ele.warrantyPeriod;
            devicePayload['expiryDate'] = ele.expiryDate;
            devicePayload['paymentStatus'] = paymentStatus;
            this.apimicroservice.updateSoldDeviceDetails(devicePayload, ele.serialNumber).subscribe(res => {
              //console.log("udated device res",res);
            });
          });
        }
        if (this.advaceData.length > 0 && this.advanceAmtCopy == true) {
          if (payload.modeOfPayment == 'Cheque') {


            let bar2 = new Promise<void>((resolve, reject) => {
              advacePayload["serviceInfo"] = info;
              advacePayload['billingCompanyRefId'] = this.billData.billingCompanyRefId;
              advacePayload['billingCompanyName'] = this.billData.billingCompanyName;
              advacePayload['billNo'] = this.billData.billNo;
              advacePayload['patientRefId'] = this.billData.patientRefId;
              advacePayload['patientName'] = this.billData.patientName;
              advacePayload['mobile'] = this.billData.mobile;
              advacePayload['branchRefId'] = this.billData.branchRefId;
              advacePayload['branchName'] = this.billData.branchName;
              advacePayload['paidAdvanceAmount'] = this.paidAdvanceAmount;
              if (payload.modeOfPayment !== 'Cheque') {
                // if(this.advaceData.length > 0)
                // {
                //       advacePayload['paidAmount'] = JSON.parse(this.advanceAmount);
                // }
                // else{
                //   advacePayload['paidAmount'] = JSON.parse(this.advanceAmount);
                // }
                advacePayload['paidAmount'] = payload.totalCal;
              } else {
                // advacePayload['paidAmount'] = 0;
                advacePayload['paidAmount'] = this.totalAmtPaidTillDate;
              }
              advacePayload['paymentDate'] = this.currentdate;
              advacePayload['modeOfPayment'] = payload.modeOfPayment;
              advacePayload['totalCal'] = payload.totalCal;
              advacePayload['totalAmount'] = this.totalAmount;
              advacePayload['balanceAmount'] = this.balanceAmount;
              advacePayload['pendingChequeAmount'] = payload.totalCal;
              advacePayload['bounceAmount'] = this.bounceAmount;
              advacePayload['gst'] = this.gst;
              advacePayload['totalDiscount'] = this.discount;
              advacePayload['paymentStatus'] = paymentHistoryStatus;
              advacePayload['patientId'] = this.billData.patientId;
              advacePayload['totalsgst'] = this.totalsgst;
              advacePayload['totalcgst'] = this.totalcgst;
              advacePayload['totaligst'] = this.totaligst;
              this.advaceData.forEach(element => {
                if (element.modeOfPayment == 'Cheque') {
                  advacePayload['chequePaymentStatus'] = "postdated";
                  console.log("advacePayload", advacePayload);

                  this.apimicroservice.updatePaymentHistoryDetails(advacePayload, element._id).subscribe(res => {
                    if (res.status) {
                      // this.apimicroservice.openSnackbar("payment done");
                      resolve();
                    }
                  })
                }


              });
            })
            bar2.then(() => {
              // this.ngOnInit();
              window.location.reload();
            });


            //  });
            // this.ngOnInit();
            // window.location.reload();
          }
          else {
            payload.advanceStatus = 'No';

            let bar = new Promise<void>((resolve, reject) => {
              advacePayload["serviceInfo"] = info;
              advacePayload['billingCompanyRefId'] = this.billData.billingCompanyRefId;
              advacePayload['billingCompanyName'] = this.billData.billingCompanyName;
              advacePayload['billNo'] = this.billData.billNo;
              advacePayload['patientRefId'] = this.billData.patientRefId;
              advacePayload['patientName'] = this.billData.patientName;
              advacePayload['mobile'] = this.billData.mobile;
              advacePayload['branchRefId'] = this.billData.branchRefId;
              advacePayload['branchName'] = this.billData.branchName;
              advacePayload['paidAdvanceAmount'] = this.paidAdvanceAmount;
              // if(payload.modeOfPayment !== 'Cheque'){
              //  if(this.advaceData.length > 0)
              //  {
              //    advacePayload['paidAmount'] = this.advanceAmount + this.paidAdvanceAmount;
              //  }
              //  else{
              //    advacePayload['paidAmount'] = this.advanceAmount;
              //  }

              // }else{
              //  advacePayload['paidAmount'] = 0;
              // }

              advacePayload['paymentDate'] = this.currentdate;
              advacePayload['modeOfPayment'] = payload.modeOfPayment;
              advacePayload['totalCal'] = payload.totalCal;
              advacePayload['totalAmount'] = this.totalAmount;
              advacePayload['balanceAmount'] = this.balanceAmount;
              advacePayload['pendingChequeAmount'] = this.pendingChequeAmount;
              advacePayload['bounceAmount'] = this.bounceAmount;
              advacePayload['gst'] = this.gst;
              advacePayload['totalDiscount'] = this.discount;
              //  if(payload.modeOfPayment !== 'Cheque'){
              //  advacePayload['receiptNo'] = receiptNo;
              //  }
              advacePayload['paymentStatus'] = paymentHistoryStatus;
              advacePayload['patientId'] = this.billData.patientId;
              advacePayload['totalsgst'] = this.totalsgst;
              advacePayload['totalcgst'] = this.totalcgst;
              advacePayload['totaligst'] = this.totaligst;
              this.advaceData.forEach(element => {
                if (element.modeOfPayment !== 'Cheque') {
                  advacePayload['paidAmount'] = JSON.parse(this.advanceAmount);
                  console.log("advacePayload", advacePayload);

                  this.apimicroservice.updatePaymentHistoryDetails(advacePayload, element._id).subscribe(res => {
                    if (res.status) {
                      // this.apimicroservice.openSnackbar("payment done");
                      resolve();
                    }
                  })
                }



              });
            })
            bar.then(() => {
              // this.ngOnInit();
              window.location.reload();
            });
          }
        }
        else {
          if (payload.modeOfPayment == 'Cheque') {
            let bar = new Promise<void>((resolve, reject) => {
              this.chequeDetails.forEach(ele => {
                //  receiptNo = Math.floor(10000 + Math.random() * 90000);
                paymentPayload['chequeDetails'] = ele;
                paymentPayload['chequePaymentStatus'] = "postdated";
                //  paymentPayload['receiptNo'] = receiptNo;
                paymentPayload['advanceStatus'] = 'No';
                this.apimicroservice.addPaymentHistoryDetails(paymentPayload).subscribe(res => {
                  if (res.status) {
                    this.apimicroservice.openSnackbar("cheque payment in-progress");
                    resolve();
                  }
                });

              });

            });
            bar.then(() => {

              window.location.reload();
            });
            // this.ngOnInit();
            //// window.location.reload();
          } else {
            payload.advanceStatus = 'No';
            console.log("paymentPayloadpaymentPayload", paymentPayload);

            this.apimicroservice.addPaymentHistoryDetails(paymentPayload).subscribe(res => {
              console.log("111111111111111");

              if (res.status) {
                console.log("2222222222222222");
                console.log("qqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",this.serialNo);
                let bar1 = new Promise<void>((resolve, reject) => {
                  if (paymentPayload['paymentStatus'] !== 'Paid') {
                    console.log("3333333333333333");
                    this.apimicroservice.openSnackbar('Your Warrenty will start after full payment');
                    resolve();
                  } 
                  else if(this.serialNo !== null) {
                    console.log("4444444444444");
                
                    
                    this.apimicroservice.openSnackbar('Your Warrenty is started');

                    // "patientName": new FormControl(''),
                    // "followupType": new FormControl('',Validators.required),
                    // "patientRefId": new FormControl(''),
                    // "followupTime": new FormControl('',Validators.required),
                    // "followupDate": new FormControl(''),
                    // "followupStatus": new FormControl(''),     
                    // "followupComments": new FormControl(''),
                    // "searchFollowup": new FormControl(),
                    // "contactNo": new FormControl(''),
                    // "createdAt": new Date(),   
                    ////////////////

                    // this.minDate = new Date();
                    // if(val == "One Week"){
                    //   this.minDate.setDate(this.minDate.getDate()+7);
                    //   this.dateDisable = true;
                    // }else if(val == "Six Month"){
                    //   this.minDate.setMonth(this.minDate.getMonth()+6);
                    //   this.dateDisable = true;
                    // }else if(val == "One Year"){
                    //   this.minDate.setMonth(this.minDate.getMonth()+12);
                    //   this.dateDisable = true;
                    // }
                    // else if(val == "Custom"){
                    //   this.dateDisable = false;
                    // } 

                    let payloadOneWeek = {};
                    this.minDate = new Date();
                    this.minDate.setHours(0,0,0,0);
                    console.log(" this.minDate", this.minDate);
                    
                    this.minDate.setDate(this.minDate.getDate() + 7);
                    payloadOneWeek['patientRefId'] = this.patientRefId;
                    payloadOneWeek['patientName'] = this.patientName;
                    payloadOneWeek['followupType'] = 'Phone Call';
                    payloadOneWeek['followupTime'] = 'One Week';
                    payloadOneWeek['followupDate'] = this.minDate;
                    payloadOneWeek['followupStatus'] = 'Follow Up Scheduled';
                    payloadOneWeek['branchRefId'] = this.branchRef;
                    payloadOneWeek['clinicRefId'] = this.clinicRef;

                    //////aletert
                    // let alertFollowupPayload = {};
                    // alertFollowupPayload['patientRefId'] =this.patientRefId;
                    // alertFollowupPayload['patientName'] = this.patientName;
                    // alertFollowupPayload['followupType'] = 'Phone Call';
                    // alertFollowupPayload['followupDate'] = 'One Week';

                    ////////

                    ///////1 week
                    // console.log("555555555555");
                    let barweek = new Promise<void>((resolve, reject) => {
                      this.apimicroservice.addFollowup(payloadOneWeek).subscribe(res => {
                        this.spinner.hide();
                        if (res.status) {
                          let data = res.data;
                          payloadOneWeek['followupId'] = data.followupId;
                          this.apimicroservice.addFollowupAlerts(payloadOneWeek).subscribe(res => {
                            if (res.status) {
                              this.apimicroservice.openSnackbar('alert created');
                            }
                          });

                        } else {
                          this.apimicroservice.openSnackbar(res.message);
                        }
                      }, (ERROR: HttpErrorResponse) => {
                        this.apimicroservice.openSnackbar(ERROR.error.message);
                        this.spinner.hide();
                      });
                      setTimeout(() => {
                        // this.spinner.show();
                        resolve();
                      }, 100);
                    });
                    barweek.then(() => {
                      let payloadSixMonth = {};
                      this.minDate2 = new Date();
                      this.minDate2.setHours(0,0,0,0);
                      this.minDate2.setMonth(this.minDate2.getMonth() + 6);
                      payloadSixMonth['patientRefId'] = this.patientRefId;
                      payloadSixMonth['patientName'] = this.patientName;
                      payloadSixMonth['followupType'] = 'Phone Call';
                      payloadSixMonth['followupTime'] = 'Six Month';
                      payloadSixMonth['followupDate'] = this.minDate2;
                      payloadSixMonth['followupStatus'] = 'Follow Up Scheduled';
                      payloadSixMonth['branchRefId'] = this.branchRef;
                      payloadSixMonth['clinicRefId'] = this.clinicRef;
                      let barMonth = new Promise<void>((resolve, reject) => {
                        this.apimicroservice.addFollowup(payloadSixMonth).subscribe(res => {
                          this.spinner.hide();
                          if (res.status) {
                            let data = res.data;
                            payloadSixMonth['followupId'] = data.followupId;
                            this.apimicroservice.addFollowupAlerts(payloadSixMonth).subscribe(res => {
                              if (res.status) {
                                this.apimicroservice.openSnackbar('alert created');
                              }
                            });

                          } else {
                            this.apimicroservice.openSnackbar(res.message);
                          }
                        }, (ERROR: HttpErrorResponse) => {
                          this.apimicroservice.openSnackbar(ERROR.error.message);
                          this.spinner.hide();
                        });
                        setTimeout(() => {
                          // this.spinner.show();
                          resolve();
                        }, 100);
                      });
                      barMonth.then(() => {
                        let payloadOne = {};
                        this.minDate3 = new Date();
                        this.minDate3.setHours(0,0,0,0);
                        this.minDate3.setMonth(this.minDate3.getMonth() + 12);

                        payloadOne['patientRefId'] = this.patientRefId;
                        payloadOne['patientName'] = this.patientName;
                        payloadOne['followupType'] = 'Phone Call';
                        payloadOne['followupTime'] = 'One Year';
                        payloadOne['followupDate'] = this.minDate3;
                        payloadOne['followupStatus'] = 'Follow Up Scheduled';
                        payloadOne['branchRefId'] = this.branchRef;
                        payloadOne['clinicRefId'] = this.clinicRef;

                        this.apimicroservice.addFollowup(payloadOne).subscribe(res => {
                          this.spinner.hide();
                          if (res.status) {
                            let data = res.data;
                            payloadOne['followupId'] = data.followupId;
                            this.apimicroservice.addFollowupAlerts(payloadOne).subscribe(res => {
                              if (res.status) {
                                this.apimicroservice.openSnackbar('alert created');
                              }
                            });

                          } else {
                            this.apimicroservice.openSnackbar(res.message);
                          }
                        }, (ERROR: HttpErrorResponse) => {
                          this.apimicroservice.openSnackbar(ERROR.error.message);
                          this.spinner.hide();
                        });
                      })
                    });
                    //////6 month

                    ///////1 yra

                    setTimeout(() => {
                      // this.spinner.show();
                      resolve();
                    }, 100);


                  }

                  setTimeout(() => {
                    // this.spinner.show();
                    resolve();
                  }, 100);
                });
                bar1.then(() => {
                  let bar = new Promise<void>((resolve, reject) => {
                    this.apimicroservice.openSnackbar("payment done");

                    setTimeout(() => {
                      resolve();
                      // this.spinner.show();
                    }, 100);

                  });
                  bar.then(() => {
                    // this.spinner.hide();
                    window.location.reload();
                  }
                  )
                }
                )




              }
            });
          }
        }
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    }, (ERROR: HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      //console.log(ERROR.error.message);
      this.spinner.hide();
    });
  }
  fetchPaymentHistoryData() {
    let pg = 1;
    let size = 25;
    let search=''; 
    this.spinner.show();
    this.searchText ='';
    // let branchRef='';
    // let clinicRef='';
    // this.totalsgstAmt = (this.totalAmount * this.totalsgst) / 100;
    // this.totalcgstAmt = (this.totalAmount * this.totalcgst) / 100;
    // this.totaligstAmt = (this.totalAmount * this.totaligst) / 100;
    // this.totalAmount = this.totalAmount + this.totalsgstAmt + this.totalcgstAmt;
    // if(data.balanceAmount !== undefined){
    //   this.balanceAmount = data.balanceAmount;
    //  }else{
    //   this.balanceAmount = this.totalAmount;
    //  }
      this.apimicroservice.getAllPaymentHistoryByBillNo(pg,size,search,this.billNo).subscribe(res => {
        this.spinner.hide(); 
        //console.log("this.paymentHistoryList res",res);
        let statusVal = false;   ////////
         // this.paymentHistoryList = res.data.data;
         this.chequeData=[];
         let receiptArray = [];
          res.data.data.forEach(element =>{
            if(element.modeOfPayment == 'Cheque' && element.chequePaymentStatus !== 'Clear'){
              this.pendingChequeAmount = this.pendingChequeAmount + element.pendingChequeAmount;
             this.chequeData.push(element);
            }
            else{      
            //  this.paymentHistoryList.push(element);
            }
                this.paymentHistoryList.push(element);
                if (element.receiptNo !== undefined) {
                  receiptArray.push(element.receiptNo)
                }
          });
      
          if (receiptArray.length == res.data.data.length) {
            this.isPrintAvailable = true;
          }
          this.totalLength = res.data.totalItems;
          //console.log("this.paymentHistoryList",this.paymentHistoryList);
         if(res.status){
           //this.apimicroservice.openSnackbar(res.message);        
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       });
     
   
  }

  // prepopulation(id) {
  //   this.spinner.show();
  //   this.apimicroservice.getInOutwardById(id).subscribe(res => {
  //     this.spinner.hide();
  //     let data = res.data[0]; 
  //     this.id = id;
  //      if(res.status){
  //        this.updateButton = true;
  //        this.submitButton = false;
  //        this.disableFromCity = true;
  //        this.disableToCity = true;
  //        this.BillingForm = this.fb.group({
  //         "branchWiseId": new FormControl(data.branchWiseId),
  //         "date": new FormControl(data.date,Validators.required),
  //         "inwardOutward": new FormControl(data.inwardOutward,Validators.required),
  //         "fromInwardOutward": new FormControl(data.fromInwardOutward,Validators.required),
  //         "toInwardOutward": new FormControl(data.toInwardOutward,Validators.required),
  //         "fromCityName": new FormControl(data.fromCityName),
  //         "toCityName": new FormControl(data.toCityName),
  //         "docketNo": new FormControl(data.docketNo,[Validators.pattern("^[0-9]*$")]), 
  //         "branchRefId": new FormControl(data.branchRefId),
  //         "item": new FormControl(data.item,Validators.required), 
  //         "branchName": new FormControl(data.branchName),
  //         "courierRefId": new FormControl(data.courierRefId,Validators.required), 
  //         "courierProviderName": new FormControl(data.courierProviderName),
  //         "clinicRefId": new FormControl(data.clinicRefId),
  //         "remark": new FormControl(data.remark),
  //         "searchBilling": new FormControl(''), 
  //       });
  //       this.superBranchId = data.branchWiseId;   
  //      }else{
  //    //    //this.apimicroservice.openSnackbar(res.message);
  //      }
  //    });
  // }

  // UpdateInwordOutword(){
  //  // this.setValidator();
  //   let payload = this.BillingForm.value;
    
  //   if(this.userType == 'Super Admin')
  //   {
  //     this.BillingForm.controls['branchRefId'].setValidators(Validators.required);
  //     this.BillingForm.controls['branchRefId'].updateValueAndValidity();
  //   }
  //   else{
  //     this.BillingForm.controls['branchRefId'].setErrors(null); 
  //     this.BillingForm.controls['branchRefId'].clearValidators();
  //     this.BillingForm.controls['branchRefId'].updateValueAndValidity();
  //   }
    
  //   if (this.BillingForm.invalid) {

  //      this.BillingForm.get("date").markAsTouched();
  //      this.BillingForm.get("inwardOutward").markAsTouched();
  //      this.BillingForm.get("fromInwardOutward").markAsTouched();
  //      this.BillingForm.get("toInwardOutward").markAsTouched();
  //      this.BillingForm.get("courierRefId").markAsTouched();
  //      this.BillingForm.get("item").markAsTouched();

  //   }else {
  //     this.updateData();
  //   }
  // }

  // updateData(){
  //   this.spinner.show();
  //   let payload = this.BillingForm.value;
  //   payload.fromCityName = this.fromCity;
  //   payload.toCityName = this.toCity;
  //   if(this.userType !== 'Super Admin')
  //   {
  //     payload.branchRefId = this.branchRef;
  //     payload.clinicRefId = this.clinicRef;
  //     payload.branchName = this.branchName;
  //   }
  
  //    this.apimicroservice.updateInOutwardDetails(payload,this.id).subscribe(res => {
  //      //console.log("Update res :",res);
  //      this.spinner.hide();
  //      if(res.status){
  //        this.apimicroservice.openSnackbar(res.message);
  //         this.updateButton = false;
  //         this.submitButton = true;
  //         this.ngOnInit();
  //      }else{ 
  //      //  //this.apimicroservice.openSnackbar(res.message);
  //      }
  //    });
  // }
 
  // cancelUpdate(){
  //   this.BillingForm.reset();
  //   this.submitButton=true;
  //   this.updateButton=false;
  //   this.ngOnInit();
  // }

  toBillingForm() {
    document.getElementById("toBillingForm").scrollIntoView();
  }
  toBillingLst() {
    document.getElementById("toBillingLst").scrollIntoView();
  }

   openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
        this.fetchBranchList();
      })
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //console.log("getAllBranch res",res);  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    })
  }

  onPageChange(event) {
    this.spinner.show();
    this.page = event;
    this.searchText = this.BillingForm.get('searchHistory').value;
    this.paymentHistoryList=[];
      this.apimicroservice.getAllPaymentHistoryByBillNo(this.page,this.size,this.searchText,this.billNo).subscribe(res => {
        this.spinner.hide(); 
         // this.paymentHistoryList = res.data.data;
         let statusVal = false;
         if(res.data.data.length > 0){
          res.data.data.forEach(ele =>{
            // if(ele.modeOfPayment == 'Cheque' && ele.chequePaymentStatus !== 'Clear'){
  
            // }
            // else{
            //   this.paymentHistoryList.push(ele);
            // }
            this.paymentHistoryList.push(ele);

          });

          // res.data.data.forEach(ele =>{
          //  // if(ele.paymentStatus == "Unpaid" || ele.paymentStatus !== "Cheque Payment Pending"){
          //   if(ele.paymentStatus == "Unpaid"){
          //       //this.submitButton = false;
          //       statusVal = true;
          //     }
          // });

          //   if(statusVal == false){
          //     this.submitButton = false;
          //   }else{
          //     this.submitButton = true;
          //   }
            
          }
          this.totalLength = res.data.totalItems;
          
          //console.log("this.paymentHistoryList",this.paymentHistoryList);
         if(res.status){
           this.apimicroservice.openSnackbar(res.message);        
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       }); 
     
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.BillingForm.get('searchHistory').value;
    this.paymentHistoryList=[];
    this.spinner.show();
    
    // let branchRef='';
    // let clinicRef='';
    
      this.apimicroservice.getAllPaymentHistoryByBillNo(this.page,this.size,this.searchText,this.billNo).subscribe(res => {
        this.spinner.hide(); 
        let statusVal = false;
        if(res.data.data.length > 0){
        res.data.data.forEach(ele =>{
          // if(ele.modeOfPayment == 'Cheque' && ele.chequePaymentStatus !== 'Clear'){

          // } 
          // else{
          //   this.paymentHistoryList.push(ele);
          // }
          this.paymentHistoryList.push(ele);
        });

        // res.data.data.forEach(ele =>{
        //   if(ele.paymentStatus == "Unpaid"){
        //       //this.submitButton = false;
        //       statusVal = true;
        //     }
        // });

        //   if(statusVal == false){
        //     this.submitButton = false;
        //   }else{
        //     this.submitButton = true;
        //   }
          
        }
        //this.paymentHistoryList = res.data.data;
          this.totalLength = res.data.totalItems;
          //console.log("this.paymentHistoryList",this.paymentHistoryList);
         if(res.status){
           this.apimicroservice.openSnackbar(res.message);        
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
       });
     
  }
  redirect(to): void {
    this.router.navigate([to]);
   }  
   ModeOfPaymentClick(e)
   {
     let mode = e; 
     let payload = this.BillingForm.value;
     if(payload.totalCal !== '')
     {
    if(e == 'Cheque')
    {
      this.isAdvance = true;
      this.apimicroservice.getBillDataById(this.billNo).subscribe(res => {
        //console.log("bill data",res);
       let data = res.data[0];
       data.serviceInfo.forEach((element,i) => {
        (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(element.servicePaidAmount);
        // this.addNew(element,i) 
      });

      });
      this.modeval = 'Cheque';
      this.balanceAmount = this.balanceAmount + JSON.parse(this.advanceAmount);
      this.pendingChequeAmount = parseInt(payload.totalCal);
      // this.pendingChequeAmount = this.pendingChequeAmount + JSON.parse(this.advanceAmount); dhanashri
     // this.advanceAmount = 0;
      let finalChequeAmt = []; 
    this.checkAmount = []; 
      this.checkAmount.push({"amount":payload.totalCal,"id":0});
      console.log("this.checkAmount",this.checkAmount);
      if(this.checkAmount.length > 0){
        finalChequeAmt = this.checkAmount; 
      }else{
        if(this.bounceAmount !== 0){
          //console.log("finalChequeAmt",finalChequeAmt);
          finalChequeAmt.push({"amount":this.bounceAmount,"id":0});
        }
      }
      //console.log("finalChequeAmt",finalChequeAmt);
      const dialogRef = this.dialog.open(PopupModeOfPaymentComponent,
        {
          //width: "800px",
          data: {
            data: mode,
            checkAmountArr:finalChequeAmt
          }
        }); 

        dialogRef.afterClosed().subscribe((result) => {

          
          let remaingAmt = this.pendingChequeAmount + parseInt(payload.totalCal);
let payableAmt = this.balanceAmount - this.pendingChequeAmount;
      let mytotalvalue = payload.totalCal;
      this.advanceAmount = payload.totalCal;
      this.disableSubmit = false;
      console.log("payload.totalCalpayload.totalCal",payload.totalCal);
      if(payload.totalCal == '' || payload.totalCal == null || payload.totalCal == undefined)
      {
      this.ngOnInit();
      }

      console.log("11111111111111111",JSON.parse(payload.totalCal));
      console.log("22222222222",this.balanceAmount);
     // console.log("33333333333333333",this.tempBalanceAmt);
      console.log("totalAmtPaidTillDate",this.totalAmtPaidTillDate);
        console.log("advanceAmt",this.advanceAmt);
      console.log("totalAmount",this.totalAmount);
      let remaing = this.totalAmount - this.totalAmtPaidTillDate - this.advanceAmt;
      console.log("remaing",remaing);
 
      let dataInfo = this.BillingForm.get('serviceInfo').value;
  
      const creds = this.BillingForm.controls.serviceInfo as FormArray;
      console.log("creds.length", creds);
      // console.log("creds",creds.controls.length);
      let valueArr = [];
      var BreakException = {}; 
      let arr = [];
      let value = 0;
      let balance = 0; 
      let myvalet = 0;  
      creds.controls.forEach((element, i) => {
        let myvalue = (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('totalServiceAmount').value 
        let oldvalue = (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('initialValue').value
      console.log("oldvalueoldvalue",oldvalue);
      
      // let mytotalvalue = payload.totalCal;
        mytotalvalue = JSON.parse(mytotalvalue) + JSON.parse(oldvalue);
        // alert(mytotalvalue);
        // alert(myvalue);
        if (mytotalvalue >= myvalue) {
          let ddd = mytotalvalue - myvalue; 
          if (Math.sign(ddd) == 1) {
            (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(0);
            (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(myvalue);
          } else {
            (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(0);
            (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(myvalue);
          }
          if (Math.sign(ddd) == 1) {
            mytotalvalue = ddd;
          } else {
            mytotalvalue = 0;
          }
        } else if (myvalue >= mytotalvalue && mytotalvalue !== 0) {
        
          let ddd = mytotalvalue - myvalue;
          console.log("dddddd",ddd);
          
          if (Math.sign(ddd) == -1) {
          
            let diffrence = myvalue - mytotalvalue;
            if (mytotalvalue == myvalue) {
  
              (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(0);
              (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(mytotalvalue);
            } else {
              (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(diffrence);
              (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(mytotalvalue);
            }
            //  dataInfo.forEach(ele => {
            //   ele.servicePaidAmount = mytotalvalue +diffrence;
            // });
          } else {
            // alert("inelsffe")
          } 
          if (Math.sign(ddd) == -1) {
            mytotalvalue = 0;
          } else {
            mytotalvalue = ddd;
          }
          console.log("Math.sign(ddd)",Math.sign(ddd));
        } else {
          (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(myvalue);
          // this.serviceAdvanceAmount=myvalue;
          (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(0);
          // dataInfo.forEach(ele => {
          //   ele.servicePaidAmount = 0 +myvalue;
          // });
        }
        // this.advanceAmount = mytotalvalue;
      
        console.log("myvaluewww",myvalue);
        
        console.log("this.totalAmount",this.totalAmount);
        console.log("this.advanceAmount",JSON.parse(this.advanceAmount));
        console.log("this.totalPaidAmount",JSON.parse(this.totalPaidAmount));
        console.log("paidAdvanceAmountpaidAdvanceAmount",this.paidAdvanceAmount);
        console.log("this.balanceAmountthis.balanceAmount",this.balanceAmount);
        let ccc = JSON.parse(this.advanceAmount) + JSON.parse(this.totalPaidAmount);
console.log("cccccc",ccc);
      })
          //console.log("temdata",result);
          this.chequeDetails = [];
          if(result !== undefined){
          this.chequeDetails = result.data;
          //console.log("this.chequeDetails",this.chequeDetails);
          }else{
            this.BillingForm.controls['modeOfPayment'].setValue('Cash');
            this.modeval = 'Cash';
            this.balanceAmount = this.balanceAmount - JSON.parse(this.advanceAmount);
            //this.advanceAmount = this.advanceAmount + this.pendingChequeAmount;
            this.pendingChequeAmount = this.pendingChequeAmount - JSON.parse(this.advanceAmount); 
          }
        });
    }
    else{
      this.isAdvance = false;
      this.modeval = mode;
      if(this.pendingChequeAmount !== 0){ 
      // this.balanceAmount = this.balanceAmount - JSON.parse(this.advanceAmount);
     // this.advanceAmount = this.advanceAmount + this.pendingChequeAmount;
      // this.pendingChequeAmount = this.pendingChequeAmount - JSON.parse(this.advanceAmount);
      this.pendingChequeAmount = this.pendingChequeAmount;
      }
    }
  }
  else
  {
    this.BillingForm.get("modeOfPayment").markAsTouched();
    this.BillingForm.controls['modeOfPayment'].setValue('');
    this.apimicroservice.openSnackbar("Please,First Enter the Amount"); 
  }

   }
   modeOfPayment(i)
   {
  
      // const dialogRef1 = this.dialog.open(PopUpItemComponent,
      //   {
      //     width: "600px",
      //     data: {
      //       data: ssno
      //     }
      //   });
    
   }
//    addRow(i) {
//     const creds = this.BillingForm.controls.chequeArray as FormArray;
//         creds.push(
//         this.fb.group({
//           "date": new FormControl(''),   
//           "chequeNo": new FormControl('',),  
//           "bankName": new FormControl(''), 
//           "amount": new FormControl(''),
//         toggle: false

// })
// );
//   }
  deleteRow(index){
    //this.dynamicRows.splice(index, 1);
    (<FormArray>this.BillingForm.get("chequeArray")).removeAt(index);
  } 
  printForm() {
    console.log("this.totalAmount - this.totalAmtPaidTillDate - this.advanceAmt",this.totalAmount - this.totalAmtPaidTillDate - this.advanceAmt);
    console.log("this.isPrintAvailable",this.isPrintAvailable);
    
    if (this.isPrintAvailable == true && (this.totalAmount - this.totalAmtPaidTillDate - this.advanceAmt) == 0) {
      this.router.navigate(['print-bill', this.billNo]);
    } else {
      this.apimicroservice.openSnackbar("First Generate all the Receipts");
    }
  }
  onback() {
    this.apimicroservice.back()
  }
  advanceCopy(e)
  { 
console.log("this.totalPaidAmount",this.totalPaidAmount);

    this.paidAdvanceAmount =0;
    this.mode =  this.advaceData[0].modeOfPayment; 
    let status = ''; 
    if(this.advaceData[0].chequeDetails[0])
    {
      status = this.advaceData[0].chequeDetails[0].chequeStatus;
      this.chequeStatus = this.advaceData[0].chequeDetails[0].chequeStatus;
    }
    this.BillingForm.controls['modeOfPayment'].setValue(this.mode);

    const creds = this.BillingForm.controls.serviceInfo as FormArray;
   if(e == 'Yes') 
   {

    this.isAdvance = true;
    this.BillingForm.controls['totalCal'].setErrors(null);
    this.BillingForm.controls['totalCal'].clearValidators();
    this.BillingForm.controls['totalCal'].updateValueAndValidity();
    this.readonly = true;
    this.showMsg = false;
     this.showMsg1 = true;
    //  this.BillingForm.controls['totalCal'].setValue(this.chequeAmt);
     let bar1 = new Promise<void>((resolve,reject) =>{
      this.BillingForm.controls['totalCal'].setValue(this.chequeAmt);
      resolve();
      });
     
     
    if(this.mode !== 'Cheque')
    { 
      this.advanceAmtCopy = true; 
      this.advaceData.forEach(ele=>{
        this.paidAdvanceAmount = this.paidAdvanceAmount + ele.paidAmount;
      })

      console.log("this.paidAdvanceAmount",this.paidAdvanceAmount);
      
       this.fullBalanceAmount= this.fullBalanceAmount - this.paidAdvanceAmount;
       this.balanceAmount = this.balanceAmount - this.paidAdvanceAmount;
       console.log("this.fullBalanceAmount66",this.fullBalanceAmount);
       console.log("this.balanceAmount77",this.balanceAmount);
      this.disableSubmit = false;
      this.divideAmount =this.paidAdvanceAmount/this.serviceInfoLength;
      creds.controls.forEach((element,index) => {
        let serviceControl = (<FormArray>this.BillingForm.get("serviceInfo")).at(index);
        let servicePaidVal = serviceControl.get("servicePaidAmount").value;
        let totalServiceAmount = serviceControl.get("totalServiceAmount").value;
        let serviceAdvance = serviceControl.get("serviceAdvanceAmount").value; 
        // let finalAmtt = parseInt(totalServiceAmount) - parseInt(servicePaidVal);
        let paidVal = parseInt(servicePaidVal)+this.divideAmount;
        let tempAmt = 0;
        //console.log("tttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt",serviceAdvance);
         
        if(serviceAdvance == null || serviceAdvance == '')
        {
          tempAmt = this.divideAmount;
        }
        else
        {
          tempAmt = this.divideAmount + parseInt(serviceAdvance);
        }
        // let paidAmt = finalAmtt - this.divideAmount;
        serviceControl.patchValue({
          // servicePaidAmount: paidVal,
          serviceAdvanceAmount:tempAmt
         });
      });
      bar1.then(()=>
      {
        this.totalCalculation();
      })
    }
    else
    {
       if(status !== 'Clear')
       {

        
      this.advanceAmtCopy = true; 
      this.advaceData.forEach(ele=>{   
         
        if(ele.modeOfPayment == 'Cheque')
        {
          this.chequeDetails.push(ele.chequeDetails);
          this.paidAdvanceAmount = this.paidAdvanceAmount + ele.chequeDetails[0].amount;
        }
        else
        {
          this.paidAdvanceAmount = this.paidAdvanceAmount + ele.paidAmount;
        }
      })
      this.pendingChequeAmount = this.pendingChequeAmount + this.paidAdvanceAmount;
      this.showMsg = false;
      this.disableSubmit = false;
      this.divideAmount =this.paidAdvanceAmount/this.serviceInfoLength;
      creds.controls.forEach((element,index) => {
        let serviceControl = (<FormArray>this.BillingForm.get("serviceInfo")).at(index);
        let servicePaidVal = serviceControl.get("servicePaidAmount").value;
        let totalServiceAmount = serviceControl.get("totalServiceAmount").value;
        let serviceAdvance = serviceControl.get("serviceAdvanceAmount").value; 
        let paidVal = parseInt(servicePaidVal)+this.divideAmount;
        let tempAmt = 0;
      
        if(serviceAdvance == null || serviceAdvance == '')
        {
          tempAmt = this.divideAmount;
        } 
        else
        {
          tempAmt = this.divideAmount + parseInt(serviceAdvance);
        }
        serviceControl.patchValue({
          serviceAdvanceAmount:tempAmt
         });
      });
    }
    else
    {
      this.advanceAmtCopy = true; 
      this.advaceData.forEach(ele=>{
        // this.paidAdvanceAmount = this.paidAdvanceAmount + ele.paidAmount;
        if(ele.modeOfPayment == 'Cheque')
        {
          this.chequeDetails.push(ele.chequeDetails);
          this.paidAdvanceAmount = this.paidAdvanceAmount + ele.chequeDetails[0].amount;
        }
        else
        {
          this.paidAdvanceAmount = this.paidAdvanceAmount + ele.paidAmount;
        }
      })

      console.log("this.paidAdvanceAmount",this.paidAdvanceAmount);
      
       this.fullBalanceAmount= this.fullBalanceAmount - this.paidAdvanceAmount;
       this.balanceAmount = this.balanceAmount - this.paidAdvanceAmount;
      this.disableSubmit = false;
      this.divideAmount =this.paidAdvanceAmount/this.serviceInfoLength;
      creds.controls.forEach((element,index) => {
        let serviceControl = (<FormArray>this.BillingForm.get("serviceInfo")).at(index);
        let servicePaidVal = serviceControl.get("servicePaidAmount").value;
        let totalServiceAmount = serviceControl.get("totalServiceAmount").value;
        let serviceAdvance = serviceControl.get("serviceAdvanceAmount").value; 
        let paidVal = parseInt(servicePaidVal)+this.divideAmount;
        let tempAmt = 0;
         
        if(serviceAdvance == null || serviceAdvance == '')
        {
          tempAmt = this.divideAmount;
        }
        else
        {
          tempAmt = this.divideAmount + parseInt(serviceAdvance);
        }
        serviceControl.patchValue({
          serviceAdvanceAmount:tempAmt
         });
      });
      // bar1.then(()=>
      // {
      //   this.totalCalculation();
      // })
    }






    }


    // bar.then(()=>
    // {
    //   let bar1 = new Promise<void>((resolve,reject) =>{
    //   this.BillingForm.controls['totalCal'].setValue(this.chequeAmt);
    //   resolve();
    //   });
    //   bar1.then(()=>
    //   {
    //     this.totalCalculation();
    //   })
    // })
   }else if(e == 'No')
   {
    this.BillingForm.controls['totalCal'].setValidators(Validators.required);
    this.BillingForm.controls['totalCal'].updateValueAndValidity();

    this.isAdvance = false;
    this.readonly = false;
    this.showMsg = true;
    this.showMsg1 = false;
    this.advanceAmtCopy = false;
    this.disableSubmit = true;
    this.paidAdvanceAmount =0;
    this.fullBalanceAmount= this.fullTempAmt;
    this.balanceAmount = this.fullBalanceAmount;
    this.ngOnInit();
    // this.showMsg = false;
   }
  }
  advanceCopyNo()
  {
    this.showMsg = false;
  }
  // eventHandler(event)
  // {
  //   console.log("event, event.keyCode",event, event.keyCode);
  //   if (event.keyCode == 8 || event.keyCode == 46) {
  //     this.ngOnInit();
  // }

  // }
  // test()
  // {
  //   let payload = this.BillingForm.value;
  //   if(payload.totalCal == '' || payload.totalCal == null || payload.totalCal == undefined)
  //   {
  //  this.ngOnInit();
  //   }
  // }
  dvalue; 

  totalCalculation() {  
    let payload = this.BillingForm.value;
console.log("11");
 
this.enterValue= parseInt(payload.totalCal);
this.RemainingAmt = this.totalAmount - this.totalAmtPaidTillDate - this.advanceAmt - payload.totalCal + this.pendingChequeAmount;
console.log("this.totalAmtPaidTillDate1",this.totalAmtPaidTillDate);
  this.totalAmtPaidTillDate=this.totalAmtPaidTillDate;
  console.log("this.totalAmtPaidTillDate",this.totalAmtPaidTillDate);
  

//  this.balanceAmount = this.tempBalanceAmt;
//   console.log("this.totalPaidAmount",this.totalPaidAmount);
// console.log("totalAmount",this.totalAmount);
console.log("pendingChequeAmountpendingChequeAmount",this.pendingChequeAmount);

// console.log("payload.totalCal",parseInt(payload.totalCal));
let remaingAmt = this.pendingChequeAmount + parseInt(payload.totalCal);
let payableAmt = this.balanceAmount - this.pendingChequeAmount;

let mytotalvalue = payload.totalCal;
this.advanceAmount = payload.totalCal;
this.disableSubmit = false;
console.log("payload.totalCalpayload.totalCal",payload.totalCal);
if(payload.totalCal == '' || payload.totalCal == null || payload.totalCal == undefined)
{
this.ngOnInit();
}

console.log("11111111111111111",JSON.parse(payload.totalCal));
console.log("22222222222",this.balanceAmount);
// console.log("33333333333333333",this.tempBalanceAmt);
console.log("totalAmtPaidTillDate",this.totalAmtPaidTillDate);
  console.log("advanceAmt",this.advanceAmt);
console.log("totalAmount",this.totalAmount);
let remaing = this.totalAmount - this.totalAmtPaidTillDate - this.advanceAmt;
console.log("remaing",remaing);
if(JSON.parse(payload.totalCal) > (remaing))
{ 
  this.apimicroservice.openSnackbar("Deposit Amount should be less than Remaining amount");
  this.BillingForm.controls['totalCal'].setValue('');
}
else if(remaingAmt > this.totalAmount)
{
  this.apimicroservice.openSnackbar("Payable Amount is: "+payableAmt);
  this.BillingForm.controls['totalCal'].setValue('');
}
else
{ 
  ////////////////////////////////////////// Patch Cal
 this.apimicroservice.getBillDataById(this.billNo).subscribe(res => {
  let data = res.data[0];
  let amt ;
  if(res.data[0].paidAmount !== undefined)
  {
    amt = res.data[0].paidAmount;
  }else{
    amt = 0;
  }
  let remainingAmount = parseInt(amt) + parseInt(payload.totalCal);
  console.log("remainingAmount",remainingAmount);
  if(remainingAmount == undefined)
  {
    remainingAmount = 0;
  }

  res.data[0].serviceInfo = res.data[0].serviceInfo.map((item,i) => {   
    console.log("pillu",item,i);
    console.log("item.totalServiceAmount",item.totalServiceAmount);
    if(item.totalServiceAmount!==undefined) 
    {
      const subtractedValue = Math.min(remainingAmount, item.totalServiceAmount);
      remainingAmount -= subtractedValue;
      console.log('subtractedValue1', subtractedValue);
      item.subValue = subtractedValue;
      (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('subValue').patchValue(subtractedValue);
      return { ...item, paid: subtractedValue };
    }else{
    const subtractedValue = Math.min(remainingAmount, 0);
    remainingAmount -= subtractedValue;
    console.log('subtractedValue2', subtractedValue);
    item.subValue = subtractedValue;
    (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('subValue').patchValue(subtractedValue);
    return { ...item, paid: subtractedValue };
    }


  });
});
  /////////////////////////////////////
let dataInfo = this.BillingForm.get('serviceInfo').value;

const creds = this.BillingForm.controls.serviceInfo as FormArray;
console.log("creds.length", creds);
// console.log("creds",creds.controls.length);
let valueArr = [];
var BreakException = {}; 
let arr = [];
let value = 0;
let balance = 0; 
let myvalet = 0;  


creds.controls.forEach((element, i) => {
  let myvalue = (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('totalServiceAmount').value 
  let oldvalue = (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('initialValue').value
console.log("oldvalueoldvalue",oldvalue);


// let mytotalvalue = payload.totalCal;
  mytotalvalue = JSON.parse(mytotalvalue) + JSON.parse(oldvalue);
  // alert(mytotalvalue);
  // alert(myvalue);
  if (mytotalvalue >= myvalue) {
    let ddd = mytotalvalue - myvalue; 
    if (Math.sign(ddd) == 1) {
      (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(0);
      (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(myvalue);
      // (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('subValue').patchValue(myvalue);
    } else {
      (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(0);
      (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(myvalue);
      // (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('subValue').patchValue(myvalue);
    }
    if (Math.sign(ddd) == 1) {
      mytotalvalue = ddd;
    } else {
      mytotalvalue = 0;
    }
  } else if (myvalue >= mytotalvalue && mytotalvalue !== 0) {
  
    let ddd = mytotalvalue - myvalue;
    console.log("dddddd",ddd);
    
    if (Math.sign(ddd) == -1) {
    
      let diffrence = myvalue - mytotalvalue;
      if (mytotalvalue == myvalue) {

        (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(0);
        (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(mytotalvalue);
        // (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('subValue').patchValue(mytotalvalue);
      } else {
        (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(diffrence);
        (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(mytotalvalue);
        // (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('subValue').patchValue(mytotalvalue);
      }
      //  dataInfo.forEach(ele => {
      //   ele.servicePaidAmount = mytotalvalue +diffrence;
      // });
    } else {
      // alert("inelsffe")
    } 
    if (Math.sign(ddd) == -1) {
      mytotalvalue = 0;
    } else {
      mytotalvalue = ddd;
    }
    console.log("Math.sign(ddd)",Math.sign(ddd));
  } else {
    (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(myvalue);
    // this.serviceAdvanceAmount=myvalue;
    (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(0);
    // (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('subValue').patchValue(0);
    // dataInfo.forEach(ele => {
    //   ele.servicePaidAmount = 0 +myvalue;
    // });
  }
  // this.advanceAmount = mytotalvalue;

  console.log("myvaluewww",myvalue);
  
  console.log("this.totalAmount",this.totalAmount);
  console.log("this.advanceAmount",JSON.parse(this.advanceAmount));
  console.log("this.totalPaidAmount",JSON.parse(this.totalPaidAmount));
  console.log("paidAdvanceAmountpaidAdvanceAmount",this.paidAdvanceAmount);
  console.log("this.balanceAmountthis.balanceAmount",this.balanceAmount);
  let ccc = JSON.parse(this.advanceAmount) + JSON.parse(this.totalPaidAmount);
console.log("cccccc",ccc);

//         if(this.isAdvance == true)
//         {
//           console.log("000000001");
    
//           this.balanceAmount = this.tempBalanceAmt- JSON.parse(this.advanceAmount);
//         }
//         else
//         {
//           console.log("11111111110");
//            this.balanceAmount = this.tempBalanceAmt - JSON.parse(this.advanceAmount);
//           // this.balanceAmount = this.totalAmount - ccc;
//           // this.balanceAmount = this.totalAmount - ccc - this.paidAdvanceAmount;
//         }
console.log("myvaluecccc",myvalue);

  console.log("mytotalvalue", mytotalvalue);
  console.log("this.balanceAmount",this.balanceAmount);
  this.balanceAmount=this.totalAmount - this.totalAmtPaidTillDate - this.advanceAmt;
  console.log("this.balanceAmount",this.balanceAmount);
  // this.BillingForm.controls['totalCal'].setValue('');

})
}

}
  totalCalculationn(val) {  
          let payload = val;
    console.log("11");
this.enterValue= parseInt(val);

  this.RemainingAmt = this.totalAmount - this.totalAmtPaidTillDate - this.advanceAmt - val + this.pendingChequeAmount;

        this.totalAmtPaidTillDate=this.totalAmtPaidTillDate;
   
 let remaingAmt = this.pendingChequeAmount + parseInt(val);
let payableAmt = this.balanceAmount - this.pendingChequeAmount;
      let mytotalvalue = val;
      this.advanceAmount = val;
      this.disableSubmit = false;
      console.log("valval",val);
      // if(val == '' || val == null || val == undefined)
      // {
      // this.ngOnInit();
      // }

      console.log("11111111111111111",JSON.parse(val));
      console.log("22222222222",this.balanceAmount);
     // console.log("33333333333333333",this.tempBalanceAmt);
      console.log("totalAmtPaidTillDate",this.totalAmtPaidTillDate);
        console.log("advanceAmt",this.advanceAmt);
      console.log("totalAmount",this.totalAmount);
      let remaing = this.totalAmount - val - this.advanceAmt;
      console.log("remaing",remaing);
   
      let dataInfo = this.BillingForm.get('serviceInfo').value;
  
      const creds = this.BillingForm.controls.serviceInfo as FormArray;
      
      console.log("creds", creds.controls);
      console.log("creds.controls",creds['controls'].length);
    
      // console.log("arry",arry);
      // console.log("arry.length",arry.length);
      let valueArr = [];
      var BreakException = {}; 
      let arr = [];
      let value = 0;
      let balance = 0; 
      let myvalet = 0;  
      creds.controls.forEach((element, i) => {
        console.log('am here',element)
        let myvalue = (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('totalServiceAmount').value 
        let oldvalue = (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('initialValue').value
      console.log("oldvalueoldvalue",oldvalue);
      
      // let mytotalvalue = val;
        mytotalvalue = JSON.parse(mytotalvalue) + JSON.parse(oldvalue);
        // alert(mytotalvalue);
        // alert(myvalue);
        if (mytotalvalue >= myvalue) {
          let ddd = mytotalvalue - myvalue; 
          if (Math.sign(ddd) == 1) {
            (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(0);
            (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(myvalue);
          } else {
            (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(0);
            (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(myvalue);
          }
          if (Math.sign(ddd) == 1) {
            mytotalvalue = ddd;
          } else {
            mytotalvalue = 0;
          }
        } else if (myvalue >= mytotalvalue && mytotalvalue !== 0) {
        
          let ddd = mytotalvalue - myvalue;
          console.log("dddddd",ddd);
          
          if (Math.sign(ddd) == -1) {
          
            let diffrence = myvalue - mytotalvalue;
            if (mytotalvalue == myvalue) {
  
              (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(0);
              (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(mytotalvalue);
            } else {
              (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(diffrence);
              (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(mytotalvalue);
            }
          } else {
            // alert("inelsffe")
          } 
          if (Math.sign(ddd) == -1) {
            mytotalvalue = 0;
          } else {
            mytotalvalue = ddd;
          }
          console.log("Math.sign(ddd)",Math.sign(ddd));
        } else {
          (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('serviceAdvanceAmount').patchValue(myvalue);
          (<FormArray>this.BillingForm.get("serviceInfo")).at(i).get('servicePaidAmount').patchValue(0);
        }
     
        console.log("myvaluewww",myvalue);
        
        console.log("this.totalAmount",this.totalAmount);
        console.log("this.advanceAmount",JSON.parse(this.advanceAmount));
        console.log("this.totalPaidAmount",JSON.parse(this.totalPaidAmount));
        console.log("paidAdvanceAmountpaidAdvanceAmount",this.paidAdvanceAmount);
        console.log("this.balanceAmountthis.balanceAmount",this.balanceAmount);
        let ccc = JSON.parse(this.advanceAmount) + JSON.parse(this.totalPaidAmount);
console.log("cccccc",ccc);

console.log("myvaluecccc",myvalue);

        console.log("mytotalvalue", mytotalvalue);
        console.log("this.balanceAmount",this.balanceAmount);
        this.balanceAmount=this.totalAmount - this.totalAmtPaidTillDate - this.advanceAmt;
        console.log("this.balanceAmount",this.balanceAmount);
      })
     
  }
  editBill(billNo) {
    if(this.serialNo == null)
    {
      const dialogRef3 = this.dialog.open(PopupServiceComponent,
        {  
          width: "1200px", 
          // height: "400px",
            data: {
              patientRefId: this.patientRefId,
              billNo: billNo,
              // appointId:this.appointId,
              patientDetails:'',
              // way:this.way,
              page:'Billing Page',
            }
        
        }); 
        dialogRef3.afterClosed().subscribe((result) => {
          this.ngOnInit();
       })
    }else
    {
      const dialogRef = this.dialog.open(PopupAddDeviceComponent,
        {
          data: {
            patientRefId: '',
            appointId: '',
            patientDetails: '',
            page: "Billing Page",
            isDraft: 'true',
            billNo: billNo,
            isBilling:'true'
          }
        }
  
      );
      dialogRef.afterClosed().subscribe((result) => {
         this.ngOnInit();
      })
    }
  
  }
  preview() {
    const dialogRef = this.dialog.open(PreviewPopupComponent,
      {
        data:{
          billNo: this.billNo,
        },
        width: "1200px",
        height: "700px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
       this.ngOnInit();
    })
  } 

  deleteReceipt(item){
    item['remainingAmt']=this.totalAmount - this.totalAmtPaidTillDate - this.advanceAmt;
    console.log("item",item);
    this.apimicroservice.deleteReceipt(item).subscribe(res => {
      if(res.status){
        this.ngOnInit();
      }
    });
  }
  
  updateAmount(): void {
    let remainingAmount = this.newAmount;
 
    this.items = this.items.map((item) => {
      const subtractedValue = Math.min(remainingAmount, item.amount);
      remainingAmount -= subtractedValue;
      console.log('subtractedValue', subtractedValue);
      return { ...item, paid: subtractedValue };
    });
  }

  subtractSavedValues(): void {
    let remainingSubtract = this.subtractAmount;

    for (let i = this.items.length - 1; i >= 0 && remainingSubtract > 0; i--) {
      const paid = this.items[i].paid;
      const subtractAmount = Math.min(remainingSubtract, paid);
      remainingSubtract -= subtractAmount;
      this.items[i].paid -= subtractAmount;
    }
  }
  openConfirmatipnPopUp(id)
  { 
    const dialogRef3 = this.dialog.open(PopupConfirmationMsgComponent,
      {  
        width: "24%",
        // height: "23%",
        data: {  
          from:'billing Form',
          billNo:this.billNo
        }
      });
      dialogRef3.afterClosed().subscribe((result) => {
       let paymentPayload={};
        if(result == 'Yes')
        {  
 let receiptNo = Math.floor(10000 + Math.random() * 90000);
  paymentPayload['receiptNo'] = receiptNo;
           this.apimicroservice.updatePaymentHistoryDetails(paymentPayload, id).subscribe(res => {
           this.ngOnInit();
           })
      
  }
})
  }
  
}








































// console.log("element",element);
// console.log("i",i);
// arr.push(i);
// console.log("arr",arr);
// let newData = 0;
// let temp =0;

// let total = val;
// // if(total > totalServiceAmount ){
// //   alert("in")
// //   let ddd =  totalServiceAmount - total;
// //   if(Math.sign(ddd) == -1 ){
// //     newData = 0
// //   }
// //   total = total - totalServiceAmount;
// // }else{
// //   total = totalServiceAmount -total
// //   newData= total
// // }
// valueArr = [];
// arr.forEach(ele=>
//   {
//     let totalServiceAmount = (<FormArray>this.BillingForm.get("serviceInfo")).at(ele).get('totalServiceAmount').value;
// console.log("totalServiceAmount",totalServiceAmount);

//     valueArr.push(totalServiceAmount)
//     valueArr.forEach((e,i)=>
//       {
       
//           // balance = e-total
       
//           balance = total - e;
//           value = balance;
//           // alert("total");
//         // if(i ==1)
//         // {
//         //   throw BreakException;
//         // }
//         console.log("totallllll",total);
//         let deviceInfo = (<FormArray>this.BillingForm.get("serviceInfo")).at(ele);
//         console.log("balancebalance",balance);
//         if(balance <0)
//         {
//           value = 0;
//         }
//         // total=balance;
//      deviceInfo.patchValue({
//        serviceAdvanceAmount:value,
//       });
//      })
//      console.log("66666666",balance);
   
//     //  balance= total
//      console.log("ele",ele);
//      console.log("total",total);
// console.log("valueArr",valueArr);

 


// // console.log("temp",temp);
// // let data = element.get('serviceAdvanceAmount').value;
// // console.log("data",data);

// // console.log("total",total);

// //  temp = totalServiceAmount-total;
// //  value = temp;
// //  console.log("temppppppppppppppp",temp);
// //  value=temp;
// //  if(temp<0)
// //  {
// //   value = 0;
// //  }
// //  else
// //  {
// //   value=temp;
// //  }
// //  let deviceInfo = (<FormArray>this.BillingForm.get("serviceInfo")).at(e);
// // if(value < this.totalAmount)
// // {
// //   deviceInfo.patchValue({
// //     serviceAdvanceAmount:value,
// //    });
// //    total=value;
// // }
// // else
// // {
// //   throw BreakException;
// // }
// /////////////////////////////////////////

// // console.log("temptemp",temp);
// // console.log("valuevalue",value);

// // console.log("totaltotal", total);

// // let deviceInfo = (<FormArray>this.BillingForm.get("serviceInfo")).at(e);
// // console.log("deviceInfo",deviceInfo);

// // if(value < this.totalAmount)
// // {
// //   deviceInfo.patchValue({
// //     serviceAdvanceAmount:value,
// //    });
// //    total=value;
// // }
// // else
// // {
// //   throw BreakException;
// // }
// // console.log("newData",newData);



// })
// // if(creds.length==i)




//   // (<FormArray>this.BillingForm.get("serviceInfo")).at(0).get('serviceAdvanceAmount').setValue(700);

   
   
 
// //  let a = element.controls .get('serviceAdvanceAmount').value;
// //  console.log("a",a);

// // element.get('selectService').setValue(true);
// });