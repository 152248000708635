import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl, Validators} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-popup-department',
  templateUrl: './popup-department.component.html',
  styleUrls: ['./popup-department.component.scss']
})
export class PopupDepartmentComponent implements OnInit {
  popupDepartmentForm: FormGroup;
  listOfDepartment: any;
  totalLength: any;
  searchText: any;
  page:number = 1;
  size:number = 3;
  sid: any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupDepartmentComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
) {dialogRef.disableClose = true; }
addNewComplaint = false;
  ngOnInit(): void {
    this.page = 1;
    this.popupDepartmentForm = this.fb.group({  
      "departmentName": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "createdAt": new Date(),
      "searchdepartment": new FormControl(''),
    });
    this.fetchDepartmentList();
  }
  fetchDepartmentList() {
    let pg = 1;
    let size = 3;
    let search='';
        this.apimicroservice.getDepartmentData(pg,size,search).subscribe(res => {
           this.listOfDepartment = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });   
 
  }
  saveDepartment(){
    let payload = this.popupDepartmentForm.value; 
    //
    this.spinner.show();
    this.apimicroservice.saveDepartmentData(payload).subscribe(res => {
      //
      this.spinner.hide();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        this.ngOnInit();
       this.onClose();        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
      this.onClose();    
  });
  }
  save() {
    if (this.popupDepartmentForm.invalid) {
      this.popupDepartmentForm.get("department").markAsTouched();
      return;
    } else {
      this.saveDepartment();
    }
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.popupDepartmentForm.get('searchdepartment').value;
    this.apimicroservice.getDepartmentData(this.page,this.size,this.searchText).subscribe(res => {
      this.listOfDepartment = res.data.data;
      this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  prepopulation(id) { 
    this.apimicroservice.getDepartmentDataById(id).subscribe(res => {
      let data = res.data[0];
      this.sid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupDepartmentForm = this.fb.group({
          "departmentName" :new FormControl(data.departmentName),
          "updateddAt": new Date(),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  updateDepartment() {
    let payload = this.popupDepartmentForm.value;
     this.apimicroservice.updateDepartmentData(payload,this.sid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.popupDepartmentForm.get('searchdepartment').value;
 
    this.apimicroservice.getDepartmentData(this.page,this.size,this.searchText).subscribe(res => {
      this.listOfDepartment = res.data.data;
      this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
  
  }
  cancelUpdate(){
    this.popupDepartmentForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
  onClose(): void {
    this.dialogRef.close();
  }
   
   
}
