import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
} from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PatientSearchPopComponent } from '../patient-search-pop/patient-search-pop.component';

@Component({
  selector: 'app-popup-service',
  templateUrl: './popup-service.component.html',
  styleUrls: ['./popup-service.component.scss']
})
export class PopupServiceComponent implements OnInit {
  popupServiceForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=5;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  filteredListPatient: any;
  patientList: any;
  totalBillAmt = 0;
  datearray = [];
  billingData = [];
  printData=[
    {
      srNo:1,
      date:'201',
      serviceName:'Test',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:2,
      date:'202',
      serviceName:'Test',
      companyName:'A Company',
      rate:200,
     },
     {
      srNo:3,
      date:'203',
      serviceName:'Test 2',
      companyName:'A Company',
      rate:100,
     },
     {
      srNo:4,
      date:'204',
      serviceName:'Test 3',
      companyName:'A Company',
      rate:150,
     },                 
  ]
  patientRefId: any;
  appointId: any;
  billingList: any;
  paientdetails:any;
  cid: any;
  superClinicRefId: any;
  servicerateList: any;
  billingInfo: any[];
  updateArr = [];
  serviceArr: any;
  billCompArr = [];
  billInfoArr = [];
  oldServiceRateInfo = [];
  tempServicArr = [];
  pagename: any;
  bId: string;
  repairRef: any;
  patientName: any;
  repairBranch: any;
  billNo: any;
  patientMobileNo: any;
  totalsgst = 0;
  totalcgst = 0;
  totaligst = 0;
  discount = 0;
  totalPaidAmount = 0;
  tempPaymentList = [];
  totalAmount = 0;
  balanceAmount = 0;
  pendingChequeAmount = 0;
  bounceAmount = 0;
  filteredListService: any;
  serviceList = [];
  patientId: any;
  estimateAmount: any;
  totalAmountByCheque = 0;
  chequeList = [];
  ServiceName: any;
  way: any;
  billRefId: any;
  allBillingData: any;
  
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PopupServiceComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
  ) { 
    this.patientRefId = data.patientRefId;
    this.appointId = data.appointId;
   // this.paientdetails = JSON.parse(data.patientDetails);
    this.pagename = data.page;
    this.estimateAmount= data.estimateAmount;
    dialogRef.disableClose = true;
    this.way = data.way;
    this.billNo = data.billNo;
  }
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;
  patientCreate= true;
  ngOnInit(): void {
    console.log("this.data111",this.data);
    
    if(this.data.patientDetails !== ''){
      this.paientdetails = JSON.parse(this.data.patientDetails);
    }else{ 
      this.paientdetails = this.data.patientDetails;
    }
    
    this.currentdate = new Date();
    //console.log("this.pagename on popup service",this.pagename);
    //console.log("this.paientdetails",this.paientdetails);
    //console.log("this.data.patientDetails",this.data.patientDetails);
    this.page=1;
    this.size = 5;
    this.bId = sessionStorage.getItem('bId');
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    //this.paientdetails = localStorage.getItem("patient");
    this.superClinicRefId = sessionStorage.getItem("superClinicRefId");
    let ddd= localStorage.getItem("patient");
    //this.paientdetails = JSON.parse(ddd);
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Billing Record' && element.create == false)
      {
          this.billingCreate = false;  
      }
      if(element.pageName == 'Billing Record' && element.update == false)
      {
          this.billingUpdate = false;  
      }  
      if(element.pageName == 'Patient' && element.create == false)
      {
          this.patientCreate = false;  
      }  
    });
  }
  ////console.log("patient ref",this.patientRefId);
  //console.log("appoint id",this.appointId);
    this.popupServiceForm = this.fb.group({
      "complaints": new FormControl(''), 
      "observations": new FormControl(''),
      "diagnoses": new FormControl(''),
      "notes": new FormControl(''),
      "followupReasons":new FormControl(''),
      "followupDate":new FormControl(''), 
     "serviceRateInfo": this.fb.array([]),
     "repairNo": new FormControl(''), 
     "repairDateOf" : new FormControl(''),
     "repairDateto":new FormControl(''),
     "patientRefId":new FormControl(this.patientRefId),
     "patientName":new FormControl('',[Validators.required]),
     "appointId":new FormControl(this.appointId),
     "branchRefId":new FormControl(),
     "branchName":new FormControl(''),
     "searchRepairId":new FormControl(''),
     
    });
   
   if(this.pagename !== 'Billing Page' && this.pagename !== 'Repair'){
    this.fetchBillList();
    this.patienthistoryDates();
   }else if(this.pagename == 'Repair'){
    this.branchRef = this.data.branchRefId;
    this.repairBranch = this.data.repairBranch;
    this.repairRef = this.data.repairRefId;
    this.patientName = this.data.patientName;
    this.patientMobileNo = this.data.patientMobileNo;
    this.patientId = this.data.patientId;

    this.popupServiceForm.controls["branchName"].setValue(this.repairBranch);
    this.popupServiceForm.controls["branchRefId"].setValue(this.branchRef);
    this.popupServiceForm.get("patientName").setValue(this.patientName);
    this.popupServiceForm.get("patientRefId").setValue(this.patientRefId);
    this.onSelectPatientByRepair();     
   } else if(this.billNo !== undefined)
   {
    this.fetchBranchList();
    this.fetchPatientList();
  this.getBillById();
   } 
   else{
   this.getPatient(this.patientRefId); 
   }
    
   // this.fetchServiceRateList();
  if(this.pagename == 'Billing Page'){
    if(this.userType !== 'Super Admin'){
      //this.fetchPatientList();
      this.popupServiceForm.controls["branchName"].setValue(this.branchName);
      this.popupServiceForm.controls["branchRefId"].setValue(this.branchRef);
    }else{
      this.fetchBranchList();

    }
     
  } 

}
getPatient(id)
{
  this.apimicroservice.getPatientById(id).subscribe(res => {
    this.spinner.hide();
    let data = res.data[0]; 
    this.popupServiceForm.get("patientName").setValue(data.firstName + " " + data.lastName);
    this.popupServiceForm.get("patientRefId").setValue(data.patientRefId);
    this.onSelectPatient(
      data
    ); 
   });
}
fetchBranchList() {
  let clinicRef= sessionStorage.getItem('clinicRef');
  this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
    if (res.status) {  
      //console.log("branch res",res);  
      this.BranchList = res.data; 
      this.filteredListBranch= this.BranchList.slice();
      let length =res.data.length - 1;
      this.branchName= res.data[length].branchName;
      this.popupServiceForm.controls["branchRefId"].setValue(res.data[length]._id); 
      if(this.userType == 'Super Admin')
      {   
        this.branchClick(res.data[length]._id,this.branchName);
        this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
          this.spinner.hide();
          this.popupServiceForm.get("branchName").setValue(res.data[length].branchName);
         });
      }
      else
      {
        this.branchClick(this.branchRef,this.branchName);  
      }
      
    } else { 
    //  this.apimicroservice.openSnackbar(res.message);
    } 
  })
} 

fetchPatientList(){ 
  this.searchText = '';
  let clinicRef= sessionStorage.getItem('clinicRef');
  let branchRef= sessionStorage.getItem('branchRef');
  let currentdate = new Date().toISOString();
  //console.log("kkkkkkkkk",this.branchRef);
      this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
      //console.log("fetch patienttttttttt list res",res);   
      if (res.status) {    
        this.patientList = res.data;
        this.filteredListPatient = this.patientList.slice();
         
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
    })
  //else
  // {
  //   let branchRef = '';
  //   let clinicRef = '';
  //     this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
  //     //console.log("fetch patienttttttttt list res",res);   
  //     if (res.status) {    
  //       this.patientList = res.data;
  //       this.filteredListPatient = this.patientList.slice();
  //     } else {

  //     }
  //   })
  // }
  
} 

branchClick(id,branchName){

  this.popupServiceForm.controls["branchName"].setValue(branchName);
 this.branchRef = id;
 this.branchName = branchName;
// this.fetchPatientList();
}
openPatientPopUp() {
    
  const dialogRef3 = this.dialog.open(PopUpPatientComponent,
    {
      width: "1200px",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      //this.fetchPatientList();
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getPatientListAll(branchRef,clinicRef).subscribe(res => {
        if (res.status) {
          // //console.log("res",res.data.data);
          this.patientList = res.data.data;
          this.filteredListPatient= this.patientList.slice();
          
          this.popupServiceForm.get("patientName").setValue(this.patientList[0].firstName + " " + this.patientList[0].lastName);
          this.onSelectPatient(this.patientList[0]);
        }
      });
    }else{
      this.apimicroservice.getPatientListAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        if (res.status) {
          // //console.log("res",res.data.data);
          this.patientList = res.data.data;
          this.filteredListPatient= this.patientList.slice();
          
          this.popupServiceForm.get("patientName").setValue(this.patientList[0].firstName + " " + this.patientList[0].lastName);
          this.onSelectPatient(this.patientList[0]);
        }
      });
    }
     });
} 
getBillById()
{ 
this.apimicroservice.getBillDataById(this.billNo).subscribe(res => {
  this.submitButton=false;
   this.updateButton =true; 
   let data = res.data[0]; 
   this.allBillingData= res.data[0]; 
     this.popupServiceForm = this.fb.group({
      "searchBilling": new FormControl(''), 
      "branchRefId": new FormControl(data.branchRefId),   
      "branchName":new FormControl(data.branchName), 
      "deviceDetails": this.fb.array([]), 
      "serviceInfo": this.fb.array([]), 
      "patientRefId":new FormControl(data.patientRefId),
      "patientName": new FormControl(data.patientName),
      "appointId":new FormControl(this.appointId),
      "billingCompanyRefId":new FormControl(data.billingCompanyRefId),
      "billingCompanyName":new FormControl(data.billingCompanyName),
      "haId":new FormControl(data.haId),
      "haRefId":new FormControl(data.haRefId),
      "serviceRateInfo": this.fb.array([]),
      "updateddAt":new Date(),
      /////////////////////////////////////
    
     "searchRepairId":new FormControl(''),
     });
    //  this.getPatient(data.patientRefId);
     this.billRefId = data._id
     let preArr = [];


    data.serviceInfo.forEach((element,i) => {
      preArr.push(element);
      //console.log("element",element);       
      const tmpDict = {}; 
      tmpDict['_id'] = new FormControl(element._id);
      // tmpDict['deviceRefId'] = new FormControl(element.deviceRefId);
      // tmpDict['model'] = new FormControl(element.model,Validators.required);
      // tmpDict['serialNumber'] = new FormControl(element.serialNumber);
      // tmpDict['tempArray'] = new FormControl(element.serialNumber);
      // tmpDict['changeModel'] = new FormControl(element.changeModel);
      // tmpDict['changeSerialNumber'] = new FormControl(element.changeSerialNumber);
      
      // tmpDict['qty'] = new FormControl(element.qty);
      // tmpDict['mrp'] = new FormControl(element.mrp,[Validators.pattern("^[0-9 ]*$"),Validators.required]);
      // tmpDict['discountType'] = new FormControl(element.discountType);
      // tmpDict['discount'] = new FormControl(element.discount); 

      // tmpDict['earStyle'] = new FormControl(element.earStyle);
      console.log("kkk",element.totalRate-parseInt(element.discount));
      console.log("parseInt(element.discount)parseInt(element.discount)",parseInt(element.discount));
      
      /////////////////////////////////////////
      tmpDict['serviceRefId'] = new FormControl(element.serviceRefId);
      tmpDict['serviceName'] = new FormControl(element.serviceName); 
      tmpDict['testType'] = new FormControl(element.testType);
      tmpDict['rate'] = new FormControl(element.rate);
      tmpDict['quantity'] = new FormControl(element.quantity);
      tmpDict['hasSac'] = new FormControl(element.hasSac);
      tmpDict['billingCompanyRefId'] = new FormControl(data.billingCompanyRefId);
      tmpDict['billingCompanyName'] = new FormControl(data.billingCompanyName);
      tmpDict['sgst'] = new FormControl(element.sgst);
      tmpDict['cgst'] = new FormControl(element.cgst);
      tmpDict['igst'] = new FormControl(element.igst);
      tmpDict['discount'] = new FormControl(parseInt(element.discount));
      tmpDict['status'] = new FormControl(element.status);
      tmpDict['totalRate'] = new FormControl(parseInt(element.totalRate));
      tmpDict['totalServiceAmount'] = new FormControl(parseInt(element.totalRate)-parseInt(element.discount));


      const creds = this.popupServiceForm.controls.serviceRateInfo as FormArray;
       creds.push(new FormGroup(tmpDict)); 
      });
     
});
}
update() {
  this.spinner.show();
  let payload = this.popupServiceForm.value;
  console.log("payload",payload);
console.log("payload.serviceRateInfo",payload.serviceRateInfo);

  console.log("this.billNo",this.billNo);

  let billingPayload = {};
  let billingPayloadNew = {};
  let haPayload = {};
  let servicePayload = {};
  let dataInfo = payload.deviceDetails;
 
  let serviceInfo2 = payload.deviceDetails;
  let info = [];
  let deviceInfo = [];
  let tempInfo = [];

    let bar = new Promise<void>((resolve, reject) => {
      console.log("2");
    
        billingPayloadNew = {};
        info = [];
        deviceInfo = [];
        let totalBill = 0;
        let totalBalance = 0;
        let discountAmt = 0;
        let totalRate = 0;
        let totalServiceAmount = 0;
        ///////////////////////////
   
       
    let preArr = [];
    let discount=0;
    
    let bar11 = new Promise<void>((resolve, reject) => {
    payload.serviceRateInfo.forEach((element,i) => {
      if(element.discount) 
      {
        element.discount = parseInt(element.discount);
      }
      preArr.push(element);
      //console.log("element",element);       
      const tmpDict = {}; 
      tmpDict['_id'] = new FormControl(element._id);
      // tmpDict['deviceRefId'] = new FormControl(element.deviceRefId);
      // tmpDict['model'] = new FormControl(element.model,Validators.required);
      // tmpDict['serialNumber'] = new FormControl(element.serialNumber);
      // tmpDict['tempArray'] = new FormControl(element.serialNumber);
      // tmpDict['changeModel'] = new FormControl(element.changeModel);
      // tmpDict['changeSerialNumber'] = new FormControl(element.changeSerialNumber);
      
      // tmpDict['qty'] = new FormControl(element.qty);
      // tmpDict['mrp'] = new FormControl(element.mrp,[Validators.pattern("^[0-9 ]*$"),Validators.required]);
      // tmpDict['discountType'] = new FormControl(element.discountType);
      // tmpDict['discount'] = new FormControl(element.discount); 

      // tmpDict['earStyle'] = new FormControl(element.earStyle);
      // console.log("kkk",element.totalRate-element.discount);
      
      /////////////////////////////////////////
      tmpDict['serviceRefId'] = new FormControl(element.serviceRefId);
      tmpDict['serviceName'] = new FormControl(element.serviceName); 
      tmpDict['testType'] = new FormControl(element.testType);
      tmpDict['rate'] = new FormControl(element.rate);
      tmpDict['quantity'] = new FormControl(element.quantity);
      tmpDict['hasSac'] = new FormControl(element.hasSac);
      tmpDict['billingCompanyRefId'] = new FormControl(element.billingCompanyRefId);
      tmpDict['billingCompanyName'] = new FormControl(element.billingCompanyName);
      tmpDict['sgst'] = new FormControl(element.sgst);
      tmpDict['cgst'] = new FormControl(element.cgst);
      tmpDict['igst'] = new FormControl(element.igst);
      if(element.discount == 0 || element.discount == '0')
      {
        tmpDict['discount'] = new FormControl(0);
      }else{
        tmpDict['discount'] = new FormControl(parseInt(element.discount));
      }

      tmpDict['status'] = new FormControl(element.status);
      tmpDict['totalRate'] = new FormControl(element.totalRate);
      if(element.discount !== null)
      {
        tmpDict['totalServiceAmount'] = new FormControl(parseInt(element.totalRate)-parseInt(element.discount));
      }else{
        tmpDict['totalServiceAmount'] = new FormControl(parseInt(element.totalRate)-0);
      }

      if(element.discount !== null)
      {
        discount = discount+parseInt(element.discount); 
      }

      totalRate = totalRate+parseInt(element.totalRate);
      const creds = this.popupServiceForm.controls.serviceRateInfo as FormArray;
      // creds.push(new FormGroup(tmpDict)); 
      let serviceControl = (<FormArray>this.popupServiceForm.get("serviceRateInfo")).at(i);
console.log("dddddddddd",parseInt(element.totalRate)-parseInt(element.discount));

      serviceControl.patchValue({
        totalServiceAmount: parseInt(element.totalRate)-parseInt(element.discount)
       });
       resolve();

      });

    });
    bar11.then(()=>{
        console.log("discountdiscount",discount);
    let serviceArr = [];
        discountAmt = discount;
        payload.serviceRateInfo.forEach((element,i) => {
          let serviceControl = (<FormArray>this.popupServiceForm.get("serviceRateInfo")).at(i);
          console.log("ddddddddddooo",parseInt(element.totalRate)-parseInt(element.discount));
          if(element.totalServiceAmount)
          {
            element.totalServiceAmount=parseInt(element.totalRate)-parseInt(element.discount);
            serviceControl.patchValue({
              totalServiceAmount: parseInt(element.totalRate)-parseInt(element.discount)
             });
             serviceArr.push(element);
          }

        });
        console.log("ww",payload.serviceRateInfo);
 console.log("tttt",serviceArr);
 
        if(discountAmt !== 0){
         totalServiceAmount = totalRate - discountAmt;
         }
        let amt = 0; 
   // if(discountAmt !== null){
   //   //amt = parseInt(ele.rate) - parseInt(ele.discount);
   //   amt = totalRate - discountAmt;
   //   totalBill = totalBill + amt;
   // }else{
   //  // totalBill = totalBill + parseInt(ele.rate);
   //  totalBill = totalBill + totalRate;
   // }
   amt = totalRate - discountAmt;
   totalBill = totalBill + amt;
   totalBalance = totalBalance + totalServiceAmount;
   //////////////////////
   console.log("totalRate",totalRate);
   console.log("discountAmt",discountAmt);
   console.log("amt",amt);
   console.log("totalBalance",totalBalance);
   
   console.log("totalBilltotalBill",totalBill);
  
        console.log("payload.serviceRateInfopayload.serviceRateInfo",payload.serviceRateInfo);
        
              billingPayloadNew["mobile"] = this.allBillingData.mobile;
              billingPayloadNew["patientName"] = this.allBillingData.patientName;
              billingPayloadNew["patientRefId"] = this.allBillingData.patientRefId;
              billingPayloadNew["patientId"] = this.allBillingData.patientId;
              billingPayloadNew["branchRefId"] = this.branchRef;
              billingPayloadNew["branchName"] = this.branchName;
              billingPayloadNew['billingCompanyRefId'] = this.allBillingData.billingCompanyRefId;
              billingPayloadNew['billingCompanyName'] = this.allBillingData.billingCompanyName;
              billingPayloadNew['billingId'] = this.allBillingData.billingId;
              billingPayloadNew['billNo'] = this.billNo;
              billingPayloadNew['totalAmount'] = totalBill;
              billingPayloadNew['balanceAmount'] = totalBalance;
              billingPayloadNew['serviceInfo'] = serviceArr;
              // billingPayloadNew['withoutAppId'] = withoutAppId; 
              billingPayloadNew['deviceInfo'] = deviceInfo;
              billingPayloadNew['paymentStatus'] = "Unpaid";
              // billingPayloadNew['haId'] = payload.haId;
              // billingPayloadNew['haRefId'] = payload.haRefId;
              
              billingPayloadNew['isDraft'] = payload.isDraft;
              console.log("billingPayloadNew",billingPayloadNew);

              this.apimicroservice.updateBillingDetails(billingPayloadNew, this.billRefId).subscribe(res => {
                this.spinner.hide();
                if (res.status) {
                  // this.apimicroservice.openSnackbar("bill generated");
                  // if(payload.haRefId !== '' && payload.haRefId !== null && payload.haRefId !== undefined)
                  // {
                  //   this.apimicroservice.updateHADetails(haPayload,payload.haRefId).subscribe(res => {
                  //   });
                  // }
                  resolve();
                }
                this.dialogRef.close();
              })
  
        
          });
      })
     

    bar.then(() => {
      this.dialogRef.close({
        data: {
          patientRefId: this.patientRefId,
          appointId: this.appointId,
          // withoutAppId:withoutAppId,
          page: this.pagename
        }
      });

    });

}
  prepopulation(billNo) {
    this.dialogRef.close();
    //this.redirect('bill-form');
    this.router.navigate(['bill-form',billNo]);
  }

  topopupServiceForm() {
    document.getElementById("topopupServiceForm").scrollIntoView();
  }
  toBillingLst() {
    document.getElementById("toBillingLst").scrollIntoView();
  }

   
  fetchBillList() {
    let search='';
    this.apimicroservice.getBillingDataByPatientId(this.patientRefId,this.appointId).subscribe(res => {
      //console.log("get all billing res",res);  
      if (res.status) {    
        this.billingList = res.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  onPageChange(event) {
    this.page = event;
    let search='';
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getBillingDetailsByPatientId(this.page,this.size,search,this.patientRefId,this.appointId,branchRef).subscribe(res => {
      //console.log("get all billing res",res);  
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
    // if (this.userType !== 'Super Admin') {
    //   this.apimicroservice.getDoctorAvailablePaginationData(this.page,this.size,this.branchRef,this.clinicRef).subscribe(res => {
    //     if (res.status) {
    //       this.printData = res.data.data;
    //       this.totalLength=res.data.totalItems;
    //     } else {
    //       this.apimicroservice.openSnackbar("Something went wrong");
    //     }
  
    //   })
    // } 
  }

  printForm()
  {
    this.redirect('print-bill');
  } 
  redirect(to): void {
    this.router.navigate([to]);
   } 
   onClose(): void {
    this.dialogRef.close();
  }
  addNew(data,i) {
    //console.log("i",i);
    let discount=0;
    if(parseInt(data.discount) !== null)
    {
      discount=parseInt(data.discount); 
    }else{
      discount=0;
    }
    const creds = this.popupServiceForm.controls.serviceRateInfo as FormArray;
        creds.push(
        this.fb.group({
          "serviceRefId": new FormControl(data.serviceRefId),
          "addService": new FormControl(false),
          "serviceName": new FormControl(data.serviceName),
          "testType": new FormControl(data.testType),
          "rate": new FormControl(data.rate),
          "quantity": new FormControl(1,[Validators.pattern("^[0-9]*$"),Validators.minLength(1)]),
          "billingCompanyRefId": new FormControl(data.billingCompanyRefId),
          "billingCompanyName":new FormControl(data.billingCompanyName),
          "sgst":new FormControl(data.sgst),
        "cgst":new FormControl(data.cgst),
        "igst":new FormControl(data.igst),
        "hasSac":new FormControl(data.hasSac),
        "discount":new FormControl(0),
        toggle: false
  
  })
  );
  let serviceControl = (<FormArray>this.popupServiceForm.get("serviceRateInfo")).at(i);
  serviceControl.get('discount').disable();
  }

  addNewRow() {
    // //console.log("i",i);
    const creds = this.popupServiceForm.controls.serviceRateInfo as FormArray;
        creds.push(
        this.fb.group({
          "serviceRefId": new FormControl(),
          //"addService": new FormControl(false),
          "serviceName": new FormControl('',[Validators.required]),
          "testType": new FormControl(''),
          "rate": new FormControl(this.estimateAmount,[Validators.required,Validators.minLength(3)]),
          "quantity": new FormControl(1,[Validators.pattern("^[0-9]*$"),Validators.minLength(1)]),
          "billingCompanyRefId": new FormControl(),
          "billingCompanyName":new FormControl(''),
          "sgst":new FormControl(),
        "cgst":new FormControl(),
        "igst":new FormControl(),
        "hasSac":new FormControl(''),
        "discount": new FormControl(0,[Validators.pattern("^[0-9]*$"),Validators.minLength(2)]),  
        toggle: false
  
  })
  );
  // let serviceControl = (<FormArray>this.popupServiceForm.get("serviceRateInfo")).at(i);
  // serviceControl.get('discount').disable();
  let i = 0;
  let serviceControl = (<FormArray>this.popupServiceForm.get("serviceRateInfo")).at(i);
  this.serviceList.forEach(ele =>{
    if(ele.serviceName == 'repair' || ele.serviceName == 'Repair')
    {
    serviceControl.patchValue({
      serviceName: ele.serviceName

     });
     this.clickService(ele,i);
    }
  })
  
  }

  clickService(item,i){
    this.billingData = [];
    let serviceid = item._id;
   let testType = item.testType;
   let rate = item.rate;
   let billingCompanyId = item.billingCompanyRefId;
   let compname = item.billingCompanyName;
   let sgst = item.sgst;
   let cgst = item.cgst;
   let igst = item.igst;
   this.ServiceName = item.serviceName;

   let control = (<FormArray>this.popupServiceForm.get("serviceRateInfo")).at(i);
   //console.log("controlcontrolcontrol",control);
   
        control.patchValue({
          serviceRefId:serviceid,
          testType:testType,
          rate:this.estimateAmount,
          billingCompanyRefId:billingCompanyId,
          billingCompanyName:compname,
          sgst:sgst,
          cgst:cgst,
          igst:igst
      });

      this.billingData.push({"billingCompanyRefId":billingCompanyId,"billingCompanyName":compname});

      if(this.billingData.length > 0){
  
            const ids = this.billingData.map(o => o.billingCompanyRefId);
            this.billingInfo = this.billingData.filter(({billingCompanyRefId},index) => !ids.includes(billingCompanyRefId,index + 1));
            this.getLastIDBilling();
          }
          //console.log("this.billingInfo",this.billingInfo);
  }

  getLastIDBilling(){
    //console.log("hii");
  let billComp = '';
  let uniqNo:any;
    if(this.billingInfo.length > 0){
          
      this.billingInfo.forEach((element,index) => {
        if(element.billingCompanyRefId !== undefined)
        {
        this.apimicroservice.getLastBillID(element.billingCompanyRefId).subscribe(res => {
          //console.log("getLastId bill res",res);   
          // if(res['data']['data'] == '')
          // {
          //   this.inquiryForm.controls['branchWiseId'].setValue('1');
          // }
          if (res['status']) {
            billComp = element.billingCompanyName.substring(0,3);
            uniqNo = Math.floor(1000 + Math.random() * 9000);
            let billIdd = res['data']['totalItems'];
            this.billingInfo[index]['billingId'] = billIdd + 1;
            let id = billIdd + 1;
            this.billingInfo[index]['billNo'] = billComp + "-" + uniqNo + id;
            // this.inquiryForm.controls['branchWiseId'].setValue(billIdd + 1);
  
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
         
        })
      }
      });
    //console.log("billing info updated",this.billingInfo);
  } 
  }

  fetchServiceListForRepair(){
    let id = '';
    let clinicId;
    if(this.userType !== 'Super Admin'){
     clinicId = this.clinicRef;
    }else{
      clinicId = this.clinicRef;
    }
    //console.log("this.superClinicRefId",this.superClinicRefId);
    //console.log("clinicId",clinicId);
    this.apimicroservice.getServiceRateById(id,clinicId).subscribe(res => {
      //console.log("service data",res);
     let data = res.data[0];
     //this.serviceList = data.serviceRateInfo;
     data.serviceRateInfo.forEach(ele =>{
      if(ele.serviceName == 'repair' || ele.serviceName == 'Repair'){
        this.serviceList.push(ele);
      }
     });
     this.filteredListService = this.serviceList.slice();
     if(data !== undefined){
        this.addNewRow();
     }
    //  if(data !== undefined){
    //   data.serviceRateInfo.forEach((element,i) => {
        
    //     if(this.pagename == 'Repair'){
          
    //       if(element.serviceName == 'repair'){
    //         this.billingData.push({"billingCompanyRefId":element.billingCompanyRefId,"billingCompanyName":element.billingCompanyName});
    //         this.addNew(element,i)
    //       }
    //     }else{
    //       this.billingData.push({"billingCompanyRefId":element.billingCompanyRefId,"billingCompanyName":element.billingCompanyName});
    //     this.addNew(element,i)
    //     }
    //   });
    //   //console.log("this.billingData",this.billingData);
    //   if(this.billingData.length > 0){
  
    //     const ids = this.billingData.map(o => o.billingCompanyRefId);
    //     this.billingInfo = this.billingData.filter(({billingCompanyRefId},index) => !ids.includes(billingCompanyRefId,index + 1));
    //     this.getLastIDBilling();
    //   }
    //   //console.log("this.billingInfo",this.billingInfo);
    //  }
     //console.log("data",data);
    });
    
  }

  fetchServiceRateList(){
    let id = '';
    let clinicId;
    if(this.userType !== 'Super Admin'){
     clinicId = this.clinicRef;
    }else{
      clinicId = this.clinicRef;
    }
    //console.log("this.superClinicRefId",this.superClinicRefId);
    //console.log("clinicId",clinicId);
    this.apimicroservice.getServiceRateById(id,clinicId).subscribe(res => {
      //console.log("service data",res);
     let data = res.data[0];
     this.servicerateList = data.serviceRateInfo;
     if(data !== undefined){
      data.serviceRateInfo.forEach((element,i) => {
        
        // if(this.pagename == 'Repair'){
          
        //   if(element.serviceName == 'repair'){
        //     this.billingData.push({"billingCompanyRefId":element.billingCompanyRefId,"billingCompanyName":element.billingCompanyName});
        //     this.addNew(element,i)
        //   }
        // }else{
          this.billingData.push({"billingCompanyRefId":element.billingCompanyRefId,"billingCompanyName":element.billingCompanyName});
        this.addNew(element,i)
        //}
      });
      //console.log("this.billingData",this.billingData);
      if(this.billingData.length > 0){
  
        const ids = this.billingData.map(o => o.billingCompanyRefId);
        this.billingInfo = this.billingData.filter(({billingCompanyRefId},index) => !ids.includes(billingCompanyRefId,index + 1));
        this.getLastIDBilling();
      }
      //console.log("this.billingInfo",this.billingInfo);
     }
     //console.log("data",data);
    });
    
  }
  patienthistoryDates(){
this.billingData = [];
    this.apimicroservice.getPatientDateHistoryById(this.patientRefId,this.appointId).subscribe(res => {
      //console.log("ddd",res);
      let ss;
      if(res.data.length > 0){
        this.updateButton = true;
        this.submitButton = false;
        // res.data.forEach(element => {
  
        //   this.datearray.push(element);
         
        //   });
        this.datearray = res.data;
        
          this.popupServiceForm = this.fb.group({
            "complaints": new FormControl(this.datearray[0].complaints),
            "observations": new FormControl(this.datearray[0].observations),
            "diagnoses": new FormControl(this.datearray[0].diagnoses),
            "notes": new FormControl(this.datearray[0].notes),
            "followupReasons":new FormControl(this.datearray[0].followupReasons),
            "followupDate":new FormControl(this.datearray[0].followupDate),
           "serviceRateInfo": this.fb.array([]),
           "repairNo": new FormControl(),
           "repairDateOf" : new FormControl(),
           "repairDateto": new FormControl(),
           "patientRefId":new FormControl(this.paientdetails.patientRefId),
           "patientName":new FormControl(''),
           "branchRefId":new FormControl(this.datearray[0].branchRefId),
           "branchName":new FormControl(this.datearray[0].branchName),
           "appointId":new FormControl(this.paientdetails.appointId),
           "searchRepairId":new FormControl(),
          });
          this.cid = this.datearray[0]._id
          let preArr = [];
         // this.oldServiceRateInfo.push(this.datearray[0].serviceRateInfo);
          this.datearray[0].serviceRateInfo.forEach(element => {
            
                preArr.push(element);
                //console.log("element",element);    
               
                
                const tmpDict = {};
                if(element.addService !== true){
                tmpDict['serviceRefId'] = new FormControl(element.serviceRefId);
                tmpDict['addService'] = new FormControl(element.addService);
                tmpDict['serviceName'] = new FormControl(element.serviceName);
                tmpDict['testType'] = new FormControl(element.testType);
                tmpDict['billingCompanyRefId'] = new FormControl(element.billingCompanyRefId),
                tmpDict['billingCompanyName'] = new FormControl(element.billingCompanyName),
                tmpDict['rate'] = new FormControl(element.rate);
                tmpDict['discount'] = new FormControl(parseInt(element.discount),[Validators.pattern("^[0-9]*$"),Validators.minLength(2)]);
                tmpDict['quantity'] = new FormControl(element.quantity,[Validators.pattern("^[0-9]*$"),Validators.minLength(1)]);
                tmpDict['sgst'] = new FormControl(element.sgst);
                tmpDict['cgst'] = new FormControl(element.cgst);
                tmpDict['igst'] = new FormControl(element.igst);
                tmpDict['hasSac'] = new FormControl(element.hasSac);
                const creds = this.popupServiceForm.controls.serviceRateInfo as FormArray;
                 creds.push(new FormGroup(tmpDict));
                 //console.log("creds",creds);
                 this.updateArr.push(element);
                 this.billingData.push({"billingCompanyRefId":element.billingCompanyRefId,"billingCompanyName":element.billingCompanyName});   
                }else{
                  this.oldServiceRateInfo.push(element);
                }
                });
                //console.log("this.updateArr",this.updateArr);
                if(this.updateArr.length > 0){
                  this.updateButton = true;
                  this.submitButton = false;
                }else{
                  this.updateButton = false;
                  this.submitButton = false;
                }
                const ids = this.billingData.map(o => o.billingCompanyRefId);
                this.billingInfo = this.billingData.filter(({billingCompanyRefId},index) => !ids.includes(billingCompanyRefId,index + 1));
                this.getLastIDBilling();

                
      }else{
        this.fetchServiceRateList();
       
        this.updateButton = false;
        this.submitButton = true;
      }
    });
  }
  
  submitRepairBill(){
    let payload = this.popupServiceForm.value;
  //console.log("payload",payload);
  //console.log("this.pagename",this.pagename);
  this.totalPaidAmount = 0;
  this.totalAmountByCheque = 0;
  const creds = this.popupServiceForm.controls.serviceRateInfo as FormArray; 
  // if(this.pagename == 'Repair'){
  //   this.popupServiceForm.controls['serviceName'].setValidators(Validators.required);
  //   this.popupServiceForm.controls['serviceName'].updateValueAndValidity();
  // }

  if(creds.invalid && this.pagename == 'Repair') { 
    creds.controls.forEach(element =>{ 
      element.get('serviceName').markAsTouched();
      element.get('rate').markAsTouched();
    });  
   
   // this.popupServiceForm.get('serviceName').markAsTouched();
  }
  else{
    this.apimicroservice.getTempPaymentById(this.repairRef).subscribe(res => {
      //console.log("payment temp",res);
      if(res.data.data.length > 0){
        this.totalPaidAmount = res.data.amt;
        this.totalAmountByCheque = res.data.amtByCheque;
         this.tempPaymentList = res.data.data;
         this.tempPaymentList.forEach(ele =>{
            if(ele.mode == 'Cheque'){
              ele.chequeDetails.forEach(element =>{
                this.chequeList.push(element);
              });
              
            }
         });
         //console.log("this.chequeList",this.chequeList);
         //console.log("this.tempPaymentList",this.tempPaymentList);
      }

      this.addRepairBillDetails();
    });
  
  }

  // this.apimicroservice.getTempPaymentById(this.repairRef).subscribe(res => {
  //   //console.log("payment temp",res);
  //   if(res.data.data.length > 0){
  //     this.totalPaidAmount = res.data.amt;
  //      this.tempPaymentList = res.data.data;
  //      //console.log("this.tempPaymentList",this.tempPaymentList);
  //   }
  //   let selectservice = false;
  //   payload.serviceRateInfo.forEach(ele =>{
  //     if(ele.addService == true){
  //       selectservice = true;
  //     }
  //   });
  //   if(selectservice == false){
  //     this.openSnackbar("Please select service to generate bill!");
  //   }else{
  //    // this.addRepairBillDetails();
  //   }
  // });

  }

  addRepairBillDetails(){
    let payload = this.popupServiceForm.value;
  //console.log("billingInfo",this.billingInfo);
  //console.log("payload",payload);
  let  paymentPayload = {};
    let billingPayload = {};
    
    // let uniqNum = Math.floor(1000 + Math.random() * 9000);
    // let withoutAppId = "appId-" + uniqNum;
    //let billingPayload = this.paientdetails;
    let info = [];
    payload.clinicRefId = this.clinicRef;
    
    this.spinner.show();
    
      let uniqNum = Math.floor(1000 + Math.random() * 9000);
      let withoutAppId = "appId-" + uniqNum;
     // this.apimicroservice.addCheckupDetails(payload).subscribe(res => {
        //  this.spinner.hide();
        // //console.log("res checkup",res);
        // if(res.status){
        //   this.apimicroservice.openSnackbar(res.message);
        
          if(this.billingInfo.length > 0){
            
            let bar = new Promise<void>((resolve,reject) =>{  
              this.billingInfo.forEach(element => {
              billingPayload = {};
              info = [];
              let totalBill = 0;
              let totalBalance = 0;
              payload.serviceRateInfo.forEach(ele =>{
               if(element.billingCompanyRefId === ele.billingCompanyRefId){
                 let totalRate = 0;
                 let totalServiceAmount = 0;
                 let servicePaidAmount = 0;
                 totalRate = parseInt(ele.quantity) * parseInt(ele.rate);
                 let sgstval = 0;
                   let cgstval = 0;
                   let igstval = 0;
                   if(ele.sgst == undefined){
                    ele.sgst = null;
                   }
                   if(ele.cgst == undefined){
                    ele.cgst = null;
                   }
                   if(ele.igst == undefined){
                    ele.igst = null;
                   }
                   if(ele.sgst !== null && ele.sgst !== ''){
                    this.totalsgst = ele.sgst;
                     sgstval = (totalRate * parseInt(ele.sgst)) /100
                   }
                   if(ele.cgst !== null && ele.cgst !== ''){
                    this.totalcgst = ele.cgst;
                     cgstval = (totalRate * parseInt(ele.cgst)) /100
                   }
                   if(ele.igst !== null && ele.igst !== ''){
                    this.totaligst = ele.igst;
                     igstval = (totalRate * parseInt(ele.igst)) /100
                   }
                   if(ele.sgst !== null && ele.cgst !== null && ele.sgst !== '' && ele.cgst !== ''){
                    totalServiceAmount = totalRate + sgstval + cgstval;
                   }else if(ele.igst !== null && ele.igst !== '' ){
                    totalServiceAmount = totalRate + igstval;
                   }else{
                    totalServiceAmount = totalRate;
                  }
                   if(ele.discount !== '' && ele.discount !== null){
                   totalServiceAmount = totalServiceAmount - parseInt(ele.discount);
                   }
                   if(this.totalPaidAmount > 0){
                    servicePaidAmount = this.totalPaidAmount;
                   }
                   if(this.totalAmountByCheque > 0){
                    servicePaidAmount = servicePaidAmount + this.totalAmountByCheque;
                   }
                   
                 info.push({"serviceName":ele.serviceName,"testType":ele.testType,"rate":ele.rate,"discount":ele.discount,"quantity":ele.quantity,"status":"unpaid","totalRate":totalRate,"totalServiceAmount":totalServiceAmount,"servicePaidAmount":servicePaidAmount,"hasSac":ele.hasSac,"sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst});
                 let amt = 0;
            if((ele.discount !== '') && (ele.discount !== null)){
              //amt = parseInt(ele.rate) - parseInt(ele.discount);
              this.discount = ele.discount;
              amt = totalRate - parseInt(ele.discount);
              totalBill = totalBill + amt;
            }else{
             // totalBill = totalBill + parseInt(ele.rate);
             totalBill = totalBill + totalRate;
            }
            totalBalance = totalBalance + totalServiceAmount;
               }
              });
              if(info.length > 0){
                if(this.totalPaidAmount > 0){
                  totalBalance = totalBalance - this.totalPaidAmount;
                }
                //billingPayload = this.paientdetails;
                this.billNo = element.billNo;
                this.totalAmount = totalBill;
                this.balanceAmount = this.totalAmount;
                billingPayload["mobile"] = this.patientMobileNo;
                billingPayload["patientName"] = this.patientName;
                billingPayload["patientRefId"] = this.patientRefId;
                billingPayload["patientId"] = this.patientId;
                billingPayload["branchRefId"] = this.branchRef;
                billingPayload["branchName"] = this.branchName;
                billingPayload['billingCompanyRefId'] = element.billingCompanyRefId;
                billingPayload['billingCompanyName'] = element.billingCompanyName;
                billingPayload['billingId'] = element.billingId;
                billingPayload['billNo'] = element.billNo;
                billingPayload['totalsgst'] = this.totalsgst;
                billingPayload['totalcgst'] = this.totalcgst;
                billingPayload['totaligst'] = this.totaligst;
                billingPayload['totalDiscount'] = this.discount; 
                billingPayload['totalAmount'] = totalBill;
                billingPayload['balanceAmount'] = totalBalance;
                billingPayload['paidAmount'] = this.totalPaidAmount;
                billingPayload['pendingChequeAmount'] = this.totalAmountByCheque;
                billingPayload['bounceAmount'] = this.bounceAmount;
                billingPayload['serviceInfo'] = info;
                billingPayload['withoutAppId'] = withoutAppId;
                billingPayload['repairRefId'] = this.repairRef;
                if((this.totalPaidAmount == totalBill) || (this.totalPaidAmount > totalBill)){
                  billingPayload['paymentStatus'] = "Paid";
                }else{
                billingPayload['paymentStatus'] = "Unpaid";
                }
                //console.log("billingPayload",billingPayload);
                if(this.tempPaymentList.length > 0){
                  paymentPayload["serviceInfo"] = info;
                  paymentPayload['billingCompanyRefId'] = element.billingCompanyRefId;
                  paymentPayload['billingCompanyName'] = element.billingCompanyName;
                  paymentPayload['billNo'] = element.billNo;
                  paymentPayload["mobile"] = this.patientMobileNo;
                  paymentPayload["patientName"] = this.patientName;
                  paymentPayload["patientRefId"] = this.patientRefId;
                  paymentPayload["branchRefId"] = this.branchRef;
                  paymentPayload["branchName"] = this.branchName;
                  paymentPayload['totalsgst'] = this.totalsgst;
                  paymentPayload['totalcgst'] = this.totalcgst;
                  paymentPayload['totaligst'] = this.totaligst;
                  paymentPayload['patientId'] = this.patientId;
                }
                this.apimicroservice.addBillingDetails(billingPayload).subscribe(res => {
                  if(res.status){
                    this.apimicroservice.openSnackbar("bill generated");
                   // this.ngOnInit();
                   resolve();
                  }
                });
              }
              
            });
          });
            // //console.log("billingPayload",billingPayload);
            bar.then(() => {
              this.spinner.hide();
              if(this.tempPaymentList.length > 0){
                this.pendingChequeAmount = 0;
                let paybar = new Promise<void>((resolve,reject) =>{
                this.tempPaymentList.forEach(ele =>{
                  let receiptNo = Math.floor(10000 + Math.random() * 90000);
                  //console.log("receiptNo",receiptNo);
                  // this.balanceAmount = this.balanceAmount - ele.advanceAmount;
                  
                  if(ele.mode !== 'Cheque'){
                  paymentPayload['paidAmount'] = ele.advanceAmount;
                  this.balanceAmount = this.balanceAmount - ele.advanceAmount;
                  }else{
                   paymentPayload['paidAmount'] = 0;
                    //this.pendingChequeAmount = this.pendingChequeAmount + parseInt(ele.advanceAmount);
                    this.balanceAmount = this.balanceAmount;
                  }
                  paymentPayload['paymentDate'] = this.currentdate;
                  paymentPayload['modeOfPayment'] = ele.mode;
                  paymentPayload['repairRefId'] = this.repairRef;
                  paymentPayload['totalAmount'] = this.totalAmount;
                  paymentPayload['balanceAmount'] = this.balanceAmount;
                  paymentPayload['pendingChequeAmount'] = this.totalAmountByCheque;
                  paymentPayload['bounceAmount'] = this.bounceAmount;
                  //paymentPayload['gst'] = this.gst;
                  paymentPayload['totalDiscount'] = this.discount;
                  if(ele.mode == 'Cheque'){
                    paymentPayload['chequeDetails'] = ele.chequeDetails;
                    paymentPayload['chequePaymentStatus'] = "postdated";
                    paymentPayload['paymentStatus'] = "Unpaid";
                  }else{
                    paymentPayload['chequeDetails'] = [];
                    paymentPayload['chequePaymentStatus'] = "";
                    paymentPayload['paymentStatus'] = "Paid";
                  }
                  //console.log("paymentPayload",paymentPayload);
                  paymentPayload['receiptNo'] = receiptNo;
                  paymentPayload['advanceStatus'] = 'No';
                  this.apimicroservice.addPaymentHistoryDetails(paymentPayload).subscribe(res => {
                    if(res.status){
                     // this.apimicroservice.openSnackbar("payment done");
                      resolve();
                    } 
                  });
                })
              });
              paybar.then(() => {
                // this.ngOnInit();
                this.dialogRef.close({data:{
                  patientRefId:this.patientRefId,
                  repairRefId:this.repairRef,
                  billNo:this.billNo,
                  ServiceName:this.ServiceName
                }});
               });
              }else{
              this.dialogRef.close({data:{
                patientRefId:this.patientRefId,
                repairRefId:this.repairRef,
                billNo:this.billNo,
                ServiceName:this.ServiceName
                
              }});
            }
            });
            
          }
          
        // }else{
        //   //this.apimicroservice.openSnackbar(res.message);
        // }
     // });  
    
  }

submitCheckupDetails() {
  let payload = this.popupServiceForm.value;
  //console.log("payload",payload);
  //console.log("this.pagename",this.pagename);
   
  // if(this.pagename !== 'Billing Page'){
  //   payload.branchRefId = this.bId;
  // }
  if(this.pagename == 'Billing Page'){
    this.popupServiceForm.controls['patientName'].setValidators(Validators.required);
    this.popupServiceForm.controls['patientName'].updateValueAndValidity();
  if(this.userType == 'Super Admin')
    {
      this.popupServiceForm.controls['branchRefId'].setValidators(Validators.required);
      this.popupServiceForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.popupServiceForm.controls['branchRefId'].setErrors(null);
      this.popupServiceForm.controls['branchRefId'].clearValidators();
      this.popupServiceForm.controls['branchRefId'].updateValueAndValidity();
    }
  }
  if(this.popupServiceForm.invalid && this.pagename == 'Billing Page') {   
    this.popupServiceForm.get('branchRefId').markAsTouched();
    this.popupServiceForm.get('patientName').markAsTouched();
  }
  else{
    let payload = this.popupServiceForm.value;
    let selectservice = false;
    payload.serviceRateInfo.forEach(ele =>{
      if(ele.addService == true){
        selectservice = true;
      }
    });
    if(selectservice == false){
      this.openSnackbar("Please select atleast one service to generate bill!");
    }else{
      this.addCheckupDetails();
    }
    
  }
}

addCheckupDetails(){
  let payload = this.popupServiceForm.value;
  //console.log("billingInfo",this.billingInfo);
  let billingPayload = {};
  let appointPayload ={};
  // let uniqNum = Math.floor(1000 + Math.random() * 9000);
  // let withoutAppId = "appId-" + uniqNum;
  appointPayload['aptStatus'] = 'Completed';
  //let billingPayload = this.paientdetails;
  let info = [];
  payload.clinicRefId = this.clinicRef;
  payload.branchRefId = this.branchRef;
  if(this.pagename !== 'Billing Page'){
    payload.branchRefId = this.bId;
  }
  //console.log("oppppppppp",payload);
  
  this.spinner.show();
  if(this.pagename !== 'Billing Page'){
  this.apimicroservice.addCheckupDetails(payload).subscribe(res => {
    this.spinner.hide();
    //console.log("res checkup",res);
    if(res.status){
      this.apimicroservice.openSnackbar(res.message);
      this.apimicroservice.updateAppointment(this.paientdetails.id,appointPayload).subscribe(res => {
        if(res.status){
      if(this.billingInfo.length > 0){
        
        this.billingInfo.forEach(element => {
          billingPayload = {};
          info = [];
          let totalBill = 0;
          let totalBalance = 0;
          payload.serviceRateInfo.forEach(ele =>{
           if(element.billingCompanyRefId === ele.billingCompanyRefId && ele.addService === true){
             let totalRate = 0;
             let totalServiceAmount = 0;
             totalRate = parseInt(ele.quantity) * parseInt(ele.rate);
             let sgstval = 0;
                 let cgstval = 0;
                 let igstval = 0;
                 if(ele.sgst !== null){
                   sgstval = (totalRate * parseInt(ele.sgst)) /100
                 }
                 if(ele.cgst !== null){
                   cgstval = (totalRate * parseInt(ele.cgst)) /100
                 }
                 if(ele.igst !== null){
                   igstval = (totalRate * parseInt(ele.igst)) /100
                 }
                 if(ele.sgst !== null && ele.cgst !== null){
                  totalServiceAmount = totalRate + sgstval + cgstval;
                 }else if(ele.igst !== null){
                  totalServiceAmount = totalRate + igstval;
                 }else{
                  totalServiceAmount = totalRate;
                }
                 if(ele.discount !== ''){
                 totalServiceAmount = totalServiceAmount - parseInt(ele.discount);
                 }
             info.push({"serviceName":ele.serviceName,"testType":ele.testType,"rate":ele.rate,"discount":ele.discount,"quantity":ele.quantity,"status":"unpaid","totalRate":totalRate,"totalServiceAmount":totalServiceAmount,"hasSac":ele.hasSac,"sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst});
             let amt = 0;
        if(ele.discount !== ''){
          //amt = parseInt(ele.rate) - parseInt(ele.discount);
          amt = totalRate - parseInt(ele.discount);
          totalBill = totalBill + amt;
        }else{
         // totalBill = totalBill + parseInt(ele.rate);
         totalBill = totalBill + totalRate;
        }
        totalBalance = totalBalance + totalServiceAmount;
           }
          });
          if(info.length > 0){
            billingPayload = this.paientdetails;
            billingPayload["mobile"] = this.paientdetails.Mob;
            billingPayload['billingCompanyRefId'] = element.billingCompanyRefId;
            billingPayload['billingCompanyName'] = element.billingCompanyName;
            billingPayload['billingId'] = element.billingId;
            billingPayload['billNo'] = element.billNo;
            billingPayload['totalAmount'] = totalBill;
            billingPayload['balanceAmount'] = totalBalance;
            billingPayload['serviceInfo'] = info;
            billingPayload['paymentStatus'] = "Unpaid";
            //console.log("billingPayload",billingPayload);
            this.apimicroservice.addBillingDetails(billingPayload).subscribe(res => {
              if(res.status){
                this.apimicroservice.openSnackbar("bill generated");
               // this.ngOnInit();
              }
            });
          }
          
        });
        // //console.log("billingPayload",billingPayload);
        this.dialogRef.close({data:{
          patientRefId:this.patientRefId,
          appointId:this.appointId
        }});
      }
      
    }
  });
    }else{
      //this.apimicroservice.openSnackbar(res.message);
    }
  });  
  }
  else{
    let uniqNum = Math.floor(1000 + Math.random() * 9000);
    let withoutAppId = "appId-" + uniqNum;
   // this.apimicroservice.addCheckupDetails(payload).subscribe(res => {
      //  this.spinner.hide();
      // //console.log("res checkup",res);
      // if(res.status){
      //   this.apimicroservice.openSnackbar(res.message);
      
        if(this.billingInfo.length > 0){
          
          let bar = new Promise<void>((resolve,reject) =>{  
            this.billingInfo.forEach(element => {
            billingPayload = {};
            info = [];
            let totalBill = 0;
            let totalBalance = 0;
            payload.serviceRateInfo.forEach(ele =>{
             if(element.billingCompanyRefId === ele.billingCompanyRefId && ele.addService === true){
               let totalRate = 0;
               let totalServiceAmount = 0;
               totalRate = parseInt(ele.quantity) * parseInt(ele.rate);
               let sgstval = 0;
                 let cgstval = 0;
                 let igstval = 0;
                 if(ele.sgst !== null){
                   sgstval = (totalRate * parseInt(ele.sgst)) /100
                 }
                 if(ele.cgst !== null){
                   cgstval = (totalRate * parseInt(ele.cgst)) /100
                 }
                 if(ele.igst !== null){
                   igstval = (totalRate * parseInt(ele.igst)) /100
                 }
                 if(ele.sgst !== null && ele.cgst !== null){
                  totalServiceAmount = totalRate + sgstval + cgstval;
                 }else if(ele.igst !== null){
                  totalServiceAmount = totalRate + igstval;
                 }else{
                  totalServiceAmount = totalRate;
                }
                 if(ele.discount !== ''){
                 totalServiceAmount = totalServiceAmount - parseInt(ele.discount);
                 }
               info.push({"serviceName":ele.serviceName,"testType":ele.testType,"rate":ele.rate,"discount":ele.discount,"quantity":ele.quantity,"status":"unpaid","totalRate":totalRate,"totalServiceAmount":totalServiceAmount,"hasSac":ele.hasSac,"sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst});
               let amt = 0;
          if(ele.discount !== ''){
            //amt = parseInt(ele.rate) - parseInt(ele.discount);
            amt = totalRate - parseInt(ele.discount);
            totalBill = totalBill + amt;
          }else{
           // totalBill = totalBill + parseInt(ele.rate);
           totalBill = totalBill + totalRate;
          }
          totalBalance = totalBalance + totalServiceAmount;
             }
            });
            if(info.length > 0){
              //billingPayload = this.paientdetails;
              billingPayload["mobile"] = this.paientdetails.mobile1;
              billingPayload["patientName"] = this.paientdetails.firstName + " " +  this.paientdetails.lastName;
              billingPayload["patientRefId"] = this.paientdetails._id;
              billingPayload["patientId"] = this.paientdetails.patientId;
              billingPayload["branchRefId"] = this.branchRef;
              billingPayload["branchName"] = this.branchName;
              billingPayload['billingCompanyRefId'] = element.billingCompanyRefId;
              billingPayload['billingCompanyName'] = element.billingCompanyName;
              billingPayload['billingId'] = element.billingId;
              billingPayload['billNo'] = element.billNo;
              billingPayload['totalAmount'] = totalBill;
              billingPayload['balanceAmount'] = totalBalance;
              billingPayload['serviceInfo'] = info;
              billingPayload['withoutAppId'] = withoutAppId;
              billingPayload['paymentStatus'] = "Unpaid";
              //console.log("billingPayload",billingPayload);
              this.apimicroservice.addBillingDetails(billingPayload).subscribe(res => {
                if(res.status){
                  this.apimicroservice.openSnackbar("bill generated");
                 // this.ngOnInit();
                 resolve();
                }
              });
            }
            
          });
        });
          // //console.log("billingPayload",billingPayload);
          bar.then(() => {
            this.spinner.hide();
            this.dialogRef.close({data:{
              patientRefId:this.patientRefId,
              appointId:this.appointId,
              withoutAppId:withoutAppId
            }});
          });
          
        }
        
      // }else{
      //   //this.apimicroservice.openSnackbar(res.message);
      // }
   // });  
  }
}

updateCheckupDetails() {
  let payload = this.popupServiceForm.value;
  let newArr = this.popupServiceForm.get('serviceRateInfo').value;
  
  let serArr = [];
   //console.log("this.pagename",this.pagename);
   
  // if(this.pagename !== 'Billing Page'){
  //   payload.branchRefId = this.bId;
  // }
  //console.log("payload",payload); 
  if(this.pagename == 'Billing Page'){
    this.popupServiceForm.controls['patientName'].setValidators(Validators.required);
    this.popupServiceForm.controls['patientName'].updateValueAndValidity();
  if(this.userType == 'Super Admin')
    {
      this.popupServiceForm.controls['branchRefId'].setValidators(Validators.required);
      this.popupServiceForm.controls['branchRefId'].updateValueAndValidity();
    }
    else{
      this.popupServiceForm.controls['branchRefId'].setErrors(null);
      this.popupServiceForm.controls['branchRefId'].clearValidators();
      this.popupServiceForm.controls['branchRefId'].updateValueAndValidity();
    }
  }
  if(this.popupServiceForm.invalid && this.pagename == 'Billing Page') {   
    this.popupServiceForm.get('branchRefId').markAsTouched();
    this.popupServiceForm.get('patientName').markAsTouched();  
  }
  else{
    this.updateCheckup();
  }
}

updateCheckup(){
  this.spinner.show();
    let payload = this.popupServiceForm.value;
    //console.log("payload",payload);
    // if(this.pagename !== 'Billing Page'){
    //   payload.branchRefId = this.bId;
    // }
    //console.log("payload",payload);
  let updateStatus = false;
  let billingPayload = {};
  let billingPayloadNew = {};
  let servicePayload = {};
  let dataInfo = payload.serviceRateInfo;
  let serviceInfo2 = payload.serviceRateInfo;
  serviceInfo2.forEach(ele =>{
    this.oldServiceRateInfo.push(ele);
  });
  //console.log("serviceInfo",serviceInfo2);
  //console.log("dataInfo",dataInfo);
  //console.log("dataInfo",this.oldServiceRateInfo);
  servicePayload['serviceRateInfo'] = this.oldServiceRateInfo; 
  //let billingPayload = this.paientdetails;
  let info = []; 
  let tempInfo = [];
    
     this.apimicroservice.updateCheckupDetails(this.cid,servicePayload).subscribe(res => {
       //console.log("Update res :",res);
       this.spinner.hide();
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
        
         this.billingList.forEach(element =>{
          billingPayload = {};
          info = [];
          let totalBill = 0;
          let totalBalance = 0;
          let totalsgstAmt = 0;
          let totalcgstAmt = 0;
          let totaligstAmt = 0;
          dataInfo.forEach(ele => {
            if(element.billingCompanyRefId === ele.billingCompanyRefId && ele.addService === true){
              let totalRate = 0;
              let totalServiceAmount = 0;
              let sgstAmt = 0;
              let cgstAmt = 0;
              let igstAmt = 0;

              let sgstval = 0;
              let cgstval = 0;
              let igstval = 0;

              totalRate = parseInt(ele.quantity) * parseInt(ele.rate);

              if(ele.sgst !== null){
                sgstval = (totalRate * parseInt(ele.sgst)) /100
              }
              if(ele.cgst !== null){
                cgstval = (totalRate * parseInt(ele.cgst)) /100
              }
              if(ele.igst !== null){
                igstval = (totalRate * parseInt(ele.igst)) /100
              }
              if(ele.sgst !== null && ele.cgst !== null){
               totalServiceAmount = totalRate + sgstval + cgstval;
              }else if(ele.igst !== null){
               totalServiceAmount = totalRate + igstval;
              }else{
                totalServiceAmount = totalRate;
              }
              if(ele.discount !== '' && ele.discount !== null){
              totalServiceAmount = totalServiceAmount - parseInt(ele.discount);
              }

              info.push({"serviceName":ele.serviceName,"testType":ele.testType,"rate":ele.rate,"discount":ele.discount,"quantity":ele.quantity,"status":"unpaid","totalRate":totalRate,"totalServiceAmount":totalServiceAmount,"hasSac":ele.hasSac,"sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst});
              let amt = 0;
         if(ele.discount !== '' && ele.discount !== null){
           //amt = parseInt(ele.rate) - parseInt(ele.discount);
           amt = totalRate - parseInt(ele.discount);
           if(ele.sgst !== null){
            
            sgstAmt = (totalRate * ele.sgst) / 100;
            totalsgstAmt = totalsgstAmt + sgstAmt;
             ////console.log("this.totalsgst",this.totalsgstAmt);
           }
           if(ele.cgst !== null){
             
             cgstAmt = (totalRate * ele.cgst) / 100;
            totalcgstAmt = totalcgstAmt + cgstAmt;
             ////console.log("this.totalcgst",this.totalcgstAmt);
           }
           if(ele.igst !== null){
            
            igstAmt = (totalRate * ele.igst) / 100;
            totaligstAmt = totaligstAmt + igstAmt;
            // //console.log("this.totaligst",this.totaligstAmt);
           }
           totalBalance = totalBill + amt + totalsgstAmt + totalcgstAmt;
           totalBill = totalBill + amt;
           
         }else{
          // totalBill = totalBill + parseInt(ele.rate);
          if(ele.sgst !== null){
            
           sgstAmt = (totalRate * ele.sgst) / 100;
           totalsgstAmt = totalsgstAmt + sgstAmt;
            ////console.log("this.totalsgst",this.totalsgstAmt);
          }
          if(ele.cgst !== null){
            
            cgstAmt = (totalRate * ele.cgst) / 100;
           totalcgstAmt = totalcgstAmt + cgstAmt;
            ////console.log("this.totalcgst",this.totalcgstAmt);
          }
          if(ele.igst !== null){
           
           igstAmt = (totalRate * ele.igst) / 100;
           totaligstAmt = totaligstAmt + igstAmt;
           // //console.log("this.totaligst",this.totaligstAmt);
          }
          totalBalance = totalBill + totalRate + totalsgstAmt + totalcgstAmt;
          totalBill = totalBill + totalRate;
          
         }
                if(tempInfo.length > 0){
                 //const found = tempInfo.some(o => o.billingCompanyRefId === element.billingCompanyRefId);
               let billArr = tempInfo.filter(o => o.billingCompanyRefId !== element.billingCompanyRefId);
               tempInfo = billArr;
               //console.log("tempInfo after removing",tempInfo);
                }
            }else if(element.billingCompanyRefId !== ele.billingCompanyRefId && ele.addService === true) {
              tempInfo.push(ele);
              //console.log("tempInfo after pushing",tempInfo);
            }   
        });
    
        if(info.length > 0){
                // billingPayload = this.paientdetails;
                // billingPayload['billingCompanyRefId'] = element.billingCompanyRefId;
                // billingPayload['billingCompanyName'] = element.billingCompanyName;
                // billingPayload['billingId'] = element.billingId;
                // billingPayload['billNo'] = element.billNo;
                if(element.totalAmount !== null){
                  totalBill = totalBill + element.totalAmount;
                }
                //console.log(totalBill);
                //console.log(element.totalAmount);
                if(element.balanceAmount !== undefined){
                  totalBalance = totalBalance + element.balanceAmount;
                  billingPayload['balanceAmount'] = totalBalance;
                }
                billingPayload['totalAmount'] = totalBill;
                element.serviceInfo.map(ele =>{
                  info.push(ele);
                });
                
                billingPayload['serviceInfo'] = info;
                billingPayload['paymentStatus'] = "Unpaid";
                //console.log("billingPayload",billingPayload);
                this.tempServicArr.push(element);
                
                //console.log("tempServicArr",this.tempServicArr);
                this.apimicroservice.updateBillingDetails(billingPayload,element._id).subscribe(res => {
                  if(res.status){
                    this.apimicroservice.openSnackbar("bill updated");
                   // this.ngOnInit();
                  }
                });
          }
    
      });
    
      //console.log("tempInfo",tempInfo);
      if(this.tempServicArr.length > 0){
        this.tempServicArr.forEach(ele =>{
          let billArr = tempInfo.filter(o => o.billingCompanyRefId !== ele.billingCompanyRefId);
          tempInfo = billArr;
        //console.log("tempInfo if same billcomp",tempInfo);
      });
      }
      if(tempInfo.length > 0){
        if(this.billingInfo.length > 0){
            
          this.billingInfo.forEach(element => {
            billingPayloadNew = {};
            info = [];
            let totalBill = 0;
            let totalBalance = 0;
            tempInfo.forEach(ele =>{
             if(element.billingCompanyRefId === ele.billingCompanyRefId && ele.addService === true){
              let totalRate = 0;
              let sgstval = 0;
              let cgstval = 0;
              let igstval = 0;
              let totalServiceAmount = 0;

              totalRate = parseInt(ele.quantity) * parseInt(ele.rate);
                 
                 if(ele.sgst !== null){
                  sgstval = (totalRate * parseInt(ele.sgst)) /100
                }
                if(ele.cgst !== null){
                  cgstval = (totalRate * parseInt(ele.cgst)) /100
                }
                if(ele.igst !== null){
                  igstval = (totalRate * parseInt(ele.igst)) /100
                }
                if(ele.sgst !== null && ele.cgst !== null){
                 totalServiceAmount = totalRate + sgstval + cgstval;
                }else if(ele.igst !== null){
                 totalServiceAmount = totalRate + igstval;
                }else{
                  totalServiceAmount = totalRate;
                }
                if(ele.discount !== ''){
                totalServiceAmount = totalServiceAmount - parseInt(ele.discount);
                }

                 info.push({"serviceName":ele.serviceName,"testType":ele.testType,"rate":ele.rate,"discount":ele.discount,"quantity":ele.quantity,"status":"unpaid","totalRate":totalRate,"totalServiceAmount":totalServiceAmount,"hasSac":ele.hasSac,"sgst":ele.sgst,"cgst":ele.cgst,"igst":ele.igst});
                 let amt = 0;
            if(ele.discount !== null){
              //amt = parseInt(ele.rate) - parseInt(ele.discount);
              amt = totalRate - parseInt(ele.discount);
              totalBill = totalBill + amt;
            }else{
             // totalBill = totalBill + parseInt(ele.rate);
             totalBill = totalBill + totalRate;
            }
            totalBalance = totalBalance + totalServiceAmount;
             }
            });
            if(info.length > 0){
              billingPayloadNew = this.paientdetails;
              //console.log("billingPayloadNew",billingPayloadNew);
              billingPayloadNew["mobile"] = this.paientdetails.Mob;
              billingPayloadNew['billingCompanyRefId'] = element.billingCompanyRefId;
              billingPayloadNew['billingCompanyName'] = element.billingCompanyName;
              billingPayloadNew['billingId'] = element.billingId;
              billingPayloadNew['billNo'] = element.billNo;
              billingPayloadNew['totalAmount'] = totalBill;
              billingPayloadNew['balanceAmount'] = totalBalance;
              billingPayloadNew['serviceInfo'] = info;
              billingPayloadNew['paymentStatus'] = "Unpaid";
              //console.log("billingPayloadNew",billingPayloadNew);
              this.apimicroservice.addBillingDetails(billingPayloadNew).subscribe(res => {
                if(res.status){
                  this.apimicroservice.openSnackbar("bill generated");
                 // this.ngOnInit();

                }
              });
            }
            
          });
          // //console.log("billingPayload",billingPayload);
        }
      }
        this.dialogRef.close({data:{
          patientRefId:this.patientRefId,
          appointId:this.appointId
        }});
       }else{
       //  //this.apimicroservice.openSnackbar(res.message);
       }
     });
}
  
selectService(event:any,i){
  //console.log("event",event.checked);
  let serviceControl = (<FormArray>this.popupServiceForm.get("serviceRateInfo")).at(i);
  
    let serviceVal = serviceControl.get("addService").value;
    
  if(serviceVal == true){
    serviceControl.get('discount').enable();
    
  }else{
    serviceControl.get('discount').disable();
    
  }
  
}

openSnackbar(message: string) {
  return this.snackBar.open(message, "close",
    { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 3800, panelClass: ['snackbarStyle'] });
}

onSelectDiscount(event,i){
  let dis = event.target.value;
  let serviceControl = (<FormArray>this.popupServiceForm.get("serviceRateInfo")).at(i);
  let rateVal = serviceControl.get("rate").value;

  if(parseInt(dis) > parseInt(rateVal)){
   this.apimicroservice.openSnackbar("Please enter discount value less than or equal to rate value");
   serviceControl.patchValue({
    discount: '',
   });
  }
}

onSelectPatient(item){
  //this.patientRefId = item.patientRefId;
  //console.log("item",item);
  this.patientRefId = item._id;
  this.appointId = '';
  this.paientdetails = item;
  this.submitButton = true;
  this.updateButton = false;
  this.servicerateList = [];
  this.billingData = [];
  this.billingInfo = [];
  //this.popupServiceForm.controls['serviceRateInfo'].setValue([]);
  (<FormArray>this.popupServiceForm.get("serviceRateInfo")).clear();
  this.fetchServiceRateList();
  
  // this.fetchBillList();
  // this.patienthistoryDates();
}

onSelectPatientByRepair(){
  //this.patientRefId = item.patientRefId;
  ////console.log("item",item);
  this.patientRefId = this.patientRefId;
  this.appointId = '';
 // this.paientdetails = item;
  this.submitButton = true;
  this.updateButton = false;
  this.serviceList = [];
  this.billingData = [];
  this.billingInfo = [];
  //this.popupServiceForm.controls['serviceRateInfo'].setValue([]);
  (<FormArray>this.popupServiceForm.get("serviceRateInfo")).clear();
 // this.fetchServiceRateList();
 this.fetchServiceListForRepair();
}

cancelUpdate(){
  this.dialogRef.close({data:{
    patientRefId:this.patientRefId,
    appointId:this.appointId
  }});
}

openBranchPopUp() {
  localStorage.clear();
  const dialogRef3 = this.dialog.open(PopUpBranchComponent,
    {
      width: "1200px",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchBranchList();  
     });
}

openPatientSearchPopUp() {
  if(this.way !== 2)
  {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%", 
    });
  
    dialogRef3.afterClosed().subscribe((result) => {
      this.popupServiceForm.get("patientName").setValue(result.data.firstName + " " + result.data.lastName);
      this.popupServiceForm.get("patientRefId").setValue(result.data._id);
      this.onSelectPatient(
        result.data 
      );
    });
  }
 
}

}