import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpCityComponent } from "../pop-up-city/pop-up-city.component";
import { PopUpItemComponent } from "../pop-up-item/pop-up-item.component";
import { PopUpCourierserviceComponent } from "../pop-up-courierservice/pop-up-courierservice.component";
import { PopupTotalBillComponent } from "../popup-total-bill/popup-total-bill.component";
@Component({
  selector: 'app-cheque-alert',
  templateUrl: './cheque-alert.component.html',
  styleUrls: ['./cheque-alert.component.scss']
})
export class ChequeAlertComponent implements OnInit {
  AlertForm: FormGroup;
  alertPayloadForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=10;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  chequeType:string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  billingList: any;
  BounceNotifyList: any;
  ChequeNotifyList: any;
  alertDataList: any;

  allAlertType=[
    {name:'Received',value:'101'}, 
   // {name:'Received Post-dated',value:'102'},
    { name:'Dishonoured ',value:'2' },
    {name:'Deposited',value:'103'},
    {name:'Cheque Cleared',value:'104'},
    {name:'Cancelled',value:'105'},
    {name:'Returned',value:'106'},
    {name:'Clinic Holiday',value:'21'},   
    {name:'Audiologist Leave',value:'22'},    
    {name:'Stock Dispatch',value:'11'},
    { name:'Stock Rejected',value:'12' },
    { name:'Stock Accepted',value:'13' },
  ]
  filteredAllAlertList: { name: string; value: string; }[];
  did: any;
  receiptNo: any;
  isStrock: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService 
  ) { }
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;
  chequeAlertCreate= true;
  chequeAlertUpdate= true;
  printData=[
    {
      srNo:1,
      date:'10/03/2020',
      aptId:101,
      patientName:'Shivani Bhosale',
      mobile:9088776653,
      status:'Paid'
     },
     {
      srNo:2,
      date:'10/03/2020',
      aptId:102,
      patientName:'Sai Mane',
      mobile:9276321653,
      status:'Unpaid'
     },
     {
      srNo:3,
      date:'10/03/2020',
      aptId:103,
      patientName:'Rahul Thakur',
      mobile:8932776653,
      status:'Unpaid'
     },
     {
      srNo:4,
      date:'10/03/2020',
      aptId:104,
      patientName:'Aishwarya Shaha',
      mobile:8877776653,
      status:'Paid'
     },                 
  ]
 // colorA = '#99999a'
  // colorA = '#EEEEF3'
  // colorB = ''; 
  ngOnInit(): void {
    this.currentdate = new Date();
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Cheque Alert' && element.create == false)
      {
          this.chequeAlertCreate = false;  
      }
      if(element.pageName == 'Cheque Alert' && element.update == false)
      {
          this.chequeAlertUpdate = false;  
      }  
     
    });
  } 
  // this.getChequeNotify();
  // this.getBounceNotifyData();
 
    this.AlertForm = this.fb.group({
    //  "createdAt": new FormControl(''),
      "alertType": new FormControl(''),
      "fdate": new FormControl(''),
      "todate": new FormControl(''), 
      "alertView": new FormControl(''), 
      "searchBilling": new FormControl(''), 
      
    });
    this.filteredAllAlertList = this.allAlertType.slice();
 // this.removeValidator();
 this.fetchAlertList();
}


 

  searchChequeData(){
  
    this.billingList = [];
    this.searchText = this.AlertForm.get('searchBilling').value;
    let payload = this.AlertForm.value;
    let fromDate = this.AlertForm.get('fdate').value;
    let toDate = this.AlertForm.get('todate').value;
    //
    //
    //
    
    // /page=1&size=10&search=&billNo=&chequeNo=&chequeStatus=&fromDate=&toDate=
    this.apimicroservice.getAlertData(this.page,this.size,this.searchText,payload.alertType,payload.fdate,payload.todate,this.branchRef).subscribe(res => {
      //  
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  toAlertForm() {
    document.getElementById("toAlertForm").scrollIntoView();
  }
 

  onPageChange(event) {
    this.page = event;
    let payload = this.AlertForm.value;
  
    this.searchText = this.AlertForm.get('searchBilling').value;
    let alertType='';
    let fdate='';
    let todate='';
    let branchRef ='';
    if(this.userType !== 'Super Admin')
    { 
    this.apimicroservice.getAlertData(this.page,this.size,this.searchText,payload.alertType,payload.fdate,payload.todate,this.branchRef).subscribe(res => {
      //  
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
    else
    {
      this.apimicroservice.getAlertData(this.page,this.size,this.searchText,payload.alertType,payload.fdate,payload.todate,branchRef).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          //
          this.billingList = res.data.data;
          this.totalLength = res.data.totalItems;
        
        //  this.apimicroservice.openSnackbar(res.message);
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      }); 
    }
  }



  onKeyUpEvent(event: any) {
    let payload = this.AlertForm.value;
    this.searchText = this.AlertForm.get('searchBilling').value;
    let alertType='';
    let fdate='';
   let todate='';
   let branchRef = '';
   if(this.userType !== 'Super Admin')
   { 
    this.apimicroservice.getAlertData(this.page,this.size,this.searchText,payload.alertType,payload.fdate,payload.todate,this.branchRef).subscribe(res => {
      //  
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
    else
    {
      this.apimicroservice.getAlertData(this.page,this.size,this.searchText,payload.alertType,payload.fdate,payload.todate,branchRef).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          //
          this.billingList = res.data.data;
          this.totalLength = res.data.totalItems;
        
        //  this.apimicroservice.openSnackbar(res.message);
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      }); 
    }
  }

  toOpenDateChnage()
  {

    let payload = this.AlertForm.value;
    // const date1 = new Date(dd.toDateString()+" "+a);
    // const date2 = new Date(dd.toDateString()+" "+b);

    
    if(payload.fdate >= payload.todate && payload.todate !== '')
    {
      this.apimicroservice.openSnackbar("From Date should be Less than To Date");
      this.AlertForm.controls['fdate'].setValue('');
    }
  }
  toCloseDateChnage()
  {
    
    let payload = this.AlertForm.value;
    // const date1 = new Date(dd.toDateString()+" "+a);
    // const date2 = new Date(dd.toDateString()+" "+b);
    
    if(payload.todate <= payload.fdate && payload.fdate !== '')
    {
      this.apimicroservice.openSnackbar("To Date should be Greater than From Date");
      this.AlertForm.controls['todate'].setValue('');
    }
  }
 

  fetchAlertList() {
    let pg = 1;
    let size = 10;
    let search='';
    let branchRef = '';
    this.spinner.show();
    this.searchText = this.AlertForm.get("searchBilling").value;
  let alertType='';
  let fdate='';
  let todate='';
  let payload = this.AlertForm.value;
  const box = document.getElementById('box');
  const boxes = Array.from(
    document.getElementsByClassName('box') as HTMLCollectionOf<HTMLElement>,
  );
  //
   
     if(this.userType !== 'Super Admin')
     { 
    this.apimicroservice.getAlertData(pg,size,search,alertType,fdate,todate,this.branchRef).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        //
        this.billingList = res.data.data;
        this.totalLength = res.data.totalItems;
    
        //this.apimicroservice.openSnackbar(res.message);
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    }); 
  }
  else
  {
    this.apimicroservice.getAlertData(pg,size,search,alertType,fdate,todate,branchRef).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        //
        this.billingList = res.data.data;
        this.totalLength = res.data.totalItems;
      
      //  this.apimicroservice.openSnackbar(res.message);
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    }); 
  }



  }
  prepopulation(id) {
    this.apimicroservice.getAlertsDataById(id).subscribe(res => {
      if (res.status) {
        let data=res.data[0];
        //
       
        if(data.transferStatus !== undefined)
        {
          this.alertPayloadForm = this.fb.group({
            "billid": new FormControl(data.billid), 
            "billNo": new FormControl(data.billNo),
            "patientName": new FormControl(data.patientName),
            "patientId": new FormControl(data.patientId),
            "receiptNo": new FormControl(data.receiptNo),
            "mobile": new FormControl(data.mobile),
            "chequeStatus": new FormControl(data.chequeStatus),
            "bankName": new FormControl(data.bankName),
            "amount": new FormControl(data.amount),
            "chequeDate": new FormControl(data.chequeDate),
            "branchRefId": new FormControl(data.branchRefId),
            "branchName": new FormControl(data.branchName),
            "alertType": new FormControl(data.alertType),
            "alertView": new FormControl(data.alertView),
            "updatedAt": new Date(),
          });
          this.isStrock = data.transferStatus;
        }
        else
        {
          this.alertPayloadForm = this.fb.group({
            "billid": new FormControl(data.billid), 
            "billNo": new FormControl(data.billNo),
            "patientName": new FormControl(data.patientName),
            "patientId": new FormControl(data.patientId),
            "receiptNo": new FormControl(data.receiptNo),
            "mobile": new FormControl(data.mobile),
            "chequeStatus": new FormControl(data.chequeStatus),
            "bankName": new FormControl(data.bankName),
            "amount": new FormControl(data.amount),
            "chequeDate": new FormControl(data.chequeDate),
            "branchRefId": new FormControl(data.branchRefId),
            "branchName": new FormControl(data.branchName),
            "alertType": new FormControl(data.alertType),
            "alertView": new FormControl(data.alertView),
            "updatedAt": new Date(),
          });
          this.isStrock = data.transferStatus;
        }
     
        this.did =data.billid; 
        this.receiptNo = data.receiptNo;
        this.updateAlertNotify();
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    }); 
  }
  clear(){
    window.location.reload();
    this.ngOnInit();
  }
  updateAlertNotify() {
    let payload = this.alertPayloadForm.value;
    payload.alertView = false;
   
    //
    //
    
    // if(this.isStrock == undefined)
    // {
      //
       //
      this.apimicroservice.updateViewAlert(this.did,payload).subscribe(res => {
        if (res.status) {     
          window.location.reload();
        //  this.ngOnInit(); 
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      }); 
      
    
 
    // {
    //   //
    //   this.apimicroservice.updateViewAlert(this.did,payload).subscribe(res => {
    //     if (res.status) {     
    //     //  window.location.reload();
    //     //  this.ngOnInit(); 
    //     } else {
    //       //this.apimicroservice.openSnackbar(res.message);
    //     }
    //   }); 
    // }
   
  }
  getAlertCount() {
    let branchRef= sessionStorage.getItem('branchRef');
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getAlertStatusCount(this.branchRef).subscribe(res => {    
     });
    }
    else
    {
      this.apimicroservice.getAlertStatusCount(branchRef).subscribe(res => {   
     });
    }   
  }
  onback() {
    this.apimicroservice.back()
  }
  
} 

