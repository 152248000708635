import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'app-pop-up-condition',
  templateUrl: './pop-up-condition.component.html',
  styleUrls: ['./pop-up-condition.component.scss']
})
export class PopUpConditionComponent implements OnInit {
  popupConditionForm:FormGroup; 
  listOFCondition:any;
  totalLength:any;
  searchText:any;
  page=1;
  size=3;
  cid:any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopUpConditionComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
  ) { dialogRef.disableClose = true;}

  ngOnInit(): void {
    this.popupConditionForm = this.fb.group({
      "conditionType": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "createdAt": new Date(),
      "searchConditionType": new FormControl(''),
  })
this.fetchConditionList();
}

fetchConditionList() {
  let pg = 1;
  let size = 3;
  let search='';
      this.apimicroservice.getConditionData(pg,size,search).subscribe(res => {
         this.listOFCondition = res.data.data;
         this.totalLength = res.data.totalItems;
         //this.buildingLenght = this.listofbuild.length;
        if(res.status){
         // this.apimicroservice.openSnackbar(res.message);
          
        }else{
          //this.apimicroservice.openSnackbar(res.message);
        }
   
      });   

}

saveCondition(){
  let payload = this.popupConditionForm.value; 
  //
  this.spinner.show();
  this.apimicroservice.submitConditionData(payload).subscribe(res => {
    //
    this.spinner.hide();
    if(res.status){
      this.apimicroservice.openSnackbar(res.message);      
      this.ngOnInit();
     this.onClose();        
    }else{
      this.apimicroservice.openSnackbar(res.message);
    }
  },(ERROR:HttpErrorResponse) => {
    this.apimicroservice.openSnackbar(ERROR.error.message);
    this.spinner.hide();
    this.onClose();    
});
}

save() {
  if (this.popupConditionForm.invalid) {
    this.popupConditionForm.get("conditionType").markAsTouched();
    return;
  } else {
    this.saveCondition();
  }
}

onKeyUpEvent(event: any) {
  this.searchText = this.popupConditionForm.get('searchConditionType').value;
  this.apimicroservice.getConditionData(this.page,this.size,this.searchText).subscribe(res => {
    this.listOFCondition = res.data.data;
    this.totalLength = res.data.totalItems;
     if(res.status){
      //  this.apimicroservice.openSnackbar(res.message);    
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
   });
}

prepopulation(id) {
  this.apimicroservice.getConditionById(id).subscribe(res => {
    let data = res.data[0];
    this.cid = id;
     if(res.status){
      this.submitButton = false;
       this.updateButton = true;
       this.cancelButton = true;
       this.popupConditionForm = this.fb.group({
        "conditionType" :new FormControl(data.conditionType),
        "updateddAt": new Date(),
       });
     }else{
   //    //this.apimicroservice.openSnackbar(res.message);
     }
   });
}
updateConditionType() {
  let payload = this.popupConditionForm.value;
   this.apimicroservice.updateConditionData(payload,this.cid).subscribe(res => {
     if(res.status){
       this.apimicroservice.openSnackbar(res.message);
        this.updateButton = false;
        this.submitButton = true;
        this.ngOnInit();
     }else{
       //this.apimicroservice.openSnackbar(res.message);
     }
   });
  
}
onPageChange(event) {
  this.page = event;
 // let p = this.page - 1;
  this.searchText = this.popupConditionForm.get('searchConditionType').value;

  this.apimicroservice.getConditionData(this.page,this.size,this.searchText).subscribe(res => {
    this.listOFCondition = res.data.data;
    this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     });

}
cancelUpdate(){
  this.popupConditionForm.reset();
  this.submitButton=true;
  this.updateButton=false;
  this.cancelButton=false;
  this.ngOnInit();
}

onClose(): void {
  this.dialogRef.close();
}




}
