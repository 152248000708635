import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "../environments/environment";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router'
import { NgZone } from "@angular/core";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    'Access-Control-Allow-Credentials': 'true',
  })
}; 

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  private history: string[] = []
  public img = '';
  // public siteList = [];
  public APIService: string = environment.serviceUrl; // this has to be load from an external config file
  public APIService1: string = environment.serviceUrl1; // this has to be load from an external config file
  constructor(private http: HttpClient, public snackBar: MatSnackBar,
    private router: Router, private location: Location, private _zone: NgZone,) {
    this.APIService = environment.serviceUrl;
    this.APIService1 = environment.serviceUrl1;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

  //api services to post migration data

  saveClinitMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/saveclinic", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveBranchMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savebranch", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveAudiologistsMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savedoctor", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  savePatientMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savepatient", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveAppointmentTypeMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/saveappointmentType", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveAppointmentMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/saveappointment", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveBillingCompanyMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savebillingcompany", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveBrandMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savebrand", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveDeviceTypeMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savedeviceType", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveStyleMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savestyle", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveBatteryTypeMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savebatterytype", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveConditionMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savecondition", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveDeviceMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savedevice", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveSalesPersonMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savesalesPerson", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveCompanyMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savecompany", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveBillingMigration(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/saveBillingData", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  getBillingMigration(id): Observable<any> {
    return this.http.get(this.APIService1 + "/getBillingData?id="+id)
      .pipe( 
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  //getAllBillingData?page=1&size=3&search=
  getAllBillingData(page,size,search): Observable<any> {
    return this.http.get(this.APIService1 + "/getAllBillingData?page="+page+"&size="+size+"&search="+search)
      .pipe(  
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  updateMigrationBilling(id, Payload): Observable<any> {
    return this.http.put(this.APIService1 + "/updateBillingData?id="+id,Payload)
      .pipe(
        tap(heroes => this.log(`Updated Data`)),
      );
  }
  
  saveEarmoldMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/saveearmold", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveRepairMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/saverepair", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveCHAMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savecustomHearingAid", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveCommunicationMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/saveCommunication", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  saveDeviceByPatientMData(Payload): Observable<any> {
    return this.http.post(this.APIService1 + "/savedevicebypatient", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }

  //curier service start here
  // http://localhost:6001/api/courierService/updateCourierServiceData?id=6200f723777b9e2e57dd82fd
  
  saveCourierServiceData(Payload): Observable<any> {
    return this.http.post(this.APIService + "/courierService/saveCourierServiceData", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }

  getCourierServiceData(page, size, search, branchRef, clinicRef): Observable<any> {

    return this.http.get(this.APIService + "/courierService/getCourierServiceData?page=" + page + "&size=" + size + "&search=" + search + "&branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }

  getCourierServiceDataById(id): Observable<any> {
    return this.http.get(this.APIService + "/courierService/getCourierServiceDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }

  updateCourierServiceData(id, Payload): Observable<any> {
    return this.http.put(this.APIService + "/courierService/updateCourierServiceData?id=" + id, Payload)
      .pipe(
        tap(heroes => this.log(`Updated Data`)),
      );
  }

  deleteCourierService(id): Observable<any> {
    return this.http.delete(this.APIService + "/courierService/deleteCourierService?id=" + id)
      .pipe(
        tap(heroes => this.log(`Deleted data`)),
      )
  }
  deleteReceipt(item): Observable<any> {
    return this.http.put(this.APIService + "/paymenthistory/deleteReceipt?id="+item._id,item)
      .pipe(
        tap(heroes => this.log(`Deleted data`)),
      )
  }
  //curier service end here

  // clinical staff master starts here

  saveClinicalStaffData(Payload): Observable<any> {
    return this.http.post(this.APIService + "/clinicalStaff/saveClinicalStaffData", Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }



  getClinicalStaffData(page, size, search, branchRef, clinicRef): Observable<any> {

    return this.http.get(this.APIService + "/clinicalStaff/getClinicalStaffData?page=" + page + "&size=" + size + "&search=" + search + "&branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }

  getClinicalStaffDataById(id): Observable<any> {
    return this.http.get(this.APIService + "/clinicalStaff/getClinicalStaffDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }

  updateClinicalStaffData(id, Payload): Observable<any> {
    return this.http.put(this.APIService + "/clinicalStaff/updateClinicalStaffData?id=" + id, Payload)
      .pipe(
        tap(heroes => this.log(`Updated Data`)),
      );
  }

  deleteClinicalStaffData(id): Observable<any> {
    return this.http.delete(this.APIService + "/clinicalStaff/deleteClinicalStaffData?id=" + id)
      .pipe(
        tap(heroes => this.log(`Deleted data`)),
      )
  }


  // clinical staff master ends here

  //billing company start here

  saveBillingData(Payload): Observable<any> { 
    return this.http.post(this.APIService + "/billing/saveBillingData",Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }

  getBilling(page, size, search, clinicRef): Observable<any> {

    return this.http.get(this.APIService + "/billing/getBillingData?page="+page+"&size="+size+"&search="+search+"&clinicRef="+clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }

  getBillingAllData(): Observable<any> {
    return this.http.get(this.APIService + "/billing/getBillingData")
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  getSalesPersonAllData(): Observable<any> {
    return this.http.get(this.APIService + "/salePerson/getSalesPersonAllData")
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  
  getBillingDataById(id,clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/billing/getBillingDataById?id="+id+"&clinicRef="+clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  getHeaderDataByBillingRefId(id): Observable<any> {
    return this.http.get(this.APIService + "/header/getHeaderDataByBillingRefId?id="+id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  updateBillingData(id, Payload): Observable<any> {
    return this.http.put(this.APIService + "/billing/updateBillingData?id="+id,Payload)
      .pipe( 
        tap(heroes => this.log(`Updated Data`)),
      );
  }

  deleteBillingData(id): Observable<any> {
    return this.http.delete(this.APIService + "/billing/deleteBillingData?id=" + id)
      .pipe(
        tap(heroes => this.log(`Deleted data`)),
      )
  }

  updateAlerts(chkno): Observable<any> {
    return this.http.get(this.APIService + "/paymenthistory/updateAlerts?chequeNo="+chkno)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }

  addBounceAlerts(Payload): Observable<any> {
    return this.http.post(this.APIService + "/paymenthistory/addBounceAlert",Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  //paymenthistory/UpdateChequeAlert?billid=9090
  UpdateChequeAlert(id,Payload): Observable<any> {
    return this.http.put(this.APIService + "/paymenthistory/UpdateChequeAlert?billid="+id,Payload)
      .pipe(
        tap(heroes => this.log(`Updated Data`)),
      );
  }
  //api/paymenthistoryaddAlertNew
  addAlertNew(Payload): Observable<any> {
    return this.http.post(this.APIService + "/paymenthistory/addAlertNew",Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  //billing company start here

  uploadOverlay() {

    var url = "/sector/overlay";

    return this.APIService + url;

  }
  //patient-master apis
  addPatient(Payload): Observable<any> {
    return this.http.post(this.APIService + "/patient/savePatientData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  getPatientList(page, size, searchtext, branchRef, clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/patient/getPatientData?page="+page+"&size="+size+"&search="+searchtext+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  } 
  getPatientListExport(searchtext,branchRef,clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/patient/getPatientDataForExport?search="+searchtext+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getPatientListAll(branchRef,clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/patient/getPatientData?branchRef="+branchRef+"&clinicRef="+clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  updatePatientDetails(Payload, pid): Observable<any> {
    return this.http.put(this.APIService + "/patient/updatePatientData?id=" + pid, Payload)
      .pipe(
        tap(heroes => this.log(`Updated Logs`)),
      );
  }

  getPatientById(pid): Observable<any> {
    return this.http.get(this.APIService + "/patient/getPatientDataById?id=" + pid)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  patientGlobalSearch(pid): Observable<any> {
    return this.http.get(this.APIService + "/appointment/getAppointmentDataByPatientd?patientRefId=" + pid)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }

  getPatientListByBranchClinic(bid,cid): Observable<any> {
    return this.http.get(this.APIService + "/patient/getPatientDataByClinicBranchId?branchRef="+bid+"&clinicRef="+cid)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getHADataByPatient(patientRefId,haFlag): Observable<any> {
    return this.http.get(this.APIService + "/HARepair/getHADataByPatient?patientRefId="+patientRefId+"&haFlag="+haFlag)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  //end of patient-master apis

//followup apis
addFollowup(Payload): Observable<any> {
  return this.http.post(this.APIService+"/followup/saveFollowupData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    ); 
}

//http://localhost:2220/api/repairAction/saveRepairActionData
addRepairAction(Payload): Observable<any> {
  return this.http.post(this.APIService+"/repairAction/saveRepairActionData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
addTempPaymentHistory(Payload): Observable<any> {
  return this.http.post(this.APIService+"/tempData/saveTempBillData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
addFollowupAlerts(Payload): Observable<any> {
  return this.http.post(this.APIService+"/paymenthistory/createFollowUpAlert",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

getFollowupList(page,size,searchtext,patientRef): Observable<any> {
  return this.http.get(this.APIService+"/followup/getFollowupData?page="+page+"&size="+size+"&search="+searchtext+"&patientRefId="+patientRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}


updateFollowupDetails(Payload,pid): Observable<any> {
  return this.http.put(this.APIService+"/followup/updateFollowupData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}

getFollowupById(pid): Observable<any> {
  return this.http.get(this.APIService+"/followup/getFollowupDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getRepairActionDataById(aid): Observable<any> {
  return this.http.get(this.APIService+"/repairAction/getRepairActionDataById?id="+aid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//end of followup apis

  //vendor-master apis

  addVendor(Payload): Observable<any> {
    return this.http.post(this.APIService + "/vendor/saveVendorData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }



  getVendorList(page, size, searchtext, branchRef, clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/vendor/getVendorData?page=" + page + "&size=" + size + "&search=" + searchtext + "&branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }

  updateVendorDetails(Payload, pid): Observable<any> {
    return this.http.put(this.APIService + "/vendor/updateVendorData?id=" + pid, Payload)
      .pipe(
        tap(heroes => this.log(`Updated Logs`)),
      );
  }

  getVendorById(pid): Observable<any> {
    return this.http.get(this.APIService + "/vendor/getVendorDataById?id=" + pid)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }

  getVendorByBranchId(bid, clientid): Observable<any> {
    return this.http.get(this.APIService + "/vendor/getVendorDataById?branchRef=" + bid + "&clinicRef=" + clientid)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  //end of vendor-master apis

  //branch master apis
  addBranch(Payload): Observable<any> {
    return this.http.post(this.APIService + "/branch/saveBranchData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  // https://audiology-master.herokuapp.com/api/branch/getBranchData?page=1&size=5&search=MG&clinicRefId="your clenic reffrance id here "
  getBranchList(page, size, search, clinicRefId): Observable<any> {
    return this.http.get(this.APIService + "/branch/getBranchData?page=" + page + "&size=" + size + "&search=" + search + "&clinicRefId=" + clinicRefId)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }

  getBranchListAll(): Observable<any> {
    return this.http.get(this.APIService + "/branch/getBranchData")
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }

  updateBranchDetails(Payload, pid): Observable<any> {
    return this.http.put(this.APIService + "/branch/updateBranchData?id=" + pid, Payload)
      .pipe(
        tap(heroes => this.log(`Updated Logs`)),
      );
  }
  getBranchExist(payload): Observable<any> {
    return this.http.post(this.APIService + "/branch/findBranchData", payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  getBranchById(pid): Observable<any> {
    return this.http.get(this.APIService + "/branch/getBranchDataById?id=" + pid)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  //by clicnicRefId
  getBranchByClinicRefId(clinicRefId): Observable<any> {
    return this.http.get(this.APIService + "/branch/getBranchDataById?clinicRefId=" + clinicRefId)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getAllBranchList(clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/branch/getBranchAllData?clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getAllPOList(): Observable<any> {
    return this.http.get(this.APIService + "/placeOrder/getPlaceOrderAllData")
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getSizeAllData(): Observable<any> {
    return this.http.get(this.APIService + "/sizeData/getSizeAllData")
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getPowerAllData(): Observable<any> {
    return this.http.get(this.APIService + "/powerData/getPowerAllData")
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getTypeAllData(): Observable<any> {
    return this.http.get(this.APIService + "/typeData/getTypeAllData")
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getAccesoriesPlaceOrderAllData(): Observable<any> {
    return this.http.get(this.APIService + "/accesoriesPlaceOrderData/getAccesoriesPlaceOrderAllData")
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  //http://localhost:6001/api/device/getDeviceBranchWiseAllData?branchRef=625d029ddcebb211af76229b
  getAllDeviceList(branchRef): Observable<any> {
    return this.http.get(this.APIService + "/device/getDeviceBranchWiseAllData?branchRef=" + branchRef)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getAllServiceTypeList(branchRef,clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/servicePopup/getServicePopupAllData?branchRef=" + branchRef+"&clinicRef="+clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getDepartmentAllData(): Observable<any> {
    return this.http.get(this.APIService + "/department/getDepartmentAllData")
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getTestTypeAllData(branchRef,clinicRef): Observable<any> {
    return this.http.get(this.APIService+"/test/getTestTypeAllData?branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  } 
  getAllDoctorsList(branchRef, clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/doctor/getDoctorAllData?branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }

  getClinicList(cid): Observable<any> {
    return this.http.get(this.APIService + "/clinic/getClinicDataById?id=" + cid)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  //end of branch master apis

  //Inquiry master apis

  saveInquiryData(payload): Observable<any> {
    return this.http.post(this.APIService + "/inquiry/saveInquiryData", payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getInquiry(page, size, search, branchRef, clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/inquiry/getInquiryData?page=" + page + "&size=" + size + "&search=" + search + "&branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  // getAllInquirylist(): Observable<any>{
  //   return this.http.get(this.APIService + "/doctor/getDoctorData")
  //     .pipe(
  //       tap(heroes => this.log(`Fetched Data`))
  //     )
  // }
  getInquiryDataById(id): Observable<any> {
    return this.http.get(this.APIService + "/inquiry/getInquiryDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  updateInquiryData(id, payload): Observable<any> {
    return this.http.put(this.APIService + "/inquiry/updateInquiryData?id=" + id, payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getLastInquiryID(branchRef): Observable<any> {
    return this.http.get(this.APIService + "/inquiry/getInquiryBranchLastId?branchRef=" + branchRef)
      .pipe(
        tap(heroes => this.log(`fetched`)),
      );
  }

  getLastInquiryAll(): Observable<any> {
    return this.http.get(this.APIService + "/inquiry/getInquiryAllData")
      .pipe(
        tap(heroes => this.log(`fetched`)),
      );
  }
  //end of inquiry apis

  //place order master apis

  savePlaceOrderData(payload): Observable<any> {
    return this.http.post(this.APIService + "/placeOrder/savePlaceOrderData", payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getPlaceOrderDetails(page, size, search, branchRef, clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/placeOrder/getPlaceOrderData?page=" + page + "&size=" + size + "&search=" + search + "&branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  // getAllInquirylist(): Observable<any>{
  //   return this.http.get(this.APIService + "/doctor/getDoctorData")
  //     .pipe(
  //       tap(heroes => this.log(`Fetched Data`))
  //     )
  // }
  getPlaceOrderDataById(id): Observable<any> {
    return this.http.get(this.APIService + "/placeOrder/getPlaceOrderDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  updatePlaceOrderData(id, payload): Observable<any> {
    return this.http.put(this.APIService + "/placeOrder/updatePlaceOrderData?id=" + id, payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getLastPlaceOrderID(branchRef): Observable<any> {
    return this.http.get(this.APIService + "/placeOrder/getPlaceOrderBranchLastId?branchRef=" + branchRef)
      .pipe(
        tap(heroes => this.log(`fetched`)),
      );
  }

  getLastPlaceOrderAll(): Observable<any> {
    return this.http.get(this.APIService + "/placeOrder/getPlaceOrderAllData")
      .pipe(
        tap(heroes => this.log(`fetched`)),
      );
  }
  //end of place order apis

  //doctor master apis

  saveDoctorsData(payload): Observable<any> {
    return this.http.post(this.APIService + "/doctor/saveDoctorData", payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getAllDoctors(page, size, search, branchRef, clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/doctor/getDoctorData?page=" + page + "&size=" + size + "&search=" + search + "&branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getAllDoctorslist(): Observable<any> {
    return this.http.get(this.APIService + "/doctor/getDoctorData")
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getDoctorById(id): Observable<any> {
    return this.http.get(this.APIService + "/doctor/getDoctorDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  editDoctorData(id, payload): Observable<any> {
    return this.http.put(this.APIService + "/doctor/updateDoctorData?id=" + id, payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  //advAmt/updateAdvAmtData?id=
  editAdvAmtData(id, payload): Observable<any> {
    return this.http.put(this.APIService + "/advAmt/updateAdvAmtData?id=" + id,payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  deleteDoctorData(id): Observable<any> {
    return this.http.delete(this.APIService + "/doctor/deleteDoctor?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  //end of doctor apis

  //doctor master apis

  saveCustomHAData(payload): Observable<any> {
    return this.http.post(this.APIService + "/customHA/saveCustomHAData", payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getCustomHA(page, size, search, branchRef, clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/customHA/getCustomHAData?page=" + page + "&size=" + size + "&search=" + search + "&branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getLastCustomHAID(branchRef): Observable<any> {
    return this.http.get(this.APIService + "/customHA/getCustomHABranchLastId?branchRef=" + branchRef)
      .pipe(
        tap(heroes => this.log(`fetched`)),
      );
  }
 
  getLastCustomHAAll(): Observable<any> {
    return this.http.get(this.APIService + "/customHA/getCustomHAAllData")
      .pipe(
        tap(heroes => this.log(`fetched`)),
      );
  } 
  getCustomHADataById(id): Observable<any> {
    return this.http.get(this.APIService + "/customHA/getCustomHADataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  updateCustomHAData(id, payload): Observable<any> {
    return this.http.put(this.APIService + "/customHA/updateCustomHAData?id=" + id, payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }

  //end of doctor apis
  // role master apis

  saveRolesData(payload): Observable<any> {
    return this.http.post(this.APIService + "/role/saveRoleData", payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getAllRoles(page, size, search, clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/role/getRoleData?page=" + page + "&size=" + size + "&search=" + search + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getAllRolelist(): Observable<any> {
    return this.http.get(this.APIService + "/role/getRoleAllData")
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getRoleById(id): Observable<any> {
    return this.http.get(this.APIService + "/role/getRoleDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  editRoleData(id, payload): Observable<any> {
    return this.http.put(this.APIService + "/role/updateRoleData?id=" + id, payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  deleteRoleData(id): Observable<any> {
    return this.http.delete(this.APIService + "/role/deleteRole?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }

  getRoleNameExist(payload): Observable<any> {
    return this.http.post(this.APIService + "/role/findRoleTypeData", payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }



  //add device
  addDevice(Payload): Observable<any> {
    return this.http.post(this.APIService + "/device/saveDeviceData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  addCommunication(Payload): Observable<any> {
    return this.http.post(this.APIService + "/communication/saveCommunicationData",Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  } 
  //get device list
  getDeviceList(page, size, search, branchRef, clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/device/getDeviceData?page=" + page + "&size=" + size + "&search=" + search + "&branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  //device fetch by id 
  getDeviceById(id): Observable<any> {
    return this.http.get(this.APIService + "/device/getDeviceDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getAdvAmtDataById(id): Observable<any> {
    return this.http.get(this.APIService + "/advAmt/getAdvAmtDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getDeviceByBranchId(bid, clinicid): Observable<any> {
    return this.http.get(this.APIService + "/device/getDeviceDataById?branchRef=" + bid + "&clinicRef=" + clinicid)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  //device update device/updateDeviceData?id=
  updateDevice(id, Payload): Observable<any> {
    return this.http.put(this.APIService + "/device/updateDeviceData?id=" + id, Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  //get User list user/getUserData?page=1&size=5 
  getAllUser(page, size, search, clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/user/getUserData?page=" + page + "&size=" + size + "&search=" + search + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  // add user
  addUser(Payload): Observable<any> {
    return this.http.post(this.APIService + "/user/saveUserData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  //user prepo 
  getUserById(id): Observable<any> {
    return this.http.get(this.APIService + "/user/getUserDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  //update user
  updateUser(id, Payload): Observable<any> {
    return this.http.put(this.APIService + "/user/updateUserData?id=" + id, Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  //http://localhost:6001/api/user/changePassword
  userChangePassword(Payload): Observable<any> { 
    return this.http.post(this.APIService + "/user/changePassword",Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  //get all
  getAllBranch(page, size, search): Observable<any> {
    return this.http.get(this.APIService + "/branch/getBranchData?page=" + page + "&size=" + size + "&search=" + search)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }

  //clinic master apis
  saveClinicMaster(Payload): Observable<any> {
    return this.http.post(this.APIService + "/clinic/saveClinicData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  updateClinicMaster(id, Payload): Observable<any> {
    return this.http.put(this.APIService + "/clinic/updateClinicData?id=" + id, Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  getClinicData(page, size, search,clinicRef): Observable<any> {

    return this.http.get(this.APIService + "/clinic/getClinicData?page=" + page + "&size=" + size + "&search=" + search+ "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  getAllClinicList(): Observable<any> {
    return this.http.get(this.APIService + "/clinic/getClinicAllData")
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }
  getClinicListAll(): Observable<any> {

    return this.http.get(this.APIService + "/clinic/getClinicData")
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  getClinicDataById(id): Observable<any> {
    return this.http.get(this.APIService + "/clinic/getClinicDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  getRepairGenericDataById(id): Observable<any> {
    return this.http.get(this.APIService + "/repairGeneric/getRepairGenericDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  deleteClinicData(id): Observable<any> {
    return this.http.delete(this.APIService + "/clinic/deleteClinicData?id=" + id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  //end of clinic master apis

  //service master apis



  getServiceData(page, size, search, branchRef, clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/services/getServicesData?page=" + page + "&size=" + size + "&search=" + search + "&branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  getNotesDataByPId(patientRefId): Observable<any> {
    return this.http.get(this.APIService + "/notes/getNotesDataByPatientId?patientRefId="+patientRefId)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  getDeviceNotesDataByDeviceId(deviceRefId,patientRef): Observable<any> {
    return this.http.get(this.APIService + "/deviceNotes/getDeviceNotesDataByDeviceId?deviceRefId="+deviceRefId+"&patientRefId="+patientRef)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  getServiceById(id,branchRef,clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/services/getServicesDataById?id="+id + "&branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
getNotesDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/notes/getNotesDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
} 
getDeviceNotesDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/deviceNotes/getDeviceNotesDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
  getTestTypeDataByService(typeName,branchRef,clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/services/getServicesDataById?serviceType=" + typeName + "&branchRef=" + branchRef + "&clinicRef=" + clinicRef)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  submitServices(Payload): Observable<any> {
    return this.http.post(this.APIService + "/services/saveServicesData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  //add notes
  submitNotes(Payload): Observable<any> {
    return this.http.post(this.APIService + "/notes/saveNotesData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  saveDeviceNotesData(Payload): Observable<any> {
    return this.http.post(this.APIService + "/deviceNotes/saveDeviceNotesData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  updateServices(id, payload): Observable<any> {
    return this.http.put(this.APIService + "/services/updateServicesData?id=" + id, payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  updateNotesData(id,payload): Observable<any> {
    return this.http.put(this.APIService + "/notes/updateNotesData?id="+id,payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  updateDeviceNotesData(id,payload): Observable<any> {
    return this.http.put(this.APIService + "/deviceNotes/updateDeviceNotesData?id="+id,payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  deleteServices(id): Observable<any> {
    return this.http.delete(this.APIService + "/services/deleteServices?id=" + id)
      .pipe(
        tap(heroes => this.log(`Delete data`)),
      )
  }

  getAppointment(branchRef, clinicRef): Observable<any> {
    return this.http.get(this.APIService+"/appointment/getAppointmentData?branchRef=" + branchRef + "&clinicRef=" + clinicRef )
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getAppointmentbydate(date): Observable<any> {
    return this.http.get(this.APIService+"/appointment/getAppointmentDataById?appointDate="+date)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getAppointmentbydatedate(date): Observable<any> {
    return this.http.get(this.APIService+"/appointment/getAppointmentDataByDate?appointDate="+date)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  
  getAppointmentbydatepatient(date,patientRef): Observable<any> {
    return this.http.get(this.APIService+"/appointment/getAppointmentDataByDate?appointDate="+date+"&patientRef="+patientRef)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  getAppointmentbydatebranch(date,branchRef): Observable<any> {
    return this.http.get(this.APIService+"/appointment/getAppointmentDataByDate?appointDate="+date+"&branchRef="+branchRef)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      ) 
  }
  getAppointmentbydatedoctor(date, doctorid): Observable<any> {
    return this.http.get(this.APIService+"/appointment/getAppointmentDataByDate?appointDate="+date+"&doctorRef="+doctorid)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  } 

  getAppointmentbyTitle(title, id): Observable<any> {
    return this.http.get(this.APIService+"/appointment/getAppointmentDataBytitle?title="+title+"&id="+id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }
  
  getAppointmentbyId(id): Observable<any> {
    return this.http.get(this.APIService+"/appointment/getAppointmentDataById?id="+id)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }

  addAppointment(Payload): Observable<any> {
    return this.http.post(this.APIService+"/appointment/saveAppointmentData",Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }

  updateAppointment(refid, Payload): Observable<any> {
    return this.http.put(this.APIService+"/appointment/updateAppointmentData?id="+refid,Payload)
      .pipe(
        tap(heroes => this.log(`Fetched Data`))
      )
  }




  getDurationListAll(): Observable<any> {
    return this.http.get(this.APIService + "/duration/getDurationAllData")
      .pipe(
        tap(heroes => this.log(`Fetch Logs`)),
      );
  }

  // /appointment/updateAppointmentData?id=6200f723777b9e2e57dd82fd
  //end of service master apis
  //goods in word apis
  addGoodsInWord(Payload): Observable<any> {
    return this.http.post(this.APIService + "/purchase/savePurchaseData",Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`))
      )
  }
  getGoodsInWordData(page, size, search, branchRef, clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/purchase/getPurchaseData?page="+page+"&size="+size+"&search="+search+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
      .pipe(
        tap(heroes => this.log(`Fetched Data`)),
      );
  }
  getGoodsInWordDataById(id): Observable<any> {
    return this.http.get(this.APIService + "/purchase/getPurchaseDataById?id="+id)
      .pipe(
        tap(heroes => this.log(`Fetched Logs`)),
      );
  }

  updateGoodsInWord(id, payload): Observable<any> {
    return this.http.put(this.APIService + "/purchase/updatePurchaseData?id=" + id, payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  deleteGoodsInWord(id): Observable<any> {
    return this.http.delete(this.APIService + "/purchase/deletePurchaseData?id=" + id)
      .pipe(
        tap(heroes => this.log(`Delete data`)),
      )
  }

  getLastPurchaseID(): Observable<any> {
    return this.http.get(this.APIService + "/purchase/getPurchaseLastId")
      .pipe(
        tap(heroes => this.log(`Fetched Logs`)),
      );
  }
  //end of goods in word apis
  //ear-mould services start here
  getEarMouldData(page, size, search): Observable<any> {
    return this.http.get(this.APIService + "/ear/getEarData?page=" + page + "&size=" + size + "&search=" + search)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  getEarMouldById(id): Observable<any> {
    return this.http.get(this.APIService + "/ear/getEarDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  submitEarMould(Payload): Observable<any> {
    return this.http.post(this.APIService + "/ear/saveEarData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  updateEarMould(id, payload): Observable<any> {
    return this.http.put(this.APIService + "/ear/updateEarData?id=" + id, payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  deleteEarMould(id): Observable<any> {
    return this.http.delete(this.APIService + "/ear/deleteEarData?id=" + id)
      .pipe(
        tap(heroes => this.log(`Delete data`)),
      )
  }

  //get left item data
  //  http://localhost:6001/api/leftItem/getLeftItemAllData
  getLeftItemData(): Observable<any> {
    return this.http.get(this.APIService + "/leftItem/getLeftItemAllData")
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  //save left item data
  submitLeftItemData(Payload): Observable<any> {
    return this.http.post(this.APIService + "/leftItem/saveLeftItemData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  // delete left item data by id
  deleteLeftItemData(id): Observable<any> {
    return this.http.delete(this.APIService + "/leftItem/deleteLeftItemData?id=" + id)
      .pipe(
        tap(heroes => this.log(`Delete data`)),
      )
  }


  // get right item data
  // http://localhost:6001/api/rightItem/getRightItemAllData
  getRightItemData(): Observable<any> {
    return this.http.get(this.APIService + "/rightItem/getRightItemAllData")
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  // save right item data
  submitRightItemData(Payload): Observable<any> {
    return this.http.post(this.APIService + "/rightItem/saveRightItemData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  // delete right item data
  deleteRightItemData(id): Observable<any> {
    return this.http.delete(this.APIService + "/rightItem/deleteRightItemData?id=" + id)
      .pipe(
        tap(heroes => this.log(`Delete data`)),
      )
  }

  // get left instruction data
  // http://localhost:6001/api/leftInstruction/getLeftInstructionAllData
  getLeftInstructionData(): Observable<any> {
    return this.http.get(this.APIService + "/leftInstruction/getLeftInstructionAllData")
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  // save left instruction data
  submitLeftInstructionData(Payload): Observable<any> {
    return this.http.post(this.APIService + "/leftInstruction/saveLeftInstructionData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  //get single left instruction data by id
  getLeftInstructionDataById(id): Observable<any> {
    return this.http.get(this.APIService + "/leftInstruction/getLeftInstructionDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  // update left instruction data by id
  updateLeftInstructionData(id, payload): Observable<any> {
    return this.http.put(this.APIService + "/leftInstruction/updateLeftInstructionData?id=" + id, payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  //delete left instruction data by id
  deleteLeftInstructionData(id): Observable<any> {
    return this.http.delete(this.APIService + "/leftInstruction/deleteLeftInstructionData?id=" + id)
      .pipe(
        tap(heroes => this.log(`Delete data`)),
      )
  }

  // right instuction


  // save right instruction data
  submitRightInstructionData(Payload): Observable<any> {
    return this.http.post(this.APIService + "/rightInstruction/saveRightInstructionData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  //get right instruction data
  // http://localhost:6001/api/rightInstruction/getRightInstructionAllData
  getRightInstructionData(): Observable<any> {
    return this.http.get(this.APIService + "/rightInstruction/getRightInstructionAllData")
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  // get right instruction data by id
  getRightInstructionDataById(id): Observable<any> {
    return this.http.get(this.APIService + "/rightInstruction/getRightInstructionDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  // update right instruction data by id
  updateRightInstructionData(id, payload): Observable<any> {
    return this.http.put(this.APIService + "/rightInstruction/updateRightInstructionData?id=" + id,payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  //delete right instruction data by id
  deleteRightInstructionData(id): Observable<any> {
    return this.http.delete(this.APIService + "/rightInstruction/deleteRightInstructionData?id=" + id)
      .pipe(
        tap(heroes => this.log(`Delete data`)),
      )
  }

  //ear-mould services end here

  // repair complaint services starts here


  getRepairData(page,size,search,branchRef,clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/repair/getRepairData?page="+page+"&size="+size+"&search="+search+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }  
  getRepairDashboardData(page,size,search,branchRef,patientRef,clinicRef): Observable<any> {
    return this.http.get(this.APIService + "/repair/getRepairData?page="+page+"&size="+size+"&search="+search+"&branchRef="+branchRef+"&patientRef="+patientRef+"&clinicRef="+clinicRef)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  getRepairDataByPatientId(id): Observable<any> {
    return this.http.get(this.APIService + "/repair/getRepairDataById?patientRefId=" + id)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  getRepairDataById(id): Observable<any> {
    return this.http.get(this.APIService + "/repair/getRepairDataById?id=" + id)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }

  submitRepairData(Payload): Observable<any> {
    return this.http.post(this.APIService + "/repair/saveRepairData", Payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  updateRepairData(id, payload): Observable<any> {
    return this.http.put(this.APIService + "/repair/updateRepairData?id=" + id, payload)
      .pipe(
        tap(heroes => this.log(`Created Logs`)),
      );
  }
  deleteRepairData(id): Observable<any> {
    return this.http.delete(this.APIService + "/repair/deleteRepairData?id=" + id)
      .pipe(
        tap(heroes => this.log(`Delete data`)),
      )
  }

getLeftRepairItemData(page,size,search): Observable<any> {
  return this.http.get(this.APIService + "/leftRepairItem/getLeftRepairItemData?page="+page+"&size="+size+"&search="+search)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
getLeftRepairItemDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/leftRepairItem/getLeftRepairItemDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

submitLeftRepairItemData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/leftRepairItem/saveLeftRepairItemData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
updateLeftRepairItemData(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/leftRepairItem/updateLeftRepairItemData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
deleteLeftRepairItemData(id):Observable<any>{
  return this.http.delete(this.APIService + "/leftRepairItem/deleteLeftRepairItemData?id="+id)
    .pipe(
      tap(heroes => this.log(`Delete data`)),
    )
}

getRightRepairItemData(page,size,search): Observable<any> {
  return this.http.get(this.APIService + "/rightRepairItem/getRightRepairItemData?page="+page+"&size="+size+"&search="+search)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

getRightRepairItemDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/rightRepairItem/getRightRepairItemDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
getRightComplaintAllData(): Observable<any>{
  return this.http.get(this.APIService + "/rightComplaint/getRightComplaintAllData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
} 
//leftRepairItem/getLeftRepairItemAllData
getLeftComplaintAllData(): Observable<any>{
  return this.http.get(this.APIService + "/leftComplaint/getLeftComplaintAllData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
//leftRepairItem/getLeftRepairItemAllData
getLeftRepairItemAllData(): Observable<any>{
  return this.http.get(this.APIService + "/leftRepairItem/getLeftRepairItemAllData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
//rightRepairItem/getRightRepairItemAllData
getRightRepairItemAllData(): Observable<any>{
  return this.http.get(this.APIService + "/rightRepairItem/getRightRepairItemAllData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
submitRightRepairItemData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/rightRepairItem/saveRightRepairItemData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
updateRightRepairItemData(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/rightRepairItem/updateRightRepairItemData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
deleteRightRepairItemData(id):Observable<any>{
  return this.http.delete(this.APIService + "/rightRepairItem/deleteRightRepairItemData?id="+id)
    .pipe(
      tap(heroes => this.log(`Delete data`)),
    )
}


getLeftComplaintData(page,size,search): Observable<any> {
  return this.http.get(this.APIService + "/leftComplaint/getLeftComplaintData?page="+page+"&size="+size+"&search="+search)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
getLeftComplaintDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/leftComplaint/getLeftComplaintDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

submitLeftComplaintData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/leftComplaint/saveLeftComplaintData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
updateLeftComplaintData(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/leftComplaint/updateLeftComplaintData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
deleteLeftComplaintData(id):Observable<any>{
  return this.http.delete(this.APIService + "/leftComplaint/deleteLeftComplaintData?id="+id)
    .pipe(
      tap(heroes => this.log(`Delete data`)),
    )
}


getRightComplaintData(page,size,search): Observable<any> {
  return this.http.get(this.APIService + "/rightComplaint/getRightComplaintData?page="+page+"&size="+size+"&search="+search)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
getRightComplaintDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/rightComplaint/getRightComplaintDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

submitRightComplaintData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/rightComplaint/saveRightComplaintData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
updateRightComplaintData(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/rightComplaint/updateRightComplaintData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
deleteRightComplaintData(id):Observable<any>{
  return this.http.delete(this.APIService + "/rightComplaint/deleteRightComplaintData?id="+id)
    .pipe(
      tap(heroes => this.log(`Delete data`)),
    )
}
//appointment type services api starts here

addAppointmentTypeData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/appointType/saveAppointmentTypeData", Payload)
  .pipe(
    tap(heroes => this.log(`Created Logs`))
  )
}
getAppointmentTypeData(page,size,search,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/appointType/getAppointmentTypeData?page="+page+"&size="+size+"&search="+search+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getAppointmentTypeDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/appointType/getAppointmentTypeDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
getAppointmentTypeExist(payload): Observable<any> {
  return this.http.post(this.APIService + "/appointType/findAppointmentTypeData",payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

updateAppointmentTypeData(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/appointType/updateAppointmentTypeData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
deleteAppointmentTypeData(id):Observable<any>{
  return this.http.delete(this.APIService + "/appointType/deleteAppointmentTypeData?id="+id)
    .pipe(
      tap(heroes => this.log(`Delete data`)),
    )
}
getAppointmentTypelist(branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/appointType/getAppointmentTypeData?branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
//last branch Id
getLastBranchID(){
  return this.http.get(this.APIService + "/branch/getBranchLastId")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}


//last role Id
getLastRoleID(){
  return this.http.get(this.APIService + "/role/getRoleLastId")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}

//last patient Id http://localhost:6001/api/patient/getPatientBranchLastId?branchRef=622c66a07addc38c0215abcd
getLastPatientID(branchRef){
  return this.http.get(this.APIService + "/patient/getPatientBranchLastId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
//api/patient/getPatientAllData
getLastPatientAll(){
  return this.http.get(this.APIService + "/patient/getPatientAllData")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}

getAllPatientlist(): Observable<any>{
  return this.http.get(this.APIService + "/patient/getPatientAllData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
//last clinic Id
getLastClinicID(){
  return this.http.get(this.APIService + "/clinic/getClinicLastId")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}


//last vendor Id

// http://localhost:6001/api/vendor/getVendorAllData
// http://localhost:6001/api/vendor/getVendorLastBranchId?branchRef=622b1def39a66ac7fd3ada29


getLastVendorID(branchRef){
  return this.http.get(this.APIService + "/vendor/getVendorLastBranchId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getLastVendorAll(){
  return this.http.get(this.APIService + "/vendor/getVendorAllData")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}


//last Device Id

// http://localhost:6001/api/device/getDeviceBranchLastId?branchRef=622c66a07addc38c0215adef
// http://localhost:6001/api/device/getDeviceAllData



getLastDeviceID(branchRef){
  return this.http.get(this.APIService + "/device/getDeviceBranchLastId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getLastDeviceAll(){
  return this.http.get(this.APIService + "/device/getDeviceAllData")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}


//last courier Id

// http://localhost:6001/api/courierService/getCourierAllData
// http://localhost:6001/api/courierService/getCourierBranchLastId?branchRef=622c66a07addc38c0215adef


getLastCourierID(branchRef){
  return this.http.get(this.APIService + "/courierService/getCourierBranchLastId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getLastCourierAll(branchRef,clinicRef): Observable<any>{
  return this.http.get(this.APIService + "/courierService/getCourierAllData?branchRef="+branchRef+"clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}

//last id for repair
getLastRepairID(branchRef){
  return this.http.get(this.APIService + "/repair/getRepairLastBranchId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getLastRepairAll(): Observable<any>{
  return this.http.get(this.APIService + "/repair/getRepairAllData")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}

//last doctor Id

// http://localhost:6001/api/doctor/getDoctorBranchLastId?branchRef=622b1def39a66ac7fd3ada29
// http://localhost:6001/api/doctor/getDrAllData



getLastDoctorID(branchRef){
  return this.http.get(this.APIService + "/doctor/getDoctorBranchLastId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getLastDoctorAll(){
  return this.http.get(this.APIService + "/doctor/getDrAllData")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}


//last billing Id
getLastBillingID(){
  return this.http.get(this.APIService + "/billing/getBillingLastId")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
// services api for last id 

// http://localhost:6001/api/services/getServicesLastId
// http://localhost:6001/api/services/getServicesAllData
// http://localhost:6001/api/services/getServicesBranchLastId?branchRef=622b1def39a66ac7fd3ada29
getLastServicesID(){
  return this.http.get(this.APIService + "/services/getServicesLastId")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getServicesBranchLastId(branchRef){
  return this.http.get(this.APIService + "/services/getServicesBranchLastId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getLastServicesAll(){
  return this.http.get(this.APIService + "/services/getServicesAllData")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}

// api for appointment type last id

getAppointmentTypeLastId(){
  return this.http.get(this.APIService + "/appointType/getAppointmentTypeLastId")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getAppointmentTypeBranchLastId(branchRef){
  return this.http.get(this.APIService + "/appointType/getAppointmentTypeBranchLastId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getAppointmentTypeAllData(){
  return this.http.get(this.APIService + "/appointType/getAppointmentTypeAllData")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getAppointmentTypeAllDataByBranch (branchRef) : Observable<any>{
  return this.http.get(this.APIService + "/appointType/getAppointmentTypeAllData?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}

//last clinical staff Id

getLastStaffID(branchRef){
  return this.http.get(this.APIService + "/clinicalStaff/getClinicalStaffBranchLastId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}

getLastStaffAll(){
  return this.http.get(this.APIService + "/clinicalStaff/getClinicalStaffAllData")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}

 // repair complaint services ends here
 //service rate apis
 addServiceRate(Payload): Observable<any> {
  return this.http.post(this.APIService + "/servicerate/saveServiceRateData", Payload)
  .pipe(
    tap(heroes => this.log(`Created Logs`))
  )
}
// getServiceRateData(page,size,search): Observable<any> {
//   return this.http.get(this.APIService + "/appointType/getAppointmentTypeData?page="+page+"&size="+size+"&search="+search)
//     .pipe(
//       tap(heroes => this.log(`Fetched Data`)),
//     );
// }
getServiceRateById(id,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/servicerate/getServiceRateById?id="+id+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
updateServiceRate(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/servicerate/updateServiceRateData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
// deleteServiceRate(id):Observable<any>{
//   return this.http.delete(this.APIService + "/appointType/deleteAppointmentTypeData?id="+id)
//     .pipe(
//       tap(heroes => this.log(`Delete data`)),
//     )
// }

 //end of service rate apis

 //start stock transfer apis
 addDispatchStockAlert(Payload): Observable<any> {
  return this.http.post(this.APIService+"/paymenthistory/addTransferAlert",Payload)
  .pipe(
    tap(heroes => this.log(`Created Logs`))
  )
} 

updateTransferAlert(sno,payload): Observable<any> {
  return this.http.put(this.APIService + "/paymenthistory/updateTransferAlert?serialNumber="+sno,payload)
    .pipe(
      tap(heroes => this.log(`updated Logs`)),
    );
}

 addStockTransfer(Payload): Observable<any> {
  return this.http.post(this.APIService+"/stock/saveStockData",Payload)
  .pipe(
    tap(heroes => this.log(`Created Logs`))
  )
}
getStockTransferList(page,size,search,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/stock/getStockData?page="+page+"&size="+size+"&search="+search+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getDispatchList(page,size,search,tobranchRef,clinicRef,transStatus): Observable<any> {
  return this.http.get(this.APIService + "/stock/getDispatchStockData?page="+page+"&size="+size+"&search="+search+"&toBranchRefId="+tobranchRef+"&clinicRef="+clinicRef+"&transferStatus="+transStatus)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getStockTransferById(id): Observable<any> {
  return this.http.get(this.APIService + "/stock/getStockDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getStockTransferLastID(){
  return this.http.get(this.APIService + "/stock/getStockLastId")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
updateStockTransfer(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/stock/updateStockData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`updated Logs`)),
    );
}
deleteStockTransfer(id):Observable<any>{
  return this.http.delete(this.APIService + "/stock/deleteStockData?id="+id)
    .pipe(
      tap(heroes => this.log(`Delete data`)),
    )
}

 //end of stock transfer apis

 //start stock maintain apis
 addStockMaintain(Payload): Observable<any> {
  return this.http.post(this.APIService +"/stockmaintain/saveStockMaintainData",Payload)
  .pipe(
    tap(heroes => this.log(`Created Logs`))
  )
}
deleteStock(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/stockmaintain/deleteStockMaintain?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
getStockMaintainList(page,size,search): Observable<any> {
  return this.http.get(this.APIService + "/stockmaintain/getStockMaintainData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getLastReurnID(branchRef,status): Observable<any>{
  return this.http.get(this.APIService + "/stockmaintain/getStockMaintainByBranchId?branchRef="+branchRef+"&status="+status)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getStockPaginationData(page,size,searchtext,branchRef,status): Observable<any> {
  return this.http.get(this.APIService + "/stockmaintain/getPurchaseReturnData?page="+page+"&size="+size+"&search="+searchtext+"&branchRef="+branchRef+"&status="+status)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getStockMaintainById(id): Observable<any> {
  return this.http.get(this.APIService + "/stockmaintain/getStockMaintainById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getStockMaintainByBranchId(id,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/stockmaintain/getStockMaintainByBranchId?id="+id+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    ); 
}
getStockMaintainByDeviceId(id,deviceId,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/stockmaintain/getStockMaintainByBranchId?id="+id+"&deviceRefId="+deviceId+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getStockMaintainByDeviceIdStatus(id,deviceId,status,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/stockmaintain/getStockMaintainByBranchId?id="+id+"&deviceRefId="+deviceId+"&status="+status+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
} 
getStockMaintainByDeviceIdDraft(id,deviceId,status,billNo,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/stockmaintain/getStockMaintainByBranchId?id="+id+"&deviceRefId="+deviceId+"&status="+status+"&billNo="+billNo+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
updateStockMaintain(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/stockmaintain/updateStockMaintainData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`updated Logs`)),
    );
}
updateStockMaintainByPurchase(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/stockmaintain/updateStockMaintainData?purchaseRefId="+id,payload)
    .pipe(
      tap(heroes => this.log(`updated Logs`)),
    );
}

updateStockMaintainByTransfer(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/stockmaintain/updateStockMaintainData?transferRefId="+id,payload)
    .pipe(
      tap(heroes => this.log(`updated Logs`)),
    );
}
updateStockMaintainBySerial(payload): Observable<any> {
  return this.http.put(this.APIService + "/stockmaintain/updateStockMaintainData",payload)
    .pipe(
      tap(heroes => this.log(`updated Logs`)),
    );
}
deleteStockMaintain(id):Observable<any>{
  return this.http.delete(this.APIService + "/stockmaintain/deleteStockMaintainData?id="+id)
    .pipe(
      tap(heroes => this.log(`Delete data`)),
    )
}
//get all color api
// getAllColor(){
//   return this.http.get("https://api.color.pizza/v1/")
//   .pipe(
//     tap(heroes => this.log(`fetched`)),
//   );
// }
deleteAppointmentData(id):Observable<any>{
  return this.http.delete(this.APIService + "/appointment/deleteAppointmentData?id="+id)
    .pipe(
      tap(heroes => this.log(`Delete data`)),
    )
}
//brand/getBrandData?page=1&size=10&search
getBrandList(): Observable<any> {
  return this.http.get(this.APIService + "/brand/getBrandAllData")
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

//add brand
submitBrandData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/brand/saveBrandData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
submitHaColorData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/haColor/saveHaColorData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
updateBrandData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/brand/updateBrandData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
//get followupreasonlist
getfollowupReasonList(): Observable<any> {
  return this.http.get(this.APIService + "/followup/getFollowupReasonAllData")
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

//add followupreason
submitfollowupReasonData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/followup/saveFollowupReasonData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
//submit city data
submitCityData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/city/saveCityData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

//get all city data
getCityList(): Observable<any> {
  return this.http.get(this.APIService + "/city/getCityAllData")
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
//
getCityDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/city/getCityDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//http://localhost:2220/api/city/getCityDataDetails?page=1&size=5&search
getCityDataDetails(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/city/getCityDataDetails?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

//
updateCityData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/city/updateCity?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
getInoutTypeList(): Observable<any> { 
  return this.http.get(this.APIService + "/inoutType/getInoutTypeAllData")
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

//fetch all expense type 

getExpenseList(): Observable<any> {
  return this.http.get(this.APIService + "/expenceType/getExpenceTypeAllData")
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

getExpenseById(id): Observable<any> {
  return this.http.get(this.APIService + "/expenceType/getExpenceTypeDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

//add expense type 
submitExpenseTypeData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/expenceType/saveExpenceTypeData", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
//http://localhost:2220/api/expenceType/getExpenceTypeDetails?page=1&size=5&search=
getExpenseTypeData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/expenceType/getExpenceTypeDetails?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
updateExpenseData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/expenceType/updateExpenceType?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
//fetch all patient source 
getPatientSourceList(branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/patientSource/getPatientSourceAllData?branchRef=" + branchRef + "&clinicRef=" + clinicRef)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
getTrackingStatusAllData(branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/trackingStatus/getTrackingStatusAllData?branchRef=" + branchRef + "&clinicRef=" + clinicRef)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
//add patient source 
submitPatientSourceData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/patientSource/savePatientSourceData", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

//add service type 
submitServiceTypeData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/servicePopup/saveServicePopupData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
saveDepartmentData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/department/saveDepartmentData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
submitTestTypeData(Payload): Observable<any> {
  return this.http.post(this.APIService+"/test/saveTestTypeData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
} 
// api for generic settings for patient start

savePatientGenericData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/genericPatient/savePatientGenericData",Payload)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getPatientGenericAllData(): Observable<any> {
  
  return this.http.get(this.APIService + "/genericPatient/getPatientGenericAllData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getPatientGenericDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/genericPatient/getPatientGenericDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

updatePatientGenericData(Payload,id): Observable<any> {
  return this.http.put(this.APIService + "/genericPatient/updatePatientGenericData?id="+id,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Data`)),
    );
} 
updateRepairGenericData(Payload,id): Observable<any> {
  return this.http.put(this.APIService + "/repairGeneric/updateRepairGenericData?id="+id,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Data`)),
    );
}

//api for generic settings for patient end

// api for generic setting of calender starts

// http://localhost:6001/api/genericAppoint/getAppointGenericAllData
// http://localhost:6001/api/genericAppoint/saveAppointGenericData
// http://localhost:6001/api/genericAppoint/getAppointGenericDataById?id=6229f825f6f501d32f7815d9
// http://localhost:6001/api/genericAppoint/updateAppointGenericData?id=6229f825f6f501d32f7815d9



saveAppointGenericData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/genericAppoint/saveAppointGenericData",Payload)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
  
getAppointGenericAllData(): Observable<any> {
  
  return this.http.get(this.APIService + "/genericAppoint/getAppointGenericAllData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getAppointGenericDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/genericAppoint/getAppointGenericDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

updateAppointGenericData(Payload,id): Observable<any> {
  return this.http.put(this.APIService + "/genericAppoint/updateAppointGenericData?id="+id,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Data`)),
    );
}
// api for generic setting of calender ends

//api for generic setting of report header and footer
saveReportHeaderGenericData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/header/saveHeaderData",Payload)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getReportHeaderGenericDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/header/getHeaderDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

updateReportHeaderGenericData(Payload,id): Observable<any> {
  return this.http.put(this.APIService + "/header/updateHeaderData?id="+id,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Data`)),
    );
}

getReportHeaderGenericAllData(branchRef,clinicRef): Observable<any> {
  
  return this.http.get(this.APIService + "/header/getHeaderAllData?branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

saveReportFooterGenericData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/footer/saveFooterData",Payload)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
saveRepairGenericData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/repairGeneric/saveRepairGenericData",Payload)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
} 
getReportFooterGenericDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/footer/getFooterDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getReportFooterGenericAllData(branchRef,clinicRef): Observable<any> {
  
  return this.http.get(this.APIService + "/footer/getFooterAllData?branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

updateReportFooterGenericData(Payload,id): Observable<any> {
  return this.http.put(this.APIService + "/footer/updateFooterData?id="+id,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Data`)),
    );
}

//end of report generic settings apis

//login api 
userLogin(Payload): Observable<any> {
  return this.http.post(this.APIService + "/userLogin/userLogin", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
//save doctorAvailable

getAllDoctorsListAvailable(bid,cid): Observable<any> {
  return this.http.get(this.APIService + "/doctorAvailable/getDoctorAvailiabilityAllData?branchRef="+bid+"&clinicRef="+cid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
saveDoctorAvailable(Payload): Observable<any> {
  return this.http.post(this.APIService + "/doctorAvailable/saveDoctorAvailiabilityData", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

getDoctorAvailablePaginationData(page,size,search,branchRef,clinicRef): Observable<any> {
  
  return this.http.get(this.APIService + "/doctorAvailable/getDoctorAvailiabilityPaginationData?page="+page+"&size="+size+"&search="+search+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getDoctorAvailableDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/doctorAvailable/getDoctorAvailiabilityDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
updateDoctorAvailable(id,payload): Observable<any>{
  return this.http.put(this.APIService + "/doctorAvailable/updateDoctorAvailiabilityData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
getDoctorAppointAvailable(branchRefId,clinicRefId,doctorRefId): Observable<any> {
  return this.http.get(this.APIService + "/doctorAvailable/getDoctorAvailiabilityAppointData?branchRef="+branchRefId+"&clinicRef="+clinicRefId+"&doctorRef="+doctorRefId)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
//checkup details apis
addPaymentHistoryDetails(Payload): Observable<any> {
  return this.http.post(this.APIService + "/paymenthistory/savePaymentHistoryData", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
 
getPaymentHistoryByPatient(patientRefId,billNo,advanceStatus): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/getPaymentHistoryByPatient?patientRefId="+patientRefId+"&billNo="+billNo+"&advanceStatus="+advanceStatus)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
updatePaymentHistoryDetails(payload,id): Observable<any>{
  return this.http.put(this.APIService + "/paymenthistory/updatePaymentHistoryData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}

getAllPaymentHistoryByBillNo(page,size,search,billNo): Observable<any> {
  
  return this.http.get(this.APIService + "/paymenthistory/getPaymentHistoryData?page="+page+"&size="+size+"&search="+search+"&billNo="+billNo)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getAllPaymentHistoryByAdvance(page,size,branchRefId,search,billNo,advanceStatus): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/getAdvancePaymentHistoryData?page="+page+"&size="+size+"&branchRef="+branchRefId+"&search="+search+"&billNo="+billNo+"&advanceStatus="+advanceStatus)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getPaymentHistoryByBillNo(billNo): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/getPaymentHistoryDataById?billNo="+billNo)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)), 
    );
}

getPaymentHistoryByReceiptNo(receiptNo): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/getPaymentHistoryDataById?receiptNo="+receiptNo)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getPaymentHistoryByChequeNo(chequeNo): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/getPaymentHistoryDataById?chequeNo="+chequeNo)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
addSoldDeviceDetails(Payload): Observable<any> {
  return this.http.post(this.APIService +"/solddevice/saveSoldDeviceModelData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

updateSoldDeviceDetails(payload,sno): Observable<any>{
  return this.http.put(this.APIService + "/solddevice/updateSoldDeviceData?serialNo="+sno,payload)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}

getBillingDeviceDataByPatientRef(patientRefID): Observable<any> {
  return this.http.get(this.APIService + "/solddevice/getBillingDeviceDataByPatient?patientRefId="+patientRefID)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
 getExternalDevice(page,size,search,patientRef): Observable<any> { 
  return this.http.get(this.APIService + "/dByP/getDeviceDetailsByPatient?page="+page+"&size="+size+"&search="+search+"&patientRef="+patientRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getInternalDevice(page,size,search,patientRefId): Observable<any> { 
  return this.http.get(this.APIService + "/solddevice/getDeviceJoinDataByPatientRef?page="+page+"&size="+size+"&search="+search+"&patientRefId="+patientRefId)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

//http://localhost:2220/api/solddevice/getDeviceJoinDataByPatientRef?page=1&size=5&search&patientRefId=6305c317799ba223ae948feb
getBillingDeviceInfoBySerialNo(serialNo,deviceRefId): Observable<any> {
  return this.http.get(this.APIService + "/solddevice/getBillingDeviceInfoBySerialNo?serialNumber="+serialNo+"&deviceRefId="+deviceRefId)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
//http://localhost:2220/api/purchase/getPurchaseDataByDeviceId?deviceName=Samsung&serialNumber=14141
getPurchaseData(deviceName,serialNumber): Observable<any> {
  return this.http.get(this.APIService + "/purchase/getPurchaseDataByDeviceId?deviceName="+encodeURIComponent(deviceName)+"&serialNumber="+serialNumber)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getTempPaymentById(repairRef): Observable<any> {
  return this.http.get(this.APIService+"/tempData/getTempBillDataById?repairRefId="+repairRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
} 
addBillingDetails(Payload): Observable<any> {
  return this.http.post(this.APIService + "/billingModel/saveBillingModelData", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
} 
addBillingDetailsAsDraft(Payload): Observable<any> {
  return this.http.post(this.APIService + "/billingModel/saveBillingModelAsDraft", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
} 
updateBillingDetails(payload,id): Observable<any>{
  return this.http.put(this.APIService + "/billingModel/updateBillingModelData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
 
getBillDataById(billNo): Observable<any> {
  return this.http.get(this.APIService + "/billingModel/getBillingModelDataById?billNo="+billNo)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getBillingDataByPatientId(patientRefID,appointId): Observable<any> {
  return this.http.get(this.APIService + "/billingModel/getBillingDataByPatient?patientRefId="+patientRefID+"&appointId="+appointId)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getBillingDetailsByPatientId(page,size,search,patientRefID,appointId,branchRef): Observable<any> {
  
  return this.http.get(this.APIService + "/billingModel/getBillingModelData?page="+page+"&size="+size+"&search="+search+"&patientRefId="+patientRefID+"&appointId="+appointId+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getBillingDetailsByWithoutAppId(page,size,search,patientRefID,withoutappid,branchRef): Observable<any> {
  
  return this.http.get(this.APIService+"/billingModel/getBillingModelData?page="+page+"&size="+size+"&search="+search+"&patientRefId="+patientRefID+"&withoutappId="+withoutappid+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getAllBillingDetails(page,size,search,patientId,patientName,billNo,status,fromDate,toDate,branchRef): Observable<any> {
  
  return this.http.get(this.APIService + "/billingModel/getBillingModelData?page="+page+"&size="+size+"&search="+search+"&patientId="+patientId+"&patientName="+patientName+"&billNo="+billNo+"&paymentStatus="+status+"&fromDate="+fromDate+"&toDate="+toDate+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getBill(status,branchRef): Observable<any> {
  
  return this.http.get(this.APIService + "/billingModel/getBillingModelData?paymentStatus="+status+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
//http://localhost:2220/api/advAmt/getAdvAmtData?page=1&size=5&search
getAdvAmtData(page,size,search,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/advAmt/getAdvAmtData?page="+page+"&size="+size+"&search="+search+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
//for dashboard
getAllBillingForDashboard(page,size,search,patientRefId,branchRef): Observable<any> {
  
  return this.http.get(this.APIService + "/billingModel/getBillingModelData?page="+page+"&size="+size+"&search="+search+"&patientRefId="+patientRefId+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
//
getInwardOutwardDataByPatientId(page,size,search,patientRef): Observable<any> {
  return this.http.get(this.APIService + "/inOutward/getInwardOutwardDataByPatientId?page="+page+"&size="+size+"&search="+search+"&patientRef="+patientRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
} 
//patientRef=62d8dfeb5cdc9fa57da32832&page=1&size=2&search=nashik
getAllAptDashboard(page,size,search,patientRef): Observable<any> { 
  return this.http.get(this.APIService + "/appointment/getAppointCountDataByPatientRefId?patientRef="+patientRef+"&page="+page+"&size="+size+"&search="+search)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
//http://localhost:6001/api/paymentHistory/billwiseCheque?page=1&size=5&fromDate=2022-06-2&toDate=2022-06-21&search=99104
getPaymentHistoryList(page,size,search,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/paymentHistory/billwiseCheque?page="+page+"&size="+size+"&search="+search+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getPaymentHistoryData2(page,size,search,billNo,chequeNo,chequeStatus,fromDate,toDate,branchRef): Observable<any> {
  
  return this.http.get(this.APIService + "/paymenthistory/billwiseCheque?page="+page+"&size="+size+"&search="+search+"&billNo="+billNo+"&chequeNo="+chequeNo+"&chequeStatus="+chequeStatus+"&fromDate="+fromDate+"&toDate="+toDate+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getLastBillID(billingCompanyRef): Observable<any>{
  return this.http.get(this.APIService + "/billingModel/getBillingLastId?billingCompanyRefId="+billingCompanyRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}

addCheckupDetails(Payload): Observable<any> {
  return this.http.post(this.APIService + "/checkupDetails/saveCheckupDetailsData", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

updateCheckupDetails(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/checkupDetails/updateCheckupDetailsData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
getPatientDateHistoryById(patientRefID,appointId): Observable<any> {
  return this.http.get(this.APIService + "/checkupDetails/getCheckupDetailsDataByPatient?patientRef="+patientRefID+"&appointId="+appointId)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
} 
getCheckupDetailsAllData(page,size,payload,searchtext,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/checkupDetails/getCheckupDetailsAllData?page="+page+"&size="+size+"&search="+searchtext+"&payload="+payload+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
} 
getRepairActionAllData(): Observable<any> {
  return this.http.get(this.APIService + "/repairAction/getRepairActionAllData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getEarMoldAllData(): Observable<any> {
  return this.http.get(this.APIService + "/earMoldLatest/getEarMoldAllData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getPatientHistoryById(id): Observable<any> {
  return this.http.get(this.APIService + "/checkupDetails/getCheckupDetailsData?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getRepairActionDataByRepairRefId(page,size,searchtext,repairRefId): Observable<any> {
  return this.http.get(this.APIService + "/repairAction/getRepairActionDataByRepairRefId?page="+page+"&size="+size+"&search="+searchtext+"&repairRefId="+repairRefId)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getRepairActionAllRepairRefId(repairRefId): Observable<any> {
  return this.http.get(this.APIService + "/repairAction/getRepairActionDataByRepairRefId?repairRefId="+repairRefId)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 

//total Refreance
getTotalRefreance(patientdropDown): Observable<any> {
  return this.http.get(this.APIService + "/checkupDetails/getTotalRefrence?patientdropDown="+patientdropDown)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getTotalBuisness(patientRef) : Observable<any>{
  return this.http.get(this.APIService + "/checkupDetails/getTotalBussiness?patientRefId="+patientRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getTotalVisits(patientRef): Observable<any>{
  return this.http.get(this.APIService + "/checkupDetails/getTotalPatientVisit?patientRefId="+patientRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getPaymentHistoryData(patientRef) : Observable<any>{
  return this.http.get(this.APIService + "/billingModel/getBillingDataByPatient?patientRefId="+patientRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getPatientDevice(patientRef) : Observable<any>{
  return this.http.get(this.APIService + "/checkupDetails/getPatientDevice?patientRefId="+patientRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
//patientFileUpload/getFileDataByPatientId?patientRef=&appointId=
getPatientFileData(patientRef,appointId) : Observable<any>{
  return this.http.get(this.APIService + "/patientFileUpload/getFileDataByPatientId?fileRef="+patientRef+"&appointId="+appointId)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
removePatientFileData(fileRef) : Observable<any>{
  return this.http.get(this.APIService + "/patientFileUpload/removeFileDataByPatientId?fileRef="+fileRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
addRepairStatusFlow(Payload): Observable<any> {
  return this.http.post(this.APIService + "/repairStepper/saveRepairStepperData", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
updateRepairStatusFlow(id,Payload): Observable<any> {
  return this.http.put(this.APIService + "/repairStepper/updateRepairStepperData?id="+id, Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
getRepairStatusFlowById(id): Observable<any> {
  return this.http.get(this.APIService + "/repairStepper/getRepairStepperDataByRepairRefId?repairRef="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getRepairStatusFlowByRepairDate(repairid,repairdt,repairtodate): Observable<any> {
  return this.http.get(this.APIService + "/repairStepper/getRepairStepperDataByRepairRefId?repairRef="+repairid+"&repairfromDate="+repairdt+"&repairtoDate="+repairtodate)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

getRepairStatusFlowByScenario(snm): Observable<any> {
  return this.http.get(this.APIService + "/repairStepper/getRepairStepperDataById?scenario="+snm)
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

//http://localhost:6001/api/HARepair/getHALastId
getLastHAID(branchRef): Observable<any>{
  return this.http.get(this.APIService + "/HARepair/getHABranchLastId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getLastHAFormAll(): Observable<any>{
  return this.http.get(this.APIService + "/HARepair/getHALastId")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
//http://localhost:6001/api/HARepair/saveHAData
addHAFormData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/HARepair/saveHAData", Payload)
  .pipe(
    tap(heroes => this.log(`Created Logs`))
  )
}
getAllDevice(): Observable<any> {
  return this.http.get(this.APIService + "/device/getDeviceAllData")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//http://localhost:6001/api/HARepair/getHAData?page=1&size=10&search=&branchRef=
getHAList(page,size,searchtext,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/HARepair/getHAData?page="+page+"&size="+size+"&search="+searchtext+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getHAIssuedList(searchtext): Observable<any> {
  return this.http.get(this.APIService + "/HARepair/getHAData?search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getHeaderData(page,size,searchtext,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/header/getHeaderData?page="+page+"&size="+size+"&search="+searchtext+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 

//http://localhost:6001/api/HARepair/getHADataById?id=624bf0969a17bb0530f365fa
getHAById(hid): Observable<any> {
  return this.http.get(this.APIService + "/HARepair/getHADataById?id="+hid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//http://localhost:6001/api/HARepair/updateHAData?id=624bf4c90976aad33231d841
updateHADetails(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/HARepair/updateHAData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}


//Add ear mold http://localhost:6001/api/earMold/saveEarMoldData
addEarMold(Payload): Observable<any> {
  return this.http.post(this.APIService + "/earMoldLatest/saveEarMoldData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
patientFileUpload(Payload): Observable<any> {
  return this.http.post(this.APIService + "/patientFileUpload/savePatientFileData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
getEarMouldDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/earMoldLatest/getEarMoldDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

getEarMouldOrderData(page,size,searchtext,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/earMoldLatest/getEarMoldData?page="+page+"&size="+size+"&search="+searchtext+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getEarMouldOrderDashboard(page,size,searchtext,branchRef,patientRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/earMoldLatest/getEarMoldData?page="+page+"&size="+size+"&search="+searchtext+"&branchRef="+branchRef+"&patientRef="+patientRef+"&clinicRef="+clinicRef )
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//update Ear mold http://localhost:6001/api/earMold/updateEarMoldData?id=624dcf98cd27cc96bcd8b795
updateEarMold(Payload,eid): Observable<any> {
  return this.http.put(this.APIService + "/earMoldLatest/updateEarMoldData?id="+eid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
//http://localhost:6001/api/earMold/getEarMoldBranchLastId?branchRef=624d8d89f57276c05cce1655
getLastEarMoldID(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/earMoldLatest/getEarMoldBranchLastId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getEarMoldLastAll(): Observable<any> {
  return this.http.get(this.APIService + "/earMoldLatest/getEarMoldLastId")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}

//forgotPassword 
//http://localhost:6001/api/user/forgotPassword?emailId=dongareprachi15@gmail.com
// forgotPassword(emailId): Observable<any> {
//   return this.http.post(this.APIService + "/user/forgotPassword?emailId="+emailId)

//     .pipe(
//       tap(heroes => this.log(`Created Logs`)),
//     );
// }
forgotPassword(Payload,emailId): Observable<any> {
  return this.http.post(this.APIService + "/user/forgotPassword?emailId="+emailId,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}


//apis clinic expenses
getLastClinicExpenseID(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/clinicExpenses/getClinicExpensesBranchLastId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}

getLastAllClinicExpenseID(): Observable<any> {
  return this.http.get(this.APIService + "/clinicExpenses/getClinicExpensesLastId")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}

addClinicExpenses(Payload): Observable<any> {
  return this.http.post(this.APIService + "/clinicExpenses/saveClinicExpensesData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

getClinicExpensesDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/clinicExpenses/getClinicExpensesDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

getClinicExpensesData(page,size,searchtext,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/clinicExpenses/getClinicExpensesData?page="+page+"&size="+size+"&search="+searchtext+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//update Ear mold http://localhost:6001/api/earMold/updateEarMoldData?id=624dcf98cd27cc96bcd8b795
updateClinicExpenses(Payload,cid): Observable<any> {
  return this.http.put(this.APIService + "/clinicExpenses/updateClinicExpensesData?id="+cid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
//add inward-outward 
addInwardOutward(Payload): Observable<any> {
  return this.http.post(this.APIService + "/inOutward/saveInwardOutwardData", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
saveAdvAmtData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/advAmt/saveAdvAmtData", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
saveInOutwardData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/newInOutward/saveInOutwardData", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
//get last id
getLastInwardOutwardAll(){
  return this.http.get(this.APIService + "/inOutward/getInwardOutwardLastId")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
//get last id 
getLastCourieID(branchRef){
  return this.http.get(this.APIService + "/courierService/getCourierBranchLastId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
//get fetch inward-outward 
getInwardOutwardList(page,size,searchtext,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/inOutward/getInwardOutwardData?page="+page+"&size="+size+"&search="+searchtext+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getInOutwardData(page,size,searchtext,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/newInOutward/getInOutwardData?page="+page+"&size="+size+"&search="+searchtext+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//couerier data
getCourierAllData(branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/courierService/getCourierAllData?branchRef="+branchRef+"clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//fetch by ib http://localhost:6001/api/inOutward/getInwardOutwardDataById?id=6257d191972c913169255a93
getInOutwardById(pid): Observable<any> {
  return this.http.get(this.APIService + "/inOutward/getInwardOutwardDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getInOutwardDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/inOutward/getInwardOutwardDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getInOutwardByBranchId(id,branchName): Observable<any> {
  return this.http.get(this.APIService + "/newInOutward/getInOutwardDataByBranchId?id="+id+"&branchName="+branchName)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//update inward inOutward/updateInwardOutwardData?id=6257d191972c913169255a93
updateInOutwardDetails(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/inOutward/updateInwardOutwardData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updateInOutwardData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/newInOutward/updateInOutwardData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
getInwordOutWordLastID(branchRef){
  return this.http.get(this.APIService + "/inOutward/getInwardOutwardBranchLastId?branchRef="+branchRef)
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
getInOutwardLastId(branchRef): Observable<any>{
  return this.http.get(this.APIService + "/newInOutward/getInOutwardLastId?branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
//get doctor http://localhost:6001/api/doctor/getDrAllData
getAllDoctorlist(): Observable<any>{
  return this.http.get(this.APIService + "/doctor/getDrAllData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
getAllclinicalStafflist(): Observable<any>{
  return this.http.get(this.APIService + "/clinicalStaff/getClinicalStaffAllData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
//add can number
submitCanNumberData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/canMaster/saveCanData", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
//can getAll Data 
//http://localhost:6001/api/canMaster/getCanAllData?branchRef=625d029ddcebb211af76229b&clinicRef=6259249d3c65764ae4d17dfe
getCanAllData(bid,cid): Observable<any> {
  return this.http.get(this.APIService + "/canMaster/getCanAllData?branchRef="+bid+"&clinicRef="+cid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

//company master apis

saveCompanyData(payload): Observable<any>{
  return this.http.post(this.APIService + "/company/saveCompanyData",payload)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
getAllCompanies(page,size,search,branchRef,clinicRef): Observable<any>{
  return this.http.get(this.APIService + "/company/getCompanyData?page="+page+"&size="+size+"&search="+search+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
getAllCompanylist(branchRef,clinicRef): Observable<any>{
  return this.http.get(this.APIService + "/company/getCompanyData?branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
getAllHaColorlist(): Observable<any>{
  return this.http.get(this.APIService + "/haColor/getHaColorData")
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
getAllHACompanylist(branchRef,clinicRef): Observable<any>{
  return this.http.get(this.APIService + "/company/getCompanyAllData?branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
getCompanyById(id): Observable<any>{
  return this.http.get(this.APIService + "/company/getCompanyDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
getSalesPersonById(id): Observable<any>{
  return this.http.get(this.APIService + "/salePerson/getSalesPersonById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
editCompanyData(id,payload): Observable<any>{
  return this.http.put(this.APIService + "/company/updateCompanyData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}

getLastCompanyID() : Observable<any>{
  return this.http.get(this.APIService + "/company/getCompanyLastId")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
dailyActivityReport() : Observable<any>{
  return this.http.get(this.APIService + "/report/dailyActivityReport")
  .pipe(
    tap(heroes => this.log(`fetched`)),
  );
}
//end of company apis

//brand pagination brand/getBrandData?page=1&size=5&search
getBrandData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/brand/getBrandData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getHaColorData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/haColor/getHaColorData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//brand get by id
getBrandDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/brand/getBrandDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getHaColorDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/haColor/getHaColorDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

monthOpdReport(): Observable<any> {
  return this.http.get(this.APIService + "/report/monthOpdReport")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
stockFileReport(branchid,patientid,fdate,tdate): Observable<any> {
  return this.http.get(this.APIService + "/report/stockFileReport?branchRef="+branchid+"&patientRefId="+patientid+"&fromDate="+fdate+"&toDate="+tdate)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
repairJobCard(branchid,patientid,fdate,tdate): Observable<any> {
  return this.http.get(this.APIService + "/report/repairJobCard?branchRef="+branchid+"&patientRefId="+patientid+"&fromDate="+fdate+"&toDate="+tdate)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//http://localhost:6001/api/report/dailySalesReport?page=1&size=15&search=&todayDate=2022-05-26T08:44:10.594Z
dailySalesReportCard(page,size,search,patientRef,fdate,todate,modeOfPayment,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/dailySalesReport?page="+page+"&size="+size+"&search="+search+"&patientRef="+patientRef+"&fdate="+fdate+"&todate="+todate+"&modeOfPayment="+modeOfPayment+"branchRef="+branchRef)
    .pipe( 
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
dailySalesAll(search,patientRef,fdate,todate,modeOfPayment,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/dailySalesReport?search="+search+"&patientRef="+patientRef+"&fdate="+fdate+"&todate="+todate+"&modeOfPayment="+modeOfPayment+"branchRef="+branchRef)
    .pipe( 
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
dailySalesReportCardDownload(page,size,search,patientRef,todayDate,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/report/dailySalesReportDownload?page="+page+"&size="+size+"&search="+search+"&patientRef="+patientRef+"&todayDate="+todayDate+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//daily Activity report
//http://localhost:6001/api/report/dailyEnquiryReport?page=1&size=10&search&todayDate=2022-05-28T08:44:10.594Z
dailyEnquiryReportCard(page,size,search,todayDate,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/report/dailyEnquiryReport?page="+page+"&size="+size+"&search="+search+"&todayDate="+todayDate+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//http://localhost:2220/api/report/getUnsoldStock?page=1&size=10&search=&status=unsold&haCompanyRef=63cfb2b7a72cf6474351a854
unsoldStockReportCard(page,size,search,status,haCompanyRef,deviceRefId,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/getUnsoldStock?page="+page+"&size="+size+"&search="+search+"&status="+status+"&haCompanyRef="+haCompanyRef+"&deviceRefId="+deviceRefId+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
followupReport(page,size,search,patientRef,fdate,todate,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/report/followupReport?page="+page+"&size="+size+"&search="+search+"&patientRef="+patientRef
  +"&fdate="+fdate+"&todate="+todate+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
followupReportAll(search,patientRef,fdate,todate,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/report/followupReport?search="+search+"&patientRef="+patientRef+"&fdate="+fdate+"&todate="+todate+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
unsoldStockReportCardDownload(status,haCompanyRef,deviceRefId,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/getUnsoldStockDownload?status="+status+"&haCompanyRef="+haCompanyRef
  +"&deviceRefId="+deviceRefId+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 

// http://localhost:2220/api/report/getUnsoldStockByCompany
unsoldStockReportByCompanyDownload(haCompanyRefId,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/getUnsoldStockByCompany?haCompanyRefId="+haCompanyRefId+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

//http://localhost:6001/api/report/dailyPatientReport?page=1
dailyPatientReportCard(page,size,search,todayDate,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/report/dailyPatientReport?page="+page+"&size="+size+"&search="+search+"&todayDate="+todayDate+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//http://localhost:6001/api/report/dailyRepairReport?page=1&size=10&search&todayDate=2022-05-28T08:44:10.594Z
dailyRepairReportCard(page,size,search,todayDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/dailyRepairReport?page="+page+"&size="+size+"&search="+search+"&todayDate="+todayDate+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
dailyEarmoldReportCard(page,size,search,todayDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/dailyEarmoldReport?page="+page+"&size="+size+"&search="+search+"&todayDate="+todayDate+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
dailyAppointReportCard(page,size,search,todayDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/dailyAppointReport?page="+page+"&size="+size+"&search="+search+"&todayDate="+todayDate+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//Monthly sales report 
montlySalesReportCard(page,size,search,patientRef,fDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/montlySalesReport?page="+page+"&size="+size+"&search="+search+"&patientRef="+patientRef+"&fromDate="+fDate+"&toDate="+toDate+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
montlySalesAll(search,patientRef,fDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/montlySalesReport?search="+search+"&patientRef="+patientRef+"&fromDate="+fDate+"&toDate="+toDate+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
montlySalesReportCardDownload(page,size,search,patientRef,fDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/montlySalesReportDownload?page="+page+"&size="+size+"&search="+search+"&patientRef="+patientRef+"&fromDate="+fDate+"&toDate="+toDate+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//Till Date
getMonthTillDateReport(page,size,search,patientRef,fromDate,toDate,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/report/getMonthTillDateOPDReport?page="+page+"&size="+size+"&search="+search+"&patientRef="+patientRef+"&fromDate="+fromDate+"&toDate="+toDate+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getMonthTillDateAll(search,patientRef,fromDate,toDate,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/report/getMonthTillDateOPDReport?search="+search+"&patientRef="+patientRef+"&fromDate="+fromDate+"&toDate="+toDate+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getMonthTillDateReportDownload(page,size,search,patientRef,fromDate,toDate,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/report/getMonthTillDateOPDReportDownload?page="+page+"&size="+size+"&search="+search+"&patientRef="+patientRef+"&fromDate="+fromDate+"&toDate="+toDate+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//Receipt
paymentReceiptReportCard(page,size,search,receiptNo,branchName,patientName,billNo,fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/paymentReceiptReport?page="+page+"&size="+size+"&search="+search+"&receiptNo="+receiptNo+"&branchName="+branchName+"&patientName="+patientName+"&billNo="+billNo+"&fromDate="+fromDate+"&toDate="+toDate+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
paymentReceiptAll(search,receiptNo,branchName,patientName,billNo,fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/paymentReceiptReport?search="+search+"&receiptNo="+receiptNo+"&branchName="+branchName+"&patientName="+patientName+"&billNo="+billNo+"&fromDate="+fromDate+"&toDate="+toDate+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
paymentReceiptReportCardDownload(page,size,search,receiptNo,branchName,billNo,fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/report/paymentReceiptReportDownload?page="+page+"&size="+size+"&search="+search+"&receiptNo="+receiptNo+"&branchName="+branchName+"&billNo="+billNo+"&fromDate="+fromDate+"&toDate="+toDate+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//Ear Mould http://localhost:6001/api/report/getEarmoldDataByPatientName?firstName=SAGAR
EarMouldCard(firstName): Observable<any> {
  return this.http.get(this.APIService + "/report/getEarmoldDataByPatientName?firstName="+firstName)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//payment report
//http://localhost:2220/api/report/getDailyPaymentReport?patientName=shivani bhosale&page=1&size=5&search
paymentListCard(page,size,search,patientName,todayDate): Observable<any> {
  return this.http.get(this.APIService + "/report/getDailyPaymentReport?page="+page+"&size="+size+"&search="+search+"&patientName="+patientName+"&todayDate="+todayDate)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//patient Export
getPatientReportDetails(page,size,search,fromDate,toDate,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/report/getPatientReportDetailsByCustomDate?page="+page+"&size="+size+"&search="+search+"&fromDate="+fromDate+"&toDate="+toDate+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getPatientAll(search,fromDate,toDate,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/report/getPatientReportDetailsByCustomDate?search="+search+"&fromDate="+fromDate+"&toDate="+toDate+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getPatientReportDetailsDownload(page,size,search,fromDate,toDate,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/report/getPatientReportDetailsByCustomDateDownload?page="+page+"&size="+size+"&search="+search+"&fromDate="+fromDate+"&toDate="+toDate+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getPaymentHistoryDataByChequeDate(chequeDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/getPaymentHistoryDataByChequeDate?chequeDate="+chequeDate+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getTodayFollowupAllData(branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/followup/getTodayFollowupAllData?branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getPatientBirthdate(branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/patient/getPatientBirthdate?branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getAllDoctorLeaveData(fromDate,toDate): Observable<any> {
  return this.http.get(this.APIService + "/holiday/getAllDoctorLeaveData?fromDate="+fromDate+"&toDate="+toDate)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//export
paymentListExport(page,size,search,patientName,todayDate): Observable<any> {
  return this.http.get(this.APIService + "/report/getDailyPaymentReportForExport?page="+page+"&size="+size+"&search="+search+"&patientName="+patientName+"&todayDate="+todayDate)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//update brand
updateBrandDetails(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/brand/updateBrandData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updateHaColorDetails(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/haColor/updateHaColorData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
getServiceType(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/servicePopup/getServicePopupData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getDepartmentData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/department/getDepartmentData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
// /http://localhost:6001/api/test/getTestTypeData
getTestType(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/test/getTestTypeData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getServiceTypeById(pid): Observable<any> {
  return this.http.get(this.APIService + "/servicePopup/getServicePopupDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getDepartmentDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/department/getDepartmentDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getDeviceByPatientDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/dByP/getDeviceByPatientDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getTestTypeDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/test/getTestTypeDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
updateServiceType(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/servicePopup/updateServicePopupData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updateDepartmentData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/department/updateDepartmentData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updateDeviceByPatientData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/dByP/updateDeviceByPatientData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updateDeviceByPatient(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/dByP/updateDeviceByPatient?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}

updateTestTypeData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/test/updateTestTypeData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
// Style form
getStyleType(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/style/getStyleData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}  
getPatientSource(page,size,searchtext,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/patientSource/getPatientSourceData?page="+page+"&size="+size+"&search="+searchtext+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
submitStyleData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/style/saveStyleData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

updateStyleType(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/style/updateStyleData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updateSourceData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/patientSource/updatePatientSourceData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
getStyleById(pid): Observable<any> {
  return this.http.get(this.APIService + "/style/getStyleDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getSourceById(pid): Observable<any> {
  return this.http.get(this.APIService + "/patientSource/getPatientSourceDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getAllStyleTypeList(): Observable<any> {
  return this.http.get(this.APIService + "/style/getStyleAllData")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 

//device Type
getDeviceType(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/deviceType/getDeviceTypeData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 

submitDeviceData(Payload): Observable<any> {
  return this.http.post(this.APIService+"/deviceType/saveDeviceTypeData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

updateDeviceType(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/deviceType/updateDeviceTypeData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}

getDeviceTypeById(pid): Observable<any> {
  return this.http.get(this.APIService + "/deviceType/getDeviceTypeDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

getAllDeviceTypeList(): Observable<any> {
  return this.http.get(this.APIService + "/deviceType/getDeviceTypeAllData")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//update postdata
updatePostData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/post/updatePostData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
//http://localhost:6001/api/post/getPostAllData
getPostAllData(): Observable<any> {
  return this.http.get(this.APIService + "/post/getPostAllData")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//http://localhost:6001/api/post/getPostDataById?id=62d5308f4fd6debf763abe8a
getPostDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/post/getPostDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//http://localhost:6001/api/post/getPostData?page=1&size=5&search
getPostData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/post/getPostData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//http://localhost:6001/api/post/savePostData
savePostData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/post/savePostData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
// faceplate
//http://localhost:6001/api/faceplate/getFaceplateData?page=1&size=5&search
getFaceplateData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/faceplate/getFaceplateData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//http://localhost:6001/api/faceplate/saveFaceplateData
saveFaceplateData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/faceplate/saveFaceplateData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
//http://localhost:6001/api/faceplate/getFaceplateDataById?id=62f33cae215a96a5fbe65c7b
getFaceplateDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/faceplate/getFaceplateDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

updateFaceplateData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/faceplate/updateFaceplateData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
//
getAllFaceplateList(): Observable<any> {
  return this.http.get(this.APIService + "/faceplate/getFaceplateAllData")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
// shell
//http://localhost:6001/api/shell/getShellData?page=1&size=5&search
getShellData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/shell/getShellData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//http://localhost:6001/api/shell/saveShellData
saveShellData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/shell/saveShellData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
//http://localhost:6001/api/shell/getShellDataById?id=62f347e64d80a003e5a176f8
getShellDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/shell/getShellDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//
updateShellData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/shell/updateShellData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
getAllShellList(): Observable<any> {
  return this.http.get(this.APIService + "/shell/getShellAllData")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//make 
//http://localhost:2220/api/make/getMakeData?page=1&size=5&search
getMakeData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/make/getMakeData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getTestTypeData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/typeData/getTestTypeData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getTypeData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/typeData/getTypeData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getAccesoriesPlaceOrderData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/accesoriesPlaceOrderData/getAccesoriesPlaceOrderData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getSalesPersonData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/salePerson/getSalesPersonData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getTrackingStatusData(page,size,searchtext,branchRef,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/trackingStatus/getTrackingStatusData?page="+page+"&size="+size+"&search="+searchtext+"&branchRef="+branchRef+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

getPowerData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/powerData/getPowerData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getSizeData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/sizeData/getSizeData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//http://localhost:2220/api/make/saveMakeData
saveMakeData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/make/saveMakeData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
saveTestTypeData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/typeData/saveTestTypeData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
saveTypeData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/typeData/saveTypeData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
saveSizeData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/sizeData/saveSizeData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
saveSalePerson(Payload): Observable<any> {
  return this.http.post(this.APIService + "/salePerson/saveSalesPersonData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
saveTrackingStatus(Payload): Observable<any> {
  return this.http.post(this.APIService + "/trackingStatus/saveTrackingStatusData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
savePowerData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/powerData/savePowerData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
//http://localhost:2220/api/make/getMakeDataById?id=62fdbcfb3716a6bfeb785e90
getMakeDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/make/getMakeDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getPowerDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/powerData/getPowerDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getSizeDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/sizeData/getSizeDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getAccesoriesPlaceOrderDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/accesoriesPlaceOrderData/getAccesoriesPlaceOrderDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}


getTypeDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/typeData/getTypeDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
updateMakeData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/make/updateMakeData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updateSalesPersonData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/salePerson/updateSalesPersonData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updateTrackingStatusData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/trackingStatus/updateTrackingStatusData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updatePowerData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/powerData/updatePowerData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updateSizeData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/sizeData/updateSizeData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updateTypeData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/typeData/updateTypeData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updateAccesoriesPlaceOrderData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/accesoriesPlaceOrderData/updateAccesoriesPlaceOrderData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
//http://localhost:2220/api/make/getMakeAllData
getAllMakeList(): Observable<any> {
  return this.http.get(this.APIService + "/make/getMakeAllData")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//accesories
getAccesoriesData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/accesories/getAccesoriesData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getInoutTypeData(page,size,searchtext): Observable<any> { 
  return this.http.get(this.APIService + "/inoutType/getInoutTypeData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

getInoutTypeDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/inoutType/getInoutTypeDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
saveAccesoriesData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/accesories/saveAccesoriesData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
saveAccesoriesPlaceOrderData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/accesoriesPlaceOrderData/saveAccesoriesPlaceOrderData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
saveInoutTypeData(Payload): Observable<any> { 
  return this.http.post(this.APIService + "/inoutType/saveInoutTypeData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

getAccesoriesDataById(pid): Observable<any> {
  return this.http.get(this.APIService + "/accesories/getAccesoriesDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
updateAccesoriesData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/accesories/updateAccesoriesData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
updateInoutTypeData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/inoutType/updateInoutTypeData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}
getAllAccesoriesList(): Observable<any> {
  return this.http.get(this.APIService + "/accesories/getAccesoriesAllData")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
// Battery Type
getBatteryType(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/battery/getBatteryData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 

submitBatteryTypeData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/battery/saveBatteryData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

updateBatteryType(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/battery/updateBatteryData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}

getBatteryTypeById(pid): Observable<any> {
  return this.http.get(this.APIService + "/battery/getBatteryDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}


getAllBatteryTypeList(): Observable<any> {
  return this.http.get(this.APIService + "/battery/getBatteryAllData")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 

//Condition
getConditionData(page,size,searchtext): Observable<any> {
  return this.http.get(this.APIService + "/condition/getConditionData?page="+page+"&size="+size+"&search="+searchtext)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 

submitConditionData(Payload): Observable<any> {
  return this.http.post(this.APIService + "/condition/saveConditionData",Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}

updateConditionData(Payload,pid): Observable<any> {
  return this.http.put(this.APIService + "/condition/updateConditionData?id="+pid,Payload)
    .pipe(
      tap(heroes => this.log(`Updated Logs`)),
    );
}

getConditionById(pid): Observable<any> {
  return this.http.get(this.APIService + "/condition/getConditionDataById?id="+pid)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

getAllConditionTypeList(): Observable<any> {
  return this.http.get(this.APIService + "/condition/getConditionAllData")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//dashboard/getPaymentStatusCountByMonth
//http://localhost:2220/api/dashboard/getPaymentStatusCountByMonth?branchRefId=62ecc1889f25133f516c9bfc
getPaymentStatusCountByMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getPaymentStatusCountByMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//payment Status Yearly
//http://localhost:2220/api/dashboard/getPaymentStatusCountByYear?branchRefId=62df714ba7c4aa075e26c32b
getPaymentStatusCountByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getPaymentStatusCountByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//payment Status custum
//http://localhost:2220/api/dashboard/getPaymentStatusCountByCustomDate?fromDate=2022-08-01T18:30:00.000Z&toDate=2022-08-31T18:30:00.000Z&branchRefId=62df714ba7c4aa075e26c32b
getPaymentStatusCountByCustom(fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getPaymentStatusCountByCustomDate?fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

//followup
//http://localhost:2220/api/dashboard/getFollowUpCountByMonth
getFollowupStatusCountByMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getFollowUpCountByMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//http://localhost:2220/api/dashboard/getFollowUpCountByYear
getFollowupStatusCountByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getFollowUpCountByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//http://localhost:2220/api/dashboard/getFollowuUpStatusCountByCustomDate?fromDate=2022-11-01T18:30:00.000Z&toDate=2022-11-29T18:30:00.000Z
getFollowTotalCountByCustomDate(fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getFollowuUpStatusCountByCustomDate?fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//PUrchase Monthly
//http://localhost:2220/api/dashboard/getPurchaseTotalCountByMonth?branchRefId=62d7ea00cc5fcd29db6f0766
getPurchaseTotalCountByMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getPurchaseTotalCountByMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//yearly
//http://localhost:2220/api/dashboard/getPurchaseTotalCountByYear?branchRefId=62d7ea00cc5fcd29db6f0766
getPurchaseTotalCountByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getPurchaseTotalCountByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//custom
//http://localhost:2220/api/dashboard/getPurchaseTotalCountByCustomDate?fromDate=2022-08-01T18:30:00.000Z&toDate=2022-08-31T18:30:00.000Z&branchRefId=62eb7297ed3b85f8daebcc7b
getPurchaseTotalCountByCustomDate(fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getPurchaseTotalCountByCustomDate?fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//Inqury
//http://localhost:2220/api/dashboard/getInquiryCountByMonth?branchRefId=62d7ea00cc5fcd29db6f0766
getInquiryCountByMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getInquiryCountByMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//yearly
//http://localhost:2220/api/dashboard/getInquiryCountByYear?branchRefId=62d7ea00cc5fcd29db6f0766
getInquiryCountByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getInquiryCountByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//custom
//http://localhost:2220/api/dashboard/getInquiryCountByCustomDate?fromDate=2022-07-01T18:30:00.000Z&toDate=2022-08-31T18:30:00.000Z&branchRefId=62df9591a7c4aa075e26c605
getInquiryCountByCustom(fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getInquiryCountByCustomDate?fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//receiver payable
//http://localhost:2220/api/dashboard/getTotalPayableReceivebleByMonth?branchRefId=634647ae342030973741b517
getTotalPayReaciveByMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getTotalPayableReceivebleByMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//yearly
getTotalPayReaciveByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getTotalPayableReceivebleByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
// http://localhost:2220/api/dashboard/getPatientCountByMonth
getPatientCountByMonth(): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getPatientCountByMonth")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getTotalPayReaciveByCustomDate(fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getTotalPayableReceivebleByCustomDate?fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}  

//stock in hands
//http://localhost:2220/api/dashboard/stockInHandCountByMonth?branchRefId=62d7ea00cc5fcd29db6f0766
stockInHandCountByMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/stockInHandCountByMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
stockInHandCount(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/stockInHandCount?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//Ha Business
getHACountByMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getHACountByMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//yearly
//http://localhost:2220/api/dashboard/stockInHandCountByYear?branchRefId=62d7ea00cc5fcd29db6f0766
stockInHandCountByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/stockInHandCountByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}  
getHACountByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getHACountByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//http://localhost:2220/api/dashboard/stockInHandCountByCustomDate?fromDate=2022-08-01T18:30:00.000Z&toDate=2022-08-31T18:30:00.000Z&branchRefId=62eb7297ed3b85f8daebcc7b
stockInHandCountByCustomDate(fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/stockInHandCountByCustomDate?fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//Ha Business getHACountByYear
getHACountByCustomDate(fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getHACountByCustomDate?fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//Mode of payment monthly
//http://localhost:2220/api/dashboard/getPaymentModeCountByMonth?branchRefId=62d7ea00cc5fcd29db6f0766
getPaymentModeCountByMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getPaymentModeCountByMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//yearly
//http://localhost:2220/api/dashboard/getPaymentModeCountByYear?branchRefId=62d7ea00cc5fcd29db6f0766
getPaymentModeCountByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getPaymentModeCountByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//custum
getPaymentModeCountByCustomDate(fromDate,toDate): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getPaymentModeCountByCustomDate?fromDate="+fromDate+"&toDate="+toDate)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

//cheque status monthly
//http://localhost:6001/api/dashboard/getChequeStatusCountByMonth
//http://localhost:2220/api/dashboard/getChequeStatusCountByMonth?branchRefId=62d7ea00cc5fcd29db6f0766
getChequeStatusCountByMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getChequeStatusCountByMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//yearly
//http://localhost:6001/api/dashboard/getChequeStatusCountByYear
//http://localhost:2220/api/dashboard/getChequeStatusCountByYear?branchRefId=62d7ea00cc5fcd29db6f0766
getChequeStatusCountByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getChequeStatusCountByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//custum
//http://localhost:6001/api/dashboard/getChequeStatusCountByCustomDate?fromDate=2022-06-01T18:30:00.000Z&toDate=2022-06-25T18:30:00.000Z
getChequeStatusCountByCustomDate(fromDate,toDate): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getChequeStatusCountByCustomDate?fromDate="+fromDate+"&toDate="+toDate)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}



// Sales
//http://localhost:2220/api/dashboard/getTotalSalesByMonth?branchRefId=62d7ea00cc5fcd29db6f0766
getTotalSalesByMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getTotalSalesByMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//inqry to patint
getInquiryConvertPatientCountByMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getInquiryConvertPatientCountByMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    ); 
}
//yealy
//http://localhost:2220/api/dashboard/getTotalSalesByYear?branchRefId=62ecc1889f25133f516c9bfc
getTotalSalesByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getTotalSalesByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getInquiryConvertPatientCountByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getInquiryConvertPatientCountByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    ); 
} 
//custum
//http://localhost:2220/api/dashboard/getTotalSalesByCustomdate?fromDate=2022-09-01T18:30:00.000Z&toDate=2022-09-15T18:30:00.000Z&branchRefId=62eb7297ed3b85f8daebcc7b
getTotalSalesByCustomdate(fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getTotalSalesByCustomdate?fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

getInquiryConvertPatientCountByCustom(fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getInquiryConvertPatientCountByCustom?fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

//apt Status dashboard/getTotalTodaysAppointmentCount?todayDate=2022-06-08T08:44:10.594Z
getTotalTodaysAppointmentCount(todayDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getTotalTodaysAppointmentCount?todayDate="+todayDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//Avg Selling price 
//http://localhost:2220/api/dashboard/getASPPerMonth?branchRefId=6347d920bd3fcd7938497241
getASPPerMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getASPPerMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getASPPerYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getASPPerYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//http://localhost:2220/api/dashboard/getASPPerCustomDate?fromDate=2022-09-05T18:30:00.000Z&toDate=2022-09-20T18:30:00.000Z
//http://localhost:2220/api/dashboard/getASPPerCustomDate?fromDate=2022-10-01T18:30:00.000Z&toDate=2022-10-15T18:30:00.000Z&branchRefId=6347d920bd3fcd7938497241
getASPPerCustomDate(fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getASPPerCustomDate?fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

// getStepperInfoByMonth
getStepperInfoByMonth(): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getStepperInfoByMonth")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//yearly
getStepperInfoByYear(): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getStepperInfoByYear")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//
getStepperInfoByCustomDate(fromDate,toDate): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/getStepperInfoByCustomDate?fromDate="+fromDate+"&toDate="+toDate)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//clinic expenses
//http://localhost:2220/api/dashboard/clinicExpencesTotalCountByMonth?branchRefId=62d7ea30cc5fcd29db6f076f
clinicExpencesTotalCountByMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/clinicExpencesTotalCountByMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//custum
//http://localhost:2220/api/dashboard/clinicExpencesTotalCountByCustomDate?fromDate=2022-08-01T18:30:00.000Z&toDate=2022-08-15T18:30:00.000Z&branchRefId=62d7ea00cc5fcd29db6f0766
clinicExpencesTotalCountByCustomDate(fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/clinicExpencesTotalCountByCustomDate?fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//Appointment DoneByDoctorInMonth
//http://localhost:2220/api/dashboard/appointmentDoneByDoctorInMonth?branchRefId=62d7ea00cc5fcd29db6f0766
appointmentDoneByDoctorInMonth(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/appointmentDoneByDoctorInMonth?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
//yearly
//http://localhost:2220/api/dashboard/appointmentDoneByDoctorInYear?branchRefId=62d7ea30cc5fcd29db6f076f
appointmentDoneByDoctorInYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/appointmentDoneByDoctorInYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//custum
//http://localhost:2220/api/dashboard/appointmentDoneByDoctorInCustomDate?fromDate=2022-08-01T18:30:00.000Z&toDate=2022-08-31T18:30:00.000Z&branchRefId=62d7ea00cc5fcd29db6f0766
appointmentDoneByDoctorInCustomDate(fromDate,toDate,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/appointmentDoneByDoctorInCustomDate?fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
// Service and Test and Rate
getServiceRateTotalCountByMonth(): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/serviceRateTotalCountByMonth")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//year
getServiceRateTotalCountByYear(): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/serviceRateTotalCountByYear")
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
// Clinic Expence year
//http://localhost:2220/api/dashboard/clinicExpencesTotalCountByYear?branchRefId=62d7ea30cc5fcd29db6f076f
getclinicExpencesTotalCountByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/clinicExpencesTotalCountByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
//year
clinicExpencesTotalCountByYear(branchRef): Observable<any> {
  return this.http.get(this.APIService + "/dashboard/clinicExpencesTotalCountByYear?branchRefId="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
clinicHoliday(payload): Observable<any> {
  return this.http.post(this.APIService + "/holiday/save", payload)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
addHolidayAlert(payload): Observable<any> {
  return this.http.post(this.APIService + "/paymenthistory/addHolidayAlert", payload)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

getClinicHolidays(page, size, search, id,branchRef): Observable<any> {
  return this.http.get(this.APIService + "/holiday/getHolidayData?page=" + page + "&size=" + size + "&search=" + search + "&id=" + id+"&branchRef="+branchRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
updateClinicHoliday(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/holiday/updateHolidayData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getDoctorLeaverData(page,size,search,id): Observable<any> {
  return this.http.get(this.APIService + "/holiday/getDoctorLeaveData?page="+page+"&size="+size+"&search="+search+"&id="+id)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getDoctorLeaveDataByDoctorId(fDate,doctorRefId): Observable<any> {
  return this.http.get(this.APIService + "/holiday/getDoctorLeaveDataByDoctorId?fDate="+fDate+"&doctorRefId="+doctorRefId)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)), 
    );
}
getDoctorLeaveDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/holiday/getDoctorLeaveDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}

getDoctorLeaveDataByRefId(did): Observable<any> {
  return this.http.get(this.APIService + "/holiday/getDoctorLeaveDataById?doctorRef="+did)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getBranchHolidayDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/holiday/getHolidayDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
saveDoctorLeave(payload): Observable<any> {
  return this.http.post(this.APIService + "/holiday/saveDoctorLeave", payload)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
updateDoctorLeave(id,payload): Observable<any> {
  return this.http.put(this.APIService + "/holiday/updateDoctorLeaveData?id="+id,payload)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}
getChequeNotify(): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/getChequeNotify")
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}
getBounceNotify(): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/getBounceNotify")
    .pipe(
      tap(heroes => this.log(`Fetched Data`)),
    );
}

// alert api
//http://localhost:6001/api/paymenthistory/geAlerts?page=1&size=2&search=&chequeType=
getAlertData(page,size,search,chequeType,fromDate,toDate,branchRefId): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/geAlerts?page="+page+"&size="+size+"&search="+search+"&chequeType="+chequeType+"&fromDate="+fromDate+"&toDate="+toDate+"&branchRefId="+branchRefId)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
AddchequeNotification(): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/AddchequeNotification")
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
checkUpcommingHolidays(): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/checkUpcommingHolidays")
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
// Update Notify
updateViewAlert(chequeNo,payload): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/updateViewAlert?chequeNo="+chequeNo,payload)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
} 
//get Alert
getAlertsDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/getAlertsDataById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
getSalesPersonDataById(id): Observable<any> {
  return this.http.get(this.APIService + "/salePerson/getSalesPersonById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
getTrackingStatusById(id): Observable<any> {
  return this.http.get(this.APIService + "/trackingStatus/getTrackingStatusById?id="+id)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}

getAlertStatusCount(branchRefId): Observable<any> {
  return this.http.get(this.APIService + "/paymenthistory/getAlertStatusCount?branchRefId="+branchRefId)
    .pipe(
      tap(heroes => this.log(`Fetched Data`))
    )
}
addPatientByDevice(Payload): Observable<any> {
  return this.http.post(this.APIService + "/dByP/saveDeviceByPatientData", Payload)
    .pipe(
      tap(heroes => this.log(`Created Logs`)),
    );
}
//get device by id dByP/getDeviceByPatientDataById?patientRefId=
getDeviceByPatientById(patientRefId): Observable<any> {
  return this.http.get(this.APIService + "/dByP/getDeviceByPatientDataById?patientRefId="+patientRefId)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getInquiryPatientDuplicate(firstName,lastName,middleName): Observable<any> {
  return this.http.get(this.APIService + "/inquiry/getInquiryPatientDuplicateCheck?firstName="+firstName+"&lastName="+lastName+"&middleName="+middleName)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getDeviceDetailsByPatient(page,size,search,patientRef,type): Observable<any> {
  return this.http.get(this.APIService + "/dByP/getDeviceDetailsByPatient?page="+page+"&size="+size+"&search="+search+"&patientRef="+patientRef+"&type="+type)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
} 
getRepairDataForGlobalSearch(page,size,search,branchRef,patientRef,SrNo,clinicRef): Observable<any> {
  return this.http.get(this.APIService + "/repair/getRepairDataForGlobalSearch?page="+page+"&size="+size+"&search="+search+"&branchRef="+branchRef+"&patientRef="+patientRef+"&SrNo="+SrNo+"&clinicRef="+clinicRef)
    .pipe(
      tap(heroes => this.log(`Fetch Logs`)),
    );
}  
//http://localhost:2220/api/repair/getRepairDataForGlobalSearch?page=1&size=3&search=&branchRef=&patientRef=6305c317799ba223ae948feb&leftSrNo=444&rightSrNo


//http://localhost:6001/api/paymenthistory/getAlertStatusCount
// getAlertStatusCount(branchRefId): Observable<any> {
//   return this.http.get(this.APIService + "/paymenthistory/getAlertStatusCount?branchRefId="+branchRefId)
//     .pipe(
//       tap(heroes => this.log(`Fetched Data`))
//     )
// }
//http://localhost:6001/api/paymenthistory/AddchequeNotification
  back(): void {
    this.history.pop()
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.router.navigateByUrl('/')
    }
  }
  private log(message: string) {
    ////
  }

  openSnackbar(message: string) {
    return this.snackBar.open(message, "close",
      { verticalPosition: 'top', horizontalPosition: 'right', duration: 3000, panelClass: ['snackbarStyle'] });
  }
  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || "";
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    ////
  }



  downloadFile(data, filename= 'data') {
     var keys = Object.keys(data[0]); 
  //// 
  let csvData = this.ConvertToCSV(data, keys);
  //  //// 
  //   ////
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
        dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

// downloadFileq(data, filename='data') 
// { var keys = Object.keys(data[0]); 
//   //// 
//   let csvData = this.ConvertToCSV(data, keys);
//    //// 
//   let blob = new Blob(['\ufeff' + csvData], 
//   { type: 'text/csv;charset=utf-8;' });
//    let dwldLink = document.createElement("a");
//    let url = URL.createObjectURL(blob);
//    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1; if (isSafariBrowser) {  
//     //if Safari open in new window to save file with random filename.    
//     dwldLink.setAttribute("target", "_blank"); }
//      dwldLink.setAttribute("href", url); 
//      dwldLink.setAttribute("download", filename + ".csv");
//       dwldLink.style.visibility = "hidden";
//        document.body.appendChild(dwldLink); 
//        dwldLink.click(); document.body.removeChild(dwldLink);
       
//   }

    
    ConvertToCSV(objArray, headerList) {
     const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
     let str = '';
     let row = 'S.No,';

     // tslint:disable-next-line:forin
     for (const index in headerList) {
         row += headerList[index] + ',';
     }
     row = row.slice(0, -1);
     str += row + '\r\n';
     for (let i = 0; i < array.length; i++) {
      ////
     
         let line = (i + 1) + '';
         // tslint:disable-next-line:forin
         for (const index in headerList) {
          
            const head = headerList[index];

            line += ',' + array[i][head];
         }
         str += line + '\r\n';
     }
     return str;
 }
}