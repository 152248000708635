import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { GetColorName } from "hex-color-to-color-name";

@Component({
  selector: 'app-appointment-type',
  templateUrl: './appointment-type.component.html',
  styleUrls: ['./appointment-type.component.scss']
})
export class AppointmentTypeComponent implements OnInit {


  appointmentTypeForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=10;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  allColorr=[
    {name:'Black', hex:'#000000'},
    {name:'White', hex:'#FFFFFF' },
    {name:'Red',hex:'#FF0000'},
     { name:'Lime',hex:'#00FF00' },
     { name:'Blue', hex:'#0000FF' },
      {name:'Yellow', hex:'#FFFF00'},
      { name:'Cyan/Aqua', hex:'#00FFFF' },
       {name:'Magenta/Fuchsia', hex:'#FF00FF'},
       { name:'Silver',hex:'#C0C0C0'},
        { name:'Gray', hex:'#808080'},
        { name:'Maroon',hex:'#800000' },
         {name:'Olive',hex:'#808000' }, 
         {  name:'Green',hex:'#008000' }, 
          { name:'Purple',hex:'#800080'}, 
            {name:'Teal',hex:'#008080'}, 
              { name:'Navy',hex:'#000080' }, 
  ]
  allAppointmentType=[
     { name:'Assessment',value:'Assessment' },
     { name:'Fitting', value:'Fitting' },
      {name:'Sub-Fitting', value:'Sub-Fitting'},
      { name:'Review Or Follow Up', value:'Review Or Follow Up' },
       {name:'Aid Adjustment', value:'Aid Adjustment'},
       { name:'Service',value:'Service'},
        { name:'HAT (Hearing Aid Trial)', value:'HAT (Hearing Aid Trial)'},
        { name:'EarImpression',value:'EarImpression' },
         {name:'Reassessment',value:'Reassessment' }, 
         {  name:'Screening',value:'Screening' }, 
          { name:'Consultation',value:'Consultation'}, 
            {name:'Remote Fine Tuning (RFT)',value:'Remote Fine Tuning (RFT)'}, 
              { name:'Busy / Blockout',value:'Busy / Blockout' }, 
  ]
  
  filteredList: { name: string; hex: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  allAppointTypeList: any;
  preAppointType = "";
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService 
  ) { }
  userPermission:any;
  appointmentTypeCreate= true;
  appointmentTypeUpdate= true;

  ngOnInit(): void {
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.filteredList = this.allColorr.slice();
    this.filteredListAppoinmentType = this.allAppointmentType.slice();
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Appointment Type' && element.create == false)
      {
          this.appointmentTypeCreate = false;  
      }
      if(element.pageName == 'Appointment Type' && element.update == false)
      {
          this.appointmentTypeUpdate = false;  
      }  
      
    });
  }
    this.appointmentTypeForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "appointmentType": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z-&\\s]*$")]),
      "color": new FormControl('',Validators.required),
      "colorName": new FormControl(''),
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "createdAt": new Date(),
      "updatedAt":new Date(),
      "searchAppointment": new FormControl(''),
      "branchName": new FormControl(),
    });
  
    this.fetchAppointmentList();
    if(this.userType !== 'Super Admin'){
      this.fetchAllAppointmentData(this.branchRef);
    }
    
  this.removeValidator();
 // this.fetchBranchList();
  this.getLastId();
  //
   
  //this.appointmentTypeForm.get("branchRefId").setValue(this.branchRef);
 // this.getAllBranch();
}
getAllBranch()
{
  let clinicRef= sessionStorage.getItem('clinicRef');
  this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
    if (res.status) {        
      let length =res.data.length - 1;
      this.appointmentTypeForm.controls["branchRefId"].setValue(res.data[length]._id);
      this.branchClick(res.data[length]._id,this.branchName);
      if(this.userType == 'Super Admin')
      {  
        this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
          this.spinner.hide();
          this.appointmentTypeForm.get("branchName").setValue(res.data[length].branchName);
         });
      }
     
    }
  });
}
 
  setValidator() { 
    this.appointmentTypeForm.controls['appointmentType'].setValidators(Validators.required);
    this.appointmentTypeForm.controls['appointmentType'].updateValueAndValidity();

    this.appointmentTypeForm.controls['color'].setValidators(Validators.required);
    this.appointmentTypeForm.controls['color'].updateValueAndValidity();
    

  }

  removeValidator() { 
    this.appointmentTypeForm.controls['appointmentType'].setErrors(null);
    this.appointmentTypeForm.controls['appointmentType'].clearValidators();
    this.appointmentTypeForm.controls['appointmentType'].updateValueAndValidity();

    this.appointmentTypeForm.controls['color'].setErrors(null);
    this.appointmentTypeForm.controls['color'].clearValidators();
    this.appointmentTypeForm.controls['color'].updateValueAndValidity();

  }

  fetchAppointmentList(){

    this.spinner.show();
    let pg = 1;
    let size = 10;
    let search='';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.searchText = this.appointmentTypeForm.get('searchAppointment').value;
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAppointmentTypeData(pg,size,search,branchRef,clinicRef).subscribe(res => {
        //
        this.spinner.hide();
        this.listOfAppointment = res.data.data;
        this.totalLength=res.data.totalItems;
        if(res.status){
         // this.apimicroservice.openSnackbar(res.message);
          
        }else{
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getAppointmentTypeData(pg,size,search,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        this.spinner.hide();
        this.listOfAppointment = res.data.data;
        this.totalLength=res.data.totalItems;
        if(res.status){
          //this.apimicroservice.openSnackbar(res.message);
          
        }else{
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
   }
   
  }

  submitAppointmentType(){
    this.setValidator();
    // if(this.userType == 'Super Admin')
    // {
    //   this.appointmentTypeForm.controls['branchRefId'].setValidators(Validators.required);
    //   this.appointmentTypeForm.controls['branchRefId'].updateValueAndValidity();
    // }
    // else{
    //   this.appointmentTypeForm.controls['branchRefId'].setErrors(null);
    //   this.appointmentTypeForm.controls['branchRefId'].clearValidators();
    //   this.appointmentTypeForm.controls['branchRefId'].updateValueAndValidity();
    // }
    if (this.appointmentTypeForm.invalid) {
      this.appointmentTypeForm.get("appointmentType").markAsTouched();
      this.appointmentTypeForm.get("color").markAsTouched();
     
      
      return;
    } else {
      this.addAppointmentType();
    }
  }
  addAppointmentType(){
    
    this.spinner.show();
    let payload = this.appointmentTypeForm.value;
    payload.createdAt= new Date();
      //
      payload.clinicRefId = this.clinicRef;
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
   
      
      this.apimicroservice.addAppointmentTypeData(payload).subscribe(res => {
        this.spinner.hide();
        // this.next.emit();
        if (res.status) {
          this.apimicroservice.openSnackbar(res.message);
         // this.appointmentTypeForm.reset();
         this.ngOnInit();
        } else {
          // this.apimicroservice.openSnackbar(res.message);
          //
          
        } 
      },(ERROR:HttpErrorResponse) => {
        this.apimicroservice.openSnackbar(ERROR.error.message);
       this.spinner.hide();
    });
  }

  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getAppointmentTypeDataById(id).subscribe(res => {
      this.submitButton=false;
       this.updateButton =true;
       this.spinner.hide();
       
       let data = res.data[0];
 
         this.appointmentTypeForm = this.fb.group({
     
          "branchWiseId": new FormControl(data.branchWiseId),
           "appointmentType": new FormControl(data.appointmentType,[Validators.required,Validators.pattern("^[a-zA-Z-&\\s]*$")]),
           "color": new FormControl(data.color,Validators.required),
           "colorName": new FormControl(data.colorName),
           "branchRefId": new FormControl(data.branchRefId),
           "clinicRefId": new FormControl(data.clinicRefId),
           "updatedAt": this.date,
           "searchAppointment": new FormControl(''),
           "branchName": new FormControl(data.branchName),
         });
         this.superBranchId = data.branchWiseId;
         this.appointmentId = id;
         this.preAppointType = data.appointmentType;
         if(this.userType == 'Super Admin'){
          this.fetchAllAppointmentData(data.branchRefId);
        }
         
       
     });


  }

  colorClick(e){
    this.appointmentTypeForm.controls.colorName.setValue(e);
    //.value);
    
  }

  onSelectColor(event:any){
  //
  let val = event.target.value;
  const colorName = GetColorName(val);
  this.appointmentTypeForm.controls.colorName.setValue(colorName);
    //.value);
  }

   updateAppointmentType() {
     this.setValidator();
    //  if(this.userType == 'Super Admin')
    //  {
    //    this.appointmentTypeForm.controls['branchRefId'].setValidators(Validators.required);
    //    this.appointmentTypeForm.controls['branchRefId'].updateValueAndValidity();
    //  }
    //  else{
    //    this.appointmentTypeForm.controls['branchRefId'].setErrors(null);
    //    this.appointmentTypeForm.controls['branchRefId'].clearValidators();
    //    this.appointmentTypeForm.controls['branchRefId'].updateValueAndValidity();
    //  }
    if (this.appointmentTypeForm.invalid) {
      this.appointmentTypeForm.get("appointmentType").markAsTouched();
      this.appointmentTypeForm.get("color").markAsTouched();
        this.appointmentTypeForm.get("branchRefId").markAsTouched();
     
      return;
    } else {
   
    }
    this.update();
  }

  update(){
    let isAvailable = "No";
    let appType = "";
    let payload = this.appointmentTypeForm.value;
     appType = this.appointmentTypeForm.get('appointmentType').value;
  //   if(appType.toLowerCase() !== this.preAppointType.toLowerCase()){
  //     this.allAppointTypeList.forEach(element =>{
  //       if(element.appointmentType.toLowerCase() == appType.toLowerCase()){
  //         isAvailable = "Yes";
  //       }
  //     });
  //     //
  //     if(isAvailable == "Yes"){
  //       this.apimicroservice.openSnackbar("Appointment Type Already Exist");
  //     }else{
  //       this.spinner.show();
  //   let payload = this.appointmentTypeForm.value;
  //   if(this.userType !== 'Super Admin')
  //    {
  //      payload.branchRefId = this.branchRef;
  //      payload.clinicRefId = this.clinicRef;
  //      payload.branchName = this.branchName;
  //    }
     
  //   this.apimicroservice.updateAppointmentTypeData(this.appointmentId,payload).subscribe(res => {
  //     this.spinner.hide();
  //     if (res.status) {
  //       this.apimicroservice.openSnackbar(res.message);
  //       this.ngOnInit();
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   },(ERROR:HttpErrorResponse) => {
  //     this.apimicroservice.openSnackbar(ERROR.error.message);
  //    this.spinner.hide();
  // });
  //   this.submitButton=true;
  //   this.updateButton=false;
  //     }
  //   }else{
    
    if(appType.toLowerCase() !== this.preAppointType.toLowerCase()){
    this.apimicroservice.getAppointmentTypeExist(payload).subscribe(res => {
      //
      if(res.data.length > 0){
        this.apimicroservice.openSnackbar(res.message);
      }else{
   //
    this.spinner.show();
    let payload = this.appointmentTypeForm.value;
    payload.clinicRefId = this.clinicRef;
    // if(this.userType !== 'Super Admin')
    //  {
    //    payload.branchRefId = this.branchRef;
    //    payload.clinicRefId = this.clinicRef;
    //    payload.branchName = this.branchName;
    //  }
     
    this.apimicroservice.updateAppointmentTypeData(this.appointmentId,payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.submitButton=true;
        this.updateButton=false;
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     this.spinner.hide();
  });
    
      }
     
    });
  }else{
    //
    this.spinner.show();
    let payload = this.appointmentTypeForm.value;
    payload.clinicRefId = this.clinicRef;
    // if(this.userType !== 'Super Admin')
    //  {
    //    payload.branchRefId = this.branchRef;
    //    payload.clinicRefId = this.clinicRef;
    //    payload.branchName = this.branchName;
    //  }
     
    this.apimicroservice.updateAppointmentTypeData(this.appointmentId,payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
        this.submitButton=true;
    this.updateButton=false;
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
     this.spinner.hide();
  });
    
  }
 
//}
  }


  onPageChange(event){
    this.spinner.show();
   this.page=event;
   let clinicRef= sessionStorage.getItem('clinicRef');
   let branchRef= sessionStorage.getItem('branchRef');
   let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
   let branchRefSuperAdmin= '';
     this.searchText = this.appointmentTypeForm.get('searchAppointment').value;
     if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAppointmentTypeData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        this.spinner.hide();
         if (res.status) {
           this.listOfAppointment = res.data.data;
           this.totalLength=res.data.totalItems;
         } else {
           this.apimicroservice.openSnackbar("Something went wrong");
         }
   
       })
     } else {
      this.apimicroservice.getAppointmentTypeData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
         if (res.status) {
           this.listOfAppointment = res.data.data;
           this.totalLength=res.data.totalItems;
         } else {
           this.apimicroservice.openSnackbar("Something went wrong");
         }
   
       })
    }
    
  }

  onKeyUpEvent(event: any) {
    this.searchText = this.appointmentTypeForm.get('searchAppointment').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAppointmentTypeData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
      
        if (res.status) {
          this.listOfAppointment = res.data.data;
          this.totalLength=res.data.totalItems;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
    } else {
      let page =1;
      let size = 10;
      //
      
      this.apimicroservice.getAppointmentTypeData(page,size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      
        if (res.status) {
          this.listOfAppointment = res.data.data;
          this.totalLength=res.data.totalItems;
          this.page=res.data.pageNumber;
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
  
      })
   }
    

  }
  cancelUpdate(){
    this.appointmentTypeForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.ngOnInit();
  }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  toAppointmentTypeForm() {
    document.getElementById("toAppointmentTypeForm").scrollIntoView();
  }
  toAppointmentTypeList() {
    document.getElementById("toAppointmentTypeList").scrollIntoView();
  }

  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
      } else {
       // this.apimicroservice.openSnackbar(res.message);
      }
        this.appointmentTypeForm.get("branchRefId").setValue(this.branchRef);
    })
  } 
  branchClick(id,branchName)
  {

    this.appointmentTypeForm.controls["branchName"].setValue(branchName);

   this.apimicroservice.getBranchById(id).subscribe(res => { 
     //
       
     let data = res.data[0];
     let payload = this.appointmentTypeForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.appointmentTypeForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if(this.updateButton == false){
        this.spinner.show();
        this.apimicroservice.getAppointmentTypeBranchLastId(payload.branchRefId).subscribe(res => {
          //   
         
          if (res['status']) {
            let appointmentIdd = res['data']['totalItems'];
            this.appointmentTypeForm.controls['branchWiseId'].setValue(appointmentIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
  getLastId()
  {
    // if(this.userType !== 'Super Admin'){
    //   this.apimicroservice.getAppointmentTypeBranchLastId(this.branchRef).subscribe(res => {
    //     //   
       
    //     if (res['status']) {
    //       let appointmentIdd = res['data']['totalItems'];
    //       this.appointmentTypeForm.controls['branchWiseId'].setValue(appointmentIdd + 1);
    //     } else {
    //       this.apimicroservice.openSnackbar("Something went wrong");
    //     }
       
    //   })
    // }else{
      this.apimicroservice.getAppointmentTypeAllData().subscribe(res => {
        //   
        
        if (res['status']) {
          let appointmentIdd = res['data']['totalItems'];
          this.superBranchId = appointmentIdd + 1;
          this.appointmentTypeForm.controls['branchWiseId'].setValue(appointmentIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
  //  }
    
  }
   openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      }); 
       dialogRef3.afterClosed().subscribe((result) => {
        let clinicRef= sessionStorage.getItem('clinicRef');
        this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
          //  
          if (res.status) {    
            this.BranchList = res.data;
            this.filteredListBranch= this.BranchList.slice();
            this.appointmentTypeForm.get("branchRefId").setValue(res.data[0]._id)
          } else {
            this.apimicroservice.openSnackbar(res.message);
          }
        })
       // this.fetchBranchList();
      })
  }

  fetchAllAppointmentData(branchRef){
    this.apimicroservice.getAppointmentTypeAllDataByBranch(branchRef).subscribe(res => {
      //  
      if (res.status) {    
        this.allAppointTypeList = res.data.data;
        
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
        
    })
  }
  onback() {
    this.apimicroservice.back()
  }
}
