const envObject = {
  //local
  // serviceUrl: 'http://localhost:2226/api',
  // serviceUrl1: 'http://localhost:2226/api',
  //production
  serviceUrl: " https://api.qa.audiologicpro.com/api",
  serviceUrl1: "https://api.qa.audiologicpro.com/api",

  // serviceUrl: 'https://audiology-backend-new.herokuapp.com/api',  //new heroku Urll
 // serviceUrl:"http://audiology-micro.flixirsolutions.com/api" 
    // serviceUrl: 'http://194.5.152.32:2226/api',
    // serviceUrl1: 'http://194.5.152.32:9909/api',
    // serviceUrl: 'https://audiology-microservice.herokuapp.com/api',
    //serviceUrl: 'https://microservice-audiology.herokuapp.com/api', //this is current url
   // serviceUrl: 'https://audiology-microservice-qa.herokuapp.com/api',  //this is QA url
};  
// /https://microservice-audiology.herokuapp.com/   
/// http://207.244.239.200:6001/api  
export const environment = { 
  production: false,  
  serviceUrl: envObject.serviceUrl,
  serviceUrl1: envObject.serviceUrl1  
}
