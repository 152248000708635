import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner"; 
@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

  errormessage: string;
  searchText: any;
  userlist: any;
  totalLength: any;
  id: any;
  rolesList: any;
  BranchList: any;
  userId: string;
  userdetails: any;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService
  ) {}
  permissionForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  cancelButton=false;
  loggedId: any;
  myimage;
  date: any;
  size:number = 5;
  page2:number = 1; 
  isPassword= true; 
  //permission code
  @Output() prev = new EventEmitter<void>();
  user_role:any;
  customerType:any;
  tab = true;
  page= true;
  view= false;
  listofCustomer:any;
  listofuser:any;
  dashboard_view:any;
  Tab1 = false;
  Tab2 = false;
  Tab3 = false;
  Tab4 = false;
  CreateTab1= false;
  CreateTab2= false;
  CreateTab3= false;
  CreateTab4= false;
  UpdateTab1= false;
  UpdateTab2= false;
  UpdateTab3= false;
  UpdateTab4= false;
  DeleteTab1= false;
  DeleteTab2= false;
  DeleteTab3= false;
  DeleteTab4= false;
  CreateTab= true;
  permission :any = [];
  user : any = [];
  selectedItemsList: any;
  listofcustuser: any;
  userSelectList:any = [];
  allPages:any = [];
  tempArray:any = [];
  customerRefId:any;
  userData: any;
  listOfRole: any;
  checkCust:Boolean = true;
  checkUser:Boolean = true;
  disableField:Boolean = false;
  tabenable: boolean = false;
  stocktabenable: boolean =false;
  userPermission:any;
  userType:any;
  custRefId:any;
  permissionList=[];
  permissionCreate = true;
  permissionUpdate = true;
  clinicRef: string;
  //end permission code
  ngOnInit() {
    ///permission code
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.checkCust = true;
    this.checkUser = true;
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('type');
    this.custRefId = sessionStorage.getItem('custRefId');
    if(this.userType !== 'Super Admin'){
    this.userPermission.forEach((element) => {    
      if(element.pageName == 'Permissions' && element.create == false)
      {
          this.permissionCreate = false;  
      }
      if(element.pageName == 'Permissions' && element.update == false)
      {
          this.permissionUpdate = false;  
      }
    });
  }
    this.allPages = [];
    //
    console.log("this.router.configLe",this.router.config.length);
    let arr = [];
    this.allPages = [];
    this.router.config.forEach((e,i)=>
    {


    
       if(e.data !== undefined){
            arr.push(e.data.name);
         
          //  this.allPages.push({pageName:e.data.name,selectAll:false,view:false,create:false,update:false,delete:false,isTab:e.data.isTab});
            if(this.allPages.indexOf(e.data.name) === -1) {
              this.allPages.push({pageName:e.data.name,selectAll:false,view:false,create:false,update:false,delete:false,isTab:e.data.isTab});
    
          }
    }

    // if(e.data !== undefined){
    //     this.allPages.push({pageName:e.data.name,selectAll:false,view:false,create:false,update:false,delete:false,isTab:e.data.isTab,id:i,isStockTab:e.data.isStockTab});
    //   }
    })
    console.log("fffff",this.allPages);
    let data = [];
    // const ids = this.allPages.map(o => o.pageName); 
    // data= this.allPages.filter(({ pageName }, index) => !ids.includes(pageName, index + 1))
    // console.log("arrarr",arr); 
    // console.log("arrarrlength",arr.length);
    // console.log("arrarroooo",arr);
    // console.log("datadata",data);
    // this.allPages =data;



      //     if(routePath !== undefined){
      //   this.allPages.push({pageName:routePath.name,selectAll:false,view:false,create:false,update:false,delete:false,isTab:routePath.isTab,id:i,isStockTab:routePath.isStockTab});
      // }
    // for(let i=0;i<this.router.config.length;i++){
    //   let routePath = this.router.config[i].data;
    //    console.log("routePath.nameroutePath.name",routePath);
       
      
    //   if(routePath !== undefined){
    //     this.allPages.push({pageName:routePath.name,selectAll:false,view:false,create:false,update:false,delete:false,isTab:routePath.isTab,id:i,isStockTab:routePath.isStockTab});
    //   }
    //   // else{ 
    //   // this.allPages.push({pageName:routePath.name,selectAll:false,view:false,create:false,update:false,delete:false,isTab:routePath.isTab,id:i,isStockTab:routePath.isStockTab});
    //   // //console.log("this.allPages",this.allPages);
    //   // }
        //  console.log("this.allPages1111",this.allPages);
    // }
    this.tempArray = this.allPages;
    //permission code end
    this.date = new Date();
    this.permissionForm = this.fb.group({
      "userType": new FormControl('',Validators.required),
      "userID": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z]*$|^[A-Za-z]\\w*$"),Validators.maxLength(25),Validators.minLength(5)]),
      "userName": new FormControl('',Validators.required),
      "emailId": new FormControl('',[Validators.required,Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      // "password": new FormControl('', [Validators.required,
      //   Validators.pattern('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$')]),
      "status": new FormControl('',Validators.required),
      "roleRefId": new FormControl('',Validators.required),
      "branchRefId": new FormControl('',Validators.required),
      "createdAt": this.date,
      "searchUser": new FormControl(''),
      "clinicRefId": new FormControl(),
      "selectAllPages":new FormControl(false),
      //permission code
      "Page": new FormControl(true, [Validators.required]),
      "Tab": new FormControl(''),
      "View": new FormControl(''),
      "userImage": '',
      "doctorRefId": new FormControl(),
      "clinicalStaffRefId": new FormControl(),
      "userPermission": new FormControl(),
    });

    this.permissionForm.controls["status"].setValue("Active");
    this.fetchRoleList();
 //   this.fetchBranchList();
    this.userId = localStorage.getItem('userId');

    if(this.userId){
      this.prepopulation(this.userId);
      }else{
        this.disableField = true;
      }
  }

  submitUser() {
    // if (this.permissionForm.invalid) {
    //   this.permissionForm.get("userType").markAsTouched();
    //   this.permissionForm.get("userName").markAsTouched();
    //   this.permissionForm.get("emailId").markAsTouched();
    //   //this.permissionForm.get("password").markAsTouched();
    //   this.permissionForm.get("status").markAsTouched();
    //   this.permissionForm.get("roleRefId").markAsTouched();
    //   this.permissionForm.get("branchRefId").markAsTouched();

    
    //   return;
    // } else {
      let userpayload= sessionStorage.getItem("userpayload");
      this.userdetails = JSON.parse(userpayload);
      this.spinner.show();
      this.date = new Date();
      let permissionPayload = this.permissionForm.value;
      let payload = this.userdetails;
      
      payload.userPermission = this.allPages;
      //console.log("all user payload==",payload);
      this.apimicroservice.addUser(payload).subscribe((res) => {
        this.spinner.hide();
        this.prev.emit();
        if (res.status) {
          this.apimicroservice.openSnackbar(res.message);
          this.ngOnInit();
          sessionStorage.removeItem('userpayload');
        } else {
        }
      },(ERROR:HttpErrorResponse) => {
             this.apimicroservice.openSnackbar(ERROR.error.message);
            this.spinner.hide();
        });
    //}
  }
  updatePermission() {
    if (this.permissionForm.invalid) {
      this.permissionForm.get("userName").markAsTouched();
      this.permissionForm.get("userType").markAsTouched();
      this.permissionForm.get("emailId").markAsTouched();
     // this.permissionForm.get("password").markAsTouched();
      this.permissionForm.get("status").markAsTouched();
      this.permissionForm.get("roleRefId").markAsTouched();
      this.permissionForm.get("branchRefId").markAsTouched();
    
      return;
    } else {
      this.spinner.show();
      let payload = this.permissionForm.value;
      
    payload.userPermission = this.allPages;
    //console.log("payload",payload);
    this.apimicroservice.updateUser(this.id,payload).subscribe(res => {
      this.spinner.hide();
      this.updateButton = false;
      this.submitButton = true;
      this.cancelButton=false;  
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);
        this.prev.emit();
      //  this.ngOnInit();
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    });
    }
  }

  
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  
  prepopulation(id) {
    //console.log("id",id);
    let backend = {
      "A":2,
      "B":2
  }
  let front = {
      "A":2,
      "B":2, 
      "C":2
  }
  console.log("Ajayyyyyyyyy Sir",Object.assign(backend, front));
    this.isPassword = false;
    this.apimicroservice.getUserById(id).subscribe(res => {
      //permission coe
      console.log("shiivv1",this.allPages);
      console.log("res.data[0].userPermissionres.data[0].userPermission",res.data[0].userPermission);
     // console.log("shivvvvvv",Object.assign(this.allPages, res.data[0].userPermission));
      this.userData = res.data[0];

     // this.allPages = Object.assign(this.allPages, res.data[0].userPermission);
     let aaaryy = [];
     let aaaryy2 = [];
     aaaryy = this.allPages.concat(res.data[0].userPermission);

         const ids = aaaryy.map(o => o.pageName); 
         this.allPages= aaaryy.filter(({ pageName }, index) => !ids.includes(pageName, index + 1))
      //    this.allPages.sort((a,b) => a.pageName > b.pageName ? 1 : -1)
      // console.log("aaaryy2aaaryy2",this.allPages);

      // var a = [1, 2, 3], b = [101, 2, 1, 10]
      // var c = a.concat(b)
      // var d = c.filter((item, pos) => c.indexOf(item) === pos)

      if(this.allPages.length !== 0 ){
      for(let i=0;i<this.allPages.length;i++){
       
        if(this.allPages[i].pageName == "Settings"){
           if(this.allPages[i].view == true){
          this.tabenable = true;
           }else{
            this.tabenable = false;
           }
        }
        if(this.allPages[i].pageName == "Stock"){
          if(this.allPages[i].view == true){
         this.stocktabenable = true;
          }else{
           this.stocktabenable = false;
          }
       }
      }
    }
      if(this.allPages.length == 0){
        this.allPages = this.tempArray;
        for(let i=0;i<this.tempArray.length;i++){
       
          if(this.tempArray[i].pageName == "Settings"){
             if(this.tempArray[i].view == true){
            this.tabenable = true;
             }else{
              this.tabenable = false;
             }
          }
          if(this.tempArray[i].pageName == "Stock"){
            if(this.tempArray[i].view == true){
           this.stocktabenable = true;
            }else{
             this.stocktabenable = false;
            }
         }
        }
      }
      //end permission code
      //console.log("res :",res);
     let data = res.data[0];
     this.submitButton = false;
     this.updateButton = true;
     this.cancelButton=true;
      if(res.status){
        this.permissionForm = this.fb.group({
          "userType": new FormControl(data.userType,Validators.required),
          "userID": new FormControl(data.userID,[Validators.required,Validators.pattern("^[a-zA-Z]*$|^[A-Za-z]\\w*$"),Validators.maxLength(25),Validators.minLength(5)]),
          "userName": new FormControl(data.userName,[Validators.required]),
          "emailId": new FormControl(data.emailId,[Validators.required,Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
          // "password": new FormControl(data.password),
          "status": new FormControl(data.status,Validators.required),
          "roleRefId": new FormControl(data.roleRefId,Validators.required),
          "branchRefId": new FormControl(data.branchRefId,Validators.required),
          "clinicRefId": new FormControl(data.clinicRefId),
          "selectAllPages":new FormControl(data.selectAllPages ? data.selectAllPages : false),
           "updatedAt": this.date,
           "userImage": '',
           "doctorRefId": new FormControl(data.doctorRefId),
           "clinicalStaffRefId": new FormControl(data.clinicalStaffRefId),
           "userPermission": new FormControl(data.userPermission),
      // "deviceId": id
        });
        this.id = id;
        // this.fetchUserList();
        // this.fetchRoleList();
        // this.fetchBranchList();
      }
    });
  }
  addNew() {
    this.permissionForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
 
 
  redirect(to): void {
    this.router.navigate([to]);
  }
  changemob1() {
    let payload = this.permissionForm.value;
    if (payload.mobile1 != '') {
      if (
        payload.mobile1 == payload.mobile2 ||
        payload.mobile1 == payload.mobile3 ||
        payload.mobile1 == payload.mobile4 ||
        payload.mobile1 == payload.mobile5
      ) {
        this.apimicroservice.openSnackbar("Mobile number 1 must be different");
        this.permissionForm.controls["mobile1"].setValue('');
      }
    }
  }
  changemob2() {
    let payload = this.permissionForm.value;
    if (payload.mobile2 != '') {
      if (
        payload.mobile2 == payload.mobile1 ||
        payload.mobile2 == payload.mobile3 ||
        payload.mobile2 == payload.mobile4 ||
        payload.mobile2 == payload.mobile5
      ) {
        this.apimicroservice.openSnackbar("Mobile number 2 must be different");
        this.permissionForm.controls["mobile2"].setValue('');
      }
    }
  }
  changemob3() {
    let payload = this.permissionForm.value;
    if (payload.mobile3 != '') {
      if (
        payload.mobile3 == payload.mobile1 ||
        payload.mobile3 == payload.mobile2 ||
        payload.mobile3 == payload.mobile4 ||
        payload.mobile3 == payload.mobile5
      ) {
        this.apimicroservice.openSnackbar("Mobile number 3 must be different");
        this.permissionForm.controls["mobile3"].setValue('');
      }
    }
  }
  changemob4() {
    let payload = this.permissionForm.value;
    if (payload.mobile4 != '') {
      if (
        payload.mobile4 == payload.mobile1 ||
        payload.mobile4 == payload.mobile2 ||
        payload.mobile4 == payload.mobile3 ||
        payload.mobile4 == payload.mobile5
      ) {
        this.apimicroservice.openSnackbar("Mobile number 4 must be different");
        this.permissionForm.controls["mobile4"].setValue('');
      }
    }
  }
  changemob5() {
    let payload = this.permissionForm.value;
    if (payload.mobile5 != '') {
      if (
        payload.mobile5 == payload.mobile1 ||
        payload.mobile5 == payload.mobile2 ||
        payload.mobile5 == payload.mobile3 ||
        payload.mobile5 == payload.mobile4
      ) {
        this.apimicroservice.openSnackbar("Mobile number 5 must be different");
        this.permissionForm.controls["mobile5"].setValue('');
      }
    }
  }
  fetchRoleList() {
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAllRoles(this.page2, this.size, this.searchText,this.clinicRef).subscribe(res => {
        //console.log("fetchRoleList res",res);
        
        if (res.status) {    
          this.rolesList = res.data.data;
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      })
    } else {
      this.apimicroservice.getAllRoles(this.page2, this.size, this.searchText,clinicRef).subscribe(res => {
        //console.log("fetchRoleList res",res);
        
        if (res.status) {    
          this.rolesList = res.data.data;
        } else {
          this.apimicroservice.openSnackbar(res.message);
        }
      })
    }
    
  }
  // fetchBranchList() {
  //   this.searchText = '';
  //   this.apimicroservice.getAllBranch(this.page2, this.size, this.searchText).subscribe(res => {
  //     //console.log("getAllBranch res",res);
      
  //     if (res.status) {    
  //       this.BranchList = res.data.branchData;
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   })
  // }
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
      this.errormessage = '';
      let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
      if (
        ext.toLowerCase() == "png" ||
        ext.toLowerCase() == "jpg" ||
        ext.toLowerCase() == "jpeg"
      ) {
        this.convertToBase64(file);
      }
  }
  cancelUpdate(){
    this.permissionForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.prev.emit(); 
    this.ngOnInit();
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.myimage = d;
      
    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }
///user permissions
onTabChange(e){
    
  if(e.target.checked)
  {

   this.tab = true;
  }
  else
  {
    
    this.tab = false;
  }
}
onPageChange2(e){
  
  if(e.target.checked)
  {

   this.page = true;
  }
  else
  {
    
    this.page = false;
  }
}

onSelectAllChange(e){
  //console.log("e",e);
  //console.log("e.target.value ",e.target.value );
  //console.log("e.target.checked ",e.target.checked );

  if(e.target.checked == true){
    //this.permission.push(e.target.value);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].selectAll = true;
        this.allPages[i].view = true;
        this.allPages[i].create = true;
        this.allPages[i].update = true;
        this.allPages[i].delete = true;
      }
      if(e.target.value == "Stock"){
        this.stocktabenable = true;
      }
      if(e.target.value == "Settings"){
        this.tabenable = true;
      }
    }
    
  }

  if(e.target.checked == false){
    //this.permission.splice(this.permission.indexOf(e.target.value),1);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].selectAll = false;
        this.allPages[i].view = false;
        this.allPages[i].create = false;
        this.allPages[i].update = false;
        this.allPages[i].delete = false;
      }
      if(e.target.value == "Stock"){
        this.stocktabenable = false;
      }
      if(e.target.value == "Settings"){
        this.tabenable = false;
      }
    }
    
  }
}

onSelectAllPagesChange(e){
  //console.log("e",e);
  ////console.log("e.target.value ",e.target.value );
  //console.log("e.target.checked ",e.checked );

  if(e.checked == true){
    //this.permission.push(e.target.value);
    for(let i=0;i<this.allPages.length;i++){
      
        this.allPages[i].selectAll = true;
        this.allPages[i].view = true;
        this.allPages[i].create = true;
        this.allPages[i].update = true;
        this.allPages[i].delete = true;
        this.stocktabenable = true;
        this.tabenable = true;
    }
    
  }

  if(e.checked == false){
    //this.permission.splice(this.permission.indexOf(e.target.value),1);
    for(let i=0;i<this.allPages.length;i++){
      
        this.allPages[i].selectAll = false;
        this.allPages[i].view = false;
        this.allPages[i].create = false;
        this.allPages[i].update = false;
        this.allPages[i].delete = false;
        this.stocktabenable = false;
        this.tabenable = false;
      
    }
    
  }
}

onViewChange(e){
  //console.log("e",e);
  //console.log("e.target.value ",e.target.value );
  //console.log("e.target.checked ",e.target.checked );
  
  if(e.target.checked == true){
    this.permission.push(e.target.value);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].view = true;

        if(this.allPages[i].view == true && this.allPages[i].create == true && this.allPages[i].update == true && this.allPages[i].delete == true){
          this.allPages[i].selectAll = true;
          //console.log("this.allPages view",this.allPages);
        }
      }
      
      if(e.target.value == "Stock"){
        this.stocktabenable = true;
      }
      if(e.target.value == "Settings"){
        this.tabenable = true;
      }
    }
    
  }
  
  if(e.target.checked == false){
    this.permission.splice(this.permission.indexOf(e.target.value),1);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].view = false;
        this.allPages[i].create = false;
        this.allPages[i].update = false;
        this.allPages[i].delete = false;
        this.allPages[i].selectAll = false;
        //console.log("this.allPages view uncheck",this.allPages);
      }
      
      if(e.target.value == "Stock"){
        this.stocktabenable = false;
      }
      if(e.target.value == "Settings"){
        this.tabenable = false;
      }
    }
    
  }
  
  if(e.target.checked && e.target.value == 'setting_view')
  {

   this.dashboard_view = 'setting_view';
 
   this.Tab1 = true;
   this.Tab2 = true;
   this.Tab3 = true;
   this.Tab4 = true;
  }
  else 
  {
  //  this.tab = true;
    this.Tab1 = false;
    this.Tab2 = false;
    this.Tab3 = false;
    this.Tab4 = false;
  }
  if(e.target.checked && e.target.value == 'dashboard_view')
  {

 
   this.view = true;
 
  }
  else 
  {
  //  this.tab = true;
    this.view = false;
 
  }
 
}
onCreateChange(e){
  if(e.target.checked == true){
    this.permission.push(e.target.value);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].create = true;

        if(this.allPages[i].selectAll== false && this.allPages[i].view == true && this.allPages[i].create == true && this.allPages[i].update == true && this.allPages[i].delete == true){
          this.allPages[i].selectAll = true;
        }
      }
      
    }
    
  }
  
  if(e.target.checked == false){
    this.permission.splice(this.permission.indexOf(e.target.value),1);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].create = false;

        if(this.allPages[i].selectAll == true && this.allPages[i].create == false && this.allPages[i].view == true && this.allPages[i].update == true && this.allPages[i].delete == true){
          this.allPages[i].selectAll = false;
        }
      }
      
    }
    
  }
  

  if(e.target.checked && e.target.value == 'setting_create')
  {
   this.CreateTab1 = true;
   this.CreateTab2 = true;
   this.CreateTab3 = true;
   this.CreateTab4 = true;
  }
  else 
  {
  //  this.tab = true;
    this.CreateTab1 = false;
    this.CreateTab2 = false;
    this.CreateTab3 = false;
    this.CreateTab4 = false;
  }
 
}
onUpdateChange(e){
  if(e.target.checked == true){
    this.permission.push(e.target.value);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].update = true;

        if(this.allPages[i].selectAll== false && this.allPages[i].view == true && this.allPages[i].create == true && this.allPages[i].update == true && this.allPages[i].delete == true){
          this.allPages[i].selectAll = true;
        }
      }
    }
    
  }
  
  if(e.target.checked == false){
    this.permission.splice(this.permission.indexOf(e.target.value),1);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].update = false;

        if(this.allPages[i].selectAll == true && this.allPages[i].create == true && this.allPages[i].view == true && this.allPages[i].update == false && this.allPages[i].delete == true){
          this.allPages[i].selectAll = false;
        }
      }
    }
    
  }

 
}
onDeleteChange(e){
  if(e.target.checked == true){
    this.permission.push(e.target.value);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].delete = true;

        if(this.allPages[i].selectAll== false && this.allPages[i].view == true && this.allPages[i].create == true && this.allPages[i].update == true && this.allPages[i].delete == true){
          this.allPages[i].selectAll = true;
        }
      }
    }
    
  }
  
  if(e.target.checked == false){
    this.permission.splice(this.permission.indexOf(e.target.value),1);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].delete = false;

        if(this.allPages[i].selectAll == true && this.allPages[i].create == true && this.allPages[i].view == true && this.allPages[i].update == true && this.allPages[i].delete == false){
          this.allPages[i].selectAll = false;
        }
      }
    }
    
  }
  
 
 
}

onCheckboxViewChange(e){
  if(e.target.checked == true){
    this.permission.push(e.target.value);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].view = true;

        if(this.allPages[i].view == true && this.allPages[i].create == true && this.allPages[i].update == true && this.allPages[i].delete == true){
          this.allPages[i].selectAll = true;
          ////console.log("this.allPages view",this.allPages);
        }
      }
    }
    
  }
  
  if(e.target.checked == false){
    this.permission.splice(this.permission.indexOf(e.target.value),1);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].view = false;
        this.allPages[i].create = false;
        this.allPages[i].update = false;
        this.allPages[i].delete = false;
        this.allPages[i].selectAll = false;
      }
    }
    
  }
  
}

onCheckboxCreateChange(e){
  if(e.target.checked == true){
    this.permission.push(e.target.value);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].create = true;
        if(this.allPages[i].selectAll== false && this.allPages[i].view == true && this.allPages[i].create == true && this.allPages[i].update == true && this.allPages[i].delete == true){
          this.allPages[i].selectAll = true;
        }
      }
    }
  }
  if(e.target.checked == false){
    this.permission.splice(this.permission.indexOf(e.target.value),1);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].create = false;
        if(this.allPages[i].selectAll == true && this.allPages[i].create == false && this.allPages[i].view == true && this.allPages[i].update == true && this.allPages[i].delete == true){
          this.allPages[i].selectAll = false;
        }
      }
    }
    
  }
}

onCheckboxUpdateChange(e){
  if(e.target.checked == true){
    this.permission.push(e.target.value);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].update = true;

        if(this.allPages[i].selectAll== false && this.allPages[i].view == true && this.allPages[i].create == true && this.allPages[i].update == true && this.allPages[i].delete == true){
          this.allPages[i].selectAll = true;
        }
      }
    }
    
  }
  
  if(e.target.checked == false){
    this.permission.splice(this.permission.indexOf(e.target.value),1);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].update = false;

        if(this.allPages[i].selectAll == true && this.allPages[i].create == true && this.allPages[i].view == true && this.allPages[i].update == false && this.allPages[i].delete == true){
          this.allPages[i].selectAll = false;
        }
      }
    }
    
  }
  
  
 
}

onCheckboxDeleteChange(e){
  if(e.target.checked == true){
    this.permission.push(e.target.value);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].delete = true;

        if(this.allPages[i].selectAll== false && this.allPages[i].view == true && this.allPages[i].create == true && this.allPages[i].update == true && this.allPages[i].delete == true){
          this.allPages[i].selectAll = true;
        }
      }
    }
    
  }
  
  if(e.target.checked == false){
    this.permission.splice(this.permission.indexOf(e.target.value),1);
    for(let i=0;i<this.allPages.length;i++){
      if(e.target.value == this.allPages[i].pageName){
        this.allPages[i].delete = false;

        if(this.allPages[i].selectAll == true && this.allPages[i].create == true && this.allPages[i].view == true && this.allPages[i].update == true && this.allPages[i].delete == false){
          this.allPages[i].selectAll = false;
        }
      }
    }
    
  }
  
  
 
}
// validateForm(){
//   let payload = this.permissionForm.value;
//   // payload.custRefId = this.customerRefId;
//   payload.userPermission = this.allPages;
//   this.apimicroservice.updateUser(this.id,payload).subscribe(res => {
//     //console.log("updated res :",res);
//     this.ngOnInit();
//     if(res.status){
//       this.apimicroservice.openSnackbar(res.message);
//       localStorage.clear();
//       this.permissionForm.reset();
//     }else{
//       this.apimicroservice.openSnackbar(res.message);
//     }
//   });
  
// }
toUserForm() {
  document.getElementById("toUserForm").scrollIntoView();
}
toUserList(){
  document.getElementById("toUserList").scrollIntoView();
}

}