import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpCityComponent } from "../pop-up-city/pop-up-city.component";
import { PopUpItemComponent } from "../pop-up-item/pop-up-item.component";
import { PopUpCourierserviceComponent } from "../pop-up-courierservice/pop-up-courierservice.component";
import { PopupTotalBillComponent } from "../popup-total-bill/popup-total-bill.component";
import { PopUpDoctorComponent } from "../pop-up-doctor/pop-up-doctor.component";

@Component({
  selector: 'app-doctor-leave',
  templateUrl: './doctor-leave.component.html',
  styleUrls: ['./doctor-leave.component.scss']
})
export class DoctorLeaveComponent implements OnInit {
  doctorLeaveForm: FormGroup;
  holidayForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=10;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  listOfBilling: any;
  id: any;
  courierList: any;
  filteredListCourier: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  filteredListDoctor: any;
  cityList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  billingList: any;
  doctorList: any;
  filteredDoctorList: any;
  branchArr: any;
  arr: any;
  minDateStr: string;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService 
  ) { }
  userPermission:any;
  billingCreate= true;
  billingUpdate= true;
  disableBranch = true;
  HalfDay = false;
  printData=[
    {
      srNo:1,
      date:'10/03/2020',
      aptId:101,
      patientName:'Shivani Bhosale',
      mobile:9088776653,
      status:'Paid'
     },
     {
      srNo:2,
      date:'10/03/2020',
      aptId:102,
      patientName:'Sai Mane',
      mobile:9276321653,
      status:'Unpaid'
     },
     {
      srNo:3,
      date:'10/03/2020',
      aptId:103,
      patientName:'Rahul Thakur',
      mobile:8932776653,
      status:'Unpaid'
     },
     {
      srNo:4,
      date:'10/03/2020',
      aptId:104,
      patientName:'Aishwarya Shaha',
      mobile:8877776653,
      status:'Paid'
     },                 
  ]
  ngOnInit(): void {
    this.currentdate = new Date();
    let mm = this.currentdate.getMonth() + 1;
    this.minDateStr = this.currentdate.getDate() + '/' + mm + '/' + this.currentdate.getFullYear();
    // this.disableBranch = true;
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));


    
    this.doctorLeaveForm = this.fb.group({
      "doctorRefId": new FormControl('',Validators.required),
      "doctorName": new FormControl('',Validators.required),
      "branchName": new FormControl('',Validators.required),
      "branchRefId": new FormControl('',Validators.required),
      "leaveType": new FormControl(''), 
      "fromDate": new FormControl('',Validators.required),
      "fromTime": new FormControl(''), 
      "toTime": new FormControl(''), 
      "toDate": new FormControl(''),
      "fDate": new FormControl(''),  
      
      "searchDoctorLeave": new FormControl(''),
    });
    this.holidayForm = this.fb.group({
      
      "branchName": new FormControl(''),
      "doctorRefId": new FormControl(''),
      "branchRefId": new FormControl(''),
      "holidayName": new FormControl(''),
      "holidayfromDate": new FormControl(''),
      "holidaytoDate": new FormControl(''), 
      "leaveType": new FormControl(''), 
      // "leaveType": new FormControl(''), 
      // "fromTime": new FormControl(''), 
      // "toTime": new FormControl(''), 
      "alertName": new FormControl('leave'),
    });
 // this.removeValidator();
  this.fetchDoctorHolidayList();
  this.fetchDoctorsList();
  this.fetchBranchList();
}


  validateSave() {
    if (this.doctorLeaveForm.invalid) {
      this.doctorLeaveForm.get("branchRefId").markAsTouched();
      this.doctorLeaveForm.get("doctorRefId").markAsTouched();
      this.doctorLeaveForm.get("fromDate").markAsTouched();
      this.doctorLeaveForm.get("toDate").markAsTouched();
    } else {
      this.saveDoctorHoliday();
    }
  }

  validateUpdate() {
    if (this.doctorLeaveForm.invalid) {
      this.doctorLeaveForm.get("branchRefId").markAsTouched();
      this.doctorLeaveForm.get("doctorName").markAsTouched();
      this.doctorLeaveForm.get("fromDate").markAsTouched();
      this.doctorLeaveForm.get("toDate").markAsTouched();
    } else {
      this.updateDoctorHoliday();
    }
  }

  searchChequeData(){
    this.billingList = [];
    this.searchText = this.doctorLeaveForm.get('searchBilling').value;
    let chequeNo = this.doctorLeaveForm.get('chequeNo').value;
    //let patientName = this.doctorLeaveForm.get('patientName').value;
    let billNo = this.doctorLeaveForm.get('billNo').value;
    let chequeStatus = this.doctorLeaveForm.get('chequeStatus').value;
    let fromDate = this.doctorLeaveForm.get('fdate').value;
    let toDate = this.doctorLeaveForm.get('todate').value;
    let branchRef= sessionStorage.getItem('branchRef');
    //
    //
    // /page=1&size=10&search=&billNo=&chequeNo=&chequeStatus=&fromDate=&toDate=
    this.apimicroservice.getPaymentHistoryData2(this.page,this.size,this.searchText,billNo,chequeNo,chequeStatus,fromDate,toDate,branchRef).subscribe(res => {
      if (res.status) {    
        this.billingList = res.data.data;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  saveDoctorHoliday() {
    this.spinner.show();
    let payload = this.doctorLeaveForm.value;
   if(payload.leaveType == 'Half Day')
   {
    payload.toDate = payload.fromDate;
   }
    const fdateTemp = new Date(payload.fromDate);
    fdateTemp.setHours(0);
    fdateTemp.setMinutes(0); 
    fdateTemp.setSeconds(0);
    let text = fdateTemp.toISOString();
    payload.fromDate =text;
    const toDateTemp = new Date(payload.toDate);
    toDateTemp.setHours(23);
    toDateTemp.setMinutes(59);
    toDateTemp.setSeconds(59);
    let text2 = toDateTemp.toISOString();
    payload.toDate = text2;

    //
    //payload.branchRefId = this.arr;
    this.apimicroservice.saveDoctorLeave(payload).subscribe(res => {
      this.spinner.hide();
      this.addHolidayAlert();
      this.ngOnInit();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    }, (ERROR: HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      //
      this.spinner.hide();
    });
  }
  addHolidayAlert(){ 
    let payload = this.doctorLeaveForm.value;
    let payload2 = this.holidayForm.value; 
    payload2.doctorRefId = payload.doctorRefId;
    payload2.branchName = payload.branchName;
    payload2.branchRefId = payload.branchRefId;
    payload2.holidayName = payload.holidayName;
    payload2.holidayfromDate = payload.fromDate;
    payload2.holidaytoDate = payload.toDate;
    payload2.leaveType = payload.leaveType;
    
    payload2.alertType = 22;
    
    this.apimicroservice.addHolidayAlert(payload2).subscribe(res => {
      if (res.status) {    
       // payload.branchName = this.arr;
        this.apimicroservice.openSnackbar(res.message);
        window.location.reload();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      } 
    });
  }
  fetchDoctorsList() {
    // this.spinner.show();
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getAllDoctorsList(branchRef,clinicRef).subscribe(res => {
      // this.spinner.hide();
      if (res.status) {
        this.doctorList = res.data;
        this.filteredDoctorList = this.doctorList.slice()
        // this.totalLength = res.data.totalItems;
      //  this.apimicroservice.openSnackbar(res.message);
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    }); 
  }

  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => { 
      if (res.status) {    
        this.BranchList = res.data;
        this.filteredListBranch= this.BranchList.slice();
        let length =res.data.length - 1;
      this.branchName= res.data[length].branchName;
      this.doctorLeaveForm.controls["branchName"].setValue(this.branchName);
      this.doctorLeaveForm.controls["branchRefId"].setValue(res.data[length]._id); 
      } else {
      //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  } 

  doctorClick(id, doctorName,branchRefId) {
    //
    this.doctorLeaveForm.controls["doctorName"].setValue(doctorName);
    this.doctorLeaveForm.controls["branchRefId"].setValue(branchRefId);
    this.doctorLeaveForm.controls["doctorRefId"].setValue(id);
   // this.branchClick(branchRefId);
     this.disableBranch=true;

     this.apimicroservice.getBranchById(branchRefId).subscribe(res => { 
      let branchName = res.data[0].branchName;
        this.doctorLeaveForm.controls["branchName"].setValue(branchName);
        this.doctorLeaveForm.controls["branchRefId"].setValue(branchRefId);
        let payload = this.doctorLeaveForm.value;
        //
        
      });

    // this.apimicroservice.getDoctorById(id).subscribe(res => {
    //   let data = res.data[0];
    //   let payload = this.doctorLeaveForm.value;
    //   payload.doctorRefId = res.data[0]._id;
    //   this.doctorLeaveForm.controls["doctorRefId"].setValue(res.data[0]._id);
    // });
  }

  // branchClick(id, branchName) {
  //   this.doctorLeaveForm.controls["branchName"].setValue(branchName);
  //   this.apimicroservice.getBranchById(id).subscribe(res => {
  //     let data = res.data[0];
  //     let payload = this.doctorLeaveForm.value;
  //     payload.doctorRefId = res.data[0]._id;
  //     this.doctorLeaveForm.controls["branchRefId"].setValue(res.data[0]._id);
  //   });
  // }

  // branchClick(id) {
  //   // if(event.source.selected){
  //   //   this.branchArr.push(branchName);
  //   //   //
  //   // }
  //   // else{
  //   //   this.branchArr = this.branchArr.filter(b => b!=branchName);
  //   //   //
  //   // } 
   
  //    this.apimicroservice.getBranchById(id).subscribe(res => {
      
  //   let branchName = res.data[0].branchName;
  //     this.doctorLeaveForm.controls["branchName"].setValue(branchName);
  //     let payload = this.doctorLeaveForm.value;
  //     //
  //   });
  //   this.disableBranch=true;
  // }
  
  redirectToBillForm(receiptNo){
    this.router.navigate(['cheque-reconciliation',receiptNo]);
  }
 
  fetchDoctorHolidayList() {
    let search = '';
    this.id = '';
    this.apimicroservice.getDoctorLeaverData(this.page,this.size,search,this.id).subscribe(res => {
      if (res.status) {
        //
        
        this.billingList = res.data.clinicalStaffData;
        this.totalLength = res.data.totalItems;
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  redirect(to): void {
    this.router.navigate([to]);
   } 

  prepopulation(id) {
    this.spinner.show(); 
    this.searchText = '';
    this.apimicroservice.getDoctorLeaveDataById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0]; 
      //
    //  this.branchClick(data.branchRefId);
      //this.disableBranch=true;
      this.id = id;
      
      // let fdate;
      // fdate=new Date();
      // fdate=this.datepipe.transform(data.fromDate, 'yyyy-MM-dd');
      // //
      

      let date;
      date=new Date(data.fromDate);
      date.setDate(date.getDate()-1);
      let fdate=this.datepipe.transform(date, 'yyyy-MM-dd');
        //

       if(res.status){
         this.updateButton = true;
         this.submitButton = false;
         this.doctorLeaveForm = this.fb.group({
          "doctorRefId": new FormControl(data.doctorRefId,Validators.required),
          "doctorName": new FormControl(data.doctorName,Validators.required),
          "branchName": new FormControl(data.branchName,Validators.required),
          "branchRefId": new FormControl(data.branchRefId,Validators.required),
          "fromDate": new FormControl(data.fromDate,Validators.required),
          "toDate": new FormControl(data.toDate), 
          "leaveType": new FormControl(data.leaveType), 
          "fromTime": new FormControl(data.fromTime), 
          "toTime": new FormControl(data.toTime), 
          "fDate": new FormControl(fdate),  
          "searchDoctorLeave": new FormControl(''),


        });  
        this.leaveType(data.leaveType);
        this.doctorClick(data.doctorRefId,data.doctorName,data.branchRefId);
        this.disableBranch=true;
       }else{

       }
     });
  }

  updateDoctorHoliday(){
    this.spinner.show();
    let payload = this.doctorLeaveForm.value;
    payload.branchRefId = this.arr;
    let date;
    date=new Date(payload.fromDate);
    date.setDate(date.getDate()-1);
    let fdate=this.datepipe.transform(date, 'yyyy-MM-dd');
      payload.fdate = fdate;

      if(payload.leaveType == 'Half Day')
      {
       payload.toDate = payload.fromDate;
      }
  // const fdateTemp = new Date();
  // fdateTemp.setHours(0);
  // fdateTemp.setMinutes(0);
  // fdateTemp.setSeconds(0);
  // fdateTemp.setSeconds(0);
  // fdateTemp.setMilliseconds(0);
  // let text = fdateTemp.toISOString();
  // payload.fromDate = text;

  // const toDateTemp = new Date();
  // toDateTemp.setHours(0);
  // toDateTemp.setMinutes(0); 
  // toDateTemp.setSeconds(0);
  // toDateTemp.setMilliseconds(0);
  // let text2 = toDateTemp.toISOString(); 
  // payload.toDate = text2;
  
      //




     this.apimicroservice.updateDoctorLeave(this.id,payload).subscribe(res => {
       this.spinner.hide(); 
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{ 
       //  //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
 
  // cancelUpdate(){
  //   this.doctorLeaveForm.reset();
  //   this.submitButton=true;
  //   this.updateButton=false;
  //   this.ngOnInit();
  // }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  todoctorLeaveForm() {
    document.getElementById("todoctorLeaveForm").scrollIntoView();
  }
  toDoctorLeaveLst() {
    document.getElementById("toDoctorLeaveLst").scrollIntoView();
  }

  onPageChange(event) {
    //
    
    this.page = event;
    this.searchText = this.doctorLeaveForm.get('searchDoctorLeave').value;
    this.id='';
    this.apimicroservice.getDoctorLeaverData(this.page,this.size,this.searchText,this.id).subscribe(res => {
      if (res.status) {    
        this.billingList = res.data.clinicalStaffData;
        this.totalLength=res.data.totalItems;
        
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.doctorLeaveForm.get('searchDoctorLeave').value;
    this.id='';
    let page=1;
    let size=10
    this.apimicroservice.getDoctorLeaverData(page,size,this.searchText,this.id).subscribe(res => {
      if (res.status) {    
        this.billingList = res.data.clinicalStaffData;
        this.totalLength=res.data.totalItems;
        this.page=res.data.pageNumber;
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  clear(){
    this.doctorLeaveForm.reset();
    window.location.reload();
    this.ngOnInit();
  }
  billing()
  {
    const dialogRef = this.dialog.open(PopupTotalBillComponent,
      {
        data: {
          patientRefId: '',
          appointId:'',
          patientDetails:'',
          page:"Billing Page"
        }
      }
      );
  }
  onback() {
    this.apimicroservice.back()
  }

  onSelectBranch(item){
    //
    // this.openTime = item.openTime;
    // this.closeTime = item.closeTime;
    // this.branchWeeklyOff = item.branchWeeklyOff;
    this.doctorLeaveForm.controls["branchName"].setValue(item.branchName);
    this.branchRef = item._id;

    // //
    // //
    // //
  }
  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      }); 
      dialogRef3.afterClosed().subscribe((result) => {
        this.fetchBranchList();
      })
  }
  

  openDoctorPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpDoctorComponent,
      {
        width: "1200px",
      });
      dialogRef3.afterClosed().subscribe((result) => {
          this.fetchDoctorsList();  
         });
  } 
  leaveType(value)
  {
    if(value == 'Half Day')
    {
      this.HalfDay = true;
    }
    else{
      this.HalfDay = false;
    }
  }
  toOpenDateChnage()
  {
    let payload = this.doctorLeaveForm.value; 
    let fDate = payload.fromDate.toString();
    let tDate = payload.toDate.toString();
    if(fDate != tDate)
    {
      if(payload.fromDate >= payload.toDate && payload.toDate !== '')
    {
      this.apimicroservice.openSnackbar("From Date should be Less than To Date");
      this.doctorLeaveForm.controls['fromDate'].setValue('');
    }
    }

  }
  toCloseDateChnage()
  {
    let payload = this.doctorLeaveForm.value;   
    let fDate = payload.fromDate.toString();
    let tDate = payload.toDate.toString();
    if(fDate != tDate)
    {
    if(payload.toDate <= payload.fromDate && payload.fromDate !== '')
    {
      this.apimicroservice.openSnackbar("To Date should be Greater than From Date");
      this.doctorLeaveForm.controls['toDate'].setValue('');
    }
  } 
  }
}


