import { Component,Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
 import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { GetColorName } from "hex-color-to-color-name";
 
@Component({
  selector: 'app-pop-up-appointmenttype',
  templateUrl: './pop-up-appointmenttype.component.html',
  styleUrls: ['./pop-up-appointmenttype.component.scss']
})
export class PopUpAppointmenttypeComponent implements OnInit {
  appointmentTypeForm: FormGroup;
  date: any;
  listOfAppointment:any;
  totalLength:any;
  appointmentId: any;
  size=5;
  page=1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  filteredList: { name: string; hex: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin= true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<PopUpAppointmenttypeComponent>,@Inject(MAT_DIALOG_DATA) public data:any
  ) { dialogRef.disableClose = true;}
  userPermission:any;
  appointmentTypeCreate= true;
  appointmentTypeUpdate= true;

  ngOnInit(): void {
    this.page=1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if(this.userType !== 'Super Admin'){
      this.isSuperAdmin= false;
    this.userPermission.forEach((element) => {
      if(element.pageName == 'Appointment Type' && element.create == false)
      {
          this.appointmentTypeCreate = false;  
      }
      if(element.pageName == 'Appointment Type' && element.update == false)
      {
          this.appointmentTypeUpdate = false;  
      }  
    });
  }
    this.appointmentTypeForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "appointmentType": new FormControl('',Validators.required),
      "color": new FormControl('',Validators.required),
      "colorName": new FormControl(''),
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "createdAt": new Date(),
      "updatedAt":new Date(),
      "searchAppointment": new FormControl(''),
      "branchName": new FormControl(),
    });
    // this.fetchAppointmentList();
//  this.removeValidator();
  this.getLastId();
  // this.getAllBranch();
}


  submitAppointmentType(){
   // this.setValidator();
    if (this.appointmentTypeForm.invalid) {
      this.appointmentTypeForm.get("appointmentType").markAsTouched();
      this.appointmentTypeForm.get("color").markAsTouched();
     
      
      return;
    } else {
      this.addAppointmentType();
    }
  }
  addAppointmentType(){
    
    this.spinner.show();
    let payload = this.appointmentTypeForm.value;
    payload.createdAt= new Date();
      //
      
    if(this.userType !== 'Super Admin')
    {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
   
      
      this.apimicroservice.addAppointmentTypeData(payload).subscribe(res => {
        this.spinner.hide();
        // this.next.emit();
        if (res.status) {
          this.apimicroservice.openSnackbar(res.message);
         // this.appointmentTypeForm.reset();
         this.ngOnInit();
         this.onClose(); 
        } else {
          // this.apimicroservice.openSnackbar(res.message);
          //
          
        } 
      },(ERROR:HttpErrorResponse) => {
        this.apimicroservice.openSnackbar(ERROR.error.message);
       this.spinner.hide();
    });
  }

  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getAppointmentTypeDataById(id).subscribe(res => {
      this.submitButton=false;
       this.updateButton =true;
       this.spinner.hide();
       
       let data = res.data[0];
 
         this.appointmentTypeForm = this.fb.group({
     
          "branchWiseId": new FormControl(data.branchWiseId),
           "appointmentType": new FormControl(data.appointmentType,Validators.required),
           "color": new FormControl(data.color,Validators.required),
           "colorName": new FormControl(data.colorName),
           "branchRefId": new FormControl(data.branchRefId),
           "clinicRefId": new FormControl(data.clinicRefId),
           "updatedAt": this.date,
           "searchAppointment": new FormControl(''),
           "branchName": new FormControl(data.branchName),
         });
         this.superBranchId = data.branchWiseId;
         this.appointmentId = id;
        
         
       
     });


  }

  colorClick(e){
    this.appointmentTypeForm.controls.colorName.setValue(e);
    //.value);
    
  }
 
  onSelectColor(event:any){ 
  //
  let val = event.target.value;
  const colorName = GetColorName(val);
  this.appointmentTypeForm.controls.colorName.setValue(colorName);
    //.value);
  }

  //  updateAppointmentType() {
  //    this.setValidator();
  //    if(this.userType == 'Super Admin')
  //    {
  //      this.appointmentTypeForm.controls['branchRefId'].setValidators(Validators.required);
  //      this.appointmentTypeForm.controls['branchRefId'].updateValueAndValidity();
  //    }
  //    else{
  //      this.appointmentTypeForm.controls['branchRefId'].setErrors(null);
  //      this.appointmentTypeForm.controls['branchRefId'].clearValidators();
  //      this.appointmentTypeForm.controls['branchRefId'].updateValueAndValidity();
  //    }
  //   if (this.appointmentTypeForm.invalid) {
  //     this.appointmentTypeForm.get("appointmentType").markAsTouched();
  //     this.appointmentTypeForm.get("color").markAsTouched();
     
  //     return;
  //   } else {
   
  //   }
  //   this.update();
  // }

  // update(){
  //   this.spinner.show();
  //   let payload = this.appointmentTypeForm.value;
  //   if(this.userType !== 'Super Admin')
  //    {
  //      payload.branchRefId = this.branchRef;
  //      payload.clinicRefId = this.clinicRef;
  //      payload.branchName = this.branchName;
  //    }
     
  //   this.apimicroservice.updateAppointmentTypeData(this.appointmentId,payload).subscribe(res => {
  //     this.spinner.hide();
  //     if (res.status) {
  //       this.apimicroservice.openSnackbar(res.message);
  //       this.ngOnInit();
  //     } else {
  //       this.apimicroservice.openSnackbar(res.message);
  //     }
  //   },(ERROR:HttpErrorResponse) => {
  //     this.apimicroservice.openSnackbar(ERROR.error.message);
  //    this.spinner.hide();
  // });
  //   this.submitButton=true;
  //   this.updateButton=false;
 
  // }

  // cancelUpdate(){
  //   this.appointmentTypeForm.reset();
  //   this.submitButton=true;
  //   this.updateButton=false;
  //   this.ngOnInit();
  // }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
 
  getLastId()
  {
    if(this.userType !== 'Super Admin'){
      this.apimicroservice.getAppointmentTypeBranchLastId(this.branchRef).subscribe(res => {
        //   
       
        if (res['status']) {
          let appointmentIdd = res['data']['totalItems'];
          this.appointmentTypeForm.controls['branchWiseId'].setValue(appointmentIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }else{
      this.apimicroservice.getAppointmentTypeAllData().subscribe(res => {
        //   
        
        if (res['status']) {
          let appointmentIdd = res['data']['totalItems'];
          this.superBranchId = appointmentIdd + 1;
         
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }
       
      })
    }
    
  }


  onClose(): void {
    this.dialogRef.close();
  }

}
