import { Component, OnInit } from "@angular/core";
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpExpenseTypeComponent } from "../pop-up-expense-type/pop-up-expense-type.component";
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import * as XLSX from "xlsx";

// import panzoom from "panzoom";
declare var google: any;
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Product A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Product B' }
  ];

  monthList = [{ "monthName": "January", "val": 0 },
  { "monthName": "February", "val": 1 },
  { "monthName": "March", "val": 2 },
  { "monthName": "April", "val": 3 },
  { "monthName": "May", "val": 4 },
  { "monthName": "June", "val": 5 },
  { "monthName": "July", "val": 6 },
  { "monthName": "August", "val": 7 },
  { "monthName": "September", "val": 8 },
  { "monthName": "October", "val": 9 },
  { "monthName": "November", "val": 10 },
  { "monthName": "December", "val": 11 },
  ];

  //Labels shown on the x-axis
  lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  // Define chart options
  lineChartOptions: ChartOptions = {
    responsive: true
  };

  // Define colors of chart segments
  lineChartColors: Color[] = [

    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
    }
  ];

  // Set true to show legends
  lineChartLegend = true;

  // Define type of chart
  lineChartType = 'bar';

  lineChartPlugins = [];
  footerData: any;
  headerData: any;
  appointmentData = [];
  enquiryData = [];
  patientReg = [];
  repairDetails = [];
  earDetails = [];
  logoimg = "";
  reportName: any;
  reportAddress: any;
  taluka: any;
  district: any;
  state: any;
  country: any;
  reportPinCode: any;
  reportPhoneNumber: any;
  openTime: any;
  closeTime: any;
  isSubmit = false;
  getFirstReport: any;
  totalLengthrepairDetails: any;
  totalLengthEnquiry: any;
  totalLengthPatient: any;
  totalLengthAppointment: any;
  monthlyData = [];
  totalLengthMonthlyData: any;
  receiptData: any;
  totalLengthReceiptData: any;
  paymentData = [];
  totalLengthPaymentData: any;
  EarMouldData: any;
  patientName: any;
  pGender: any;
  pAge: number;
  option13Show = false;
  monthlyTillDateData = [];
  totalLengthMonthlyTillDate: any;
  userName: string;
  docName: string;
  cancelButton: false;
  fullName: string;
  paymentDataExport: any;
  listOfPatientsExport: any;
  listOfPatients: any;
  listOfRecipts: any;
  firstDate: Date;
  lastDate: Date;
  earData = [];
  getThirteenReport: any;
  HAcompany: any;
  filteredHACompanyList: any;
  monthlyDataDownload: any;
  getFirstReportDownload: any;
  monthlyTillDateDataDownload: any;
  receiptDataDownload: any;
  listOfPatientsDownload: any;
  getThirteenReportDownload: any;
  followupData: any;
  totalLengthFollowup: any;
  DeviceList2: any;
  filteredListDevice2: any;
  getUnsoldAll: any;
  getDailySalesAll: any;
  monthlyDataAll: any[];
  getMonthTillDateAll: any[];
  receiptDataAll: any[];
  listOfPatientsAll: any;
  followupDataAll: any;
  TotalAmt: number;
  cash: number;
  NonCash: number;
  paidAmt: number;
  cashAmt: any;
  getDailySalesTotal: any;


  // events
  chartClicked_bar({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //
  }
  myDate = new Date();
  chartHovered_bar({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //
  }
  // public barChartOptions = {
  //   scaleShowVerticalLines: false,
  //   responsive: true
  // };
  // public barChartLegend2:boolean= false;
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          stepSize: 10,
          beginAtZero: true,
          max: 120
        }
      }]
    }
  };
  public barChartLabels = ['250', '500', '750', '1k', '2k', '3k', '4k', '6k', '8k', '10k', '12k', '14k'];
  public barChartType = 'bar';
  public barChartLegend = false;
  // optionsVariable : { scales : { yAxes: [{ ticks: { steps : 10, stepValue : 10, max : 100, } }] } }
  public barChartData = [
    {
      data: [{
        type: "column",
        dataPoints: [
          { x: 10, y: 71, label: "cat 1" },
          { x: 20, y: 55, label: "cat 2" },
          { x: 30, y: 50, label: "cat 3" },
          { x: 40, y: 65, label: "cat 4" },
          { x: 50, y: 95, label: "cat 5" },
          { x: 60, y: 68, label: "cat 6" },
          { x: 70, y: 28, label: "cat 7" },
          { x: 80, y: 34, label: "cat 8" },
          { x: 90, y: 14, label: "cat 9" }


        ]
      }], label: 'Series A'
    },
    { data: [], label: 'Series B' }
  ];
  public barChartOptions2: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  reportForm: FormGroup;
  date: any;
  listOfExpenses: any;
  totalLength: any;
  totalLengthEarMould: any;
  size = 10;
  page = 1;
  page13 = 1;
  totalLength13: any;
  patientSelect = false;
  pid: any;
  pagePatient = 1;
  pageEnquiry = 1;
  pageRepair = 1;
  pageSales = 1;
  pageAppoint = 1;
  pageEarmold = 1;
  pageFollow = 1;
  pageReceiptReport = 1;
  pageNoEarmold = 1;
  searchText = '';
  submitButton = true;
  updateButton = false;
  showMonth = false;
  showToDate = true;
  showFromDate = true;
  errormessage: string;
  clientImage: any;
  getFourthReport: any;
  getSeventhReport: any;
  modeOfPayment = false;
  // allExpensesType=[
  //   {name:'Telephone Expenses', value:'Telephone Expenses'},
  //   {name:'Travelling Expenses', value:'Travelling Expenses' },
  //   {name:'Office Equipment and Supplies',value:'Office Equipment and Supplies'},
  //    { name:'Electricity / Utility',value:'Electricity / Utility' },
  //    { name:'Property Tax', value:'Property Tax' },
  //     {name:'Legal Expenses', value:'Legal Expenses'},
  //     { name:'Bank Charges', value:'Bank Charges' },
  //      {name:'Repair and Maintenance Expenses', value:'Repair and Maintenance Expenses'},
  //      { name:'Insurance Expenses',value:'Insurance Expenses'},
  //       { name:'Advertising Expenses', value:'Advertising Expenses'},
  //       { name:'Rent',value:'Rent' },
  //        {name:'Courier charges',value:'Courier charges' }, 
  // ]

  allExpensesType = [];
  filteredList: any;
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  branchList: any;
  isSuperAdmin = true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: Date;
  expenseId: any;
  modal: any;
  modalImg: any;
  branchCreate = true;
  doctorList: any;
  filteredListDoctor: any;
  patientList: any;
  filteredListPatient: any;
  filteredListMonth: any;
  filteredPatientList: any;
  option1 = false;
  option2 = false;
  option3 = false;
  option4 = false;
  option5 = false;
  option6 = false;
  option7 = false;
  option8 = false;
  option9 = false;
  option10 = false;
  option11 = false;
  option12 = false;
  option13 = false;
  option14 = false;
  title: any;
  getNinthReport: any;
  getDailysaleSReport: any;
  trimmedleftComplaints: any;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService
  ) { }
  userPermission: any;
  clinicExpensesCreate = true;
  clinicExpensesUpdate = true;
  PatientCreate = true;
  ngOnInit(): void {

    this.showMonth = false;
    this.showToDate = true;
    this.showFromDate = true;
    this.currentdate = new Date();
    this.filteredListMonth = this.monthList.slice();
    this.page = 1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userName = sessionStorage.getItem('userName');
    //this.filteredList = this.allExpensesType.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if (this.userType !== 'Super Admin') {
      this.userPermission.forEach((element) => {
        if (element.pageName == 'Patient' && element.create == false) {
          this.PatientCreate = false;
        }

      });
    }
    if (this.userType == 'Doctor') {
      this.docName = this.userName;
    }
    else {
      this.docName = '';
    }
    if (this.userType !== 'Super Admin') {
      this.isSuperAdmin = false;


    }
    this.getReportHeaderGenericAllData();
    this.getReportFooterGenericAllData();
    this.fetchDoctorList();
    this.getAppPatient();
    this.fetchBranchList();

    this.reportForm = this.fb.group({
      "branchWiseId": new FormControl(''),
      "fdate": new FormControl(''),
      "todate": new FormControl(''),

      "reportType": new FormControl('', Validators.required),
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "branchName": new FormControl(),
      "doctorRefId": new FormControl(),
      "patientName": new FormControl(''),
      "monthName": new FormControl(),
      "patientNm": new FormControl(''),
      "HaCompany": new FormControl(''),
      "deviceRefId": new FormControl(''),
      "modeOfPayment": new FormControl(''),
      "createdAt": new Date(),
    });

    //this.reportForm.controls['fdate'].setValue(this.currentdate);

    this.clientImage = "";
    let today = new Date();
    this.reportForm.controls['fdate'].setValue(today);
    this.reportForm.controls['todate'].setValue(today);
    //
    //this.removeValidator();
    this.getAllBranch();
    this.getHACompany();
  }

  unsoldStock() {
    var doc = new jsPDF();
    var col = ["HA Company Name", "Invoice No", "Invoice Date", "Device Name", "Serial No", "Unit Price", "Quantity"];
    var rows = [];
    this.getUnsoldAll.forEach(element => {
      let datee = new Date(element.invoiceDate).toISOString();
      let invoiceDate = this.datepipe.transform(datee, 'dd-MM-yyyy');
      var temp = [element.haCompanyName, element.invoiceNo, invoiceDate, element.deviceName, element.serialNumber
        , element.unitPrice, element.serialNumber.length];
      rows.push(temp);
    });
    (doc as any).autoTable(col, rows,
      {
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 20 },
          2: { cellWidth: 20 },
          3: { cellWidth: 20 },
          4: { cellWidth: 60 },
          5: { cellWidth: 20 },
          6: { cellWidth: 20 },
          // etc
        }
      });
    doc.save('Unsold_stock.pdf');
  }
  dailySales() {
    var doc = new jsPDF();
    var col = ["Patient Name", "Bill No", "Mode Of Payment", "Billing Company",
    "Payment Status","Total Amt (Rs.)","Cash (Rs.)", "Non Cash (Rs.)", "Amt Collected (Rs.)"];
    var rows = [];
    let TotalAmt = 0;
    let cash = 0;
    let NonCash = 0;
    let paidAmt = 0;
    this.getDailySalesAll.forEach(element => {
      let cashAmt = 0
      let pendingChequeAmount = 0
      let paidAmount = 0
      let totalAmount = 0
      let paymentStatus='';
      if(element.paidAmount !== 0 && element.paidAmount !== undefined && element.paidAmount !== null)
      {
        if(element.modeOfPayment == 'Cash')
        {
          cashAmt = element.paidAmount.toFixed(2);
        }else{
          cashAmt = 0;
        }
      }
    
        if(element.modeOfPayment == 'Cheque')
        {
          pendingChequeAmount = element.pendingChequeAmount.toFixed(2);
        }else if(element.modeOfPayment == 'UPI' || element.modeOfPayment == 'Card'
        || element.modeOfPayment == 'RTGS/NEFT/IMPS'){
          pendingChequeAmount = element.paidAmount;
        }
        else{
          pendingChequeAmount = 0;
        }
       
       if(element.paidAmount !== 0 && element.paidAmount !== undefined && element.paidAmount !== null)
      {
        paidAmount = element.paidAmount.toFixed(2);
      }  
       if(element.totalAmount !== 0 && element.totalAmount !== undefined && element.totalAmount !== null)
      {
        totalAmount = element.totalAmount.toFixed(2);
      } 

      if(element.paidAmount !== element.totalAmount)
      {
        paymentStatus = 'Unpaid'
      }else if(element.paidAmount == element.totalAmount){
        paymentStatus = 'Paid';
      }
      var temp = [element.patientName, element.billNo, element.modeOfPayment, element.billingCompanyName,
        paymentStatus,totalAmount, cashAmt,
        pendingChequeAmount, paidAmount];
        rows.push(temp);

        TotalAmt += Math.round(totalAmount);
        cash += Math.round(cashAmt);
        NonCash += Math.round(pendingChequeAmount);
        paidAmt += Math.round(paidAmount);

    });
   

    var temp2 = ['', '', '', '',
      'Total', TotalAmt,
      cash, NonCash,paidAmt];
    rows.push(temp2);
    (doc as any).autoTable(col, rows,
      {
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 20 },
          2: { cellWidth: 20 },
          3: { cellWidth: 20 },
          4: { cellWidth: 20 },
          5: { cellWidth: 20 },
          6: { cellWidth: 20 },
          7: { cellWidth: 20 },
          8: { cellWidth: 20 },
          // etc
        }
      });

    

    doc.save('daily_sales.pdf');
    // doc.save('daily_sales.xlsx');
  }
  monthlySales() {
    var doc = new jsPDF();
    var col = ["Patient Name", "Bill No", "Test Name", "No Of Tests",
      "Cash (Rs.)", "Non Cash (Rs.)", "Amt Collected (Rs.)", "Total Amt (Rs.)"];
    var rows = [];
    this.monthlyDataAll.forEach(element => {
      let cashAmt = 0
      let pendingChequeAmount = 0
      let paidAmount = 0
      let totalAmount = 0
      if(element.paidAmount !== 0 && element.paidAmount !== undefined && element.paidAmount !== null)
      {
        cashAmt = element.paidAmount.toFixed(2);
      }else if(element.pendingChequeAmount !== 0 && element.pendingChequeAmount !== undefined && element.pendingChequeAmount !== null)
      {
        pendingChequeAmount = element.pendingChequeAmount.toFixed(2);
      }else if(element.paidAmount !== 0 && element.paidAmount !== undefined && element.paidAmount !== null)
      {
        paidAmount = element.paidAmount.toFixed(2);
      } else if(element.totalAmount !== 0 && element.totalAmount !== undefined && element.totalAmount !== null)
      {
        totalAmount = element.totalAmount.toFixed(2);
      }  

      let serviceName = [];
      element.serviceInfo.forEach(e => {
        serviceName.push(e.serviceName);
      });
      var temp = [element.patientName, element.billNo, serviceName, element.serviceInfo.length, cashAmt,
        pendingChequeAmount, paidAmount, totalAmount];
      rows.push(temp);
    });
    (doc as any).autoTable(col, rows,
      {
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 30 },
          2: { cellWidth: 20 },
          3: { cellWidth: 20 },
          4: { cellWidth: 20 },
          5: { cellWidth: 20 },
          6: { cellWidth: 20 },
          7: { cellWidth: 20 },
          // etc
        }
      });
    doc.save('monthly_sales.pdf');
  }
  monthlyTillDate() {
    var doc = new jsPDF();
    var col = ["Patient Name", "Appoint Date", "Followup Date", "Tracking Status",
      "Appointment Status"];
    var rows = [];
 
    this.getMonthTillDateAll.forEach(element => {
      let datee = new Date(element.appointDate).toISOString();
      let datee2 = new Date(element.followupDate).toISOString();
      let appointDate = this.datepipe.transform(datee, 'dd-MM-yyyy');
      let followupDate = this.datepipe.transform(datee2, 'dd-MM-yyyy');
      let trackingStatus = [];
      element.trackingStatusName.forEach(e => {
        trackingStatus.push(e);
      });

      var temp = [element.patientName, appointDate, followupDate, trackingStatus, element.aptStatus];
      rows.push(temp);

    });

    (doc as any).autoTable(col, rows,
      {
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 30 },
          2: { cellWidth: 30 },
          3: { cellWidth: 40 },
          4: { cellWidth: 30 },
          // etc
        }
      });
    doc.save('monthlyTillDate.pdf');

  }
  stockFile() {
    var doc = new jsPDF();
    var col = ["Date", "Product", "Serial Number", "Company",
      "Style", "Qty", "Purchase Rate", "Sell Rate",
      "Age"];
    var rows = [];

    this.getSeventhReport.forEach(element => {
      let datee = new Date(element.PURCHASE_DATE).toISOString();
      let PURCHASE_DATE = this.datepipe.transform(datee, 'dd-MM-yyyy');

      var temp = [PURCHASE_DATE, element.PRODUCT_NAME, element.SERIAL_NUMBER, element.PRODUCT_COMPANY, element.HA_STYLE,
        element.QUANTITY_ON_HAND, element.PURCHASE_RATE, element.SELL_RATE, element.DEVICE_AGE];

      rows.push(temp);
    });
    (doc as any).autoTable(col, rows,
      {
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 20 },
          2: { cellWidth: 30 },
          3: { cellWidth: 30 },
          4: { cellWidth: 10 },
          5: { cellWidth: 10 },
          6: { cellWidth: 20 },
          7: { cellWidth: 10 },
          8: { cellWidth: 10 },
          // etc
        }
      });
    doc.save('stock_file.pdf');
  }
  paymentReceipt() {
    var doc = new jsPDF();
    var col = ["Receipt No", "Patient Name", "Test Name", "Amount", "Discount", "BalanceAmt"];
    var rows = [];

    this.receiptDataAll.forEach(element => {
      let serviceName = [];
      element.serviceInfo.forEach(e => {
        serviceName.push(e.serviceName);
      });
      var temp = [element.receiptNo, element.patientName, serviceName, element.totalAmount, element.totalDiscount,
      element.balanceAmount];

      rows.push(temp);
    });
    (doc as any).autoTable(col, rows,
      {
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 40 },
          2: { cellWidth: 30 },
          3: { cellWidth: 20 },
          4: { cellWidth: 20 },
          5: { cellWidth: 30 },
        }
      });
    doc.save('payment_receipt.pdf');
  }
  patientListReport() {
    var doc = new jsPDF();
    var col = ["Patient ID", "First Name", "Last Name", "Age", "Mobile Number", "Street Address", "District"];
    var rows = [];

    this.listOfPatientsAll.forEach(element => {
      var temp = [element.patientId, element.firstName, element.lastName, element.age,
      element.mobile1, element.streetAddress, element.district];

      rows.push(temp);
    });
    (doc as any).autoTable(col, rows,
      {
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 30 },
          2: { cellWidth: 30 },
          3: { cellWidth: 10 },
          4: { cellWidth: 30 },
          5: { cellWidth: 30 },
          6: { cellWidth: 30 },
        }
      });
    doc.save('patient_List.pdf');
  }
  EarMouldReport() {
    var doc = new jsPDF();
    var col = ["Ear", "Company", "HA/Type", "Hardness", "Removal String", "Style", "Vent", "Canal Lenght"];
    var rows = [];

    this.earData.forEach(element => {
      let HAType;
      let Hardness;
      let RemovalString;
      let Style;
      let Vent;
      let CanalLenght;

      if (element.leftHAType == '') {
        HAType = element.rightHAType;
      } else {
        HAType = element.leftHAType;
      }
      if (element.leftHardness == '') {
        Hardness = element.rightHardness;
      } else {
        Hardness = element.leftHardness;
      }
      if (element.leftRemovalString == '') {
        RemovalString = element.rightRemovalString;
      } else {
        RemovalString = element.leftRemovalString;
      }
      if (element.leftStyle == '') {
        Style = element.rightStyle;
      } else {
        Style = element.leftStyle;
      }
      if (element.leftVent == '') {
        Vent = element.rightVent;
      } else {
        Vent = element.leftVent;
      }
      if (element.leftCanalLenght == '') {
        CanalLenght = element.rightCanalLenght;
      } else {
        CanalLenght = element.leftCanalLenght;
      }

      var temp = [element.ear, element.billingCompanyName, HAType, Hardness, RemovalString, Style, Vent, CanalLenght];

      rows.push(temp);
    });
    (doc as any).autoTable(col, rows,
      {
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 30 },
          2: { cellWidth: 20 },
          3: { cellWidth: 20 },
          4: { cellWidth: 30 },
          5: { cellWidth: 25 },
          6: { cellWidth: 20 },
          7: { cellWidth: 20 },
        }
      });
    doc.save('earMould.pdf');
  }
  followup() {
    var doc = new jsPDF();
    var col = ["Follow Up Date", "Patient Name", "Follow Up Type", "Follow Up Status"];
    var rows = [];

    this.followupDataAll.forEach(element => {
      let datee = new Date(element.followupDate).toISOString();
      let followupDate = this.datepipe.transform(datee, 'dd-MM-yyyy');
      var temp = [followupDate, element.patientName, element.followupType, element.followupStatus];
      rows.push(temp);
    });
    (doc as any).autoTable(col, rows,
      {
        columnStyles: {
          0: { cellWidth: 45 },
          1: { cellWidth: 45 },
          2: { cellWidth: 45 },
          3: { cellWidth: 45 },
        }
      });
    doc.save('followup.pdf');
  }
  getAllBranch() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {
        let length = res.data.length - 1;
        this.branchName = res.data[length].branchName;
        this.reportForm.get("branchRefId").setValue(res.data[length]._id);

        this.branchClick(res.data[length]._id, this.branchName);
        if (this.userType == 'Super Admin') {
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.reportForm.get("branchName").setValue(res.data[length].branchName);
          });
        }

      }
    });
  }

  getReportHeaderGenericAllData() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getReportHeaderGenericAllData(branchRef,clinicRef).subscribe(res => {
      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);


        this.headerData = res.data;
        //
        if (res.data.length > 0) {
          this.reportName = this.headerData[0].reportName;
          this.reportAddress = this.headerData[0].reportAddress;
          this.taluka = this.headerData[0].taluka;
          this.district = this.headerData[0].district;
          this.state = this.headerData[0].state;
          this.country = this.headerData[0].country;
          this.reportPinCode = this.headerData[0].reportPinCode;
          this.reportPhoneNumber = this.headerData[0].reportPhoneNumber;
          this.openTime = this.headerData[0].openTime;
          this.closeTime = this.headerData[0].closeTime;
          this.logoimg = res.data[0].uploadLogo;
        } else {
          this.reportName = "";
          this.reportAddress = "";
          this.taluka = "";
          this.district = "";
          this.state = "";
          this.country = "";
          this.reportPinCode = "";
          this.reportPhoneNumber = "";
          this.openTime = "";
          this.closeTime = "";
          this.logoimg = res.data[0].uploadLogo;
        }

        if (this.logoimg == "" || this.logoimg == '../../assets/main.jpeg')
          this.logoimg = "../../assets/clinic_logo3.jpg"
        //
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }else{
    this.apimicroservice.getReportHeaderGenericAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);


        this.headerData = res.data;
        //
        if (res.data.length > 0) {
          this.reportName = this.headerData[0].reportName;
          this.reportAddress = this.headerData[0].reportAddress;
          this.taluka = this.headerData[0].taluka;
          this.district = this.headerData[0].district;
          this.state = this.headerData[0].state;
          this.country = this.headerData[0].country;
          this.reportPinCode = this.headerData[0].reportPinCode;
          this.reportPhoneNumber = this.headerData[0].reportPhoneNumber;
          this.openTime = this.headerData[0].openTime;
          this.closeTime = this.headerData[0].closeTime;
          this.logoimg = res.data[0].uploadLogo;
        } else {
          this.reportName = "";
          this.reportAddress = "";
          this.taluka = "";
          this.district = "";
          this.state = "";
          this.country = "";
          this.reportPinCode = "";
          this.reportPhoneNumber = "";
          this.openTime = "";
          this.closeTime = "";
          this.logoimg = res.data[0].uploadLogo;
        }

        if (this.logoimg == "" || this.logoimg == '../../assets/main.jpeg')
          this.logoimg = "../../assets/clinic_logo3.jpg"
        //
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  }
  getReportFooterGenericAllData() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getReportFooterGenericAllData(branchRef,clinicRef).subscribe(res => {
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);
        this.footerData = res.data;
        //
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }else{
    this.apimicroservice.getReportFooterGenericAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);
        this.footerData = res.data;
        //
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  }
  fetchClinicExpensesList() {
    // let pg = 1;
    // let size = 15;
    this.spinner.show();
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    let search = '';
    this.searchText = this.reportForm.controls['searchExpenses'].value;
    //
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getClinicExpensesData(this.page, this.size, search, branchRef, clinicRef).subscribe(res => {
        this.spinner.hide();
        //
        this.listOfExpenses = res.data.data;
        this.totalLength = res.data.totalItems;

        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getClinicExpensesData(this.page, this.size, search, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        //
        this.listOfExpenses = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
  }

  // setValidator() { 
  //   this.reportForm.controls['date'].setValidators(Validators.required);
  //   this.reportForm.controls['date'].updateValueAndValidity();

  //   this.reportForm.controls['amount'].setValidators([Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(2),Validators.maxLength(7)]);
  //   this.reportForm.controls['amount'].updateValueAndValidity();

  //   this.reportForm.controls['expensesType'].setValidators(Validators.required);
  //   this.reportForm.controls['expensesType'].updateValueAndValidity();

  //   this.reportForm.controls['expenseName'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z ]*$")]);
  //   this.reportForm.controls['expenseName'].updateValueAndValidity();

  // }

  // removeValidator() { 
  //   this.reportForm.controls['date'].setErrors(null);
  //   this.reportForm.controls['date'].clearValidators();
  //   this.reportForm.controls['date'].updateValueAndValidity();

  //   this.reportForm.controls['amount'].setErrors(null);
  //   this.reportForm.controls['amount'].clearValidators();
  //   this.reportForm.controls['amount'].updateValueAndValidity();

  //   this.reportForm.controls['expensesType'].setErrors(null);
  //   this.reportForm.controls['expensesType'].clearValidators();
  //   this.reportForm.controls['expensesType'].updateValueAndValidity();

  //   this.reportForm.controls['expenseName'].setErrors(null);
  //   this.reportForm.controls['expenseName'].clearValidators();
  //   this.reportForm.controls['expenseName'].updateValueAndValidity();

  // }
  pName(e, l, id) {
    this.patientName = e;
    this.fullName = e + ' ' + l;
    this.apimicroservice.getPatientById(id).subscribe(res => {
      let data = res.data[0];
      this.pGender = data.sex;
      if (data.dob) {
        var timeDiff = Math.abs(Date.now() - new Date(data.dob).getTime());
        let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        //
        this.pAge = age;
      }
    });
  }

  monthSelect(val) {
    let month = val;
    let todayDate = new Date();
    let monthval = this.reportForm.controls['monthName'].value;
    this.firstDate = new Date(todayDate.getFullYear(), month, 1);
    this.lastDate = new Date(todayDate.getFullYear(), month + 1, 0);
    //
    //
  }

  submitClinicExpenses() {
    //  this.setValidator();
    this.isSubmit = true;
    this.page = 1;
    let payload = this.reportForm.value;
    if (payload.reportType == '10' || payload.reportType == '5' || payload.reportType == '8') {
      this.reportForm.controls['patientNm'].setValidators(Validators.required);
      this.reportForm.controls['patientNm'].updateValueAndValidity();
    }
    else {
      this.reportForm.controls['patientNm'].setErrors(null);
      this.reportForm.controls['patientNm'].clearValidators();
      this.reportForm.controls['patientNm'].updateValueAndValidity();
    }
    if (payload.reportType == '3') {
      this.reportForm.controls['monthName'].setValidators(Validators.required);
      this.reportForm.controls['monthName'].updateValueAndValidity();
      // this.reportForm.controls['fdate'].setValidators(Validators.required);
      // this.reportForm.controls['fdate'].updateValueAndValidity();

      // this.reportForm.controls['todate'].setValidators(Validators.required);
      // this.reportForm.controls['todate'].updateValueAndValidity();
    }
    else {
      this.reportForm.controls['monthName'].setErrors(null);
      this.reportForm.controls['monthName'].clearValidators();
      this.reportForm.controls['monthName'].updateValueAndValidity();
      // this.reportForm.controls['fdate'].setErrors(null);
      // this.reportForm.controls['fdate'].clearValidators();
      // this.reportForm.controls['fdate'].updateValueAndValidity();

      // this.reportForm.controls['todate'].setErrors(null);
      // this.reportForm.controls['todate'].clearValidators();
      // this.reportForm.controls['todate'].updateValueAndValidity();
    }

    if (payload.reportType == '13') {
      this.reportForm.controls['HaCompany'].setValidators(Validators.required);
      this.reportForm.controls['HaCompany'].updateValueAndValidity();
      // this.reportForm.controls['fdate'].setValidators(Validators.required);
      // this.reportForm.controls['fdate'].updateValueAndValidity();

      // this.reportForm.controls['todate'].setValidators(Validators.required);
      // this.reportForm.controls['todate'].updateValueAndValidity();
    }
    else {
      this.reportForm.controls['HaCompany'].setErrors(null);
      this.reportForm.controls['HaCompany'].clearValidators();
      this.reportForm.controls['HaCompany'].updateValueAndValidity();
      // this.reportForm.controls['fdate'].setErrors(null);
      // this.reportForm.controls['fdate'].clearValidators();
      // this.reportForm.controls['fdate'].updateValueAndValidity();

      // this.reportForm.controls['todate'].setErrors(null);
      // this.reportForm.controls['todate'].clearValidators();
      // this.reportForm.controls['todate'].updateValueAndValidity();
    }
    // if(payload.todate !== '')
    // {
    //   this.reportForm.controls['fdate'].setValidators(Validators.required);
    //   this.reportForm.controls['fdate'].updateValueAndValidity();
    // }
    // else
    // {
    //   this.reportForm.controls['fdate'].setErrors(null);
    //   this.reportForm.controls['fdate'].clearValidators();
    //   this.reportForm.controls['fdate'].updateValueAndValidity();
    // }
    if (this.reportForm.invalid) {

      this.reportForm.get('patientNm').markAsTouched();
      this.reportForm.get('HaCompany').markAsTouched();

      this.reportForm.get('reportType').markAsTouched();

      this.reportForm.get('fdate').markAsTouched();
      this.reportForm.get('monthName').markAsTouched();
      this.reportForm.get('todate').markAsTouched();
      return;

    }
    else {
      let search = '';
      //
      let todayDate = new Date();
      if (payload.reportType == "1") {
        this.getDailySalesAll=[];
        this.option1 = true;
        this.option13Show = false;
        this.option2 = false;
        this.option3 = false;
        this.option4 = false;
        this.option5 = false;
        this.option6 = false;
        this.option7 = false;
        this.option8 = false;
        this.option9 = false;
        this.option10 = false;
        this.option11 = false;
        this.option12 = false;
        this.option13 = false;
        this.option14 = false;
        let patientName = this.reportForm.controls['patientName'].value;
        this.getFirstReport = [];
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.dailySalesReportCard(this.page, this.size, search, patientName, payload.fdate,
          payload.todate, payload.modeOfPayment,branchRef).subscribe(res => {
            this.getFirstReport = res.data.data;
            this.totalLength = res.data.totalItems;
          }); 
          let bar = new Promise<void>((resolve, reject) => {
          this.apimicroservice.dailySalesAll(search, patientName, payload.fdate,
            payload.todate, payload.modeOfPayment,branchRef).subscribe(res => {
              this.getDailySalesAll= res.data.data;
              ////////
             this.TotalAmt = 0;
             this.cash = 0;
             this.NonCash = 0;
             this.paidAmt = 0;
           let cashAmt = 0
            let pendingChequeAmount = 0
            let paidAmount = 0
            let totalAmount = 0
            let paymentStatus='';
            if(this.getDailySalesAll.paidAmount !== 0 && this.getDailySalesAll.paidAmount !== undefined && this.getDailySalesAll.paidAmount !== null)
            {
              if(this.getDailySalesAll.modeOfPayment == 'Cash')
              {
                cashAmt = this.getDailySalesAll.paidAmount.toFixed(2);
              }else{
                cashAmt = 0;
              }
            }
         
            if(this.getDailySalesAll.modeOfPayment == 'Cheque')
            {
              pendingChequeAmount = this.getDailySalesAll.pendingChequeAmount.toFixed(2);
            }else if(this.getDailySalesAll.modeOfPayment == 'UPI' || this.getDailySalesAll.modeOfPayment == 'Card'
            || this.getDailySalesAll.modeOfPayment == 'RTGS/NEFT/IMPS'){
              pendingChequeAmount = this.getDailySalesAll.paidAmount;
            }
            else{
              pendingChequeAmount = 0;
            }
             if(this.getDailySalesAll.paidAmount !== 0 && this.getDailySalesAll.paidAmount !== undefined && this.getDailySalesAll.paidAmount !== null)
            {
              paidAmount = this.getDailySalesAll.paidAmount.toFixed(2);
            } 
              if(this.getDailySalesAll.totalAmount !== 0 && this.getDailySalesAll.totalAmount !== undefined && this.getDailySalesAll.totalAmount !== null)
            {
              totalAmount = this.getDailySalesAll.totalAmount.toFixed(2);
            } 
      
            if(this.getDailySalesAll.paidAmount !== this.getDailySalesAll.totalAmount)
            {
              paymentStatus = 'Unpaid'
            }else if(this.getDailySalesAll.paidAmount == this.getDailySalesAll.totalAmount){
              paymentStatus = 'Paid';
            }
            ////////
            
            
            this.getDailySalesAll.forEach(element => {
              this.TotalAmt += Math.round(element.totalAmount);
              if(element.modeOfPayment == 'Cash')
              {
                this.cash += Math.round(element.paidAmount);
              }
              if(element.modeOfPayment == 'Cheque')
              {
                this.NonCash += Math.round(element.pendingChequeAmount);
              }
              else if(element.modeOfPayment == 'UPI' || element.modeOfPayment == 'Card'
              || element.modeOfPayment == 'RTGS/NEFT/IMPS')
              {
                this.NonCash += Math.round(element.paidAmount);
              }

              this.paidAmt += Math.round(element.paidAmount);
            });
              resolve();

            

            }); 

          });
            
            // 
            // 
            // 
            // var temp2 = ['', '', '', '',
            // 'Total', this.TotalAmt,
            // this.cash, this.NonCash,this.paidAmt];
         // this.getDailySalesAll.push(temp2);
         this.getDailySalesTotal=[];
          bar.then(()=>{
  this.getDailySalesTotal.push({"f1": '',"f2":'',"f3":'',"f4":'',"f5": '',
  "f6":'Total', "f7":this.TotalAmt,
  "f8":this.cash, "f9":this.NonCash,"f10":this.paidAmt});

          
          })
        
          

      } else if (payload.reportType == "2") {
        this.option13Show = false;
        this.option1 = false;
        this.option2 = true;
        this.option3 = false;
        this.option4 = false;
        this.option5 = false;
        this.option6 = false;
        this.option7 = false;
        this.option8 = false;
        this.option9 = false;
        this.option10 = false;
        this.option11 = false;
        this.option12 = false;
        this.option13 = false;
        this.option14 = false;
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
        let branchRefSuperAdmin= '';
        if (this.userType !== 'Super Admin') {
        this.apimicroservice.dailyEnquiryReportCard(this.pageEnquiry, this.size, search, todayDate,branchRef,clinicRef).subscribe(res => {
          this.enquiryData = res.data.data;
          this.totalLengthEnquiry = res.data.totalItems;
        });
      }else{
        this.apimicroservice.dailyEnquiryReportCard(this.pageEnquiry, this.size, search, todayDate,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          this.enquiryData = res.data.data;
          this.totalLengthEnquiry = res.data.totalItems;
        });
      }
        this.apimicroservice.dailyPatientReportCard(this.pagePatient, this.size, search, todayDate,clinicRef).subscribe(res => {
          this.patientReg = res.data.data;
          this.totalLengthPatient = res.data.totalItems;
        });
        this.apimicroservice.dailyRepairReportCard(this.pageRepair, this.size, search, todayDate,branchRef).subscribe(res => {
          this.repairDetails = res.data.data;
          this.totalLengthrepairDetails = res.data.totalItems;
        });
        this.apimicroservice.dailyEarmoldReportCard(this.pageEarmold, this.size, search, todayDate,branchRef).subscribe(res => {
          this.earDetails = res.data.data;
          this.totalLengthEarMould = res.data.totalItems;
        });
        this.apimicroservice.dailyAppointReportCard(this.pageAppoint, this.size, search, todayDate,branchRef).subscribe(res => {
          this.appointmentData = res.data.data;
          this.totalLengthAppointment = res.data.totalItems;
        });

      } else if (payload.reportType == "3") {
        this.monthlyDataAll =[];
        this.option13Show = false;
        let size = 10;
        let page = 1;
        this.pageSales = 1;
        this.option1 = false;
        this.option2 = false;
        this.option3 = true;
        this.option4 = false;
        this.option5 = false;
        this.option6 = false;
        this.option7 = false;
        this.option8 = false;
        this.option9 = false;
        this.option10 = false;
        this.option11 = false;
        this.option12 = false;
        this.option13 = false;
        this.option14 = false;
        let patientName = this.reportForm.controls['patientName'].value;
        let search = '';
        // if(patientSearch == null){
        //   patientSearch = "";
        // }
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.montlySalesReportCard(this.pageSales, size, search, patientName, this.firstDate, this.lastDate,branchRef).subscribe(res => {
          this.monthlyData = res.data.data;
          //
          this.totalLengthMonthlyData = res.data.totalItems;
        });
        this.apimicroservice.montlySalesAll(search, patientName, this.firstDate, this.lastDate,clinicRef).subscribe(res => {
          this.monthlyDataAll = res.data.data;
        });
      } else if (payload.reportType == "4") {
        let page = 1;
        let size = 10;
        this.option13Show = false;
        this.option1 = false;
        this.option2 = false;
        this.option3 = false;
        this.option4 = true;
        this.option5 = false;
        this.option6 = false;
        this.option7 = false;
        this.option8 = false;
        this.option9 = false;
        this.option10 = false;
        this.option11 = false;
        this.option12 = false;
        this.option13 = false;
        this.option14 = false;
        this.getMonthTillDateAll = [];
        this.apimicroservice.monthOpdReport().subscribe(res => {
          this.getFourthReport = res.result;
          //this.getMeterReport1 = res.result.data1;
          //
          var lookup = {};
          var items = res.result;
          var result = [];
          for (var item, i = 0; item = items[i++];) {
            var name = item.serviceRateInfo;

            if (!(name in lookup)) {
              lookup[name] = 1;
              result.push(name);
            }
          }
          //
          this.title = result;

          if (res.status) {
            // this.ngOnInit();
            //   this.apimicroservice.openSnackbar(res.message);
          } else {
            //   this.apimicroservice.openSnackbar(res.message);
          }
        });
        //
        //
        let fromDate: Date;
        let toDate: Date;
        if (payload.fdate !== '' && payload.todate !== '') {
          fromDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
          toDate = new Date(todayDate);
        } else {
          fromDate = payload.fdate;
          toDate = payload.todate;
        }

        let patientName = this.reportForm.controls['patientName'].value;
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');

        this.apimicroservice.getMonthTillDateReport(page, size, search, patientName, fromDate, toDate,clinicRef).subscribe(res => {
          this.monthlyTillDateData = res.data.data;
          this.totalLengthMonthlyTillDate = res.data.totalItems;
        }); 
        this.apimicroservice.getMonthTillDateAll(search, patientName, fromDate, toDate,clinicRef).subscribe(res => {
          this.getMonthTillDateAll = res.data.data;
        }); 

      } else if (payload.reportType == "5") {
        this.option13Show = false;
        this.option1 = false;
        this.option2 = false;
        this.option3 = false;
        this.option4 = false;
        this.option5 = true;
        this.option6 = false;
        this.option7 = false;
        this.option8 = false;
        this.option9 = false;
        this.option10 = false;
        this.option11 = false;
        this.option12 = false;
        this.option13 = false;
        this.option14 = false;
      } else if (payload.reportType == "6") {
        this.option13Show = false;
        this.option1 = false;
        this.option2 = false;
        this.option3 = false;
        this.option4 = false;
        this.option5 = false;
        this.option6 = true;
        this.option7 = false;
        this.option8 = false;
        this.option9 = false;
        this.option10 = false;
        this.option11 = false;
        this.option12 = false;
        this.option13 = false;
        this.option14 = false;
      } else if (payload.reportType == "7") {
        this.option13Show = false;
        this.option1 = false;
        this.option2 = false;
        this.option3 = false;
        this.option4 = false;
        this.option5 = false;
        this.option6 = false;
        this.option7 = true;
        this.option8 = false;
        this.option9 = false;
        this.option10 = false;
        this.option11 = false;
        this.option12 = false;
        this.option13 = false;
        this.option14 = false;
        this.apimicroservice.stockFileReport(payload.branchRefId, payload.patientName, payload.fdate, payload.todate).subscribe(res => {
          this.getSeventhReport = res.result;
        });
      } else if (payload.reportType == "8") {
        this.option13Show = false;
        this.option1 = false;
        this.option2 = false;
        this.option3 = false;
        this.option4 = false;
        this.option5 = false;
        this.option6 = false;
        this.option7 = false;
        this.option8 = true;
        this.option9 = false;
        this.option10 = false;
        this.option11 = false;
        this.option12 = false;
        this.option13 = false;
        this.option14 = false;
        this.apimicroservice.EarMouldCard(this.patientName).subscribe(res => {
          this.earData = res.data.data;
          if (res.data.data.length > 0) {
            this.EarMouldData = res.data.data[0];
            this.totalLengthEarMould = res.data.totalItems;
          }

        });
      }
      else if (payload.reportType == "9") {
        this.receiptDataAll =[];
        this.option13Show = false;
        this.option1 = false;
        this.option2 = false;
        this.option3 = false;
        this.option4 = false;
        this.option5 = false;
        this.option6 = false;
        this.option7 = false;
        this.option8 = false;
        this.option9 = true;
        this.option10 = false;
        this.option11 = false;
        this.option12 = false;
        this.option13 = false;
        this.option14 = false;
        let receiptNo = '';
        let billNo = '';
        let branchRef= sessionStorage.getItem('branchRef');
        //http://localhost:6001/api/report/paymentReceiptReport?page=1&size=15&search=&receiptNo=&branchName=&billNo=&fromDate=2022-05-27T08:44:10.594Z&toDate=2022-05-28T08:44:10.594Z
        this.apimicroservice.paymentReceiptReportCard(this.pageReceiptReport, this.size, search, receiptNo, payload.branchName, payload.patientNm, billNo, payload.fdate, payload.todate,branchRef).subscribe(res => {
          this.receiptData = res.data.data;
          this.totalLengthReceiptData = res.data.totalItems;
        });
        this.apimicroservice.paymentReceiptAll(search,receiptNo,payload.branchName,payload.patientNm,billNo, payload.fdate,payload.todate,branchRef).subscribe(res => {
          this.receiptDataAll = res.data.data;
        });
      } else if (payload.reportType == "10") {
        //
        this.option13Show = false;
        this.option1 = false;
        this.option2 = false;
        this.option3 = false;
        this.option4 = false;
        this.option5 = false;
        this.option6 = false;
        this.option7 = false;
        this.option8 = false;
        this.option9 = false;
        this.option10 = true;
        this.option11 = false;
        this.option12 = false;
        this.option13 = false;
        this.option14 = false;
        this.apimicroservice.repairJobCard(payload.branchRefId, payload.patientName, payload.fdate, payload.todate).subscribe(res => {
          this.getNinthReport = res.result;
          //this.getMeterReport1 = res.result.data1;
          //


          if (res.status == true) {

            this.apimicroservice.openSnackbar(res.message);
          } if (res.status == false) {
            this.ngOnInit();
            this.apimicroservice.openSnackbar(res.message);
          }
        });
      }
      else if (payload.reportType == "11") {
        this.option13Show = false;
        this.option1 = false;
        this.option2 = false;
        this.option3 = false;
        this.option4 = false;
        this.option5 = false;
        this.option6 = false;
        this.option7 = false;
        this.option8 = false;
        this.option9 = false;
        this.option10 = false;
        this.option11 = true;
        this.option12 = false;
        this.option13 = false;
        this.option14 = false;
        // let patientName = '';
        // let billNo = '';
        //http://localhost:2220/api/report/getDailyPaymentReport?patientName=shivani bhosale&page=1&size=5&search
        let size = 10;
        let page = 1;
        let patientName = this.reportForm.controls['patientName'].value;
        this.apimicroservice.paymentListCard(page, size, search, patientName, todayDate).subscribe(res => {
          this.paymentData = res.data.data;
          //

          this.totalLength = res.data.totalItems;
          this.paymentListExport();
        });
      }
      else if (payload.reportType == "12") {
        this.listOfPatientsAll = [];
        let size = 10;
        let page = 1;
        this.option13Show = false;
        this.option1 = false;
        this.option2 = false;
        this.option3 = false;
        this.option4 = false;
        this.option5 = false;
        this.option6 = false;
        this.option7 = false;
        this.option8 = false;
        this.option9 = false;
        this.option10 = false;
        this.option11 = false;
        this.option12 = true;
        this.option13 = false;
        this.option14 = false;
        let patientName = '';
        let patientSearch = this.reportForm.controls['patientNm'].value;
        if (patientSearch == null) {
          patientSearch = "";
        }
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRef= sessionStorage.getItem('clinicRef');
        //
        this.apimicroservice.getPatientReportDetails(page, size, patientSearch, payload.fdate, payload.todate,branchRef,clinicRef).subscribe(res => {
          this.listOfPatients = res.data.data;
          this.totalLength = res.data.totalItems;
          //this.patientExportData();
        });
        this.apimicroservice.getPatientAll(patientSearch,payload.fdate,payload.todate,branchRef,clinicRef).subscribe(res => {
          this.listOfPatientsAll = res.data.data;
        });
      }
      else if (payload.reportType == "13") {
        let status = 'unsold';
        this.option13Show = true;
        let haCompanyRef = ''
        this.option1 = false;
        this.option2 = false;
        this.option3 = false;
        this.option4 = false;
        this.option5 = false;
        this.option6 = false;
        this.option7 = false;
        this.option8 = false;
        this.option9 = false;
        this.option10 = false;
        this.option11 = false;
        this.option12 = false;
        this.option13 = true;
        this.option14 = false;
        let HaCompany = this.reportForm.controls['HaCompany'].value;
        let deviceRefId = this.reportForm.controls['deviceRefId'].value;
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.unsoldStockReportCard(this.page13, this.size, search, status, HaCompany, deviceRefId,branchRef).subscribe(res => {
          this.getThirteenReport = res.data.data;
          this.totalLength13 = res.data.totalItems;
        });
        this.apimicroservice.unsoldStockReportCardDownload(status, HaCompany, deviceRefId,branchRef).subscribe(res => {
          this.getUnsoldAll = res.data.data;
        });
      }
      else if (payload.reportType == "14") {
        this.followupDataAll = [];
        this.option13Show = false;
        this.option1 = false;
        this.option2 = false;
        this.option3 = false;
        this.option4 = false;
        this.option5 = false;
        this.option6 = false;
        this.option7 = false;
        this.option8 = false;
        this.option9 = false;
        this.option10 = false;
        this.option11 = false;
        this.option12 = false;
        this.option13 = false;
        this.option14 = true;
        let patientName = this.reportForm.controls['patientName'].value;
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.followupReport(this.pagePatient, this.size, search, patientName, payload.fdate,
          payload.todate,branchRef,clinicRef).subscribe(res => {
            this.followupData = res.data.data;
            this.totalLengthFollowup = res.data.totalItems;
          });
          this.apimicroservice.followupReportAll(search,patientName,payload.fdate,payload.todate,branchRef,clinicRef).subscribe(res => {
              this.followupDataAll = res.data.data;
            });
      }
    }

  }


  onKeyUpEvent(event: any) {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.searchText = this.reportForm.get('searchExpenses').value;

    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getClinicExpensesData(this.page, this.size, this.searchText, branchRef, clinicRef).subscribe(res => {
        this.spinner.hide();
        //
        this.listOfExpenses = res.data.data;
        this.totalLength = res.data.totalItems;

        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    } else {
      this.apimicroservice.getClinicExpensesData(this.page, this.size, this.searchText, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        //
        this.listOfExpenses = res.data.data;
        this.totalLength = res.data.totalItems;
        //this.buildingLenght = this.listofbuild.length;
        if (res.status) {
          //  this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
  }
  onPageChangeAppoint(event) {
    this.pageAppoint = event;
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRef = '';
    let todayDate = new Date();
    let payload = this.reportForm.value;

    // this.apimicroservice.dailyPatientReportCard(this.pagePatient,this.size,this.searchText,todayDate).subscribe(res => {
    //   this.patientReg = res.data.data;
    //   this.totalLengthPatient = res.data.totalItems;
    //  });
    this.apimicroservice.dailyAppointReportCard(this.pageAppoint, this.size, this.searchText, todayDate,branchRef).subscribe(res => {
      this.appointmentData = res.data.data;
      this.totalLengthAppointment = res.data.totalItems;
    });
  }

  onPageChangePatient(event) {
    this.pagePatient = event;

    let clinicRef= sessionStorage.getItem('clinicRef');
    let todayDate = new Date();
    let payload = this.reportForm.value;


    this.apimicroservice.dailyPatientReportCard(this.pagePatient, this.size, this.searchText, todayDate,clinicRef).subscribe(res => {
      this.patientReg = res.data.data;
      this.totalLengthPatient = res.data.totalItems;
    });
  }

  onPageChangeRepair(event) {
    this.pageRepair = event;

    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRef = '';
    let todayDate = new Date();
    let payload = this.reportForm.value;


    this.apimicroservice.dailyRepairReportCard(this.pageRepair, this.size, this.searchText, todayDate,branchRef).subscribe(res => {
      this.repairDetails = res.data.data;
      this.totalLengthrepairDetails = res.data.totalItems;
    });
  }

  onPageChangeEar(event) {
    this.pageEarmold = event;

    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRef = '';
    let todayDate = new Date();
    let payload = this.reportForm.value;


    this.apimicroservice.dailyEarmoldReportCard(this.pageEarmold, this.size, this.searchText, todayDate,branchRef).subscribe(res => {
      this.earDetails = res.data.data;
      this.totalLengthEarMould = res.data.totalItems;
    });
  }
  onPageChangeFollowup(event) {
    this.pageFollow = event;
    let todayDate = new Date();
    let payload = this.reportForm.value;
    let patientName = this.reportForm.controls['patientName'].value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.followupReport(this.pagePatient, this.size, this.searchText, patientName, payload.fdate,
      payload.todate,branchRef,clinicRef).subscribe(res => {
        this.followupData = res.data.data;
        this.totalLengthFollowup = res.data.totalItems;
      });
  }
  onPageChange(event) {
    this.page = event;
    this.pageEnquiry = event;

    this.pageRepair = event;
    this.pageSales = event;
    this.pageEarmold = event;
    this.pageAppoint = event
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    // let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    // let branchRefSuperAdmin= '';
    let todayDate = new Date();
    //  this.searchText = this.reportForm.get('searchExpenses').value;
    let payload = this.reportForm.value;
    if (this.userType !== 'Super Admin') {
      if (payload.reportType == "1") {
        let patientName = this.reportForm.controls['patientName'].value;
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.dailySalesReportCard(this.page, this.size, this.searchText, patientName, payload.fdate,
          payload.todate, payload.modeOfPayment,branchRef).subscribe(res => {
            this.getFirstReport = res.data.data;
            this.totalLength = res.data.totalItems;
          });
      }

      else if (payload.reportType == "2") {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
        let branchRefSuperAdmin= '';
        if (this.userType !== 'Super Admin') {
        this.apimicroservice.dailyEnquiryReportCard(this.pageEnquiry, this.size, this.searchText, todayDate,branchRef,clinicRef).subscribe(res => {
          this.enquiryData = res.data.data;
          this.totalLengthEnquiry = res.data.totalItems;
        });
      }else{
        this.apimicroservice.dailyEnquiryReportCard(this.pageEnquiry, this.size, this.searchText, todayDate,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          this.enquiryData = res.data.data;
          this.totalLengthEnquiry = res.data.totalItems;
        });
      }

      }
      else if (payload.reportType == "11") {
        let size = 10;
        let patientName = this.reportForm.controls['patientName'].value;
        this.apimicroservice.paymentListCard(this.page, size, this.searchText, patientName, todayDate).subscribe(res => {
          this.paymentData = res.data.data;
          //

          this.totalLength = res.data.totalItems;
        });
        this.paymentListExport();
      }
      else if (payload.reportType == "3") {
        let patientName = this.reportForm.controls['patientName'].value;
        let search = '';
        //let patientSearch = this.reportForm.controls['patientNm'].value;
        // if(patientSearch == null){
        //   patientSearch = "";
        // }
        this.apimicroservice.montlySalesReportCard(this.pageSales, this.size, search, patientName, this.firstDate, this.lastDate,branchRef).subscribe(res => {
          this.monthlyData = res.data.data;
          this.totalLengthMonthlyData = res.data.totalItems;
        });
      } else if (payload.reportType == "4") {
        let search = '';
        let fromDate: Date;
        let toDate: Date;
        if (payload.fdate !== '' && payload.todate !== '') {
          fromDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
          toDate = new Date(todayDate);
        } else {
          fromDate = payload.fdate;
          toDate = payload.todate;
        }
        let patientName = this.reportForm.controls['patientName'].value;

        this.apimicroservice.getMonthTillDateReport(this.page, this.size, search, patientName, fromDate, toDate,clinicRef).subscribe(res => {
          this.monthlyTillDateData = res.data.data;
          this.totalLengthMonthlyTillDate = res.data.totalItems;
        });
      }
      // else if(payload.reportType == "1"){
      //   this.apimicroservice.dailySalesReportCard(this.page,this.size,this.searchText,todayDate).subscribe(res => {
      //     this.getFirstReport = res.data.data;
      //     this.totalLength = res.data.totalItems;
      //   });
      // }
      else if (payload.reportType == "12") {
        this.apimicroservice.getPatientList(this.page, this.size, this.searchText, branchRef, clinicRef).subscribe(res => {
          this.spinner.hide();
          //

          this.listOfPatients = res.data.data;
          this.totalLength = res.data.totalItems;
        });
      }
      else if (payload.reportType == "13") {
        let HaCompany = this.reportForm.controls['HaCompany'].value;
        let deviceRefId = this.reportForm.controls['deviceRefId'].value;
        let status = 'unsold';
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.unsoldStockReportCard(this.page, this.size, this.searchText, status, HaCompany, deviceRefId,branchRef).subscribe(res => {
          this.getThirteenReport = res.data.data;
          this.totalLength = res.data.totalItems;
        });

      }
      // else if(payload.reportType == "7"){
      //   let patientName = this.reportForm.controls['patientName'].value;
      //   this.apimicroservice.stockFileReport(payload.branchRefId,payload.patientName,payload.fdate,payload.todate).subscribe(res => {
      //     this.getSeventhReport = res.result;

      //     //this.getMeterReport1 = res.result.data1;
      //     //


      //    });
      // }
      else {
        this.apimicroservice.getClinicExpensesData(this.page, this.size, this.searchText, branchRef, clinicRef).subscribe(res => {
          this.spinner.hide();
          //
          this.listOfExpenses = res.data.data;
          this.totalLength = res.data.totalItems;
        });
      }

    }

    else {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      if (payload.reportType == "1") {
        let patientName = this.reportForm.controls['patientName'].value;
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.dailySalesReportCard(this.page, this.size, this.searchText, patientName, payload.fdate,
          payload.todate, payload.modeOfPayment,branchRef).subscribe(res => {
            this.getFirstReport = res.data.data;
            this.totalLength = res.data.totalItems;
          });
      }
      else if (payload.reportType == "2") {
        let clinicRef= sessionStorage.getItem('clinicRef');
        let branchRef= sessionStorage.getItem('branchRef');
        let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
        let branchRefSuperAdmin= '';
        if (this.userType !== 'Super Admin') {
        this.apimicroservice.dailyEnquiryReportCard(this.pageEnquiry, this.size, this.searchText, todayDate,branchRef,branchRef).subscribe(res => {
          this.enquiryData = res.data.data;
          this.totalLengthEnquiry = res.data.totalItems;
        });
      }else{
        this.apimicroservice.dailyEnquiryReportCard(this.pageEnquiry, this.size, this.searchText, todayDate,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
          this.enquiryData = res.data.data;
          this.totalLengthEnquiry = res.data.totalItems;
        });
      }

      }
      else if (payload.reportType == "3") {
        // let patientSearch = this.reportForm.controls['patientNm'].value;
        // if(patientSearch == null){
        //   patientSearch = "";
        // }
        let patientName = this.reportForm.controls['patientName'].value;
        let search = '';
        this.apimicroservice.montlySalesReportCard(this.pageSales, this.size, search, patientName, this.firstDate, this.lastDate,branchRef).subscribe(res => {
          this.monthlyData = res.data.data;
          this.totalLengthMonthlyData = res.data.totalItems;
        });
      }

      else if (payload.reportType == "4") {
        let search = '';
        let fromDate: Date;
        let toDate: Date;
        if (payload.fdate !== '' && payload.todate !== '') {
          fromDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
          toDate = new Date(todayDate);
        } else {
          fromDate = payload.fdate;
          toDate = payload.todate;
        }
        let patientName = this.reportForm.controls['patientName'].value;

        this.apimicroservice.getMonthTillDateReport(this.page, this.size, search, patientName, fromDate, toDate,clinicRef).subscribe(res => {
          this.monthlyTillDateData = res.data.data;
          this.totalLengthMonthlyTillDate = res.data.totalItems;
        });
      }
      else if (payload.reportType == "11") {
        let size = 10;
        let patientName = this.reportForm.controls['patientName'].value;
        this.apimicroservice.paymentListCard(this.page, size, this.searchText, patientName, todayDate).subscribe(res => {
          this.paymentData = res.data.data;
          //

          this.totalLength = res.data.totalItems;
        });
        this.paymentListExport();
      }

      else if (payload.reportType == "12") {
        this.apimicroservice.getPatientList(this.page, this.size, this.searchText, branchRef, clinicRef).subscribe(res => {
          this.spinner.hide();
          //

          this.listOfPatients = res.data.data;
          this.totalLength = res.data.totalItems;
        });
      }
      // else if(payload.reportType == "9"){
      //   // this.apimicroservice.getPatientList(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
      //   //   this.spinner.hide();
      //   //   //

      //   //     this.listOfPatients = res.data.data;
      //   //     this.totalLength = res.data.totalItems;   
      //   //  });
      //   let receiptNo = '';
      //   let billNo = '';
      //   this.apimicroservice.paymentReceiptReportCard(this.page,this.size,this.searchText,receiptNo,payload.branchName,billNo,payload.fdate,payload.todate).subscribe(res => {
      //     this.receiptData = res.data.data;
      //     //
      //     this.listOfRecipts = res.data.data;
      //     this.totalLengthReceiptData = res.data.totalItems;
      //    }); 
      //   }
      else if (payload.reportType == "9") {
        //let patientName = this.reportForm.controls['patientName'].value;
        let search = '';
        let receiptNo = '';
        let billNo = '';
        let branchRef= sessionStorage.getItem('branchRef');
        this.apimicroservice.paymentReceiptReportCard(this.page, this.size, this.searchText, receiptNo, payload.branchName, payload.patientNm, billNo, payload.fdate, payload.todate,branchRef).subscribe(res => {
          this.receiptData = res.data.data;
          //
          //this.listOfRecipts = res.data.data;
          this.totalLengthReceiptData = res.data.totalItems;
        });

      }
      // else if(payload.reportType == "2"){
      //   let size = 10;
      //   this.apimicroservice.dailyAppointReportCard(this.pageAppoint,size,this.searchText,todayDate).subscribe(res => {
      //     this.appointmentData = res.data.data;
      //     this.totalLengthAppointment = res.data.totalItems;
      //    });
      // } 
      else {
        this.apimicroservice.getClinicExpensesData(this.page, this.size, this.searchText, branchRef, clinicRef).subscribe(res => {
          this.spinner.hide();
          //
          this.listOfExpenses = res.data.data;
          this.totalLength = res.data.totalItems;
        });
      }

      // this.apimicroservice.getClinicExpensesData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
      //   this.spinner.hide();
      //   //
      //   this.listOfExpenses = res.data.data;
      //   this.totalLength = res.data.totalItems;
      // });
    }


  }
  onPage13Change(event) {
    this.page13 = event;
    let HaCompany = this.reportForm.controls['HaCompany'].value;
    let deviceRefId = this.reportForm.controls['deviceRefId'].value;
    let status = 'unsold';
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.unsoldStockReportCard(this.page13, this.size, this.searchText, status, HaCompany, deviceRefId,branchRef).subscribe(res => {
      this.getThirteenReport = res.data.data;
      this.totalLength13 = res.data.totalItems;
    });
  }
  cancelUpdate() {
    this.clientImage = "../../assets/no_data_found.jpeg";
    this.submitButton = true;
    this.updateButton = false;
    this.ngOnInit();
    this.reportForm.reset();
    this.currentdate = new Date();
    this.reportForm.controls['date'].setValue(this.currentdate);
  }

  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  toreportForm() {
    document.getElementById("toreportForm").scrollIntoView();
  }
  toClinicExpensesList() {
    document.getElementById("toClinicExpensesList").scrollIntoView();
  }

  getLastId() {
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getLastClinicExpenseID(this.branchRef).subscribe(res => {
        //   
        if (res['status']) {

          let clientIdd = res.data.totalItems;
          //

          this.reportForm.controls['branchWiseId'].setValue(clientIdd + 1);

        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    }
    else {
      this.apimicroservice.getLastAllClinicExpenseID().subscribe(res => {
        //   
        if (res['status']) {

          let clientIdd = res.data.totalItems;
          //

          this.superBranchId = clientIdd + 1;

          //this.reportForm.controls['branchWiseId'].setValue(clientIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    }
  }

  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        //  
        if (res.status) {
          this.branchList = res.data;
          this.filteredListBranch = this.branchList.slice();
          this.branchClick(res.data[0]._id, res.data[0].branchName)
          this.reportForm.get("branchRefId").setValue(res.data[0]._id);
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      })
      // this.fetchBranchList();
    })
  }

  clear() {
    this.reportForm.reset();
    window.location.reload();
    this.ngOnInit();
  }

  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //  
      if (res.status) {
        this.branchList = res.data;
        let branchId;
        // this.branchList.map(element =>{
        //   if(element.branchName == 'Home'){
        //     branchId = element._id;
        //     this.reportForm.controls['branchRefId'].setValue(branchId);
        //   }
        // })
        this.filteredListBranch = this.branchList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  branchClick(id, branchName) {
    this.reportForm.controls["branchName"].setValue(branchName);
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getAllDeviceList(branchRef).subscribe(res => {
      if (res.status) {
        this.DeviceList2 = res.data.data;
        this.filteredListDevice2 = this.DeviceList2.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
    this.apimicroservice.getBranchById(id).subscribe(res => {
      let payload = this.reportForm.value;
      // payload.clinicRefId = res.data[0].clinicRefId;
      this.reportForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      // let idd = '';
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
        if (res.status) {
          // //
          this.patientList = res.data;
          this.filteredPatientList = this.patientList.slice();
        }
      });


      if (this.updateButton == false) {
        this.spinner.show();
        this.apimicroservice.getLastClinicExpenseID(payload.branchRefId).subscribe(res => {
          //   
          if (res['status']) {
            let clientIdd = res.data.totalItems;
            //

            this.reportForm.controls['branchWiseId'].setValue(clientIdd + 1);
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }

  closeClick() {
    this.modal.style.display = "none";
  }
  imgClick() {
    this.modal.style.display = "block";
    this.modalImg.src = this.clientImage;
  }
  onChange($event: Event) {
    const file = ($event.target as HTMLInputElement).files[0];
    const fileName = file.name;
    this.errormessage = '';
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "csv" ||
      ext.toLowerCase() == "xlsx" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "docx"
    ) {
      this.convertToBase64(file);
    }
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.clientImage = d;

    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);
      subscriber.complete();
    };
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }

  openExpensePopUp() {
    //localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpExpenseTypeComponent,
      {
        width: "500px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      this.fetchExpenseTypeList();
    });
  }

  fetchExpenseTypeList() {

    this.apimicroservice.getExpenseList().subscribe(res => {
      //
      this.allExpensesType = res.data;
      this.filteredList = this.allExpensesType.slice();
    });
  }
  fetchDoctorList() {
    //
    //
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getAllDoctorsList('', '').subscribe(res => {
        //

        if (res.status) {
          this.doctorList = res.data;
          this.filteredListDoctor = this.doctorList.slice();
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
    else {
      this.apimicroservice.getAllDoctorslist().subscribe(res => {
        //
        if (res.status) {
          //
          this.doctorList = res.data.data;
          this.filteredListDoctor = this.doctorList.slice();
        }
      });
    }
  }
  getAppPatient() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');

    this.apimicroservice.getPatientListByBranchClinic(branchRef, clinicRef).subscribe(res => {
      if (res.status) {
        // //
        this.patientList = res.data;
        this.filteredPatientList = this.patientList.slice();
      }
    });



  }
  toOpenDateChnage() {

    let payload = this.reportForm.value;
    // const date1 = new Date(dd.toDateString()+" "+a);
    // const date2 = new Date(dd.toDateString()+" "+b);


    if (payload.fdate >= payload.todate && payload.todate !== '') {
      this.apimicroservice.openSnackbar("From Date should be Less than To Date");
      this.reportForm.controls['fdate'].setValue('');
    }
  }
  toCloseDateChnage() {

    let payload = this.reportForm.value;
    // const date1 = new Date(dd.toDateString()+" "+a);
    // const date2 = new Date(dd.toDateString()+" "+b);


    if (payload.todate <= payload.fdate && payload.fdate !== '') {
      this.apimicroservice.openSnackbar("To Date should be Greater than From Date");
      this.reportForm.controls['todate'].setValue('');
    }
  }
  reportClick(e) {
    if (e == '1') {
      this.modeOfPayment = true;
    }
    else {
      this.modeOfPayment = false;
    }
    if (e == '13') {
      this.option13Show = true;
    }
    else {
      this.option13Show = false;
    }
    if (e == '3') {
      this.showMonth = true;
      this.showToDate = false;
      this.showFromDate = false;
    } else {
      this.showMonth = false;
      this.showToDate = true;
      this.showFromDate = true;
    }
    if (e == '2') {
      //  this.reportForm.controls['fdate'].setValue(this.currentdate);
    }
    else {
      this.reportForm.controls['fdate'].setValue('');
    }
  }
  openPatientPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
  }
  globalPrint() {
    //  window.print(); 
    let currentDate = new Date();
    let date = currentDate.toLocaleDateString('en-GB');
    let payload = this.reportForm.value;
    let titleName = 'Audiology_' + date

    if (payload.reportType == '1') {
      // titleName = 'Daily Sales Report_'+date;

      // var tempTitle = document.title;
      // document.title = titleName;
      // window.print();
      // document.title = tempTitle;
      this.dailySales(); 

    } else if (payload.reportType == "2") {
      titleName = 'Daily Activity Report_' + date;

      var tempTitle = document.title;
      document.title = titleName;
      window.print();
      document.title = tempTitle;

    } else if (payload.reportType == "3") {
      // titleName = 'Monthly Sales Report_'+date;

      // var tempTitle = document.title;
      // document.title = titleName;
      // window.print();
      // document.title = tempTitle;
      this.monthlySales();

    } else if (payload.reportType == "4") {
      this.monthlyTillDate();

    } else if (payload.reportType == "5") {
      titleName = 'Patient Report 1_' + date;

      var tempTitle = document.title;
      document.title = titleName;
      window.print();
      document.title = tempTitle;

    } else if (payload.reportType == "6") {
      titleName = '6.pdf';
    } else if (payload.reportType == "7") {
      // titleName = 'Stock File Report_'+date;

      // var tempTitle = document.title;
      // document.title = titleName;
      // window.print();
      // document.title = tempTitle;
      this.stockFile();

    } else if (payload.reportType == "8") {
      this.EarMouldReport();

    } else if (payload.reportType == "9") {
      this.paymentReceipt();

    } else if (payload.reportType == "10") {
      titleName = 'Repair Job Card_' + date;

      var tempTitle = document.title;
      document.title = titleName;
      window.print();
      document.title = tempTitle;
    }
    else if (payload.reportType == "12") {
      this.patientListReport()
    }
    else if (payload.reportType == "13") {
      this.unsoldStock();
    }
    else if (payload.reportType == "14") {
      this.followup();
    }
    else {
      var tempTitle = document.title;
      document.title = titleName;
      window.print();
      document.title = tempTitle;
    }

  }
  onback() {
    this.apimicroservice.back()
  }

  onSelectPatient(id) {
    this.patientSelect = true;
    this.spinner.show();
    this.apimicroservice.getPatientById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      this.pid = id;
      //

      this.reportForm.controls["firstName"].setValue(data.firstName);
      this.reportForm.controls["lastName"].setValue(data.lastName);
      this.reportForm.controls["patientUniqueId"].setValue(data.patientId);
      this.reportForm.controls["mobile1"].setValue(data.mobile1);
      this.reportForm.controls["age"].setValue(data.age);


      if (data.dob) {
        var timeDiff = Math.abs(Date.now() - new Date(data.dob).getTime());
        let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        //
        this.reportForm.controls["age"].setValue(age);
      }


    });
  }

  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      //
      //
      this.reportForm.get("patientNm").setValue(result.data.firstName + ' ' + result.data.lastName);
      this.reportForm.get("patientName").setValue(result.data._id);
      // this.reportForm.get("patientRefId").setValue(result.data._id);

      this.pName(result.data.firstName, result.data.lastName, result.data._id);
      // this.reportForm.get("patientRefId").setValue(result.data._id);
      //this.onSelectPatient(result.data._id);

    });
  }
  fileName = "Daily Payment Report.xlsx";
  fileName2 = "Patient List.xlsx";
  paymentListExport() {
    let patientName = this.reportForm.controls['patientName'].value;
    let search = '';
    let todayDate = new Date();
    this.apimicroservice.paymentListExport(this.page, this.size, search, patientName, todayDate).subscribe(res => {
      this.paymentDataExport = res.data.data;
      //
    });
  }
  patientExportData() {
    let pg = 1;
    let size = 10;
    this.searchText = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getPatientList(pg, size, this.searchText, branchRef, clinicRef).subscribe(res => {
      //
      this.listOfPatients = res.data.data;
      this.totalLength = res.data.totalItems;
      this.page = res.data.pageNumber;
      //this.buildingLenght = this.listofbuild.length;
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message);

      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
    ///////
    this.apimicroservice.getPatientListExport(this.searchText, branchRef, clinicRef).subscribe(res => {
      this.listOfPatientsExport = res.data.data;
    });
  }
  //   export() 
  // {
  //   let payload = this.reportForm.value;
  //     this.exportAsExcelFile(this.fileName); 
  // }
  export() {
    //  window.print(); 
    let currentDate = new Date();
    let date = currentDate.toLocaleDateString('en-GB');
    let payload = this.reportForm.value;
    let titleName = 'Audiology_' + date

    if (payload.reportType == '1') {

      let dailySalesData = [];
      let TotalAmt = 0;
      let cash = 0;
      let NonCash = 0;
      let paidAmt = 0;

      this.getDailySalesAll.forEach(element => {
        let cashAmt = 0
      let pendingChequeAmount = 0
      let paidAmount = 0
      let totalAmount = 0
      let paymentStatus='';
      if(element.paidAmount !== 0 && element.paidAmount !== undefined && element.paidAmount !== null)
      {
        if(element.modeOfPayment == 'Cash')
        {
          cashAmt = element.paidAmount.toFixed(2);
        }else{
          cashAmt = 0;
        }
      }
      //  if(element.pendingChequeAmount !== 0 && element.pendingChequeAmount !== undefined && element.pendingChequeAmount !== null)
      // { 
      //   if(element.modeOfPayment == 'Cheque')
      //   {
      //     pendingChequeAmount = element.pendingChequeAmount.toFixed(2);
      //   }else{
      //     pendingChequeAmount = 0;
      //   }
      // }
      if(element.modeOfPayment == 'Cheque')
      {
        pendingChequeAmount = element.pendingChequeAmount.toFixed(2);
      }else if(element.modeOfPayment == 'UPI' || element.modeOfPayment == 'Card'
      || element.modeOfPayment == 'RTGS/NEFT/IMPS'){
        pendingChequeAmount = element.paidAmount;
      }
      else{
        pendingChequeAmount = 0;
      }
       if(element.paidAmount !== 0 && element.paidAmount !== undefined && element.paidAmount !== null)
      {
        paidAmount = element.paidAmount.toFixed(2);
      } 
        if(element.totalAmount !== 0 && element.totalAmount !== undefined && element.totalAmount !== null)
      {
        totalAmount = element.totalAmount.toFixed(2);
      } 

      if(element.paidAmount !== element.totalAmount)
      {
        paymentStatus = 'Unpaid'
      }else if(element.paidAmount == element.totalAmount){
        paymentStatus = 'Paid';
      }

        dailySalesData.push({
          "Patient Name": element.patientName,
          "Bill No": element.billNo, "Mode Of Payment": element.modeOfPayment, "Billing Company": element.billingCompanyName,
          "Payment Status": paymentStatus,"Total Amt (Rs.)": totalAmount,
          "Cash (Rs.)": cashAmt, "Non Cash (Rs.)": pendingChequeAmount, "Amt Collected (Rs.)": paidAmount
          
        });
        
        TotalAmt += Math.round(totalAmount);
        cash += Math.round(cashAmt); 
        NonCash += Math.round(pendingChequeAmount);
        paidAmt += Math.round(paidAmount);
      });

      dailySalesData.push({"Patient Name": '',
      "Bill No": '',"Mode Of Payment": '', "Billing Company": '',
      "Payment Status": 'Total',"Total Amt (Rs.)": TotalAmt,
      "Cash (Rs.)": cash, "Non Cash (Rs.)": NonCash, "Amt Collected (Rs.)": paidAmt});

      this.exportAsExcelFile('Daily_sales_report', dailySalesData);

    } else if (payload.reportType == "2") {
      titleName = 'Daily Activity Report_' + date;

      var tempTitle = document.title;
      document.title = titleName;
      window.print();
      document.title = tempTitle;

    } else if (payload.reportType == "3") {
       let monthlySales = []; 
      this.monthlyDataAll.forEach(element => {
        let cashAmt = 0
        let pendingChequeAmount = 0
        let paidAmount = 0
        let totalAmount = 0
        if(element.paidAmount !== 0 && element.paidAmount !== undefined && element.paidAmount !== null)
        {
          cashAmt = element.paidAmount.toFixed(2);
        }else if(element.pendingChequeAmount !== 0)
        {
          pendingChequeAmount = element.pendingChequeAmount.toFixed(2);
        }else if(element.paidAmount !== 0 && element.paidAmount !== undefined && element.paidAmount !== null)
        {
          paidAmount = element.paidAmount.toFixed(2);
        } else if(element.totalAmount !== 0 && element.totalAmount !== undefined && element.totalAmount !== null)
        {
          totalAmount = element.totalAmount.toFixed(2);
        }  
        
        let serviceName = [];
        element.serviceInfo.forEach(e => {
          serviceName.push(e.serviceName);
        });
      
        monthlySales.push({
          "Patient Name": element.patientName,
          "Bill No":element.billNo, "Test Name":serviceName, "No Of Tests":element.serviceInfo.length,
        "Cash (Rs.)":cashAmt, "Non Cash (Rs.)":pendingChequeAmount, "Amt Collected (Rs.)":paidAmount, "Total Amt (Rs.)":totalAmount
        });
  
      });
      this.exportAsExcelFile('Monthly_sales_report', monthlySales);
    } else if (payload.reportType == "4") {
      titleName = 'Month Till Date OPD Report_' + date;
      let monthlySalesTillDate = [];
      this.getMonthTillDateAll.forEach(element => {
        let datee = new Date(element.appointDate).toISOString();
        let datee2 = new Date(element.followupDate).toISOString();
        let appointDate = this.datepipe.transform(datee, 'dd-MM-yyyy');
        let followupDate = this.datepipe.transform(datee2, 'dd-MM-yyyy');
        let trackingStatus = [];
        element.trackingStatusName.forEach(e => {
          trackingStatus.push(e);
        }); 
        
        monthlySalesTillDate.push({
          "Patient Name": element.patientName,
          "Appoint Date": appointDate, "Followup Date":followupDate, "Tracking Status":JSON.stringify(trackingStatus),
          "Appointment Status":element.aptStatus
          }); 
  
      });

      this.exportAsExcelFile('Month_Till_Date_OPD_Report', monthlySalesTillDate);
    } else if (payload.reportType == "5") {
      titleName = 'Patient Report 1_' + date;

      var tempTitle = document.title;
      document.title = titleName;
      window.print();
      document.title = tempTitle;

    } else if (payload.reportType == "6") {
      titleName = '6.pdf';
    } else if (payload.reportType == "7") {
      let stockFile = [];
      this.getSeventhReport.forEach(element => {
        let datee = new Date(element.PURCHASE_DATE).toISOString();
        let PURCHASE_DATE = this.datepipe.transform(datee, 'dd-MM-yyyy');
        stockFile.push({
          "Date": PURCHASE_DATE,
          "Product":element.PRODUCT_NAME, "Serial Number":element.SERIAL_NUMBER, "Company":element.PRODUCT_COMPANY,
          "Style":element.HA_STYLE, "Qty":element.QUANTITY_ON_HAND, "Purchase Rate":element.PURCHASE_RATE,
           "Sell Rate":element.SELL_RATE,"Age":element.DEVICE_AGE
         });
      });
      this.exportAsExcelFile('stock_report', stockFile);
    } else if (payload.reportType == "8") {
      let EarMould=[];
      this.earData.forEach(element => {
        let HAType;
        let Hardness;
        let RemovalString;
        let Style;
        let Vent;
        let CanalLenght;
  
        if (element.leftHAType == '') {
          HAType = element.rightHAType;
        } else {
          HAType = element.leftHAType;
        }
        if (element.leftHardness == '') {
          Hardness = element.rightHardness;
        } else {
          Hardness = element.leftHardness;
        }
        if (element.leftRemovalString == '') {
          RemovalString = element.rightRemovalString;
        } else {
          RemovalString = element.leftRemovalString;
        }
        if (element.leftStyle == '') {
          Style = element.rightStyle;
        } else {
          Style = element.leftStyle;
        }
        if (element.leftVent == '') {
          Vent = element.rightVent;
        } else {
          Vent = element.leftVent;
        }
        if (element.leftCanalLenght == '') {
          CanalLenght = element.rightCanalLenght;
        } else {
          CanalLenght = element.leftCanalLenght;
        }
        EarMould.push({
          "Ear":element.ear, "Company":element.billingCompanyName, "HA/Type":HAType, "Hardness":Hardness,
           "Removal String":RemovalString, "Style":Style, "Vent":Vent, "Canal Lenght":CanalLenght
         });
      });
      this.exportAsExcelFile('EarMouldReport', EarMould);
    } else if (payload.reportType == "9") {
      let paymentReceipt=[];
      this.receiptDataAll.forEach(element => {
        let serviceName = [];
        element.serviceInfo.forEach(e => {
          serviceName.push(e.serviceName);
        });
        paymentReceipt.push({
          "Receipt No":element.receiptNo, 
           "Patient Name":element.patientName, "Test Name":JSON.stringify(serviceName), "Amount":element.totalAmount,
            "Discount":element.totalDiscount, "BalanceAmt":element.balanceAmount
         });
      });
      this.exportAsExcelFile('paymentReceipt', paymentReceipt);
    } else if (payload.reportType == "10") {
      titleName = 'Repair Job Card_' + date;

      var tempTitle = document.title;
      document.title = titleName;
      window.print();
      document.title = tempTitle;
    }
    else if (payload.reportType == "12") {
      let patientListReport=[];
      this.listOfPatientsAll.forEach(element => {
        patientListReport.push({
          "Patient ID":element.patientId, 
          "First Name":element.firstName, "Last Name":element.lastName, "Age":element.age, 
          "Mobile Number":element.mobile1, "Street Address":element.streetAddress, "District":element.district
         });
      });
      this.exportAsExcelFile('patientReport', patientListReport);
    }
    else if (payload.reportType == "13") {
      let unsoldStock=[];
      this.getUnsoldAll.forEach(element => {
        let datee = new Date(element.invoiceDate).toISOString();
        let invoiceDate = this.datepipe.transform(datee, 'dd-MM-yyyy');
        unsoldStock.push({
          "HA Company Name":element.haCompanyName, 
          "Invoice No":element.invoiceNo, "Invoice Date":invoiceDate, "Device Name":element.deviceName,
           "Serial No":element.serialNumber, "Unit Price":element.unitPrice, "Quantity":element.serialNumber.length
          }); 
      });
      this.exportAsExcelFile('unsoldStockReport', unsoldStock);
    }
    else if (payload.reportType == "14") {
      let followup = [];
      this.followupDataAll.forEach(element => {
        let datee = new Date(element.followupDate).toISOString();
        let followupDate = this.datepipe.transform(datee, 'dd-MM-yyyy');
        followup.push({
          "Follow Up Date":followupDate, 
          "Patient Name":element.patientName, "Follow Up Type":element.followupType, 
          "Follow Up Status":element.followupStatus
           });
      });
      this.exportAsExcelFile('followup_report', followup);
    }
    else {
      var tempTitle = document.title;
      document.title = titleName;
      window.print();
      document.title = tempTitle;
    }

  }
  export2() {
    let payload = this.reportForm.value;
    this.exportAsExcelFile2(this.fileName2);
  }
  public exportAsExcelFile(excelFileName: string, data): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  public exportAsExcelFile2(excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.listOfPatientsExport);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile2(excelBuffer, excelFileName);
  }
  private saveAsExcelFile2(buffer: any, fileName2: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName2 + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  download() {
    // const wwe = ['PURCHASE_DATE', 'BATCH_ID','PRODUCT_BARCODE','PRODUCT_CODE','PRODUCT_COMPANY','PRODUCT_NAME','SERIAL_NUMBER','HA_PRODUCT_TYPE','PRODUCT_MODEL_NO','PRODUCT_GROUP','PRODUCT_SUBGROUP','PRODUCT_SKU','PRODUCT_UOM','HSN_CODE',
    //               'PURCHASE_RATE','SELL_RATE','DEVICE_AGE','LOCATION','HA_STYLE','QUANTITY_ON_HAND'];
    //

    this.apimicroservice.downloadFile(this.getSeventhReport, 'jsontocsv');
  }

  download1() {
    // const wwe = ['PURCHASE_DATE', 'BATCH_ID','PRODUCT_BARCODE','PRODUCT_CODE','PRODUCT_COMPANY','PRODUCT_NAME','SERIAL_NUMBER','HA_PRODUCT_TYPE','PRODUCT_MODEL_NO','PRODUCT_GROUP','PRODUCT_SUBGROUP','PRODUCT_SKU','PRODUCT_UOM','HSN_CODE',
    //               'PURCHASE_RATE','SELL_RATE','DEVICE_AGE','LOCATION','HA_STYLE','QUANTITY_ON_HAND'];
    // //
    let todayDate = new Date();
    let patientName = this.reportForm.controls['patientName'].value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.dailySalesReportCardDownload(this.page, this.size, this.searchText, patientName, todayDate,branchRef,clinicRef).subscribe(res => {
      this.getFirstReportDownload = res.data.data;
      this.totalLength = res.data.totalItems;
    });
    this.apimicroservice.downloadFile(this.getFirstReportDownload, 'jsontocsv');
  }



  download3() {
    // const wwe = ['PURCHASE_DATE', 'BATCH_ID','PRODUCT_BARCODE','PRODUCT_CODE','PRODUCT_COMPANY','PRODUCT_NAME','SERIAL_NUMBER','HA_PRODUCT_TYPE','PRODUCT_MODEL_NO','PRODUCT_GROUP','PRODUCT_SUBGROUP','PRODUCT_SKU','PRODUCT_UOM','HSN_CODE',
    //               'PURCHASE_RATE','SELL_RATE','DEVICE_AGE','LOCATION','HA_STYLE','QUANTITY_ON_HAND'];
    // //
    let patientName = this.reportForm.controls['patientName'].value;
    let search = '';
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.montlySalesReportCardDownload(this.pageSales, this.size, search, patientName, this.firstDate, this.lastDate,branchRef).subscribe(res => {
      this.monthlyDataDownload = res.data.data;
      this.totalLengthMonthlyData = res.data.totalItems;
    });
    this.apimicroservice.downloadFile(this.monthlyDataDownload, 'jsontocsv');
  }

  download4() {
    let payload = this.reportForm.value;
    // let patientName = this.reportForm.controls['patientName'].value;

    let search = '';
    let fromDate: Date;
    let toDate: Date;
    let todayDate = new Date();
    if (payload.fdate !== '' && payload.todate !== '') {
      fromDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
      toDate = new Date(todayDate);
    } else {
      fromDate = payload.fdate;
      toDate = payload.todate;
    }
    let patientName = this.reportForm.controls['patientName'].value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getMonthTillDateReportDownload(this.page, this.size, search, patientName, fromDate, toDate,clinicRef).subscribe(res => {
      this.monthlyTillDateDataDownload = res.data.data;
      this.totalLengthMonthlyTillDate = res.data.totalItems;
    });
    this.apimicroservice.downloadFile(this.monthlyTillDateDataDownload, 'jsontocsv');
  }
  download9() {
    let search = '';
    let payload = this.reportForm.value;
    let receiptNo = '';
    let billNo = '';
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.paymentReceiptReportCardDownload(this.page, this.size, this.searchText, receiptNo, payload.branchName, billNo, payload.fdate, payload.todate,branchRef).subscribe(res => {
      this.receiptDataDownload = res.data.data;
      //
      //this.listOfRecipts = res.data.data;
      this.totalLengthReceiptData = res.data.totalItems;
    });

    this.apimicroservice.downloadFile(this.receiptDataDownload, 'jsontocsv');
  }
  download12() {
    let size = 10;
    let page = 1;
    let payload = this.reportForm.value;
    let patientSearch = this.reportForm.controls['patientNm'].value;
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRef= sessionStorage.getItem('clinicRef');
    if (patientSearch == null) {
      patientSearch = "";
    }
    //
    this.apimicroservice.getPatientReportDetailsDownload(page, size, patientSearch, payload.fdate, payload.todate,branchRef,clinicRef).subscribe(res => {
      this.listOfPatientsDownload = res.data.data;
      //

      this.totalLength = res.data.totalItems;
      //

      //this.patientExportData();
    });
    this.apimicroservice.downloadFile(this.listOfPatientsDownload, 'jsontocsv');
  }

  download13() {
    let HaCompany = this.reportForm.controls['HaCompany'].value;
    let status = 'unsold';
    let search = '';
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.unsoldStockReportByCompanyDownload(HaCompany,branchRef).subscribe(res => {
      this.getThirteenReportDownload = res.countData.dataPush;
      // this.totalLength = res.data.totalItems;
    });

    this.apimicroservice.downloadFile(this.getThirteenReportDownload, 'jsontocsv');
  }
  getHACompany() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getAllHACompanylist(branchRef,clinicRef).subscribe(res => {
      //
      if (res.status) {
        this.HAcompany = res.data.data;
        this.filteredHACompanyList = this.HAcompany.slice();
        let lenght = res.data.totalItems - 1;
        this.reportForm.controls['HaCompany'].setValue(res.data.data[lenght]._id);
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }else{
    this.apimicroservice.getAllHACompanylist(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //
      if (res.status) {
        this.HAcompany = res.data.data;
        this.filteredHACompanyList = this.HAcompany.slice();
        let lenght = res.data.totalItems - 1;
        this.reportForm.controls['HaCompany'].setValue(res.data.data[lenght]._id);
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  }




}
