import { AnimateTimings } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from '../api-service.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-pop-up-style',
  templateUrl: './pop-up-style.component.html',
  styleUrls: ['./pop-up-style.component.scss']
})
export class PopUpStyleComponent implements OnInit {
popupStyleForm: FormGroup;
listOfStyle:any;
totalLength:any;
searchText:any;
page = 1;
size =3;
sid:any;
submitButton = true;
updateButton = false;
cancelButton = false;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopUpStyleComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,
     ) {dialogRef.disableClose = true; }

  ngOnInit(): void {
    this.page = 1;
    this.popupStyleForm = this.fb.group({
      "styleName": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "createdAt": new Date(),
      "searchStyleType": new FormControl(''),
    });
    this.fetchStyleList();
  }


  fetchStyleList() {
    let pg = 1;
    let size = 3;
    let search='';
        this.apimicroservice.getStyleType(pg,size,search).subscribe(res => {
           this.listOfStyle = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });   

  }

  saveStyleType(){
    let payload = this.popupStyleForm.value; 
    //
    this.spinner.show();
    this.apimicroservice.submitStyleData(payload).subscribe(res => {
      //
      this.spinner.hide();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        this.ngOnInit();
       this.onClose();        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
      this.onClose();    
  });
  }

  save() {
    if (this.popupStyleForm.invalid) {
      this.popupStyleForm.get("styleName").markAsTouched();
      return;
    } else {
      this.saveStyleType();
    }
  }

  onKeyUpEvent(event: any) {
    this.searchText = this.popupStyleForm.get('searchStyleType').value;
    this.apimicroservice.getStyleType(this.page,this.size,this.searchText).subscribe(res => {
      this.listOfStyle = res.data.data;
      this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  prepopulation(id) {
    this.apimicroservice.getStyleById(id).subscribe(res => {
      let data = res.data[0];
      this.sid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupStyleForm = this.fb.group({
          "styleName" :new FormControl(data.styleName),
          "updateddAt": new Date(),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  } 
  updateStyleType() {
    let payload = this.popupStyleForm.value;
     this.apimicroservice.updateStyleType(payload,this.sid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.popupStyleForm.get('searchStyleType').value;
 
    this.apimicroservice.getStyleType(this.page,this.size,this.searchText).subscribe(res => {
      this.listOfStyle = res.data.data;
      this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
  
  }
  cancelUpdate(){
    this.popupStyleForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
  onClose(): void {
    this.dialogRef.close();
  }
  
}


