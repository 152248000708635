import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'app-pop-up-batterytype',
  templateUrl: './pop-up-batterytype.component.html',
  styleUrls: ['./pop-up-batterytype.component.scss']
})
export class PopUpBatterytypeComponent implements OnInit {
  popupBatteryTypeForm:FormGroup;
  listOFBatteryType:any;
  totalLength:any;
  searchText:any;
  page=1;
  size=3;
  did:any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopUpBatterytypeComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    public apimicroservice: ApiServiceService,
    private spinner: NgxSpinnerService,

  ) {dialogRef.disableClose = true;}

  ngOnInit(): void {
    this.popupBatteryTypeForm = this.fb.group({
      "batteryType": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "createdAt": new Date(),
      "searchBatteryType": new FormControl(''),
    })
this.fetchBatteryTypeList();
  }


  fetchBatteryTypeList() {
    let pg = 1;
    let size = 3;
    let search='';
        this.apimicroservice.getBatteryType(pg,size,search).subscribe(res => {
           this.listOFBatteryType = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });   

  }

  saveBatteryType(){
    let payload = this.popupBatteryTypeForm.value; 
    //
    this.spinner.show();
    this.apimicroservice.submitBatteryTypeData(payload).subscribe(res => {
      //
      this.spinner.hide();
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        this.ngOnInit();
       this.onClose();        
      }else{
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
      this.onClose();    
  });
  }
  save() {
    if (this.popupBatteryTypeForm.invalid) {
      this.popupBatteryTypeForm.get("batteryType").markAsTouched();
      return;
    } else {
      this.saveBatteryType();
    }
  }

  onKeyUpEvent(event: any) {
    this.searchText = this.popupBatteryTypeForm.get('searchBatteryType').value;
    this.apimicroservice.getBatteryType(this.page,this.size,this.searchText).subscribe(res => {
      this.listOFBatteryType = res.data.data;
      this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  prepopulation(id) {
    this.apimicroservice.getBatteryTypeById(id).subscribe(res => {
      let data = res.data[0];
      this.did = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupBatteryTypeForm = this.fb.group({
          "batteryType" :new FormControl(data.batteryType),
          "updateddAt": new Date(),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  updateBatteryType() {
    let payload = this.popupBatteryTypeForm.value;
     this.apimicroservice.updateBatteryType(payload,this.did).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.popupBatteryTypeForm.get('searchBatteryType').value;
 
    this.apimicroservice.getBatteryType(this.page,this.size,this.searchText).subscribe(res => {
      this.listOFBatteryType = res.data.data;
      this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
  
  }
  cancelUpdate(){
    this.popupBatteryTypeForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }

  onClose(): void {
    this.dialogRef.close();
  }



}
