import {Component,OnInit} from '@angular/core';
import {FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';
import {Router} from '@angular/router';
import { ApiServiceService } from "../api-service.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http'; 
import { NgxSpinnerService } from "ngx-spinner"; 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  LoginForm: FormGroup;
  errorMessage;
  private formSumitAttempt: boolean;
  EmployeeId: any;
  Forgot: FormGroup;
  login = true;
  forgotpassword = false;
  changepassword = false;
  loginbtn = true;
  fieldTextType = false;
  userPermission:any;
  permissionList=[];
  ForgotPasswordView= true;
  ChangePasswordView= true;
  constructor(
private fb: FormBuilder,
   public router: Router,
   public snackBar: MatSnackBar,
   public apimicroservice: ApiServiceService,
   private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {

    this.LoginForm = this.fb.group({
      "userID": new FormControl(''),
      'emailId': ['', [Validators.required,Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}|^[a-zA-Z0-9]+$")]],
      'password': ['', [Validators.required, Validators.minLength(6)]]
     });
  }
  isFieldValid(field: string) {
    return (
      (!this.LoginForm.get(field).valid && this.LoginForm.get(field).touched) ||
      (this.LoginForm.get(field).untouched && this.formSumitAttempt)
    );
  }
  isFieldValids(field: string) {
    // return (
    //   (!this.Forgot.get(field).valid && this.Forgot.get(field).touched) ||
    //   (this.Forgot.get(field).untouched && this.formSumitAttempt)
    // );
  }
  onSubmit() {
    this.formSumitAttempt = true;
    if (this.LoginForm.valid) {
      // 
    }
  } 
  Login()
{
  let payload = this.LoginForm.value;
  if (payload.userID == '' && payload.emailId == '') {
    this.LoginForm.get("userID").markAsTouched();
    this.LoginForm.get("emailId").markAsTouched();
    return;
  } else {
    this.onLogin();
  }
}
  onLogin() {
    this.spinner.show();
      this.loginbtn = false;
      let payLoadData = this.LoginForm.value;
      let dd = "";
      dd = payLoadData.emailId;
      if(dd.includes('@')){
        payLoadData.emailId = dd;
        payLoadData.userID = "";
      }else{
        payLoadData.emailId = "";
        payLoadData.userID = dd;
      }
      let data = {
        "userID": payLoadData.userID,
        "emailId": payLoadData.emailId,
        "password": payLoadData.password
      }
      ////
      
      // accountLogin API start
      this.apimicroservice.userLogin(data).subscribe(res => {
      //
      
        if(res.status){

         sessionStorage.setItem('token',res.token);
           sessionStorage.setItem('userRef',res.data.id);
           sessionStorage.setItem('branchRef',res.data.branchRefId); 
           sessionStorage.setItem('userType',res.data.userType);
           sessionStorage.setItem("userPermission",JSON.stringify(res.data.userPermission));
           sessionStorage.setItem('userName',res.data.userName);
           sessionStorage.setItem('userImage',res.data.userImage);
           sessionStorage.setItem('doctorRefId',res.data.doctorRefId);
           sessionStorage.setItem('emailId',res.data.emailId);
           sessionStorage.setItem('userID',res.data.userID);
          this.userPermission = sessionStorage.getItem('userPermission');
          this.fetAletData();
          this.fetAletHolidayData();
         //
         if(res.data.userType !== 'Super Admin') 
           {
          this.apimicroservice.getBranchById(res.data.branchRefId).subscribe(res => {   
            let data = res.data[0];
            sessionStorage.setItem('clinicRef',res.data[0].clinicRefId);
            sessionStorage.setItem('branchName',res.data[0].branchName);    
           });
           }  
          else{
            sessionStorage.setItem('clinicRef',res.data.clinicRef);
          }
           
           this.spinner.hide();
           if(res.data.userType == 'Super Admin')
           {
             this.redirect('home');
           }
           else if(res.data.userType !== 'Super Admin')
           {
             this.userPermission = res.data.userPermission;
            //
            
             if(this.userPermission == '')
             {
               this.apimicroservice.openSnackbar("User does't have any permissions!");
             } 
             else
             { 
               var BreakException = {}; 
               this.userPermission.forEach((element) => {
              this.permissionList.push(element.pageName);
         
              // if(element.pageName == 'Home' && element.view == true)
              // {
              //     this.redirect('home');    
              //     throw BreakException;
              // }
             if(element.pageName == 'Dashboard' && element.view == true)
              {
                this.redirect('dashboard');
                throw BreakException;
              }
              else if(element.pageName == 'Book Appointments' && element.view == true)
              {
                this.redirect('calander');
                
                throw BreakException;
              }
              else if(element.pageName == 'Inquiry' && element.view == true)
              {
                this.redirect('inquiry');
                
                throw BreakException;
              }
              else if(element.pageName == 'Billing' && element.view == true)
              {
                this.redirect('billing');
                
                throw BreakException;
              }
              else if(element.pageName == 'Purchase Invoice' && element.view == true)
              {
                this.redirect('goods-in-word');
                 
                throw BreakException;
              }
              else if(element.pageName == 'Stock Transfer' && element.view == true)
              {
                this.redirect('stock-transfer');
                
                throw BreakException;
              }
              else if(element.pageName == 'Repair Complaint' && element.view == true)
              {
                this.redirect('repair-complaint');
                
                throw BreakException;
              }
              else if(element.pageName == 'Ear Mould' && element.view == true)
              {
                this.redirect('ear-mould');
                
                throw BreakException;
              }
              // else if(element.pageName == 'Clinic Expenses' && element.view == true)
              // {
              //   this.redirect('clinic-expenses');
                
              //   throw BreakException;
              // }
              else if(element.pageName == 'Custom Hearing Aid' && element.view == true)
              {
                this.redirect('custom-hearing-aid');
                
                throw BreakException;
              }
              else if(element.pageName == 'HA Form' && element.view == true)
              {
                this.redirect('ha-form');
                
                throw BreakException;
              }
              else if(element.pageName == 'Place Order' && element.view == true)
              {
                this.redirect('place-order');
                
                throw BreakException;
              }
               else if(element.pageName == 'Todays Appointment' && element.view == true)
              {
                this.redirect('opt/dashboard');
                
                throw BreakException;
              } 
              else if(element.pageName == 'Inward Outward' && element.view == true)
              {
                this.redirect('inward-outward');
                
                throw BreakException;
              }  
              else if(element.pageName == 'Report' && element.view == true)
              {
                this.redirect('report');
                
                throw BreakException;
              } 
              else if(element.pageName == 'Patient' && element.view == true)
              {
                this.redirect('patient-master');
                
                throw BreakException;
              }
              else if(element.pageName == 'Clinic' && element.view == true)
              {
                this.redirect('clinic-master');
                
                throw BreakException;
              }
              else if(element.pageName == 'Branch' && element.view == true)
              {
                this.redirect('branch-master');
                
                throw BreakException;
              }
              else if(element.pageName == 'Vendor' && element.view == true)
              {
                this.redirect('vendor-master');
                
                throw BreakException;
              }
              else if(element.pageName == 'Devices' && element.view == true)
              {
                this.redirect('devices-master');
                
                throw BreakException;
              }
              else if(element.pageName == 'Billing Company' && element.view == true)
              {
                this.redirect('billing-company');
                
                throw BreakException;
              }
              else if(element.pageName == 'Billing Form' && element.view == true)
              {
                this.redirect('bill-form');
                
                throw BreakException;
              }
              else if(element.pageName == 'Print Bill' && element.view == true)
              {
                this.redirect('print-bill');
                
                throw BreakException;
              }
              else if(element.pageName == 'Total Bill' && element.view == true)
              {
                this.redirect('popup-total-bill');
                
                throw BreakException;
              }
              else if(element.pageName == 'Add Service' && element.view == true)
              {
                this.redirect('popup-service');
                
                throw BreakException;
              }
              else if(element.pageName == 'Add Device' && element.view == true)
              {
                this.redirect('popup-add-device');
                
                throw BreakException;
              }
              else if(element.pageName == 'User' && element.view == true)
              {
                this.redirect('user-tab');
                
                throw BreakException;
              }

               else if(element.pageName == 'Clinical Staff' && element.view == true)
              {
                this.redirect('clinical-staff-master');
                
                throw BreakException;
              }
               else if(element.pageName == 'Appointment Type' && element.view == true)
              {
                this.redirect('appointment/type-appointment');
                
                throw BreakException;
              }
               else if(element.pageName == 'Services' && element.view == true)
              {
                this.redirect('services-master');
                
                throw BreakException;
              } 
              else if(element.pageName == 'Doctor' && element.view == true)
              {
                this.redirect('doctor-master');
                
                throw BreakException;
              }
              else if(element.pageName == 'Courier Service Provider' && element.view == true)
              {
                this.redirect('curier-service-provider');
                
                throw BreakException;
              }
              else if(element.pageName == 'Role' && element.view == true)
              {
                this.redirect('role-master');
                
                throw BreakException;
              }
              else if(element.pageName == 'Service Rate' && element.view == true)
              {
                this.redirect('service-rate');
                
                throw BreakException;
              }
              else if(element.pageName == 'Generic Setting' && element.view == true)
              {
                this.redirect('generic-setting');
                
                throw BreakException;
              }
              else if(element.pageName == 'Doctor Availability' && element.view == true)
              {
                this.redirect('doctor-availability');
                
                throw BreakException;
              }
              else if(element.pageName == 'Cheque List' && element.view == true)
              {
                this.redirect('cheque-list');
                
                throw BreakException;
              }
              else if(element.pageName == 'Holiday/Leave' && element.view == true)
              {
                this.redirect('holiday-leave');
                
                throw BreakException;
              }
              else if(element.pageName == 'Clinic Holidays' && element.view == true)
              {
                this.redirect('clinic-holiday');
                
                throw BreakException;
              }
              else if(element.pageName == 'Doctor Leave' && element.view == true)
              {
                this.redirect('doctor-leave');
                
                throw BreakException;
              }
              else if(element.pageName == 'Global Search' && element.view == true)
              {
                this.redirect('patient-global-search');
                
                throw BreakException;
              }
              else if(element.pageName == 'Alert' && element.view == true)
              {
                this.redirect('cheque-alert');
                
                throw BreakException;
              }
              // else if(element.pageName == 'Server Configuration' && element.view == true)
              // {
              //   this.redirect('server-configuration');
                
              //   throw BreakException;
              // }
            });
             }
           

           }
           
          
          //  this.spinner.hide(); 
               
         }
         else if(res.message == 'User Not Found'){
          this.spinner.hide();
          this.apimicroservice.openSnackbar("User Not Found");
        }
        else{
           this.spinner.hide();
         }
       } 
       ,(ERROR:HttpErrorResponse) => {
          // this.apimicroservice.openSnackbar(ERROR.error.message);
          this.apimicroservice.openSnackbar("User Not Found");
          this.spinner.hide();
      }
      ); 
  }
  fetAletData()
  {
    this.apimicroservice.AddchequeNotification().subscribe(res => {
      if(res.status){
        //this.apimicroservice.openSnackbar(res.message);
        
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  fetAletHolidayData()
  {
    this.apimicroservice.checkUpcommingHolidays().subscribe(res => {
    
    });
  }
  Forgotpassword() {
   // this.apimicroservice.openSnackbar('Clicked on forgot password ');
    
  }
  
  openSnackbar(message: string) {
    return this.snackBar.open(message, "close",
      { verticalPosition: 'bottom', horizontalPosition: 'right', duration: 4000, panelClass: ['snackbarStyle'] });
  }
 
  redirect(to): void {
   this.router.navigate([to]);
  } 
  customerDetails() {
    // this.forgotpassword = true;
    // this.login = false;
  }
  customerDetailsClose() {
    // this.forgotpassword = false;
    // this.login = true;
  }
  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.onLogin();
      this.onSubmit();
    }
  }

  toggleFieldTextType(){
    this.fieldTextType = !this.fieldTextType;
  }
  loginPage()
  {
    this.redirect('home');
  }
}