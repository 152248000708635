import { DatePipe } from '@angular/common';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ApiServiceService } from '../api-service.service';

@Component({
  selector: 'app-pop-up-repair-data',
  templateUrl: './pop-up-repair-data.component.html',
  styleUrls: ['./pop-up-repair-data.component.scss']
})
export class PopUpRepairDataComponent implements OnInit {
  listOfRepair: any;
  userType: string;
  branchRef: string;
  clinicRef: string;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService, 
    public router: Router,
     public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public route : ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private dialogRef: MatDialogRef<PopUpRepairDataComponent>
  ) { dialogRef.disableClose = true; }
  repairPopUpForm: FormGroup;
  page:number = 1;
  size:number = 5;
  listOfPatients: any;
  searchText: any;
  totalLength:number;
  ngOnInit(): void { 
  this.page=1;
  this.branchRef = sessionStorage.getItem('branchRef');
  this.clinicRef = sessionStorage.getItem('clinicRef');
  this.userType = sessionStorage.getItem('userType');
    this.repairPopUpForm = this.fb.group({
      "searchRepair": new FormControl(''),
    });
    this.fetchRepairList();
  }
  fetchRepairList() {
    let pg = 1;
    let size = 5;
    let search='';
 //   this.spinner.show();
 let clinicRef= sessionStorage.getItem('clinicRef');
 let branchRef= sessionStorage.getItem('branchRef');
 let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
 let branchRefSuperAdmin= '';
    this.searchText = this.repairPopUpForm.get('searchRepair').value;

    if(this.userType !== 'Super Admin')
     {
      this.apimicroservice.getRepairData(pg,size,search,branchRef,clinicRef).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;
        
        
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }else{
      this.apimicroservice.getRepairData(pg,size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;
        //
        //this.buildingLenght = this.listofbuild.length;
       if(res.status){
        // this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
      });
     }
  }
  toRepairForm() {
    document.getElementById("toRepairForm").scrollIntoView();
  }
  toRepairList() {
    document.getElementById("toRepairList").scrollIntoView();
  }
  
  onPageChange(event) {
    // this.spinner.show();
    this.page = event;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
     this.searchText = this.repairPopUpForm.get('searchRepair').value;
       
     if(this.userType !== 'Super Admin')
     {
      this.apimicroservice.getRepairData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;
        
       if(res.status){
       //  this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     });
     }else{
      this.apimicroservice.getRepairData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems;
    
        
       if(res.status){
      //   this.apimicroservice.openSnackbar(res.message);
         
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
  
     });
     }

     

  }
  onKeyUpEvent(event: any) {
    
    this.searchText = this.repairPopUpForm.get('searchRepair').value;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if(this.userType !== 'Super Admin')
    {
      this.apimicroservice.getRepairData(this.page,this.size,this.searchText,branchRef,clinicRef).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems; 
       if(res.status){
       //  this.apimicroservice.openSnackbar(res.message); 
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }else{
      this.apimicroservice.getRepairData(this.page,this.size,this.searchText,branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //
        this.listOfRepair = res.data.data;
        this.totalLength = res.data.totalItems; 
       if(res.status){
       //  this.apimicroservice.openSnackbar(res.message); 
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    }
    
   
  }
  toPatientForm(e) { 
    //
    let fromG = localStorage.getItem('fromGlobal');
    if(fromG == 'true') 
    { 
      this.dialogRef.close(); 
    //  this.router.navigate(['/home/patient-global-search'],{ queryParams: { PatientId: e._id } })
      this.router.navigate(['/global-dashboard'],{ queryParams: { PatientId: e.patientRefId  } });
    }
    else  
    {
      this.dialogRef.close({ data: e })
    }
  }
  redirectToRepairForm(repairNo){ 
    this.onClose();
    this.router.navigate(['repair-complaint',repairNo]);
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
