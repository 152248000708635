import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { PopUpBranchComponent } from "../pop-up-branch/pop-up-branch.component";
import { PopUpCityComponent } from "../pop-up-city/pop-up-city.component";
import { PopUpItemComponent } from "../pop-up-item/pop-up-item.component";
import { PopUpCourierserviceComponent } from "../pop-up-courierservice/pop-up-courierservice.component";
import { ElementRef } from '@angular/core';
import SignaturePad from 'signature_pad';
import { PopUpPatientComponent } from "../pop-up-patient/pop-up-patient.component";
import { PatientSearchPopComponent } from "../patient-search-pop/patient-search-pop.component";
import { PopupCompanyComponent } from "../popup-company/popup-company.component";
import { InwardOutwadPopupTypeComponent } from "../inward-outwad-popup-type/inward-outwad-popup-type.component";
import { ActivatedRoute } from "@angular/router";
import { stringify } from "@angular/compiler/src/util";
@Component({
  selector: 'app-inward-outward-new',
  templateUrl: './inward-outward-new.component.html',
  styleUrls: ['./inward-outward-new.component.scss']
})
export class InwardOutwardNewComponent implements OnInit {
  errormessage: string;
  bId: any;
  companyList: any;
  filteredListCompany: any;
  filteredListCourier: any;
  inwardOutwardForm: FormGroup;
  date: any;
  listOfAppointment: any;
  totalLength: any;
  appointmentId: any;
  size = 10;
  page = 1;
  searchText: any;
  submitButton = true;
  updateButton = false;
  allColor: any;
  filteredListPatient: any;
  filteredList: { name: string; value: string; }[];
  filteredListAppoinmentType: { name: string; value: string; }[];
  userType: string;
  branchRef: string;
  clinicRef: string;
  BranchList: any;
  isSuperAdmin = true;
  superBranchId: any;
  branchName: string;
  filteredListBranch: any;
  currentdate: any;
  listOfInwardOutward: any;
  patientList: any;
  id: any;
  courierList: any;
  disableFromCity = false;
  disableToCity = true;
  tocityList: any;
  cityList: any;
  typeeList: any;
  filteredTypeeList: any;
  fromCity: any;
  toCity: any;
  courierServiceCreate = true;
  branchCreate = true;
  filteredCityList: any;
  filteredToCityList: any;
  inward = false;
  outward = false;
  pId: any;
  patientID: any;
  pid: any;
  client = false;
  manufacturer = false;
  isDevices = false;
  isDocuments = false;
  patientSelect = false;
  filteredListCity: any;
  filteredListInoutType: any;
  inoutTypeList: any;
  inwardOutwardId: string;
  inwardOutwardBranch: string;
  repairData: any;
  filteredListRepair: any;
  clinicName: any;
  repairId: any;
  repairActionId: any;
  copyAraay: any;
  copyData: any;
  filteredListEarMould: any;
  earMouldData: any;
  customHAData: any[];
  filteredListCustomHA: any[];
  placeOrderData: any[];
  filteredListPlaceOrder: any[];
  earMoldId: any;
  customHAId: any;
  placeOrederId: any;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { }
  userPermission: any;
  inwardOutwardCreate = true;
  inwardOutwardUpdate = true;
  whenUpdate = false;
  ngOnInit(): void {
    let date = new Date();
    this.copyAraay=[];
    this.copyData = [];
    this.whenUpdate = false;
    this.isDevices = false;
    this.currentdate = this.datepipe.transform(date, 'dd/MM/yyyy');

    this.page = 1;
    this.branchRef = sessionStorage.getItem('branchRef');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.branchName = sessionStorage.getItem('branchName');
    this.userType = sessionStorage.getItem('userType');
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    if (this.userType !== 'Super Admin') {
      this.isSuperAdmin = false;
      this.userPermission.forEach((element) => {
        if (element.pageName == 'Inward Outward' && element.create == false) {
          this.inwardOutwardCreate = false;
        }
        if (element.pageName == 'Inward Outward' && element.update == false) {
          this.inwardOutwardUpdate = false;
        }
        if (element.pageName == 'Courier Service Provider' && element.create == false) {
          this.courierServiceCreate = false;
        }
        if (element.pageName == 'Branch' && element.create == false) {
          this.branchCreate = false;
        }
      });
    }
    this.inwardOutwardId = this.route.snapshot.paramMap.get('inwardOutwardId');
    this.inwardOutwardBranch = this.route.snapshot.paramMap.get('branchName');
    this.disableFromCity = false;
    this.disableToCity = true;
    this.inwardOutwardForm = this.fb.group({
      "id": new FormControl(''),
      "date": new FormControl('', Validators.required),
      "inwardOutward": new FormControl('', Validators.required),
      "patientName": new FormControl(''),
      "patientRefId": new FormControl(),
      "companyRefId": new FormControl(),
      "companyName": new FormControl(''),
      "courierRefId": new FormControl(),
      "courierName": new FormControl(''),
      "cityRefId": new FormControl(),
      "cityName": new FormControl(''),
      "trackingNumber": new FormControl('', Validators.pattern("^[a-zA-Z0-9]*$")),
      "documentsNumber": new FormControl('', Validators.pattern("^[a-zA-Z0-9/ ]*$")),
      "docDate": new FormControl(''),
      "deviceType": new FormControl(),
      "refType": new FormControl('', Validators.required),
      "typeRefId": new FormControl('', Validators.required),
      "docType": new FormControl(''),
      "notes": new FormControl(''),
      "searchInwardOutward": new FormControl(''),
      "branchWiseId": new FormControl(''),
      "branchName": new FormControl(),
      "branchRefId": new FormControl(),
      "clinicRefId": new FormControl(),
      "repairRefId": new FormControl(),
      "typeName": new FormControl(''),
      "repaidId": new FormControl(''),
      "earMoldId": new FormControl(''),
      "customHAId": new FormControl(''),
      "placeOrederId": new FormControl(''),
      "remark": new FormControl(''),
      


    });
    this.inwardOutwardForm.get("refType").setValue('Manufacturer');
    this.selectRef('Manufacturer');
    this.inwardOutwardForm.get("inwardOutward").setValue('Inward');
    if (this.inwardOutwardId !== '' && this.inwardOutwardId !== undefined
      && this.inwardOutwardId !== null) {
      this.prepopulationByBranchId(this.inwardOutwardId, this.inwardOutwardBranch);
    }
    this.inwardOutwardForm.controls['date'].setValue(this.currentdate);
    let today = new Date();
    this.inwardOutwardForm.controls['docDate'].setValue(today);
    this.getLastRepairId();
    this.getLastEarMouldId();
    this.getLastCustomHAId();
    this.getAllPOList();
    this.clinicPrepopulation(this.clinicRef);
    this.getRepair();
    this.getEarMould();
    this.getCustomHA();
    this.getPlaceOrder();   
    this.getLastId();
    this.fetchInwardOutward();
    this.fetchAllCompanies();
    this.fetchAllCourier();
    this.fetchAllCity();
    this.fetchAllType();
    //  this.InwardOutwardClick('Inward');
    this.fetchBranchList();
    this.getAllBranch();
  }
  fetchAllCompanies() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
      //console.log("all companies res",res);
      if (res.status) {
        this.companyList = res.data.data;
        this.filteredListCompany = this.companyList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  fetchAllCourier() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
    this.apimicroservice.getLastCourierAll(branchRef,clinicRef).subscribe(res => {
      //console.log("all Courier res",res); 
      if (res.status) {
        this.courierList = res.data.data;
        this.filteredListCourier = this.courierList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }else{
    this.apimicroservice.getLastCourierAll(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
      //console.log("all Courier res",res); 
      if (res.status) {
        this.courierList = res.data.data;
        this.filteredListCourier = this.courierList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  }
  fetchAllCity() {
    this.apimicroservice.getCityList().subscribe(res => {
      //console.log("all City res",res); 
      if (res.status) {
        this.cityList = res.data;
        this.filteredListCity = this.cityList.slice();
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  fetchAllType() {
    this.apimicroservice.getInoutTypeList().subscribe(res => {
      //console.log("all getInoutTypeList res",res);  
      if (res.status) {
        this.inoutTypeList = res.data;
        this.filteredListInoutType = this.inoutTypeList.slice();
        this.inwardOutwardForm.controls['typeRefId'].setValue(res.data[0]._id);
        this.type(res.data[0].inoutType);
        this.inwardOutwardForm.controls['deviceType'].setValue('For Repair');
      } else {
        //  this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  submitInwordOutword() {
    // this.setValidator();
    //this.InwardOutwardClick();
    let payload = this.inwardOutwardForm.value;
    this.inwardOutwardForm.controls['branchRefId'].setValidators(Validators.required);
    this.inwardOutwardForm.controls['branchRefId'].updateValueAndValidity();
    if (this.inwardOutwardForm.invalid) {
      this.inwardOutwardForm.get("date").markAsTouched();
      this.inwardOutwardForm.get("inwardOutward").markAsTouched();
      this.inwardOutwardForm.get("refType").markAsTouched();
      this.inwardOutwardForm.get("typeRefId").markAsTouched();
      this.inwardOutwardForm.get("patientName").markAsTouched();
      this.inwardOutwardForm.get("companyRefId").markAsTouched();
      this.inwardOutwardForm.get("courierRefId").markAsTouched();
      this.inwardOutwardForm.get("trackingNumber").markAsTouched();
      this.inwardOutwardForm.get("documentsNumber").markAsTouched();
    } else {
      this.addData();
    }
  }
  clinicPrepopulation(id) {
    this.apimicroservice.getClinicDataById(id).subscribe(res => {
      let data = res.data[0];
      this.clinicName = data.clinicName;
    });
  }
 
  getLastRepairId() {
    this.apimicroservice.getRepairActionAllData().subscribe(res => {
      this.repairActionId = res.data.data;
      this.repairActionId.sort((a, b) => b.repairActionId - a.repairActionId);
      this.repairActionId = this.repairActionId[0].repairActionId;
    });
  }
  getLastEarMouldId() {
  
    this.apimicroservice.getEarMoldAllData().subscribe(res => {
      this.earMoldId = res.data;
      this.earMoldId.sort((a, b) => b.earMoldId - a.earMoldId);
      this.earMoldId = this.earMoldId[0].earMoldId;
    });
  }
  getLastCustomHAId() {
    this.apimicroservice.getLastCustomHAAll().subscribe(res => {
      this.customHAId = res.data.data;
      this.customHAId.sort((a, b) => b.customHAId - a.customHAId);
      this.customHAId = this.customHAId[0].customHAId;
    });
  }
  getAllPOList() {
    this.apimicroservice.getAllPOList().subscribe(res => {
      this.placeOrederId = res.data.data;
      this.placeOrederId.sort((a, b) => b.placeOrederId - a.placeOrederId);
      this.placeOrederId = this.placeOrederId[0].placeOrederId;
    });
  }
  repairClick(event: any, id: any, bid: any, fName: string, Lname: string) {
    this.copyAraay = [];
    if (event.source.selected) {
      this.repairId = id;
      this.copyAraay.push({ id: bid, name: fName + ' ' + Lname, value: event.source.value });
      // Clear the copyData array before adding new elements
     // this.copyData = [];
      this.copyAraay.forEach(element => {
        this.copyData.push('Repair Id:' + element.id + ', ' + 'Patient:' + element.name);
      });
      console.log("this.copyAraay",this.copyAraay);
      console.log("this.copyData",this.copyData);
    } else {
      console.log("event.source.value8", bid, this.copyAraay.indexOf(bid));
      let cData =  "Repair Id:" +bid+","+" Patient:"+fName+' ' + Lname; 
     // this.copyData = [];
      // Use Array.prototype.findIndex to find the index of the element with matching bid
      const indexToRemove = this.copyAraay.findIndex(element => element.id === bid);
      const indexToRemove2 = this.copyData.findIndex(element => element === cData);
      
      if (indexToRemove !== -1) {
        this.copyAraay.splice(indexToRemove, 1);
      }
      if (indexToRemove2 !== -1) {
        this.copyData.splice(indexToRemove2, 1);
      }
      console.log("this.copyAraay",this.copyAraay);
      console.log("this.copyData",this.copyData);
      
      // this.copyAraay.forEach(element => {
      //   this.copyData.push('Repair Id:' + element.id + ', ' + 'Patient:' + element.name);
      // });
    }
  
    this.inwardOutwardForm.controls['notes'].setValue(this.copyData);
  }
  earMouldClick(event: any, id: any, bid: any, fName: string, Lname: string) {
    this.copyAraay = [];
    if (event.source.selected) {
      this.repairId = id;
      this.copyAraay.push({ id: bid, name: fName + ' ' + Lname, value: event.source.value });
      // Clear the copyData array before adding new elements
   //   this.copyData = [];
      this.copyAraay.forEach(element => {
        this.copyData.push('Ear Mould Id:' + element.id + ', ' + 'Patient:' + element.name);
      });
    } else {
    //  this.copyData = [];
      // Use Array.prototype.findIndex to find the index of the element with matching bid
      let cData =  "Ear Mould Id:" +bid+", "+"Patient:"+fName+' ' + Lname; 

      const indexToRemove = this.copyAraay.findIndex(element => element.id === bid);
      const indexToRemove2 = this.copyData.findIndex(element => element === cData);
     // const indexToRemove2 = this.copyData.findIndex(element => element.Repair Id === bid);
      if (indexToRemove !== -1) {
        this.copyAraay.splice(indexToRemove, 1);
      }
      if (indexToRemove2 !== -1) {
        this.copyData.splice(indexToRemove2, 1);
      }
      console.log("this.copyAraay",this.copyAraay);
      console.log("this.copyData",this.copyData);

      // this.copyAraay.forEach(element => {
      //   this.copyData.push('Ear Mould Id:' + element.id + ', ' + 'Patient:' + element.name);
      // });
    }
  
    this.inwardOutwardForm.controls['notes'].setValue(this.copyData);
  }
  
  customHAClick(event: any, id: any, bid: any, fName: string, Lname: string) {
    this.copyAraay = [];
    if (event.source.selected) {
      this.repairId = id;
      this.copyAraay.push({ id: bid, name: fName + ' ' + Lname, value: event.source.value });
      // Clear the copyData array before adding new elements
     // this.copyData = [];
      this.copyAraay.forEach(element => {
        this.copyData.push('Custom HA Id:' + element.id + ', ' + 'Patient:' + element.name);
      });
    } else {
      let cData =  "Custom HA Id:" +bid+", "+"Patient:"+fName+' ' + Lname; 

      const indexToRemove = this.copyAraay.findIndex(element => element.id === bid);
      const indexToRemove2 = this.copyData.findIndex(element => element === cData);
     // const indexToRemove2 = this.copyData.findIndex(element => element.Repair Id === bid);
      if (indexToRemove !== -1) {
        this.copyAraay.splice(indexToRemove, 1);
      }
      if (indexToRemove2 !== -1) {
        this.copyData.splice(indexToRemove2, 1);
      }
      // this.copyAraay.forEach(element => {
      //   this.copyData.push('Custom HA Id:' + element.id + ', ' + 'Patient:' + element.name);
      // });
    }
  
    this.inwardOutwardForm.controls['notes'].setValue(this.copyData);
  }
  placeOrderClick(event: any, id: any, bid: any, fName: string, Lname: string) {
    this.copyAraay = [];
    if (event.source.selected) {
      this.repairId = id;
      this.copyAraay.push({ id: bid, name: fName + ' ' + Lname, value: event.source.value });
      // Clear the copyData array before adding new elements
     // this.copyData = [];
      this.copyAraay.forEach(element => {
        this.copyData.push('Place Order Id:' + element.id + ', ' + '' + element.name);
      });
    } else {
      let cData =  "Place Order Id:" +bid+", "+""+fName+' ' + Lname; 

      const indexToRemove = this.copyAraay.findIndex(element => element.id === bid); 
      const indexToRemove2 = this.copyData.findIndex(element => element === cData);

      if (indexToRemove !== -1) {
        this.copyAraay.splice(indexToRemove, 1);
      }
      if (indexToRemove2 !== -1) {
        this.copyData.splice(indexToRemove2, 1);
      }
    }
  
    this.inwardOutwardForm.controls['notes'].setValue(this.copyData);
  }
  // placeOrderClick(event: any, id: any, bid: any, fName: string, Lname: string) {
  //   if (event.source.selected) {
  //     this.repairId = id;
  //     this.copyAraay.push({ id: bid, value: event.source.value });
  //     this.copyData = [];
  //     this.copyAraay.forEach(element => {
  //       this.copyData.push('Place Order Id:' + element.id);
  //     });
  //   } else {
  //     this.copyData = [];
  //     const indexToRemove = this.copyAraay.findIndex(element => element.id === bid);
  //     if (indexToRemove !== -1) {
  //       this.copyAraay.splice(indexToRemove, 1);
  //     }
  //     this.copyAraay.forEach(element => {
  //       this.copyData.push('Place Order Id:' + element.id);
  //     });
  //   }
  
  //   this.inwardOutwardForm.controls['notes'].setValue(this.copyData);
  // }
  
  addData() {
    this.spinner.show();
    let payload = this.inwardOutwardForm.value;
   
    payload.notes =JSON.stringify(payload.notes);
    if (this.userType !== 'Super Admin') {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    if(payload.repaidId)
    {
    payload.repaidId.forEach((element,i) => {
      this.apimicroservice.getRepairDataById(element).subscribe(res => {
        let repairData= res.data[0]; 
        let payload1 = this.inwardOutwardForm.value;
        payload1['patientName'] = repairData.patientName; 
        payload1['clientVendorBranchRadioBtn'] = 'Vendor'; 
        
        payload1['patientRefId'] = repairData.patientRefId;
        payload1['repairActionId'] = this.repairActionId + i+1;
        payload1['inwardOutwardId'] = payload.branchWiseId;
        payload1['repairRefId'] = element;
        payload1['clinicName'] = this.clinicName;
        payload1['branchName'] = payload.branchName;
        payload1['status'] = 'In-process';
        payload1['billingCompanyRefId'] = payload.billingCompanyRefId;
        if (payload.inwardOutward == 'Inward') {
          payload1['actionId'] = '7'; 
          payload1['repairActionName'] = 'Received From';
        }
        if (payload.inwardOutward == 'Outward') {
          payload1['actionId'] = '1';
          payload1['repairActionName'] = 'Sent To';
        }
       
       
       ////Repair
       let payloadRepair = {};
       if (payload.inwardOutward == 'Inward') {
        payloadRepair['repairActionLastStatus'] = 'Received From';
      }
      if(payload.inwardOutward == 'Outward') {
        payloadRepair['repairActionLastStatus'] = 'Sent To';
      }
             this.apimicroservice.updateRepairData(element, payloadRepair).subscribe(res => {
               if (res.status) {
                 this.apimicroservice.openSnackbar(res.message);
               } else {
                 //  //this.apimicroservice.openSnackbar(res.message);
               }
             },(ERROR:HttpErrorResponse) => {
               this.apimicroservice.openSnackbar(ERROR.error.message);
               this.spinner.hide();
           });

        this.apimicroservice.addRepairAction(payload1).subscribe(res => {
          this.spinner.hide();
          if (res.status) { 
            this.apimicroservice.openSnackbar(res.message);
          //  this.ngOnInit();
            //resolve();
          } else { 
            this.apimicroservice.openSnackbar(res.message);
          }
        }, (ERROR: HttpErrorResponse) => {
          this.apimicroservice.openSnackbar(ERROR.error.message);
          this.spinner.hide();
        });
      });
   
    });
  }
    ///Ear Mould
    if(payload.earMoldId)
    {
    payload.earMoldId.forEach((element,i) => {
      this.apimicroservice.getEarMouldDataById(element).subscribe(res => {
        let earMouldData= res.data[0]; 
        let payload2 = this.inwardOutwardForm.value;
        payload2['patientName'] = earMouldData.patientName; 
        payload2['notes'] = '';
        payload2['patientRefId'] = earMouldData.patientRefId;
        payload2['earMoldId'] = this.earMoldId + i+1;
     
        payload2['status'] = 'Inprocess';
        payload2['billingCompanyRefId'] = payload.billingRefId;
        payload2['clinicRefId'] = earMouldData.clinicRefId;
        payload2['companyName'] = earMouldData.companyName;
        payload2['companyRefId'] = earMouldData.companyRefId;
     
        this.apimicroservice.addEarMold(payload2).subscribe(res => {
          this.spinner.hide();
          if (res.status) { 
            this.apimicroservice.openSnackbar(res.message);
            //resolve();
          } else { 
            this.apimicroservice.openSnackbar(res.message);
          }
        }, (ERROR: HttpErrorResponse) => {
          this.apimicroservice.openSnackbar(ERROR.error.message);
          this.spinner.hide();
        });
      });
   
    });
          
  }
    //CustomHA 
    if(payload.customHAId)
    {
    payload.customHAId.forEach((element,i) => {
      this.apimicroservice.getCustomHADataById(element).subscribe(res => {
        let earMouldData= res.data[0]; 
        let payload3 = this.inwardOutwardForm.value;
        payload3['patientName'] = earMouldData.patientName; 
        payload3['patientRefId'] = earMouldData.patientRefId;
        payload3['customHAId'] = this.customHAId + i+1;
        // payload2['status'] = 'Inprocess';
        payload3['clinicRefId'] = earMouldData.clinicRefId;
        payload3['companyName'] = earMouldData.companyName;
        payload3['companyRefId'] = earMouldData.companyRefId;
        payload3['billingCompanyRefId'] = payload.billingRefId;
        this.apimicroservice.saveCustomHAData(payload3).subscribe(res => {
          this.spinner.hide();
          if (res.status) { 
            this.apimicroservice.openSnackbar(res.message);
            //resolve();
          } else { 
            this.apimicroservice.openSnackbar(res.message);
          }
        }, (ERROR: HttpErrorResponse) => {
          this.apimicroservice.openSnackbar(ERROR.error.message);
          this.spinner.hide();
        });
      });
   
    });
  }
    ////Place Order
    if(payload.placeOrederId)
    {
    payload.placeOrederId.forEach((element,i) => {
      this.apimicroservice.getPlaceOrderDataById(element).subscribe(res => {
        let earMouldData= res.data[0]; 
        let payload5 = this.inwardOutwardForm.value;
        payload5['patientName'] = earMouldData.patientName; 
        payload5['notes'] = '';
        payload5['patientRefId'] = earMouldData.patientRefId;
        payload5['placeOrederId'] = this.placeOrederId + i+1;
        payload5['clinicRefId'] = earMouldData.clinicRefId;
        payload5['companyName'] = earMouldData.companyName;
        payload5['companyRefId'] = earMouldData.companyRefId;
     
        payload5['placeOrderStatus'] = 'Pending';
        payload5['billingCompanyRefId'] = payload.billingRefId;
          this.apimicroservice.savePlaceOrderData(payload5).subscribe(res => {
          this.spinner.hide();
          if (res.status) { 
            this.apimicroservice.openSnackbar(res.message);
            //resolve();
          } else { 
            this.apimicroservice.openSnackbar(res.message);
          }
        }, (ERROR: HttpErrorResponse) => {
          this.apimicroservice.openSnackbar(ERROR.error.message);
          this.spinner.hide();
        });
      });
   
    });
  }
    ///Inword-outword
    this.apimicroservice.saveInOutwardData(payload).subscribe(res => {
      this.spinner.hide();

      if (res.status) {
        //  this.signatureImg = "../../assets/user2.png";
        this.apimicroservice.openSnackbar(res.message);
        this.ngOnInit();

      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    }, (ERROR: HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
      this.spinner.hide();
    });


  }

  getLastId() {
    let branchRef = '';
    if (this.userType !== 'Super Admin') {
      //console.log("777777");
      this.apimicroservice.getInOutwardLastId(this.branchRef).subscribe(res => {
        //console.log("getLastId res",res);   
        // if(res['data']['data'] == '')
        // {
        //   this.clinicalStaffMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          // let clientIdd = res.data[0].branchWiseId; 
          // this.bId=clientIdd + 1;

          let clientIdd = res['data']['totalItems'];
          this.bId = clientIdd + 1;
          //this.clinicalStaffMasterForm.controls['branchWiseId'].setValue(staffIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })
    } else {
      //console.log("1111111111111");
      //console.log("branchRefbranchRef",branchRef);
      this.apimicroservice.getInOutwardLastId(branchRef).subscribe(res => {
        //console.log("getLastId res",res);    
        // if(res['data']['data'] == '')
        // {
        //   this.clinicalStaffMasterForm.controls['branchWiseId'].setValue('1');
        // }
        if (res['status']) {
          // let clientIdd =  res.data[0].branchWiseId; 
          // this.superBranchId = clientIdd + 1;

          let clientIdd = res['data']['totalItems'];
          this.superBranchId = clientIdd + 1;

          this.bId = clientIdd + 1;
          //this.clinicalStaffMasterForm.controls['branchWiseId'].setValue(staffIdd + 1);
        } else {
          this.apimicroservice.openSnackbar("Something went wrong");
        }

      })

    }

  }
  fetchInwardOutward() {
    let pg = 1;
    let size = 10;
    let search = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    this.spinner.show();
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getInOutwardData(pg, size, search, branchRef, clinicRef).subscribe(res => {
        this.spinner.hide();
        this.listOfInwardOutward = res.data.data;
        this.totalLength = res.data.totalItems;
        if (res.status) {
          // this.apimicroservice.openSnackbar(res.message);        
        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
    else {
      this.apimicroservice.getInOutwardData(pg, size, search, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        this.listOfInwardOutward = res.data.data;
        this.totalLength = res.data.totalItems;
      });
    }
  }

  prepopulation(id) {
    this.spinner.show();
    this.apimicroservice.getInOutwardDataById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      this.whenUpdate = true;
      // this.id = id;
      if (res.status) {
        this.updateButton = true;
        this.submitButton = false;
        this.disableFromCity = true;
        this.disableToCity = true;
        this.inwardOutwardForm = this.fb.group({
          "id": new FormControl(data.branchWiseId),
          "date": new FormControl(data.date, Validators.required),
          "inwardOutward": new FormControl(data.inwardOutward, Validators.required),
          "patientName": new FormControl(data.patientName),
          "patientRefId": new FormControl(data.patientRefId),
          "companyRefId": new FormControl(data.companyRefId),
          "companyName": new FormControl(data.companyName),
          "courierRefId": new FormControl(data.courierRefId),
          "courierName": new FormControl(data.courierName),
          "trackingNumber": new FormControl(data.trackingNumber, Validators.pattern("^[a-zA-Z0-9]*$")),
          "documentsNumber": new FormControl(data.documentsNumber, Validators.pattern("^[a-zA-Z0-9/ ]*$")),
          "docDate": new FormControl(data.docDate),
          "cityRefId": new FormControl(data.cityRefId),
          "cityName": new FormControl(data.cityName),
          "deviceType": new FormControl(data.deviceType),
          "refType": new FormControl(data.refType, Validators.required),
          "typeRefId": new FormControl(data.typeRefId, Validators.required),
          "docType": new FormControl(data.docType),
          "notes": new FormControl(data.notes),
          "searchInwardOutward": new FormControl(''),
          "branchWiseId": new FormControl(data.branchWiseId),
          "branchRefId": new FormControl(data.branchRefId),
          "clinicRefId": new FormControl(data.clinicRefId),
          "branchName": new FormControl(data.branchName),
          "typeName": new FormControl(data.typeName),
          "repaidId": new FormControl(data.repaidId),
          "earMoldId": new FormControl(data.earMoldId),
          "customHAId": new FormControl(data.customHAId),
          "placeOrederId": new FormControl(data.placeOrederId),
          "remark": new FormControl(data.remark),
          "repairRefId": new FormControl(),

        });


        this.superBranchId = data.branchWiseId;
        this.bId = data.branchWiseId;
        this.id = id;
        // this.InwardOutwardClick(data.inwardOutward);
        this.type(data.typeName);
      } else {
        //    //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  prepopulationByBranchId(id, branchName) {
    this.spinner.show();
    this.apimicroservice.getInOutwardByBranchId(id, branchName).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      // this.id = id;
      if (res.status) {
        this.updateButton = true;
        this.submitButton = false;
        this.disableFromCity = true;
        this.disableToCity = true;
        this.inwardOutwardForm = this.fb.group({
          "id": new FormControl(data.branchWiseId),
          "date": new FormControl(data.date, Validators.required),
          "inwardOutward": new FormControl(data.inwardOutward, Validators.required),
          "patientName": new FormControl(data.patientName),
          "patientRefId": new FormControl(data.patientRefId),
          "companyRefId": new FormControl(data.companyRefId),
          "companyName": new FormControl(data.companyName),
          "courierRefId": new FormControl(data.courierRefId),
          "courierName": new FormControl(data.courierName),
          "trackingNumber": new FormControl(data.trackingNumber, Validators.pattern("^[a-zA-Z0-9]*$")),
          "documentsNumber": new FormControl(data.documentsNumber, Validators.pattern("^[a-zA-Z0-9/ ]*$")),
          "docDate": new FormControl(data.docDate),
          "cityRefId": new FormControl(data.cityRefId),
          "cityName": new FormControl(data.cityName),
          "deviceType": new FormControl(data.deviceType),
          "refType": new FormControl(data.refType, Validators.required),
          "typeRefId": new FormControl(data.typeRefId, Validators.required),
          "docType": new FormControl(data.docType),
          "notes": new FormControl(data.notes),
          "searchInwardOutward": new FormControl(''),
          "branchWiseId": new FormControl(data.branchWiseId),
          "branchRefId": new FormControl(data.branchRefId),
          "clinicRefId": new FormControl(data.clinicRefId),
          "branchName": new FormControl(data.branchName),
          "typeName": new FormControl(data.typeName),
          "repairRefId": new FormControl(),
          "repaidId": new FormControl(data.repaidId),
          "earMoldId": new FormControl(data.earMoldId),
          "customHAId": new FormControl(data.customHAId),
          "placeOrederId": new FormControl(data.placeOrederId),
          "remark": new FormControl(data.remark),

        });

        this.superBranchId = data.branchWiseId;
        this.bId = data.branchWiseId;
        this.id = id;
        // this.InwardOutwardClick(data.inwardOutward);
        this.type(data.typeName);
      } else {
        //    //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  UpdateInwordOutword() {
    // this.setValidator();
    let payload = this.inwardOutwardForm.value;
    this.inwardOutwardForm.controls['branchRefId'].setValidators(Validators.required);
    this.inwardOutwardForm.controls['branchRefId'].updateValueAndValidity();
    if (this.inwardOutwardForm.invalid) {
      this.inwardOutwardForm.get("date").markAsTouched();
      this.inwardOutwardForm.get("inwardOutward").markAsTouched();
      this.inwardOutwardForm.get("refType").markAsTouched();
      this.inwardOutwardForm.get("typeRefId").markAsTouched();
    } else {
      this.updateData();
    }
  }

  updateData() {
    this.spinner.show();
    let payload = this.inwardOutwardForm.value;
    if (this.userType !== 'Super Admin') {
      payload.branchRefId = this.branchRef;
      payload.clinicRefId = this.clinicRef;
      payload.branchName = this.branchName;
    }
    //  if(payload.inwardOutward == 'Inward')
    //  {
    //   payload.companyRefId = undefined;
    //   payload.companyName = '';
    //  }
    //  else if(payload.inwardOutward == 'Outward')
    //  {
    //   payload.patientName = '';
    //  }
    payload.notes =JSON.stringify(payload.notes);
    this.apimicroservice.updateInOutwardData(payload, this.id).subscribe(res => {
      //console.log("Update res :",res);
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        // this.signatureImg = "../../assets/user2.png";
        this.updateButton = false;
        this.submitButton = true;
        this.ngOnInit();

      } else {
        //  //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  cancelUpdate() {
    this.inwardOutwardForm.reset();
    this.submitButton = true;
    this.updateButton = false;
    this.ngOnInit();
  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }
  toInwardOutwardForm() {
    document.getElementById("toInwardOutwardForm").scrollIntoView();
  }
  toInwardOutwardLst() {
    document.getElementById("toInwardOutwardLst").scrollIntoView();
  }
  onPageChange(event) {
    this.spinner.show();
    this.page = event;
    this.searchText = this.inwardOutwardForm.get('searchInwardOutward').value;
    if (this.searchText == null) {
      this.searchText = '';
    }
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getInOutwardData(this.page, this.size, this.searchText, branchRef, clinicRef).subscribe(res => {
        this.spinner.hide();
        this.listOfInwardOutward = res.data.data;
        this.totalLength = res.data.totalItems;
        if (res.status) {
          //  this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }

      });
    }
    else {
      this.apimicroservice.getInOutwardData(this.page, this.size, this.searchText, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        this.listOfInwardOutward = res.data.data;
        this.totalLength = res.data.totalItems;
        if (res.status) {
          //  this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }

      });
    }
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.inwardOutwardForm.get('searchInwardOutward').value;
    let page = 1;
    let size = 10;
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
    let branchRefSuperAdmin= '';
    if (this.userType !== 'Super Admin') {
      this.apimicroservice.getInOutwardData(page, size, this.searchText, branchRef, clinicRef).subscribe(res => {
        this.spinner.hide();
        this.listOfInwardOutward = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page = res.data.pageNumber;
        if (res.status) {
          //  this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
    else {
      this.apimicroservice.getInOutwardData(page, size, this.searchText, branchRefSuperAdmin, clinicRefSuperAdmin).subscribe(res => {
        this.spinner.hide();
        this.listOfInwardOutward = res.data.data;
        this.totalLength = res.data.totalItems;
        this.page = res.data.pageNumber;
        if (res.status) {
          //  this.apimicroservice.openSnackbar(res.message);

        } else {
          //this.apimicroservice.openSnackbar(res.message);
        }
      });
    }
  }
  // InwardOutwardClick(e)
  // {
  //   if(e == 'Inward')
  //   {
  //     this.inwardOutwardForm.controls['patientName'].setValidators(Validators.required);
  //     this.inwardOutwardForm.controls['patientName'].updateValueAndValidity();

  //   this.inwardOutwardForm.controls['companyRefId'].setErrors(null);
  //   this.inwardOutwardForm.controls['companyRefId'].clearValidators();
  //   this.inwardOutwardForm.controls['companyRefId'].updateValueAndValidity();

  //     this.inward = true;
  //     this.outward = false;
  //     this.selectRef('Client');
  //     this.inwardOutwardForm.get("refType").setValue('Client');      
  //   }
  //   else if(e == 'Outward')
  //   {
  //     this.inwardOutwardForm.controls['companyRefId'].setValidators(Validators.required);
  //     this.inwardOutwardForm.controls['companyRefId'].updateValueAndValidity();

  //     this.inwardOutwardForm.controls['patientName'].setErrors(null);
  //     this.inwardOutwardForm.controls['patientName'].clearValidators();
  //     this.inwardOutwardForm.controls['patientName'].updateValueAndValidity();


  //     this.outward = true;
  //     this.inward = false;
  //     this.selectRef('Manufacturer');
  //     this.inwardOutwardForm.get("refType").setValue('Manufacturer');     
  //   }
  // }
  selectRef(e) {
    if (e == 'Client') {
      this.client = true;
      this.manufacturer = false;
      this.inward = true;
      this.outward = false;
      // this.inwardOutwardForm.get("inwardOutward").setValue('Inward'); 
    }
    else if (e == 'Manufacturer') {
      this.manufacturer = true;
      this.client = false;
      this.outward = true;
      this.inward = false;
      // this.inwardOutwardForm.get("inwardOutward").setValue('Outward'); 
    }
  }
  type(e) {
    this.inwardOutwardForm.get("typeName").setValue(e);
    //console.log("e",e);

    if (e == 'Hearing Device' || e == 'Hearing Devices') {
      this.isDevices = true;
      this.isDocuments = false;
    }
    else if (e == 'Documents' || e == 'documents') {
      this.isDocuments = true;
      this.isDevices = false;
      this.inwardOutwardForm.get("docType").setValue('Miscellaneous');
    }
    else {
      this.isDevices = false;
      this.isDocuments = false;
    }
  }
  openCompanyPopUp() {
    const dialogRef3 = this.dialog.open(PopupCompanyComponent,
      {
        width: "900px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getAllCompanylist(branchRef,clinicRef).subscribe(res => {
        //console.log("all companies res",res);
        if (res.status) {
          this.companyList = res.data.data;
          this.filteredListCompany = this.companyList.slice();
          this.inwardOutwardForm.get("companyRefId").setValue(res.data.data[0]._id);
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      })

    });
  }
  // openPatientPopUp() {

  //   const dialogRef3 = this.dialog.open(PopUpPatientComponent,
  //     {
  //       width: "1200px",
  //       height: "600px"
  //     });
  //     dialogRef3.afterClosed().subscribe((result) => {
  //      this.fetchPatientList();  
  //      let branchRef = '';
  //      let clinicRef = '';
  //      this.apimicroservice.getPatientListByBranchClinic(branchRef,clinicRef).subscribe(res => {
  //       //console.log("fetch patienttttttttt list res",res);

  //       if (res.status) {    
  //         this.patientList = res.data;
  //         this.filteredListPatient = this.patientList.slice();
  //        // this.onSelectPatient(this.patientList[0]._id);
  //       this.inwardOutwardForm.get("patientRefId").setValue(this.patientList[0]._id);

  //       } else {
  //        // this.apimicroservice.openSnackbar(res.message);
  //       }
  //     })
  //      });
  // }

  onSelectPatient(id) {
    //console.log('hahahahah',id)
    this.patientSelect = true;
    this.spinner.show();
    this.apimicroservice.getPatientById(id).subscribe(res => {
      this.spinner.hide();
      let data = res.data[0];
      this.pid = id;


      this.inwardOutwardForm.controls["patientName"].setValue(data.firstName + ' ' + data.lastName);
      this.inwardOutwardForm.controls["patientRefId"].setValue(data._id);
      this.inwardOutwardForm.controls["firstName"].setValue(data.firstName);
      this.inwardOutwardForm.controls["lastName"].setValue(data.lastName);
      this.inwardOutwardForm.controls["sex"].setValue(data.sex);
      this.inwardOutwardForm.controls["mobile1"].setValue(data.mobile1);
      this.inwardOutwardForm.controls["streetAddress"].setValue(data.streetAddress);
      this.pId = data._id;
      this.patientID = data.patientId;
      // this.fetchModelList(data._id);
      let payload = this.inwardOutwardForm.value;

    });

  }
  openPatientPopUp() {

    const dialogRef3 = this.dialog.open(PopUpPatientComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      // this.fetchPatientList();  
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      this.apimicroservice.getPatientListByBranchClinic(branchRef, clinicRef).subscribe(res => {
        //console.log("fetch patienttttttttt list res",res);

        if (res.status) {
          this.patientList = res.data;
          this.filteredListPatient = this.patientList.slice();
          this.onSelectPatient(this.patientList[0]._id);
          this.inwardOutwardForm.get("patientRefId").setValue(this.patientList[0]._id);

        } else {
          // this.apimicroservice.openSnackbar(res.message);
        }
      })
    });
  }
  openDepartmentPopUp() {
    const dialogRef3 = this.dialog.open(PatientSearchPopComponent, {
      width: "97%",
      height: "90%",
    });

    dialogRef3.afterClosed().subscribe((result) => {
      this.inwardOutwardForm.get("patientName").setValue(result.data.firstName + ' ' + result.data.lastName);
      this.inwardOutwardForm.get("patientRefId").setValue(result.data._id);
      // this.onSelectPatient(result.data._id);

    });
  }
  selectCompany(e) {
    //console.log("e",e);

    this.inwardOutwardForm.get("companyName").setValue(e);
  }
  selectCourier(e) {
    this.inwardOutwardForm.get("courierName").setValue(e);
  }
  selectCity(e) {
    this.inwardOutwardForm.get("cityName").setValue(e);
  }
  getAllBranch() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      if (res.status) {
        let length = res.data.length - 1;
        this.branchName = res.data[length].branchName;
        //console.log("this.branchNamethis.branchNamethis.branchName",this.branchName);

        this.inwardOutwardForm.controls["branchRefId"].setValue(res.data[length]._id);
        this.branchClick(res.data[length]._id, this.branchName);
        if (this.userType == 'Super Admin') {
          this.apimicroservice.getBranchById(res.data[length]._id).subscribe(res => {
            this.spinner.hide();
            this.inwardOutwardForm.get("branchName").setValue(this.branchName);
          });
        }
      }
    });
  }
  fetchBranchList() {
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
      //console.log("getAllBranch res",res);  
      if (res.status) {
        this.BranchList = res.data;
        this.filteredListBranch = this.BranchList.slice();
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    })
  }
  branchClick(id, branchName) {

    this.inwardOutwardForm.controls["branchName"].setValue(branchName);

    this.apimicroservice.getBranchById(id).subscribe(res => {
      //console.log("res",res);

      let data = res.data[0];
      let payload = this.inwardOutwardForm.value;
      payload.clinicRefId = res.data[0].clinicRefId;
      this.inwardOutwardForm.controls["clinicRefId"].setValue(res.data[0].clinicRefId);
      if (this.updateButton == false) {
        this.spinner.show();
        this.apimicroservice.getInOutwardLastId(payload.branchRefId).subscribe(res => {
          //console.log("getLastId res",res);   

          if (res['status']) {
            let staffIdd = res['data']['totalItems'];
            this.inwardOutwardForm.controls['branchWiseId'].setValue(staffIdd + 1);
            //this.bId=staffIdd + 1;
          } else {
            this.apimicroservice.openSnackbar("Something went wrong");
          }
          this.spinner.hide();
        })
      }
    });
  }
  openBranchPopUp() {
    localStorage.clear();
    const dialogRef3 = this.dialog.open(PopUpBranchComponent,
      {
        width: "1200px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      this.apimicroservice.getAllBranchList(clinicRef).subscribe(res => {
        //console.log("getAllBranch res",res);  
        if (res.status) {
          this.BranchList = res.data;
          this.filteredListBranch = this.BranchList.slice();

          this.branchClick(res.data[0]._id, res.data[0].branchName);
          this.inwardOutwardForm.get("branchRefId").setValue(res.data[0]._id);
        }
      })
      //this.fetchBranchList();
    })
  }
  onback() {
    this.apimicroservice.back()
  }
  openFromCityPopUp() {

    const dialogRef3 = this.dialog.open(PopUpCityComponent,
      {
        width: "500px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      this.apimicroservice.getCityList().subscribe(res => {
        //console.log("fetch city res",res);

        if (res.status) {
          this.cityList = res.data;

          this.filteredListCity = this.cityList.slice();
          //  this.selectCity(res.data[0]._id,res.data[0].cityName)
          //  this.inwardOutwardForm.get("selectCity").setValue(res.data[0]._id)
        }
      });
      //this.fetchFromCityList();  
    });
  }
  openTypePopUp() {

    const dialogRef3 = this.dialog.open(InwardOutwadPopupTypeComponent,
      {
        width: "500px",
      });
    dialogRef3.afterClosed().subscribe((result) => {
      this.apimicroservice.getInoutTypeList().subscribe(res => {
        //console.log("all getInoutTypeList res",res);  
        if (res.status) {
          this.inoutTypeList = res.data;
          let count = res.data.length - 1;
          this.filteredListInoutType = this.inoutTypeList.slice();
          this.inwardOutwardForm.get("typeRefId").setValue(res.data[count]._id);
          this.type(res.data[count].inoutType);
        } else {
          //  this.apimicroservice.openSnackbar(res.message);
        }
      })

    }

    );
  }
  openCourierServicePopUp() {
    const dialogRef3 = this.dialog.open(PopUpCourierserviceComponent,
      {
        width: "1200px",
      });

    dialogRef3.afterClosed().subscribe((result) => {
      let clinicRef= sessionStorage.getItem('clinicRef');
      let branchRef= sessionStorage.getItem('branchRef');
      let clinicRefSuperAdmin= sessionStorage.getItem('clinicRef');
      let branchRefSuperAdmin= '';
      if (this.userType !== 'Super Admin') {
      this.apimicroservice.getCourierAllData(branchRef,clinicRef).subscribe(res => {
        //console.log("getCourierAllData res",res);  
        if (res.status) {
          this.courierList = res.data.data;
          //console.log(" this.courierList", this.courierList);

          this.filteredListCourier = this.courierList.slice();
          this.selectCourier(res.data.data[0].serviceProviderName);
          this.inwardOutwardForm.get("courierRefId").setValue(res.data.data[0]._id);
        }
      })
    }else{
      this.apimicroservice.getCourierAllData(branchRefSuperAdmin,clinicRefSuperAdmin).subscribe(res => {
        //console.log("getCourierAllData res",res);  
        if (res.status) {
          this.courierList = res.data.data;
          //console.log(" this.courierList", this.courierList);

          this.filteredListCourier = this.courierList.slice();
          this.selectCourier(res.data.data[0].serviceProviderName);
          this.inwardOutwardForm.get("courierRefId").setValue(res.data.data[0]._id);
        }
      })
    }
      // this.fetchCourierList(); 
    });
  }
  getRepair() {
    this.apimicroservice.getLastRepairAll().subscribe(res => {
      this.repairData = [];
      if (res['status'] == true) {
        this.repairData = res.data.data;
        this.filteredListRepair = this.repairData.slice();
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }

    })
  }
  getEarMould() {
    let pg = '';
    let size = '';
    let search = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getEarMouldOrderData(pg,size,search,branchRef,clinicRef).subscribe(res => {  
      this.earMouldData = res.data.data;
      this.filteredListEarMould = this.earMouldData.slice();
      if (res.status) {
        //  this.apimicroservice.openSnackbar(res.message)
      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }
  getCustomHA() {
    let pg = '';
    let size = '';
    let search = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getCustomHA(pg,size,search,branchRef,clinicRef).subscribe(res => {
      this.spinner.hide();
      this.customHAData = res.data.data;
      this.filteredListCustomHA = this.customHAData.slice();
      if(res.status){
       // this.apimicroservice.openSnackbar(res.message);    
      }else{
        //this.apimicroservice.openSnackbar(res.message);
      }
    });

  }
  getPlaceOrder() {
    let pg = '';
    let size = '';
    let search = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    let branchRef= sessionStorage.getItem('branchRef');
    this.apimicroservice.getPlaceOrderDetails(pg, size, search,branchRef,clinicRef).subscribe(res => 
      {    
      if (res['status'] == true) {
        this.placeOrderData = res.data.data;
        this.filteredListPlaceOrder = this.placeOrderData.slice();
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }

    })
  }
}  