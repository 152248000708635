import { Component, Inject, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ApiServiceService } from "../api-service.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AfterViewInit, Directive, ViewChild } from "@angular/core";

import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-pop-up-branch',
  templateUrl: './pop-up-branch.component.html',
  styleUrls: ['./pop-up-branch.component.scss']
})
export class PopUpBranchComponent implements OnInit {
  errormessage: string;
  vid: any;
  searchText: any;
  totalLength: number;
  page: number = 1;
  size: number = 5;
  cid: string;
  clinicList: any;
  allState = [
    { name: 'Andaman and Nicobar Islands', value: 'AN' },
    { name: 'Andhra Pradesh', value: 'AP' },
    { name: 'Arunachal Pradesh', value: 'AR' },
    { name: 'Assam', value: 'AS' },
    { name: 'Bihar', value: 'BR' },
    { name: 'Chandigarh', value: 'CH' },
    { name: 'Chhattisgarh', value: 'CT' },
    { name: 'Dadra and Nagar Haveli', value: 'DN' },
    { name: 'Daman and Diu', value: 'DD' },
    { name: 'Delhi', value: 'DL' },
    { name: 'Goa', value: 'GA' },
    { name: 'Gujarat', value: 'GJ' },
    { name: 'Haryana', value: 'HR' },
    { name: 'Himachal Pradesh', value: 'HP' },
    { name: 'Jammu and Kashmir', value: 'JK' },
    { name: 'Jharkhand', value: 'JH' },
    { name: 'Karnataka', value: 'KA' },
    { name: 'Kerala', value: 'KL' },
    { name: 'Ladakh', value: 'LA' },
    { name: 'Lakshadweep', value: 'LD' },
    { name: 'Madhya Pradesh', value: 'MP' },
    { name: 'Maharashtra', value: 'MH' },
    { name: 'Manipur', value: 'MN' },
    { name: 'Meghalaya', value: 'ML' },
    { name: 'Mizoram', value: 'MZ' },
    { name: 'Nagaland', value: 'NL' },
    { name: 'Odisha', value: 'OR' },
    { name: 'Puducherry', value: 'PY' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Punjab', value: 'PB' },
    { name: 'Rajasthan', value: 'RJ' },
    { name: 'Sikkim', value: 'SK' },
    { name: 'Tamil Nadu', value: 'TN' },
    { name: 'Telangana', value: 'TG' },
    { name: 'Tripura', value: 'TR' },
    { name: 'Uttar Pradesh', value: 'UP' },
    { name: 'Uttarakhand', value: 'UT' },
    { name: 'West Bengal', value: 'WB' },
  ]
  allDays = [
    { name: 'None' },
    { name: 'Sunday', value: 'Sunday' },
    { name: 'Monday', value: 'Monday' },
    { name: 'Tuesday', value: 'Tuesday' },
    { name: 'Wednesday', value: 'Wednesday' },
    { name: 'Thursday', value: 'Thursday' },
    { name: 'Friday', value: 'Friday' },
    { name: 'Saturday', value: 'Saturday' },
  ]
  filteredList: { name: string; value: string; }[];
  filteredListDays: ({ name: string; value?: undefined; } | { name: string; value: string; })[];
  filteredListClinic: any;
  userType: string;
  clinicRef: string;
  clinicRefId: any;
  clinicData: any;
  preId: any;
  bId: any;
  stateCityArr2: any[];
  stateCity2: any;
  districtData2: any;
  filteredList2: { state: string; districts: string; }[];
  filteredListDistrict2: { district: string; }[];
  constructor(private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public apimicroservice: ApiServiceService,
    public router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient,
    public dialogRef: MatDialogRef<PopUpBranchComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
    dialogRef.disableClose = true;
  }

  branchMasterForm: FormGroup;
  status: any;
  submitButton = true;
  updateButton = false;
  deleteButton = false;
  disableUpdate = false;
  disableSubmit = false;
  cancelButton = false;
  userPermission: any;
  branchCreate = true;
  branchUpdate = true;

  ngOnInit(): void {
    this.page = 1;
    this.disableUpdate = false;
    this.disableSubmit = false;
    this.filteredList = this.allState.slice();
    this.filteredListDays = this.allDays.slice();
    this.userPermission = JSON.parse(sessionStorage.getItem("userPermission"));
    this.userType = sessionStorage.getItem('userType');
    this.clinicRef = sessionStorage.getItem('clinicRef');
    this.clinicRefId = sessionStorage.getItem('clinicRef');
    if (this.userType !== 'Super Admin') {
      this.userPermission.forEach((element) => {
        if (element.pageName == 'Branch' && element.create == false) {
          this.branchCreate = false;
        }
        if (element.pageName == 'Branch' && element.update == false) {
          this.branchUpdate = false;
        }
      });
    }
    this.branchMasterForm = this.fb.group({
      "branchId": new FormControl(),
      "clinicRefId": new FormControl(Validators.required),
      "branchCode": new FormControl(''),
      "branchName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),

      "branchType": new FormControl('', Validators.required),
      "branchPinCode": new FormControl('', [Validators.required, Validators.pattern("^[0-9]{6}$")]),
      "branchPhoneNumber": new FormControl('', [Validators.required, Validators.pattern("(0|91)?[6-9][0-9]{9}")]),
      "branchEmailId": new FormControl('', [Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      "branchWorkingHours": new FormControl('', Validators.pattern("^[0-9]*$")),
      "branchWeeklyOff": new FormControl(''),
      "branchStatus": new FormControl(''),
      "openTime": new FormControl('', [Validators.required]),
      "closeTime": new FormControl('', [Validators.required]),
      "createdAt": new Date(),
      "searchBranch": new FormControl(''),
      "branchStreetAddress": new FormControl('', Validators.required),
      "taluka": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "district": new FormControl('', Validators.pattern("^[a-zA-Z ]*$")),
      "state": new FormControl('', Validators.required),
      "pincode": new FormControl('', Validators.pattern("^[0-9]{6}$")),
      "country": new FormControl('', [Validators.pattern("[A-Za-z _-]{3,19}$"),]),
      "branchLandline": new FormControl('', Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
    });
    this.branchMasterForm.controls["branchStatus"].setValue("Active");
    this.branchMasterForm.controls["clinicRefId"].setValue(this.clinicRef);
    this.branchMasterForm.controls["country"].setValue("India");
    this.branchMasterForm.controls["state"].setValue("Maharashtra");
    let arr = []
    arr = [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldhana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal"
    ]
    this.getDistrict2(arr);
    this.fetchClinicData();
    this.getLastId();
    //  this.removeValidator();
    this.getState2();
  }
  getState2() {
    this.httpClient.get("assets/state-city.json").subscribe(data => {
      this.stateCity2 = data;
      this.stateCityArr2 = [];
      this.stateCity2.states.forEach((element) => {
        this.stateCityArr2.push(element);
      });
      this.filteredList2 = this.stateCityArr2.slice();
      //
    })
  }
  getDistrict2(state) {
    //
    this.districtData2 = [];
    state.forEach((element) => {
      //
      this.districtData2.push({ "district": element });
    });
    //
    let arr = [];
    arr = state;
    this.filteredListDistrict2 = this.districtData2.slice();
  }
  submitBranch() {
    //this.setValidor();
    if (this.branchMasterForm.invalid) {
      this.branchMasterForm.get("clinicRefId").markAsTouched();
      this.branchMasterForm.get("branchCode").markAsTouched();
      this.branchMasterForm.get("branchName").markAsTouched();
      this.branchMasterForm.get("branchStreetAddress").markAsTouched();
      this.branchMasterForm.get("branchType").markAsTouched();
      this.branchMasterForm.get("branchPinCode").markAsTouched();
      this.branchMasterForm.get("branchPhoneNumber").markAsTouched();
      this.branchMasterForm.get("state").markAsTouched();
      this.branchMasterForm.get("openTime").markAsTouched();
      this.branchMasterForm.get("closeTime").markAsTouched();

      return;
    } else {
      this.addBranch();
    }
  }

  // removeValidator() { 
  //   this.branchMasterForm.controls['clinicRefId'].setErrors(null);
  //   this.branchMasterForm.controls['clinicRefId'].clearValidators();
  //   this.branchMasterForm.controls['clinicRefId'].updateValueAndValidity();

  //   this.branchMasterForm.controls['branchCode'].setErrors(null);
  //   this.branchMasterForm.controls['branchCode'].clearValidators();
  //   this.branchMasterForm.controls['branchCode'].updateValueAndValidity();

  //   this.branchMasterForm.controls['branchName'].setErrors(null);
  //   this.branchMasterForm.controls['branchName'].clearValidators();
  //   this.branchMasterForm.controls['branchName'].updateValueAndValidity();

  //   this.branchMasterForm.controls['branchStreetAddress'].setErrors(null);
  //   this.branchMasterForm.controls['branchStreetAddress'].clearValidators();
  //   this.branchMasterForm.controls['branchStreetAddress'].updateValueAndValidity();

  //   this.branchMasterForm.controls['branchType'].setErrors(null);
  //   this.branchMasterForm.controls['branchType'].clearValidators();
  //   this.branchMasterForm.controls['branchType'].updateValueAndValidity();

  //   this.branchMasterForm.controls['branchPinCode'].setErrors(null);
  //   this.branchMasterForm.controls['branchPinCode'].clearValidators();
  //   this.branchMasterForm.controls['branchPinCode'].updateValueAndValidity();

  //   this.branchMasterForm.controls['branchPhoneNumber'].setErrors(null);
  //   this.branchMasterForm.controls['branchPhoneNumber'].clearValidators();
  //   this.branchMasterForm.controls['branchPhoneNumber'].updateValueAndValidity();


  //   }

  // setValidor() { 
  //   this.branchMasterForm.controls['branchName'].setValidators([Validators.required,Validators.pattern("^[a-zA-Z ]*$")]);
  //   this.branchMasterForm.controls['branchName'].updateValueAndValidity();

  //   this.branchMasterForm.controls['branchCode'].setValidators([Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(6)]);
  //   this.branchMasterForm.controls['branchCode'].updateValueAndValidity();

  //   this.branchMasterForm.controls['clinicRefId'].setValidators(Validators.required);
  //   this.branchMasterForm.controls['clinicRefId'].updateValueAndValidity();

  //   this.branchMasterForm.controls['branchType'].setValidators(Validators.required);
  //   this.branchMasterForm.controls['branchType'].updateValueAndValidity();

  //   this.branchMasterForm.controls['branchPhoneNumber'].setValidators([Validators.required,Validators.pattern("(0|91)?[6-9][0-9]{9}")]);
  //   this.branchMasterForm.controls['branchPhoneNumber'].updateValueAndValidity();

  //   this.branchMasterForm.controls['branchStreetAddress'].setValidators(Validators.required);
  //   this.branchMasterForm.controls['branchStreetAddress'].updateValueAndValidity();

  //   this.branchMasterForm.controls['branchPinCode'].setValidators([Validators.required,Validators.pattern("^[0-9]{6}$")]);
  //   this.branchMasterForm.controls['branchPinCode'].updateValueAndValidity();

  //   }



  updateBranchDetails() {
    //this.setValidor();
    if (this.branchMasterForm.invalid) {
      this.branchMasterForm.get("clinicRefId").markAsTouched();
      this.branchMasterForm.get("branchCode").markAsTouched();
      this.branchMasterForm.get("branchName").markAsTouched();
      this.branchMasterForm.get("branchStreetAddress").markAsTouched();
      this.branchMasterForm.get("branchType").markAsTouched();
      this.branchMasterForm.get("branchPinCode").markAsTouched();
      this.branchMasterForm.get("branchPhoneNumber").markAsTouched();
      this.branchMasterForm.get("state").markAsTouched();
      this.branchMasterForm.get("openTime").markAsTouched();
      this.branchMasterForm.get("closeTime").markAsTouched();
      return;
    } else {
      this.updateBranch();
    }
  }

  addBranch() {
    this.spinner.show();
    let payload = this.branchMasterForm.value;
    this.apimicroservice.addBranch(payload).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        // this.ngOnInit();
        this.onClose();
      } else {
        this.apimicroservice.openSnackbar(res.message);
      }
    });

  }
  openSnackbar(message: string) {
    return this._snackBar.open(message, "close", {
      verticalPosition: "bottom",
      horizontalPosition: "right",
      duration: 2000,
      panelClass: ["snackbarStyle"],
    });
  }

  addNew() {
    this.branchMasterForm.reset();
    this.updateButton = false;
    this.deleteButton = false;
  }
  updateBranch() {
    this.spinner.show();
    let payload = this.branchMasterForm.value;
    //  payload.uuid_owner = 1;
    //
    this.apimicroservice.updateBranchDetails(payload, this.vid).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.apimicroservice.openSnackbar(res.message);
        this.updateButton = false;
        this.submitButton = true;
        this.cancelButton = false;
        this.ngOnInit();
      } else {
        //  //this.apimicroservice.openSnackbar(res.message);
      }
    });

  }


  fetchClinicData() {
    this.apimicroservice.getAllClinicList().subscribe(res => {
      this.clinicList = res.data;
      this.filteredListClinic = this.clinicList.slice();
      if (res.status) {
        // this.apimicroservice.openSnackbar(res.message);

      } else {
        //this.apimicroservice.openSnackbar(res.message);
      }
    });
  }

  cancelUpdate() {
    this.branchMasterForm.reset();
    this.submitButton = true;
    this.updateButton = false;
    this.cancelButton = false;
    this.ngOnInit();
  }
  getLastId() {
    this.apimicroservice.getLastBranchID().subscribe(res => {
      //
      // if(res['data']['data'] == '')
      // {
      //   this.branchMasterForm.controls['branchId'].setValue(1);
      // }
      if (res['status']) {
        let branchIdd = res['data']['totalItems'];
        this.bId = branchIdd + 1;
        this.branchMasterForm.controls['branchId'].setValue(branchIdd + 1);
      } else {
        this.apimicroservice.openSnackbar("Something went wrong");
      }

    })
  }

  onSelectClinic(id) {
    //
  }

  redirect(to): void {
    this.router.navigate([to]);
  }

  toOpenTimeChange() {
    let payload = this.branchMasterForm.value;
    let dd = new Date();
    let a = '';
    let b = '';
    a = payload.openTime;
    b = payload.closeTime;
    //
    //

    const date1 = new Date(dd.toDateString() + " " + a);
    const date2 = new Date(dd.toDateString() + " " + b);
    if (date2 <= date1 && payload.closeTime !== '') {
      this.apimicroservice.openSnackbar("Open Time should be Less than Close Time");
      this.branchMasterForm.controls['openTime'].setValue('');
    }
  }
  toCloseTimeChange() {
    let payload = this.branchMasterForm.value;
    let dd = new Date();
    let a = '';
    let b = '';
    a = payload.openTime;
    b = payload.closeTime;
    //
    //
    // const [hh,mm,ss] = a.split(':');
    // const [hh2,mm2,ss2] = b.split(':');
    const date1 = new Date(dd.toDateString() + " " + a);
    const date2 = new Date(dd.toDateString() + " " + b);
    // + " " + a);
    //
    if (date2.getTime() <= date1.getTime() && payload.openTime !== '') {
      this.apimicroservice.openSnackbar("Close Time should be Greater than Open Time");
      this.branchMasterForm.controls['closeTime'].setValue('');
    }
  }
  copyFromClinic() {
    let pg = 1;
    let size = 5;
    let search = '';
    let clinicRef= sessionStorage.getItem('clinicRef');
    this.apimicroservice.getClinicData(pg, size, search,clinicRef).subscribe(res => {
      this.clinicData = res.data.clinicData;
      this.totalLength = res.data.totalItems;

      if (this.clinicData.length > 0) {
        this.preId = res.data.clinicData[0]._id;
        this.prepopulation2(this.preId);
      }
      else {
        this.apimicroservice.openSnackbar("Clinic Is Not Available");
      }
    });
  }

  prepopulation2(id) {
    this.apimicroservice.getClinicDataById(id).subscribe(res => {
      let data = res.data[0];
      this.branchMasterForm = this.fb.group({
        "branchId": new FormControl(),
        "clinicRefId": new FormControl(data._id, Validators.required),
        "branchPinCode": new FormControl(data.clinicPinCode, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(6), Validators.minLength(6)]),
        "branchPhoneNumber": new FormControl(data.clinicPhoneNumber, [Validators.required, Validators.pattern("^((\\+91?)|0)?[0-9]{10}$")]),
        "branchLandline": new FormControl(data.clinicLandline, Validators.pattern("[0-9]{3,4}[-][0-9]{6,8}")),
        "branchEmailId": new FormControl(data.clinicEmail, [Validators.pattern("(?![0-9]+@)[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
        "branchWeeklyOff": new FormControl(data.clinicWeeklyOff),
        "openTime": new FormControl(data.openTime, Validators.required),
        "closeTime": new FormControl(data.closeTime, Validators.required),
        "branchStreetAddress": new FormControl(data.clinicAddress, Validators.required),
        "taluka": new FormControl(data.taluka, Validators.pattern("^[a-zA-Z ]*$")),
        "district": new FormControl(data.district, Validators.pattern("^[a-zA-Z ]*$")),
        "state": new FormControl(data.state, Validators.required),
        "branchCode": new FormControl(''),
        "branchName": new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]),
        "branchType": new FormControl('', Validators.required),
        "branchStatus": new FormControl(''),
        "pincode": new FormControl('', Validators.pattern("^[0-9]{6}$")),
        "country": new FormControl('', [Validators.pattern("[A-Za-z _-]{3,19}$"),]),
        
        "createdAt": new Date(),
        "searchBranch": new FormControl(''),
      });

      //this.CId =id;
      // this.logoIcon = data.uploadLogo ? data.uploadLogo : "../../assets/main.jpeg"
    });
    this.fetchClinicData();
    this.getLastId();
    // this.removeValidator();
  }
  onClose(): void {
    this.dialogRef.close();
  }

  WhatsApp() {
    let payload = this.branchMasterForm.value;
    let num = payload.branchPhoneNumber;
    if (num.length == 10) {
      sessionStorage.setItem('whatsAppNumber', payload.branchPhoneNumber);
      let mobileNumber = sessionStorage.getItem('whatsAppNumber');
      window.open("https://wa.me/91" + mobileNumber);
    }
    else {
      this.branchMasterForm.get("branchPhoneNumber").markAsTouched();
    }
  }
}