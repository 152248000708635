import { Component, Inject, OnInit } from "@angular/core";
import {FormGroup,FormBuilder,FormControl, Validators} from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-popup-brand',
  templateUrl: './popup-brand.component.html',
  styleUrls: ['./popup-brand.component.scss']
})
export class PopupBrandComponent implements OnInit {
  complaintId:any;
  popupBrandForm: FormGroup;
  variables3: any;
  filteredList1: any;
  filteredList2: any;
  variables4: any;
  brandData: any;
  submitButton = true;
  updateButton = false;
  cancelButton = false;
  userType: string;
  listOfBrand: any;
  totalLength: any;
  searchText: any;
  page:number = 1;
  size:number = 3;
  bid: any;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<PopupBrandComponent>,@Inject(MAT_DIALOG_DATA) public data:any, 
    public apimicroservice: ApiServiceService,
) { dialogRef.disableClose = true;}
 addNewComplaint = false;
  ngOnInit() {
    this.page =1;
    this.userType = sessionStorage.getItem('userType');
    this.popupBrandForm = this.fb.group({  
      "brandName": new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z0-9 -]+$")]),
      "createdAt": new Date(),
      "searchBrand": new FormControl(''),
      
    });
    this.fetchBrandList();
  }
  fetchBrandList() {
    let pg = 1;
    let size = 3;
    let search='';
    
      if (this.userType !== 'Super Admin') {
        this.apimicroservice.getBrandData(pg,size,search).subscribe(res => {
           this.listOfBrand = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
            //this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });   
      } else {

        this.apimicroservice.getBrandData(pg,size,search).subscribe(res => {
           this.listOfBrand = res.data.data;
           this.totalLength = res.data.totalItems;
           //this.buildingLenght = this.listofbuild.length;
          if(res.status){
           // this.apimicroservice.openSnackbar(res.message);
            
          }else{
            //this.apimicroservice.openSnackbar(res.message);
          }
     
        });
        
      }

  }
  onPageChange(event) {
    this.page = event;
   // let p = this.page - 1;
    this.searchText = this.popupBrandForm.get('searchBrand').value;
 
      this.apimicroservice.getBrandData(this.page,this.size,this.searchText).subscribe(res => {
        this.listOfBrand = res.data.data;
        this.totalLength = res.data.totalItems;
         if(res.status){
          //  this.apimicroservice.openSnackbar(res.message);
           
         }else{
           //this.apimicroservice.openSnackbar(res.message);
         }
    
       });
  
  }
  onKeyUpEvent(event: any) {
    this.searchText = this.popupBrandForm.get('searchBrand').value;
    this.apimicroservice.getBrandData(this.page,this.size,this.searchText).subscribe(res => {
      this.listOfBrand = res.data.data;
      this.totalLength = res.data.totalItems;
       if(res.status){
        //  this.apimicroservice.openSnackbar(res.message);    
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }

  prepopulation(id) {
    this.apimicroservice.getBrandDataById(id).subscribe(res => {
      let data = res.data[0];
      this.bid = id;
       if(res.status){
        this.submitButton = false;
         this.updateButton = true;
         this.cancelButton = true;
         this.popupBrandForm = this.fb.group({
          "brandName" :new FormControl(data.brandName),
         });
       }else{
     //    //this.apimicroservice.openSnackbar(res.message);
       }
     });
  }
  saveBrand(){
    let payload = this.popupBrandForm.value; 
    //
    
    this.apimicroservice.submitBrandData(payload).subscribe(res => {
      //
      
      if(res.status){
        this.apimicroservice.openSnackbar(res.message);      
        this.ngOnInit();
       this.onClose();        
      }else if(res.status == false){       
        this.apimicroservice.openSnackbar(res.message);
      }
    },(ERROR:HttpErrorResponse) => {
      this.apimicroservice.openSnackbar(ERROR.error.message);
  });
  } 
  save() {
    if (this.popupBrandForm.invalid) {
      this.popupBrandForm.get("brandName").markAsTouched();
      return;
    } else {
      this.saveBrand();
    }
  }
  getLeftComplaintData(){
    // this.apimicroservice.getLeftComplaintAllData().subscribe(res => {
    //   //
    //   this.variables3 = res.data;
    //   this.filteredList1 = this.variables3.slice();
    // });
    
  }
  
  onClose(): void {
    this.dialogRef.close();
  }
  addNewComplaintFunc()
  {
    this.addNewComplaint = true;
  }
  cancelFunc()
  {
    this.addNewComplaint = false;
  }
  updateBrand() {
    let payload = this.popupBrandForm.value;
     this.apimicroservice.updateBrandDetails(payload,this.bid).subscribe(res => {
       if(res.status){
         this.apimicroservice.openSnackbar(res.message);
          this.updateButton = false;
          this.submitButton = true;
          this.ngOnInit();
       }else{
         //this.apimicroservice.openSnackbar(res.message);
       }
     });
    
  }
  cancelUpdate(){
    this.popupBrandForm.reset();
    this.submitButton=true;
    this.updateButton=false;
    this.cancelButton=false;
    this.ngOnInit();
  }
} 